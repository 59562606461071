<template>
  <div>
    <loader v-if="isLoading" />
  </div>
</template>

<script>
import loader from "@/components/shared/loader.vue";

export default {
  components: {
    loader,
  },
  data() {
    return {
      isLoading: true,
    };
  },
  created() {
    this.$router.push({
      name: "AnunciosBusqueda",
    });
  },
};
</script>

<style></style>
