import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/assets/css/tailwind.css";
import Vuelidate from "vuelidate";
import VueFormulate from "@braid/vue-formulate";
import { es } from "@braid/vue-formulate-i18n";
import VueMeta from "vue-meta";
import VueGtag from "vue-gtag";
import ReadMore from "vue-read-more";
import Notifications from "vt-notifications";
import VueFileAgent from "vue-file-agent";
import InstantSearch from "vue-instantsearch";

Vue.use(ReadMore);
Vue.use(Notifications);
Vue.use(Vuelidate);
Vue.use(VueFormulate, {
  plugins: [es],
});
Vue.use(VueMeta);
Vue.use(
  VueGtag,
  {
    // config: { id: "347656082" }
    config: { id: "G-BWTYHPQ97X" },
  },
  router
);
Vue.config.productionTip = false;
Vue.use(VueFileAgent);
Vue.use(InstantSearch);
new Vue({
  router,
  render: (h) => h(App),
  store,
}).$mount("#app");
