<template>
  <div class="w-full">
    <div class="shadow-md w-full relative rounded-lg">
      <PostuladosLandEmp></PostuladosLandEmp>
    </div>
  </div>
</template>
<script>
import PostuladosLandEmp from "@/views/UI/PostuladosLandEmp.vue";

export default {
  name: "PostuladosTablaAnalista",

  components: {
    PostuladosLandEmp,
  },
  data() {
    return {};
  },
};
</script>
