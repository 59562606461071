<template>
  <div>
    <div class="h-full shadow-xl top-40">
      <button
        @click="isOpen = !isOpen"
        class="fixed bottom-20 right-5 z-40 p-3 rounded-full bg-white md:hidden border-2 border_postulados"
      >
        <!-- SVG icon -->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>
      </button>
      <div
        :class="{
          'w-64': sidebarOpen,
          'w-16': !sidebarOpen,
          'left-0': isOpen,
          '-left-full': !isOpen,
        }"
        class="bg-white h-full dark:bg-gray-700 transition-all duration-300 fixed md:relative md:block top-0 md:left-0 z-50 md:z-0"
      >
        <div class="flex items-center justify-evenly pt-6">
          <transition name="fade" mode="out-in">
            <p
              class="font-bold text-gray-800 text-xl mr-12 transition duration-500"
              :class="{ hidden: !sidebarOpen }"
            >
              Menu
            </p>
          </transition>
          <div class="ml-4">
            <button @click="toggleSidebar">
              <svg
                v-if="!sidebarOpen"
                width="16"
                height="16"
                fill="currentColor"
                viewBox="0 0 512 512"
                xmlns="http://www.w3.org/2000/svg"
                class="mr-4 h-6 w-auto fd transform relative"
              >
                <path
                  d="M256 8c137 0 248 111 248 248S393 504 256 504 8 393 8 256 119 8 256 8zM140 300h116v70.9c0 10.7 13 16.1 20.5 8.5l114.3-114.9c4.7-4.7 4.7-12.2 0-16.9l-114.3-115c-7.6-7.6-20.5-2.2-20.5 8.5V212H140c-6.6 0-12 5.4-12 12v64c0 6.6 5.4 12 12 12z"
                />
              </svg>

              <svg
                v-else
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="#134992"
                class="w-6 h-6"
                @click="closeMenu"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
        <nav class="mt-6">
          <div>
            <router-link to="/personLandin">
              <a
                class="w-full cursor-default text-gray-400 hover:text-gray-800 dark:text-white flex items-center px-auto p-4 my-2 transition-colors duration- justify-start relative"
                :class="{
                  'border-l-4 border_postulados':
                    $route.path === '/personLandin',
                }"
                title="Inicio"
              >
                <span class="text-left text-gray-800">
                  <svg
                    width="20"
                    height="20"
                    fill="currentColor"
                    viewBox="0 0 1792 1792"
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-auto"
                  >
                    <path
                      d="M1472 992v480q0 26-19 45t-45 19h-384v-384h-256v384h-384q-26 0-45-19t-19-45v-480q0-1 .5-3t.5-3l575-474 575 474q1 2 1 6zm223-69l-62 74q-8 9-21 11h-3q-13 0-21-7l-692-577-692 577q-12 8-24 7-13-2-21-11l-62-74q-8-10-7-23.5t11-21.5l719-599q32-26 76-26t76 26l244 204v-195q0-14 9-23t23-9h192q14 0 23 9t9 23v408l219 182q10 8 11 21.5t-7 23.5z"
                    ></path>
                  </svg>
                </span>
                <span
                  class="mx-4 text-sm font-semibold transition-all duration-700"
                  :class="{ hidden: !sidebarOpen }"
                >
                  Inicio
                </span>
              </a>
            </router-link>

            <router-link to="/personPerfil">
              <a
                class="w-full cursor-default text-gray-400 hover:text-gray-800 dark:text-white flex items-center px-auto p-4 my-2 transition-colors duration- justify-start relative"
                :class="{
                  'border-l-4 border_postulados':
                    $route.path === '/personPerfil',
                }"
                title="Mis anuncios"

              >
                <span class="text-left">
                  <img class="w-6 h-auto cursor-pointer" src="./img/ads.svg" />
                </span>

                <span
                  class="mx-4 text-sm font-semibold transition-all duration-700"
                  :class="{ hidden: !sidebarOpen }"
                >
                  Mis anuncios
                  <span
                    class="hidden p-1 ml-4 rounded-lg w-4 h-2 bg-gray-200 text-gray-400 text-xs"
                  >
                    0
                  </span>
                </span>
              </a>
            </router-link>

            <router-link to="/datoscv">
              <a
                class="w-full cursor-default text-gray-400 hover:text-gray-800 dark:text-white flex items-center px-auto p-4 my-2 transition-colors duration- justify-start relative"
                :class="{
                  'border-l-4 border_postulados': $route.path === '/datoscv',
                }"
                title="Mis Datos"
              >
                <span class="text-left">
                  <img
                    class="w-6 h-auto cursor-pointer"
                    src="./img/postulPerson.svg"
                  />
                </span>
                <span
                  class="mx-4 text-sm font-semibold transition-all duration-700"
                  :class="{ hidden: !sidebarOpen }"
                >
                  Mis datos
                  <span
                    class="hidden p-1 ml-4 rounded-lg w-4 h-2 bg-gray-200 text-gray-400 text-xs"
                  >
                    0
                  </span>
                </span>
              </a>
            </router-link>

            <router-link to="/personDatos">
              <a
                class="w-full cursor-default text-gray-400 flex items-center px-auto p-4 my-2 transition-colors duration-200 justify-start hover:text-gray-800"
                :class="{
                  'border-l-4 border_postulados':
                    $route.path === '/personDatos',
                }"
                title="Consultar"
              >
                <span class="text-left">
                  <img
                    class="w-6 h-auto cursor-pointer"
                    src="./img/consulta.svg"
                  />
                </span>
                <span
                  class="mx-4 text-sm font-semibold transition-all duration-700"
                  :class="{ hidden: !sidebarOpen }"
                >
                  Consultar
                </span>
              </a>
            </router-link>

            <router-link to="/notificaciones">
              <a
              class="w-full cursor-default text-gray-400 flex items-center px-auto p-2 my-2 transition-colors duration-200 justify-start hover:text-gray-800"
                :class="{
                  'border-l-4 border_postulados':
                    $route.path === '/notificaciones',
                }"
                title="Mis alertas"
              >
                <span class="text-left">
                  <div class="relative m-0 inline-flex w-fit">
                    <div
                      class="absolute bottom-auto left-auto right-0 top-0 z-10 inline-block -translate-y-1/2 translate-x-2/4 rotate-0 skew-x-0 skew-y-0 scale-x-100 scale-y-100 rounded-full bg-red-600 p-1 text-xs"
                    ></div>
                    <div
                      class="flex items-center justify-center rounded-lg bg-primary-500 px-2 py-1 text-center text-white dark:text-gray-200 cursor-pointer"
                    >
                      <svg
                        class="w-6 h-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 20 21"
                      >
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="m9.046 3.59-.435-2.324m.435 2.324a5.338 5.338 0 0 1 6.033 4.333l.331 1.77c.439 2.344 2.383 2.587 2.599 3.76.11.586.22 1.171-.309 1.271L5 17.101c-.529.1-.639-.488-.749-1.074-.219-1.172 1.506-2.102 1.067-4.447l-.331-1.769a5.338 5.338 0 0 1 4.059-6.22Zm-7.13 4.602a8.472 8.472 0 0 1 2.17-5.048m2.646 13.633A3.472 3.472 0 0 0 13.46 16l.089-.5-6.817 1.277Z"
                        />
                      </svg>
                    </div>
                  </div>
                </span>
                <span
                  class="mx-4 text-sm font-semibold transition-all duration-700"
                  :class="{ hidden: !sidebarOpen }"
                >
                  Mis alertas
                </span>
              </a>
            </router-link>

            <router-link class="hidden" to="/personDatos">
              <a
                class="w-full text-gray-400 flex items-center pl-6 p-2 my-2 transition-colors duration-200 justify-start hover:text-gray-800 border-l-4 border-transparent"
                href="#"
              >
                <span class="text-left">
                  <svg
                    width="20"
                    height="20"
                    fill="currentColor"
                    viewBox="0 0 2048 1792"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M580 461q0-41-25-66t-66-25q-43 0-76 25.5t-33 65.5q0 39 33 64.5t76 25.5q41 0 66-24.5t25-65.5zm743 507q0-28-25.5-50t-65.5-22q-27 0-49.5 22.5t-22.5 49.5q0 28 22.5 50.5t49.5 22.5q40 0 65.5-22t25.5-51zm-236-507q0-41-24.5-66t-65.5-25q-43 0-76 25.5t-33 65.5q0 39 33 64.5t76 25.5q41 0 65.5-24.5t24.5-65.5zm635 507q0-28-26-50t-65-22q-27 0-49.5 22.5t-22.5 49.5q0 28 22.5 50.5t49.5 22.5q39 0 65-22t26-51zm-266-397q-31-4-70-4-169 0-311 77t-223.5 208.5-81.5 287.5q0 78 23 152-35 3-68 3-26 0-50-1.5t-55-6.5-44.5-7-54.5-10.5-50-10.5l-253 127 72-218q-290-203-290-490 0-169 97.5-311t264-223.5 363.5-81.5q176 0 332.5 66t262 182.5 136.5 260.5zm592 561q0 117-68.5 223.5t-185.5 193.5l55 181-199-109q-150 37-218 37-169 0-311-70.5t-223.5-191.5-81.5-264 81.5-264 223.5-191.5 311-70.5q161 0 303 70.5t227.5 192 85.5 263.5z"
                    ></path>
                  </svg>
                </span>
                <span
                  class="mx-4 text-sm font-normal transition-all duration-700"
                  :class="{ hidden: !sidebarOpen }"
                >
                  Datos registrados
                </span>
              </a>
            </router-link>
          </div>
        </nav>
      </div>
    </div>
    <div
      v-if="isOpen"
      @click="closeMenu"
      class="fixed z-30 inset-0 bg-black bg-opacity-50 bd-blur"
    ></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      mostrarTexto: false,
      sidebarOpen: false,
      showImage: false,
      isOpen: false,
      aplicarClaseMenu: false,
    };
  },
  methods: {
    closeMenu() {
      this.isOpen = false;
      this.mostrarTexto = !this.mostrarTexto;
    },
    toggleSidebar() {
      this.sidebarOpen = !this.sidebarOpen;
      // Puedes agregar aquí cualquier lógica adicional que necesites cuando se hace clic en el botón de toggleSidebar.
    },
    toggleSidebarAndCloseMenu() {
      this.closeMenu(); // Llama al método closeMenu
    },
  },
};
</script>

<style scoped>
.filter-post {
  filter: invert(80%) sepia(0%) saturate(0%) hue-rotate(146deg) brightness(93%)
    contrast(81%);
}
.filter-hover:hover {
  filter: invert(8%) sepia(21%) saturate(24%) hue-rotate(100deg)
    brightness(104%) contrast(95%);
}
.border_postulados {
  border-color: #134992;
}
.fd {
  color: #134992;
}
.bd-blur {
  backdrop-filter: blur(8px);
}
</style>

