<template>
  <div id="root" class="w-12/12 h-3/5">
    <ais-instant-search
      :search-client="searchClient"
      index-name="dev_algoliadev"
      :routing="routing"
      :insights="true"
    >
      <ais-configure
        :attributes-to-snippet.camel="['description:10']"
        :snippet-ellipsis-text.camel="'…'"
        :remove-words-if-no-result.camel="'allOptional'"
      />

      <header
        class="relative header w-full max-w-full mx-auto flex justify-center items-center"
        id="header"
      >
        <ais-search-box
          placeholder="Cargo o área …"
          class="max-w-md mx-auto mt-36"
          :class-names="object"
        >
          <template #submit-icon>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 18 18"
              width="16"
              height="16"
            >
              <g
                fill="none"
                fill-rule="evenodd"
                stroke="#134892"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.67"
                transform="translate(1 1)"
              >
                <circle cx="7.11" cy="7.11" r="7.11" />
                <path d="M16 16l-3.87-3.87" />
              </g>
            </svg>
          </template>
        </ais-search-box>
      </header>
      <div class="bg-white">
        <a
          href="https://www.algolia.com/es/policies/acceptable-use/"
          aria-label="Go to the Algolia website"
        >
          <div class="flex flex-row content-center justify-center">
            <div class="mr-2 mt-2">
              <p class="normal-case text-blue-900">Búsqueda patrocinada por:</p>
            </div>
            <div class="my-1 mr-2">
              <img
                :src="require('@/components/img/Algolia-logo-blue.png')"
                alt="Logo algolia"
                class="h-4"
              />
            </div>
          </div>
        </a>
      </div>

      <main class="container bg-gray-50 w-full max-w-full mx-auto p-0">
        <div class="container-wrapper">
          <section class="container-filters m-0 lg:mx-3">
            <div class="container-header">
              <h2>Filtros</h2>

              <ais-clear-refinements data-layout="desktop">
                <template #resetLabel>
                  <div class="clear-filters">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="10"
                      viewBox="0 0 11 11"
                    >
                      <g fill="none" fill-rule="evenodd" opacity=".4">
                        <path d="M0 0h11v11H0z" />
                        <path
                          fill="#000"
                          fill-rule="nonzero"
                          d="M8.26 2.75a3.896 3.896 0 1 0 1.102 3.262l.007-.056a.49.49 0 0 1 .485-.456c.253 0 .451.206.437.457 0 0 .012-.109-.006.061a4.813 4.813 0 1 1-1.348-3.887v-.987a.458.458 0 1 1 .917.002v2.062a.459.459 0 0 1-.459.459H7.334a.458.458 0 1 1-.002-.917h.928z"
                        />
                      </g>
                    </svg>
                    Limpiar filtros
                  </div>
                </template>
              </ais-clear-refinements>

              <ais-stats data-layout="mobile">
                <template #default="{ nbHits }">
                  <span class="ais-Stats-text">
                    <strong>{{ formatNumber(nbHits) }}</strong> resultados
                  </span>
                </template>
              </ais-stats>
            </div>

            <div class="container-body">
              <ais-panel>
                <template #header> Área </template>
                <template #default>
                  <ais-refinement-list
                    attribute="area"
                    searchable
                    searchable-placeholder="Buscar áreas…"
                  />
                </template>
              </ais-panel>
            </div>
          </section>

          <footer class="container-filters-footer" data-layout="mobile">
            <clear-refinements
              class="container-filters-footer-button-wrapper"
              @click="closeFilters"
            />

            <ais-stats class="container-filters-footer-button-wrapper">
              <template #default="{ nbHits }">
                <button class="button button-primary" @click="closeFilters">
                  Ver {{ formatNumber(nbHits) }} resultados
                </button>
              </template>
            </ais-stats>
          </footer>
        </div>

        <section class="container-results">
          <header class="container-header container-options">
            <ais-sort-by
              class="container-option hidden"
              :items="[{ value: 'desc(createdAt)', label: 'Más recientes' }]"
            />

            <ais-hits-per-page
              class="container-option"
              :items="[
                {
                  label: '16 resultados por página',
                  value: 16,
                  default: true,
                },
                {
                  label: '32 resultados por página',
                  value: 32,
                },
                {
                  label: '64 resultados por página',
                  value: 64,
                },
              ]"
            />
          </header>

          <ais-hits>
            <template #item="{ item }">
              <article class="hit">
                <div class="w-full max-w-lg h-96">
                  <anuncioResultsAlgolia
                    :assets="item"
                    :key="item.id"
                    class=""
                  />
                </div>
              </article>
            </template>
          </ais-hits>

          <no-results />

          <footer class="container-footer">
            <div class="flex justify-center">
              <ais-pagination :padding="2">
                <template
                  #default="{
                    currentRefinement,
                    pages,
                    isFirstPage,
                    isLastPage,
                    refine,
                  }"
                >
                  <nav aria-label="Pagination Navigation">
                    <ul class="flex space-x-2 p-18">
                      <li v-if="!isFirstPage" class="page-item">
                        <a
                          class="px-4 py-2 border postula2Primary rounded-md"
                          href="#"
                          @click.prevent="refine(currentRefinement - 1)"
                          aria-label="Previous"
                        >
                          <span aria-hidden="true">&laquo;</span>
                        </a>
                      </li>
                      <li v-for="page in pages" :key="page" class="page-item">
                        <a
                          class="px-4 py-2 border postula2Primary rounded-md"
                          :class="{
                            'bg-colorPost': currentRefinement === page,
                            'text-white': currentRefinement === page,
                          }"
                          href="#"
                          @click.prevent="refine(page)"
                          :aria-label="`Page ${page + 1}`"
                        >
                          {{ page + 1 }}
                        </a>
                      </li>
                      <li v-if="!isLastPage" class="page-item">
                        <a
                          class="px-4 py-2 border postula2Primary rounded-md"
                          href="#"
                          @click.prevent="refine(currentRefinement + 1)"
                          aria-label="Next"
                        >
                          <span aria-hidden="true">&raquo;</span>
                        </a>
                      </li>
                    </ul>
                  </nav>
                </template>
              </ais-pagination>
            </div>
          </footer>
        </section>
      </main>

      <aside data-layout="mobile">
        <button class="filters-button" @click="openFilters">
          <svg xmlns="http://www.w3.org/2000/svg" viewbox="0 0 16 14">
            <path
              d="M15 1H1l5.6 6.3v4.37L9.4 13V7.3z"
              stroke="#fff"
              stroke-width="1.29"
              fill="none"
              fill-rule="evenodd"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          Filtros
        </button>
      </aside>
    </ais-instant-search>
  </div>
</template>

<script>
import algoliasearch from "algoliasearch";
import cx from "classnames";
import getRouting from "@/components/layouts/searchbar/js/routing";
import { formatNumber } from "@/components/layouts/searchbar/js/utils";
import ClearRefinements from "@/components/layouts/searchbar/js/widgets/ClearRefinements.vue";
import NoResults from "@/components/layouts/searchbar/js/widgets/NoResults.vue";
import anuncioResultsAlgolia from "@/components/layouts/searchbar/anuncioResultsAlgolia.vue";

//import "@/components/layouts/searchbar/css/theme.css";
import "@/components/layouts/searchbar/css/app.css";
import "@/components/layouts/searchbar/css/app.mobile.css";
import "@/components/layouts/searchbar/js/widgets/PriceSlider.css";

export default {
  components: {
    anuncioResultsAlgolia,
    ClearRefinements,
    NoResults,
  },
  created() {
    this.onKeyUp = (event) => {
      if (event.key !== "Escape") {
        return;
      }
      this.closeFilters();
    };

    this.onClick = (event) => {
      if (event.target !== this.header) {
        return;
      }

      this.closeFilters();
    };
  },
  mounted() {
    this.resultsContainer = document.querySelector(".container-results");
    this.header = document.querySelector("#header");
  },
  data() {
    return {
      cx,
      searchClient: algoliasearch(
        "T5G5WB5OT5",
        "3ba9f61c83c25c5f78483f6ac385c855"
      ),
      routing: getRouting({ indexName: "instant_search" }),
    };
  },
  methods: {
    formatNumber,
    toValue(value, range) {
      return [
        typeof value.min === "number" ? value.min : range.min,
        typeof value.max === "number" ? value.max : range.max,
      ];
    },
    openFilters() {
      document.body.classList.add("filtering");
      window.scrollTo(0, 0);
      window.addEventListener("keyup", this.onKeyUp);
      window.addEventListener("click", this.onClick);
    },
    closeFilters() {
      document.body.classList.remove("filtering");
      this.resultsContainer.scrollIntoView();
      window.removeEventListener("keyup", this.onKeyUp);
      window.removeEventListener("click", this.onClick);
    },
    transformAreaItems(items) {
      return items.filter(
        (item) => item.label === "Opción 1" || item.label === "Opción 2"
      );
    },
    updateFacetConfig() {
      const client = algoliasearch(
        "T5G5WB5OT5",
        "3ba9f61c83c25c5f78483f6ac385c855"
      );
      const index = client.initIndex("dev_algoliadev");

      index.getSettings().then((settings) => {
        const attributesForFaceting = settings.attributesForFaceting || [];
        if (!attributesForFaceting.includes("area")) {
          attributesForFaceting.push("area");

          index
            .setSettings({
              attributesForFaceting: [
                "searchable(area)",
                "searchable(jerarquia)",
                "searchable(modalidtrabj)",
                "searchable(tipoempleo)",
                "searchable(tipoAnunc)",
                "searchable(ofertsalarl)",
              ],
              ranking: ["desc(createdAt)"],
            })
            .then(() => {
              // console.log("Facet configuration updated");
            })
            .catch((error) => {
              console.error("Error updating facet configuration", error);
            });
          index
            .search({
              page: 0,
              hitsPerPage: 5,
            })
            .then(({ hits }) => {
              console.log(hits);
            });
        }
      });
    },
  },
};
</script>

<style>
.postula2Primary {
  border-color: #134992;
}

.bg-colorPost {
  background-color: #134992;
}




/* Global */

[class^='ais-'] {
    box-sizing: border-box;
    font-size: 0.6rem;
    font-weight: 400;
    text-align: left;
  }
  
  a[class^='ais-'] {
    color: #21243d;
    text-decoration: none;
    
  }
  
  /*
    We need to target the root element because Angular InstantSearch
    creates web components which are not targetable with the `*` selector.
  */
  [class^='ais-'][class$='--disabled'],
  /*
    We need to target all elements for widgets containing
    multiple sub elements (e.g. RangeSlider)
  */
  [class^='ais-'][class$='--disabled'] * {
    cursor: not-allowed;
  }
  
  .ais-Breadcrumb,
  .ais-ClearRefinements,
  .ais-CurrentRefinements,
  .ais-ClearRefinements-button,
  .ais-GeoSearch,
  .ais-HierarchicalMenu,
  .ais-Hits,
  .ais-Results,
  .ais-HitsPerPage,
  .ais-ResultsPerPage,
  .ais-InfiniteHits,
  .ais-InfiniteResults,
  .ais-Menu,
  .ais-MenuSelect,
  .ais-NumericMenu,
  .ais-NumericSelector,
  .ais-Pagination,
  .ais-Panel,
  .ais-PoweredBy,
  .ais-RangeInput,
  .ais-RangeSlider,
  .ais-RatingMenu,
  .ais-RefinementList,
  .ais-SearchBox,
  .ais-SortBy,
  .ais-SortBy-select,
  .ais-HitsPerPage-select,
  .ais-Stats,
  .ais-ToggleRefinement {
    color: #21243d;
    font-size: 0.75rem;
  }
  
  /* Highlighting */
  
  .ais-Highlight-highlighted,
  .ais-Snippet-highlighted {
    background: rgba(226, 164, 0, 0.4);
  }
  
  /* Hits */
  
  .ais-Hits-list {
    display: grid;
    grid-gap: 8px;
    grid-template-columns: 1fr;
  }
  
  @media (min-width: 680px) {
    .ais-Hits-list {
      grid-template-columns: 1fr;
    }
  }
  
  @media (min-width: 768px) {
    .ais-Hits-list {
      grid-template-columns: 1fr 1fr;
    }
  }
  
  @media (min-width: 1260px) {
    .ais-Hits-list {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  
  
  /* Menus */
  
  .ais-RefinementList-item,
  .ais-Menu-item,
  .ais-HierarchicalMenu-item,
  .ais-RatingMenu-item {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  .ais-RefinementList-item,
  /*
   The refinement list item in InstantSearch.js contains a wrapping `div` because of
   the template behavior. We therefore need to apply the styles to all the elements
   in a refinement list.
  */
  .ais-RefinementList-item *,
  .ais-RatingMenu-item {
    cursor: pointer;
  }
  
  .ais-HierarchicalMenu-link,
  .ais-RatingMenu-item,
  .ais-RefinementList-item {
    padding-bottom: 1rem;
  }
  
  .ais-Breadcrumb-item--selected,
  .ais-HierarchicalMenu-item--selected,
  .ais-Menu-item--selected {
    font-weight: bold;
  }
  
  .ais-RatingMenu-starIcon--full {
    fill: #e2a400;
  }
  
  .ais-RatingMenu-starIcon--empty {
    fill: rgba(0, 0, 0, 0.08);
  }
  
  /* Panel */
  
  .ais-Panel--collapsible {
    position: relative;
  }
  
  .ais-Panel--collapsed .ais-Panel-body,
  .ais-Panel--collapsed .ais-Panel-footer {
    display: none;
  }
  
  .ais-Panel-collapseButton {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    position: absolute;
    right: 0;
  }
  
  .ais-Panel-header {
    border: none;
    color: #21243d;
    font-size: 0.678rem;
    font-weight: 600;
    letter-spacing: 0.08rem;
    line-height: 1.6;
    padding-bottom: 1rem;
    text-transform: uppercase;
  }
  
  /* Search box */

  @media (max-width: 680px) {
    .ais-SearchBox-form {
     position: relative;
    }
  }
  
  .ais-SearchBox-form {
    position: relative;
  }

 
  
  .ais-SearchBox-input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: rgba(65, 66, 71, 0.06);
    border: none;
    border-radius: 3px;
    color: rgba(33, 36, 61, 0.8);
    font-family: inherit;
    min-height: 54px;
    outline: none;
    padding-left: 56px;
    width: 100%;
  }
  @media (max-width: 680px) {
    .ais-SearchBox-input{
      padding-left: 0;
    }
  }
  
  .ais-SearchBox-input::placeholder {
    color: rgba(33, 36, 61, 0.5);
    opacity: 1; /* Firefox */
  }
  
  .ais-SearchBox-input:-ms-input-placeholder {
    color: rgba(33, 36, 61, 0.5);
  }
  
  .ais-SearchBox-input::-ms-input-placeholder {
    color: rgba(33, 36, 61, 0.5);
  }
  
  .ais-SearchBox-reset,
  .ais-SearchBox-loadingIndicator,
  .ais-SearchBox-submit {
    align-items: center;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: flex;
    height: 100%;
    justify-content: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 48px;
  }
  
  .ais-SearchBox-reset[hidden],
  .ais-SearchBox-loadingIndicator[hidden] {
    display: none;
  }
  .ais-SearchBox-reset{
    display: none;
  }
  
  
  .ais-SearchBox-submit {
    left: 0;
  }
  
  .ais-SearchBox-reset,
  .ais-SearchBox-loadingIndicator {
    right: 0;
  }
  
  .ais-SearchBox-resetIcon {
    width: 10px;
    height: 10px;
  }
  
  /* SFFV search box */
  
  .ais-RefinementList .ais-SearchBox-input {
    border-radius: 3px;
    color: rgba(33, 36, 61, 0.8);
    font-size: 0.8rem;
    min-height: 40px;
    padding: 0 44px;
  }
  
  .ais-RefinementList .ais-SearchBox-form {
    margin-bottom: 1rem;
  }
  
  /* Menus */
  
  .ais-HierarchicalMenu-link,
  .ais-RatingMenu-link,
  .ais-RefinementList-label {
    align-items: center;
    display: flex;
  }
  
  .ais-RefinementList-checkbox {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: rgba(65, 66, 71, 0.08);
    border: none;
    border-radius: 2px;
    height: 1rem;
    margin: 0;
    margin-right: 1rem;
    position: relative;
    width: 1rem;
  }
  
  .ais-RefinementList-item--selected {
    font-weight: bold;
  }
  
  .ais-RefinementList-item--selected .ais-RefinementList-checkbox {
    background-color: #134992;
  }
  
  .ais-RefinementList-item--selected .ais-RefinementList-checkbox::after {
    background-color: #fff;
    border-radius: 4px;
    content: '';
    height: 4px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translateX(-2px) translateY(-2px);
    width: 4px;
  }
  
  .ais-HierarchicalMenu-count,
  .ais-Menu-count,
  .ais-RefinementList-count,
  .ais-ToggleRefinement-count,
  .ais-RatingMenu-count {
    align-items: center;
    background-color: rgba(65, 66, 71, 0.08);
    border-radius: 4px;
    color: rgba(33, 36, 61, 0.8);
    display: flex;
    font-size: 0.64rem;
    font-weight: 600;
    letter-spacing: 1.1px;
    margin-left: 8px;
    padding: 0 4px;
  }
  
  .ais-HierarchicalMenu-showMore,
  .ais-Menu-showMore,
  .ais-RefinementList-showMore {
    margin-top: 1rem;
  }
  
  .ais-HierarchicalMenu-list {
    font-weight: normal;
  }
  
  .ais-HierarchicalMenu-link::before {
    align-items: center;
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3E%3Cpath fill='%2390919E' fill-rule='nonzero' d='M0 4l4-4 4 4z'/%3E%3C/svg%3E%0A");
    display: flex;
    height: 8px;
    justify-content: center;
    margin-right: 1rem;
    width: 8px;
  }
  
  .ais-HierarchicalMenu-item--selected .ais-HierarchicalMenu-link::before {
    transform: rotate(180deg);
  }
  
  .ais-HierarchicalMenu-item--selected
    .ais-HierarchicalMenu-item:not(.ais-HierarchicalMenu-item--selected)
    .ais-HierarchicalMenu-link::before {
    transform: rotate(0);
  }
  
  /* ClearRefinements */
  
  .ais-ClearRefinements,
  .ais-ClearRefinements-button {
    color: rgba(33, 36, 61, 0.7);
  }
  
  .ais-ClearRefinements-button--disabled {
    color: rgba(33, 36, 61, 0.5);
  }
  
  /* ToggleRefinement */
  
  .ais-ToggleRefinement-label {
    cursor: pointer;
    display: flex;
  }
  
  .ais-ToggleRefinement-checkbox {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: rgba(65, 66, 71, 0.08);
    border: none;
    border-radius: 8px;
    cursor: pointer;
    height: 16px;
    margin-right: 16px;
    min-width: 30px;
    transition: background 150ms ease-out;
  }
  
  .ais-ToggleRefinement-checkbox:checked {
    background: #134992;
  }
  
  .ais-ToggleRefinement-checkbox::after {
    background-image: linear-gradient(to top, #f5f5fa, #fff);
    border-radius: 100%;
    box-shadow: 0 4px 11px 0 rgba(37, 44, 97, 0.15),
      0 2px 3px 0 rgba(93, 100, 148, 0.2);
    content: '';
    height: 16px;
    position: absolute;
    transition: transform 150ms ease-out;
    width: 16px;
  }
  
  .ais-ToggleRefinement-checkbox:checked::after {
    transform: translateX(100%);
  }
  
  /* Selectors */
  
  .ais-SortBy,
  .ais-HitsPerPage {
    position: relative;
  }
  
  .ais-SortBy::after,
  .ais-HitsPerPage::after {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='7' viewBox='0 0 12 7'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M14-5v16H-2V-5z'/%3E%3Cpath fill='%23000' stroke='%23FFF' stroke-width='.5' d='M2.228 1.332a.664.664 0 0 0-.942.001.665.665 0 0 0-.002.941l4.247 4.247c.259.26.679.26.938 0l4.247-4.247a.664.664 0 0 0-.002-.94.666.666 0 0 0-.942-.002L6 5.105 2.228 1.332z'/%3E%3C/g%3E%3C/svg%3E%0A");
    display: inline-block;
  }
  
  .ais-SortBy-select,
  .ais-HitsPerPage-select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: none;
    border: none;
    color: #21243d;
    font-family: inherit;
  }
  
  /* Sliders */
  
  .ais-RangeSlider .rheostat-horizontal {
    cursor: pointer;
    width: calc(100% - 10px);
  }
  
  .ais-RangeSlider .rheostat-background {
    background-color: rgba(65, 66, 71, 0.08);
    border: none;
    border-radius: 3px;
    height: 3px;
  }
  
  .ais-RangeSlider .rheostat-progress {
    background-color: #e2a400;
    border-radius: 3px;
    height: 3px;
    top: 0;
  }
  
  .ais-RangeSlider .rheostat-tooltip {
    font-weight: bold;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  .ais-RangeSlider .rheostat-handle {
    background-image: linear-gradient(to top, #f5f5fa, #fff);
    border: none;
    box-shadow: 0 4px 11px 0 rgba(37, 44, 97, 0.15),
      0 2px 3px 0 rgba(93, 100, 148, 0.2);
    margin-left: -5px;
    top: -9px;
  }
  
  .ais-RangeSlider .rheostat-marker {
    background-color: rgba(65, 66, 71, 0.08);
  }
  
  /* RangeInput */
  
  .ais-RangeInput-input {
    background: none;
    border: none;
    border-bottom: 1px solid #ebecf3;
    color: #21243d;
    font-family: inherit;
    font-size: 0.875rem;
    font-weight: 600;
    min-width: none;
    padding: 0;
    padding-bottom: 3px;
  }
  
  .ais-RangeInput-label:first-of-type {
    margin-right: 6px;
  }
  
  .ais-RangeInput-label:last-of-type {
    margin-left: 6px;
  }
  
  /* Pagination */
  
  .ais-Pagination-list {
    border: 2px solid red;
    justify-content: center;
  }
  
  .ais-Pagination-item,
  .ais-Pagination-link {
    align-items: center;
    display: flex;
    justify-content: center;
  }
  
  .ais-Pagination-item {
    height: 38px;
    width: 38px;
  }
  
  .ais-Pagination-item {
    background-color: rgba(65, 66, 71, 0.08);
    border-radius: 4px;
    color: #414247;
  }
  
  .ais-Pagination-item--selected {
    background-color: #e2a400;
    background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.34),
      rgba(255, 255, 255, 0)
    );
    font-weight: bold;
  }
  
  .ais-Pagination-item--firstPage,
  .ais-Pagination-item--previousPage,
  .ais-Pagination-item--nextPage,
  .ais-Pagination-item--lastPage {
    background: none;
  }
  
  .ais-Pagination-item--disabled {
    opacity: 0.33;
  }
  
  .ais-Pagination-item--selected a {
    color: #fff;
  }
  
  .ais-Pagination-item.ais-Pagination-item--page {
    margin-right: 4px;
  }
  
  .ais-Pagination-item.ais-Pagination-item--previousPage {
    margin-right: 1rem;
  }
  
  .ais-Pagination-item.ais-Pagination-item--nextPage {
    margin-left: calc(1rem - 4px);
  }
  
  .ais-Pagination-link {
    height: 100%;
    width: 100%;
  }
</style>
