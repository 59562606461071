<template>
  <div class="w-full">
    <div class="shadow-md w-full relative rounded-lg">
      <CuadroAnuncio></CuadroAnuncio>
    </div>
  </div>
</template>
<script>
import CuadroAnuncio from "@/views/UI/CuadroAnuncio.vue";

export default {
  name: "AnuncioTablaAnalista",

  components: {
    CuadroAnuncio,
  },
  data() {
    return {};
  },
};
</script>
