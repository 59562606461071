<template>
  <tr>
    <td class="px-3 py-3 border-b border-gray-200 bg-white text-xs">
      <div class="flex items-center">
        <div class="flex-shrink-0 hidden">
          <a href="#" class="block relative">
            <img
              alt="profil"
              src="/images/person/8.jpg"
              class="mx-auto object-cover rounded-full h-10 w-10"
            />
          </a>
        </div>
        <div class="ml-3">
          <estatusPostulado :info="idAn" :info2="persona.id"></estatusPostulado>
        </div>
      </div>
    </td>
    <td class="px-3 py-3 border-b border-gray-200 bg-white text-xs hidden">
      <div class="flex items-center">
        <div class="ml-3">
          <p class="text-gray-900 whitespace-no-wrap">
            {{ "|---|" }} {{ persona.id }}
          </p>
        </div>
      </div>
    </td>
    <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
      <p class="text-gray-900 whitespace-no-wrap">
        {{ persona.nombreUser + " " + persona.apellidoUser }}
      </p>
    </td>
    <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
      <p
        class="inset-0 bg-green-400 opacity-50 px-3 py-1 rounded-full text-green-900 font-bold whitespace-no-wrap"
      >
        {{ persona.emailUser }}
      </p>
    </td>
    <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
      <p class="text-gray-900 whitespace-no-wrap">
        {{ persona.telf1 }}
      </p>
    </td>
    <td class="py-5 border-b border-gray-200 bg-white text-sm">
      <fechaPostAnuncio :info="idAn" :info2="persona.id" />
    </td>
    <td class="py-5 border-b border-gray-200 bg-white text-sm hidden">
      {{ persona.createdAt | formatedTime }}
    </td>

    <td class="py-5 border-b border-gray-200 bg-white text-sm">
      <div class="flex items-center mx-2">
        <button
          v-on:click="
            toggleModalVerMs();
            prueba(persona.id);
          "
          type="button"
          class="bg-blue-400 max-w-md border text-xs font-bold rounded-md text-white hover:bg-blue-500 px-2 py-1"
        >
          Ver más
        </button>
      </div>
      <!--//////////////////////// showModalVerMs ////////////////////////-->
      <div>
        <div
          v-if="showModalVerMs"
          class="overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex"
        >
          <div class="relative w-full max-h-full my-6 mx-auto max-w-6xl">
            <!--content-->
            <div
              class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
            >
              <!--header-->
              <div
                class="flex items-start justify-between lg:mt-2 px-7 py-2 border-b border-solid border-blueGray-200 rounded-t"
              >
                <h3 class="text-2xl">
                  Anuncio: {{ persona.tituloAnunc }}
                </h3>
                <button
                  class="ml-auto border-0 text-black opacity-90 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                  v-on:click.prevent="toggleModalVerMs()"
                >
                  <span
                    class="text-black opacity-80 h-6 w-6 text-3xl block outline-none focus:outline-none"
                  >
                    ×
                  </span>
                </button>
              </div>
              <!--body-->
              <loader v-if="isLoading && !$store.state.userData" class="">
                <template v-slot:msg>
                  {{ msg }}
                </template>

                <template v-slot:msg1>
                  {{ msg1 }}
                </template>
              </loader>
              <div class="relative p-2 flex-auto">
                <personDataCv
                  :persona="persona"
                  :userData="userData"
                ></personDataCv>
              </div>
              <!--footer-->
              <div
                class="flex items-center justify-end px-6 py-2 border-t border-solid border-blueGray-200 rounded-b"
              >
                <button
                  class="text-red-500 bg-transparent border border-solid border-red-500 hover:bg-red-500 hover:text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  v-on:click="toggleModalVerMs()"
                >
                  Cerrar
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="showModalVerMs"
          class="opacity-25 fixed inset-0 z-40 bg-black"
        ></div>
      </div>
      <!--//////////////////////// /showModalVerMs ////////////////////////-->
    </td>
  </tr>
</template>

<script>
import loader from "@/components/shared/loader.vue";
import personDataCv from "@/components/personDataCvParaEmprs.vue";
//import personNotasEmprs from "@/components/personNotasEmprs.vue";
import estatusPostulado from "@/components/estatusPostulado.vue";
import fechaPostAnuncio from "@/components/fechaPostAnuncio.vue";
import CryptoJS from "crypto-js";


import axios from "axios";

export default {
  name: "trPostuladoEnAnuncio",
  components: {
    personDataCv,
    loader,
    /// personNotasEmprs,
    estatusPostulado,
    //  sideBarMenu,
    fechaPostAnuncio,
  },
  props: {
    persona: Object,
    userData: {},
  },
  data() {
    return {
      hidePane2: "",
      test: "pruebas2",
      assets: [],
      postulads: [],
      idAn: "",
      anuncio: {},
      nmbre: "",
      showModalVerMs: false,
      showModalVerNots: false,
      showModalCrearNots: false,
      msg: "",
      msg1: "",
      isLoading: true,
      uri: "data:application/vnd.ms-excel;base64,",
      template:
        '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
      base64: function (s) {
        return window.btoa(unescape(encodeURIComponent(s)));
      },
      format: function (s, c) {
        return s.replace(/{(\w+)}/g, function (m, p) {
          return c[p];
        });
      },
      fechaPostAnunc: null,
      idAnuncio: "",
      idPersona: "",
    };
  },
  methods: {
    toggleModalVerMs: function () {
      this.showModalVerMs = !this.showModalVerMs;
    },
    toggleModalVerNots: function () {
      this.showModalVerNots = !this.showModalVerNots;
    },
    toggleModalCrearNots: function () {
      this.showModalCrearNots = !this.showModalCrearNots;
    },

    prueba: function (value) {
      ////CLG("persona Id:", value);
      this.consultPerson(value);
      this.$store.state.anuncs.tituloAnunc;
    },
    volver() {
      this.$router.go(-1);
    },
    tableToExcel(table, name) {
      if (!table.nodeType) table = this.$refs.table;
      var ctx = { worksheet: name || "Prueba1", table: table.innerHTML };
      window.location.href =
        this.uri + this.base64(this.format(this.template, ctx));
    },
    tomo(value, value2, value3) {
      this.postulads = value;
      this.idAnuncio = value2;
      this.anuncio = value3;

      //CLG("postulados: ", this.postulads);
      //CLG("anuncio Titulo: ", value2);
    },
    consultPerson: function async(value) {
      //console.log("value asumo id", value);

      axios({
        method: "post",
        //url: `https://api.postula2.com/persona/?owner=${value}`,personByOwnerId
        url: `https://api.postula2.com/personByOwnerId`,
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.myTokToApiComs,
        },
        data: {
          ownerId: value,
          anuncioId: this.$store.state.anuncs.id,
        },
      })
        .then((res) => {
           //console.log("resp", res.data.persona);

          let secretKey = "gqcEWRhfDMBrAeVihjvBwZiacPPG";

          const decryptedData = CryptoJS.AES.decrypt(res.data.persona, secretKey);
          const originalText = decryptedData.toString(CryptoJS.enc.Utf8);

          res.data.persona = JSON.parse(originalText);

           console.log("datos desencriptado login info desde back:", res.data);

          this.$store.commit("getUserData", res.data.persona[0]);
          this.$store.commit("getPersonEvaluacn", res.data.evaluacions);

          //Perform Success Action getPersonEvaluacn
          //CLG("mandado al commit store", res.data[0]);
          // //CLG("id de owner es: ", this.$store.state.userData.owner.id);
          // //CLG("id de anuncio es: ", this.$store.state.anuncs.id);
          // //CLG("id de analista es: ", this.$store.state.analista.id);
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG("mission pelaste bolas");
          //CLG(error);
        });
    },
    logPersonaEval() {
      console.log("Valor de personaEval:", this.personaEval);
    },
  },
  watch: {
    showModalVerMs() {
      this.isLoading = true;
      if (this.showModalVerMs === false) {
        this.$store.commit("getPersonEvaluacn", "");
        this.$store.commit("getUserData", "");

        //this.consultPerson();
      }
      //console.log("cambiando");
    },
  },
  filters: {
    formatedTime: function (value) {
      var date = new Date(value);
      //console.log("Fecha en filtro:", value); // <------Este valor
      return (
        date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate() // +
        // " " +
        //date.getHours() +
        //":" +
        // date.getMinutes() +
        //":" +
        //date.getSeconds()
      );
      //CLG(formatedTime);
    },
    formatedAnuncActivo: function (value) {
      //var date = new Date(value);
      if (value == true) {
        return "Activo";
      } else {
        return "Inactivo";
      }
      //res.data[0].nombreUser+res.data[0].apellidoUser //CLG(formatedTime) https://api.postula2.com/registroPrimarioUser?id=;
    },
    reviewed: function (value) {
      if (value == "false") return;
      else return "Revisado";
    },

    evaluated: function (value) {
      if (value == "false") return;
      else return "En evaluación";
    },

    contacted: function (value) {
      if (value == "false") return;
      else return "Contactado";
    },

    finished: function (value) {
      if (value == "false") return;
      else return "Finalizado";
    },
  },
  created() {
    this.postulads = this.$store.state.anuncs.registroPrimarioUsers;
    this.idAnuncio = this.$store.state.anuncs.tituloAnunc;
    this.anuncio = this.$store.state.anuncs;
    this.idAn = this.$store.state.anuncs.id;
  },
  mounted() {
    //console.log(this.persona)
    //--------------------------/////////////////////
    //console.log(this.idAn);
    //console.log(this.$store.state);
    if (this.$store.state.regEmprs.id) {
      this.isLoading = false;
    } else {
      if (this.$store.state.regUserEmprs.empresa) {
        this.isLoading = false;
      } else {
        alert("Debe iniciar sesión o registrarse para ver esta información");
        this.$router.push({
          name: "loginEmprs",
        });
      }
    }
  },
};
</script>
