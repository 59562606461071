<template>
  <div
    class="flex-row gap-2 lg:gap-6 flex justify-center items-center min-w-full"
    v-bind:class="{
      'border-2 border-red-700': debg,
    }"
  >
    <div class="flex flex-wrap lg:w-52 gap-3 lg:gap-0">
      <div class="w-full lg:w-1/4 flex items-center justify-center">
        <!-- Imagenes -->
        <div
          v-if="
            this.$store.state.profileImage.flagImgUploaded === true &&
            !this.$store.state.userData.id &&
            this.$store.state.regUserEmprs.empresa
          "
        >
          <a @click="goTo" class="block relative">
            <img
              v-if="this.$store.state.profileImage.flagImgUploaded === true"
              alt="profil"
              :src="`https://api.postula2.com/imagesTiEmprs?id=${this.$store.state.profileImage.image.imageName}`"
              class="object-cover rounded-full h-10 w-10 cursor-pointer"
            />
          </a>
        </div>
        <div
          v-else-if="
            !this.$store.state.userData.id &&
            this.$store.state.regUserEmprs.empresa
          "
        >
          <a
            v-if="this.$store.state.regUserEmprs.empresa.imageProfileInfo"
            @click="goTo"
            class="block relative"
          >
            <img
              alt="profil"
              src="@/components/img/profileFemale.png"
              class="object-cover rounded-full h-10 w-10 cursor-pointer"
            />
          </a>
          <a
            v-else-if="this.$store.state.regUserEmprs.empresa[0].id"
            @click="goTo2"
            class="block relative cursor-pointer h-10 w-10"
          >
            <img
              v-if="
                this.$store.state.regUserEmprs.empresa[0].imgEmpr.length > 0
              "
              @click="$router.push('home')"
              class="object-cover rounded-full h-10 w-10"
              :src="`https://api.postula2.com/imagesTiEmprs?id=${this.$store.state.regUserEmprs.empresa[0].imgEmpr}`"
              alt=""
            />
            <img
              v-else
              @click="$router.push('home')"
              class="object-cover rounded-full h-10 w-10"
              src="@/components/img/edificio.png"
              alt=""
            />
          </a>
          <a v-else @click="goTo" class="block relative cursor-pointer">
            <img
              @click="$router.push('home')"
              class="object-cover rounded-full h-10 w-10 border"
              src="@/components/img/profilepic.png"
              alt=""
            />
          </a>
        </div>
      </div>

      <!-- Nombres -->
      <div
        class="w-full lg:w-2/4 flex items-center justify-center px-2 lg:pr-2"
      >
        <div
          v-if="
            !this.$store.state.userData.id &&
            this.$store.state.regUserEmprs.empresa
          "
          @click="goTo2"
          class="flex flex-col cursor-pointer"
        >
          <span class="text-gray-600 dark:text-white text-xs font-medium">
            {{ this.$store.state.regUserEmprs.empresa[0].nombreEmpr }}
          </span>
          <span class="hidden text-gray-400 text-xs"> CTO </span>
        </div>
        <div
          v-else-if="
            !this.$store.state.analista &&
            !this.$store.state.regUserEmprs.empresa
          "
          @click="goTo2"
          class="flex flex-col cursor-pointer"
        >
          <span
            class="text-gray-600 dark:text-white text-xs font-medium border-2 border-red-600"
          >
            {{ this.$store.state.regEmprs.nombreEmpr }}
          </span>
          <span class="hidden text-gray-400 text-xs"> CTO </span>
        </div>
      </div>
      <div class="w-full lg:w-1/4 lg:flex lg:items-center">
        <button
          v-if="
            !this.$store.state.userData.id &&
            this.$store.state.regUserEmprs.empresa
          "
          @click="reloaded()"
          class="py-1.5 px-2 normal-case bg-red-300 hover:bg-red-600 focus:ring-red-500 focus:ring-offset-red-200 text-white transition ease-in duration-200 text-center text-xs font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg lg:uppercase"
        >
          Salir
        </button>
      </div>
    </div>
    <div class="inline-block text-right">
      <loader v-if="isLoading" class="">
        <template v-slot:msg>
          {{ msg }}
        </template>

        <template v-slot:msg1>
          {{ msg1 }}
        </template>
      </loader>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import loader from "@/components/shared/loader.vue";

export default {
  name: "EmpresaPerfilAvatar",
  data() {
    return {
      menu: false,
      isLoading: false,
      msg: "",
      msg1: "",
      sugerencia: false,
      searchQuery: "",
      responseQuery: [],
      isActive: true,
      open: false,
      rutaUploadedImg: "ts/s",
      rutaDefaultImg: "./img/picture1.jpg",
      imgUploaded: "",
      debg: false,
    };
  },
  components: {
    loader,
  },
  created() {
    if (this.$store.state.userData) {
      if (
        this.$store.state.userData.length > 0 &&
        this.$store.state.userData.imageProfileInfo.length > 0
      ) {
        this.rutaUploadedImg =
          $store.state.userData.imageProfileInfo[0].imageEncryptName;
      }
    }
  },
  methods: {
    goTo: function () {
      this.$router.push({
        name: "personLandin",
      });
    },
    goTo2: function () {
      this.$router.push({
        name: "landing",
      });
    },
    goTo3: function () {
      this.$router.push({
        name: "homeAnalista",
      });
    },
    reloaded: function () {
      this.isLoading = true;
      sessionStorage.clear();
      setTimeout(() => {
        this.$router.push({ name: "HeaderHome" });

        setTimeout(() => {
          this.$router.go();
        }, 500);
      }, 1500);
      //location.reload();
    },
    borradorQuery: function () {
      this.sugerencia = false;
      this.searchQuery = null;
      this.responseQuery = null;
    },
    anuncioId: function () {
      this.sugerencia = false;
      //CLG("anuncio es: ", elId);
      this.$store.commit({
        type: "getAnuncsByPalabra",
        searchQuery: this.searchQuery,
        responseQuery: this.responseQuery,
      });
      this.$router.push({
        name: "pivot",
      });

      this.searchQuery = null;
      this.responseQuery = [];
    },
  },
  watch: {
    searchQuery: function () {
      if (this.searchQuery) {
        if (this.searchQuery.length == 0) this.sugerencia = false;
        else this.sugerencia = true;
      } else {
        this.sugerencia = false;
      }
      axios
        .get(
          `https://api.postula2.com/searchAnuncio?palabra=${this.searchQuery}`
          // https://api.postula2.com/searchAnuncio?palabra=${this.searchQuery}
          //`https://api.postula2.com/empresa/${this.$store.state.regUserEmprs.empresa[0].id}/anuncios`
        )
        .then((res) => {
          //Perform Success Action
          //alert("Usuario no encontrado, debe registrarse");
          this.sugerencia = true;
          this.responseQuery = res.data.anunciosBypalabra;
          //CLG("query buscado", this.searchQuery);
          //CLG("respuesta obtenida", this.responseQuery);
          //this.isLoading = false;
          //this.assets = res.data;
          ////CLG("assets", this.assets);
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG("mission pelaste bolas");
          //CLG(error);
        })
        .finally(() => {
          //Perform action in always
        });
    },
    search() {
      //CLG("busqueda por barra es: ", this.searchQuery);
    },
    toggle() {
      this.open = !this.open;
    },
  },
};
</script>

<style></style>
