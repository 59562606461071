<template>
  <div class="grid grid-cols-1 xl:grid-cols-2">
    <div class="border-t border-gray-200">
      <dl>
        <div class="hidden">
          {{ regUser.id }}
        </div>
        <div
          v-if="$store.state.userData"
          class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
        >
          <dt
            v-if="!editNombreUserFlag"
            class="text-sm text-left font-medium text-gray-500"
          >
            Nombre(s) y Apellido(s)
          </dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex">
            <FormulateForm v-if="editNombreUserFlag" #default="{ hasErrors }">
              <div
                v-if="editNombreUserFlag"
                class="grid w-3/4 md:w-full max-w-sm space-y-3 md:space-y-2 justify-start"
              >
                <div class="flex md:space-x-3 flex-row">
                  <div class="">
                    <label
                      for="price"
                      class="block text-xs font-medium text-gray-700 text-left ml-1"
                    >
                      <strong>Editar Nombre </strong>
                    </label>
                    <FormulateInput
                      type="text"
                      name="nombre"
                      :value="regUser.nombreUser"
                      v-model="data.nombreUser"
                      placeholder="Nombre"
                      validation-behavior="value"
                      validation="required|min:4|matches:/^[A-Za-z\s*\á\é\í\ó\ú\ñ]+$/"
                      class="rounded-xl border-transparent flex-1 appearance-none w-32 lg:w-60 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-xs focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                      :validation-messages="{
                        min: 'Debe tener al menos 4 caracteres',
                        matches: 'Este campo solo acepta letras',
                        required: 'Este campo es obligatorio',
                      }"
                    />
                  </div>

                  <div
                    class="flex w-auto text-gray-500 items-end justify-between pb-0.5 pt-2 ml-2 gap-2"
                  >
                    <FormulateInput
                      type="submit"
                      :disabled="hasErrors"
                      @click.prevent="editNombreUser(regUser.id)"
                      outer-class="
                        p-1
                        h-8
                        pt-1
                        items-center
                        transition
                        ease-in
                        duration-200
                        uppercase
                        rounded-full
                        border border-green-200
                        text-green-600
                        hover:border-green-600
                        hover:bg-green-600
                        hover:text-yellow-200
                        focus:outline-none
                      "
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
                        />
                      </svg>
                    </FormulateInput>
                    <FormulateInput
                      type="submit"
                      @click="editNombreUserFlag = !editNombreUserFlag"
                      outer-class="
                        items-center
                        p-1
                        h-8
                        pt-1
                        transition
                        ease-in
                        duration-200
                        uppercase
                        rounded-full
                        border border-red-300
                        text-red-500
                        hover:bg-red-800 hover:text-yellow-200
                        focus:outline-none
                      "
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                    </FormulateInput>
                  </div>
                </div>
                <!--  -->
                <div class="flex md:space-x-2 flex-row">
                  <div class="">
                    <label
                      for="price"
                      class="block text-xs font-medium text-gray-700 text-left ml-1"
                    >
                      <strong>Editar Apellido</strong>
                    </label>
                    <FormulateInput
                      type="text"
                      name="apellido"
                      :value="regUser.apellidoUser"
                      v-model="data.apellidoUser"
                      validation-behavior="value"
                      placeholder="Apellido"
                      validation="required|min:4|matches:/^[A-Za-z\s*\á\é\í\ó\ú\ñ]+$/"
                      class="rounded-lg border-transparent flex-1 appearance-none w-32 lg:w-60 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-xs focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                      :validation-messages="{
                        min: 'Debe tener al menos 4 caracteres',
                        matches: 'Este campo solo acepta letras',
                        required: 'Este campo es obligatorio',
                      }"
                    />
                  </div>

                  <div
                    class="flex w-auto text-gray-500 items-end justify-between mt-2 pb-0.5 gap-2 ml-2"
                  >
                    <FormulateInput
                      type="submit"
                      :disabled="hasErrors"
                      @click.prevent="editApellidoUser(regUser.id)"
                      outer-class="
                        p-1
                        h-8
                        pt-1
                        items-center
                        transition
                        ease-in
                        duration-200
                        uppercase
                        rounded-full
                        border border-green-200
                        text-green-600
                        hover:border-green-600
                        hover:bg-green-600
                        hover:text-yellow-200
                        focus:outline-none
                      "
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
                        />
                      </svg>
                    </FormulateInput>
                    <FormulateInput
                      type="submit"
                      @click="editNombreUserFlag = !editNombreUserFlag"
                      outer-class="
                        items-center
                        p-1
                        h-8
                        pt-1
                        inline-block
                        align-middle
                        transition
                        ease-in
                        duration-200
                        uppercase
                        rounded-full
                        border border-red-300
                        text-red-500
                        hover:bg-red-800 hover:text-yellow-200
                        focus:outline-none
                      "
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                    </FormulateInput>
                  </div>
                </div>
              </div>
            </FormulateForm>

            <div class="flex" v-if="!editNombreUserFlag">
              <span
                class="items-center text-gray-700 mr-6 dark:text-white font-normal"
              >
                {{ regUser.nombreUser + " " + regUser.apellidoUser }}
              </span>
              <label class="flex items-center">
                <button
                  @click="editNombreUserFlag = !editNombreUserFlag"
                  class="flex items-center p-1 transition ease-in duration-200 uppercase rounded-full hover:bg-blue-800 hover:text-yellow-200 focus:outline-none"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-3.5 w-3.5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                    />
                  </svg>
                </button>
              </label>
            </div>
          </dd>
        </div>

        <div
          v-if="$store.state.userData"
          class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
        >
          <dt
            v-if="!editTelf1Flag"
            class="text-sm text-left font-medium text-gray-500"
          >
            Teléfono
          </dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0">
            <FormulateForm #default="{ hasErrors }">
              <div v-if="editTelf1Flag" class="max-w-sm justify-start">
                <div class="flex flex-wrap flex-col">
                  <label
                    for="price"
                    class="text-xs font-medium text-gray-700 text-left ml-1"
                  >
                    <strong>Teléfono</strong>
                  </label>
                </div>
                <div class="flex flex-row w-80 ">
                  <!-- caja 1 -->
                  <div class="px-1 w-1/5 lg:w-2/6">
                    <FormulateInput
                      v-model.lazy="cod"
                      :options="{
                        '0412': '0412',
                        '0414': '0414',
                        '0424': '0424',
                        '0416': '0416',
                        '0426': '0426',
                      }"
                      type="select"
                      placeholder="04XX"
                      validation="required"
                      :validation-messages="{
                        required: 'Debes rellenar este campo',
                      }"
                      errors-class="text-left ml-1"
                    />
                  </div>
                  <!-- caja 2 -->
                  <div class="w-2/5 lg:w-3/6">
                    <FormulateInput
                      type="number"
                      placeholder="1234567"
                      name="numeroCelular"
                      v-model.lazy="numero"
                      validation="required|max:7,length|min:7,length|matches:/^[0-9]/|"
                      error-behavior="value"
                      :validation-messages="{
                        required: 'Debes rellenar este campo',
                        max: 'Maximo 7 digitos',
                        min: 'Al menos 7 digitos',
                        matches: 'Deben ser numeros',
                        not: 'No permitido',
                      }"
                      errors-class="text-left ml-1  z-10"
                    />
                  </div>

                  <!-- Caja 3 -->

                  <div
                    class="pt-0 flex text-gray-500 items-start justify-between pb-0.5 space-x-1 w-auto ml-2 gap-1"
                  >
                    <button
                      type="submit"
                      :disabled="hasErrors"
                      @click.prevent="editTelf1(regUser)"
                      class="p-1 flex items-center transition ease-in duration-200 uppercase rounded-full border border-green-200 text-green-600 hover:border-green-600 hover:bg-green-600 hover:text-yellow-200 focus:outline-none"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
                        />
                      </svg>
                    </button>
                    <button
                      @click="editTelf1Flag = !editTelf1Flag"
                      class="items-center p-1 transition ease-in duration-200 uppercase rounded-full border border-red-300 text-red-500 hover:bg-red-800 hover:text-yellow-200 focus:outline-none"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </FormulateForm>

            <div class="flex" v-if="!editTelf1Flag">
              <span class="text-gray-700 mr-6 dark:text-white font-normal">
                {{ regUser.telf1 }}
              </span>
              <label class="flex items-center">
                <button
                  @click="editTelf1Flag = !editTelf1Flag"
                  class="flex items-center p-1 transition ease-in duration-200 uppercase rounded-full hover:bg-blue-800 hover:text-yellow-200 focus:outline-none"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-3.5 w-3.5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                    />
                  </svg>
                </button>
              </label>
            </div>
          </dd>
        </div>

        <div
          v-if="$store.state.userData"
          class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
        >
          <dt
            v-if="!editNroDocumentFlag"
            class="text-sm text-left font-medium text-gray-500"
          >
            Identificación
          </dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex">
            <form
              v-if="editNroDocumentFlag"
              class="flex flex-col md:flex-row w-3/4 md:w-full max-w-sm md:space-x-3 space-y-3 md:space-y-0 justify-start"
            >
              <div class="">
                <label
                  for="price"
                  class="text-xs font-medium text-gray-700 text-left ml-1"
                >
                  <strong>Identificación</strong>
                </label>
                <select
                  v-model="data.tipoDocument"
                  id="operador"
                  name="codigoOperador"
                  class="text-sm focus:ring-indigo-500 h-10 py-2 px-1 border border-gray-300 focus:border-indigo-500 w-full border-transparent bg-transparent text-gray-500 bg-white rounded-md"
                  placeholder="est"
                  aria-placeholder="asd"
                >
                  <option disabled value="" class="">Tipo</option>
                  <option value="cedula">Cedula</option>
                  <option value="pasaporte">Pasaporte</option>
                </select>
              </div>
              <div class="mt-0.5 w-3/4">
                <FormulateInput
                  type="number"
                  placeholder="1234567"
                  name="numeroCelular"
                  v-model="data.nroDocument"
                  class="mt-3 w-60 rounded-lg border-transparent flex-1 appearance-none border border-gray-300 px-2 py-2 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-xs focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                  validation="required|max:8,length|min:6,length|matches:/^[0-9]/|"
                  :validation-messages="{
                    required: 'Debes rellenar este campo',
                    max: 'Maximo 8 digitos',
                    min: 'Al menos 6 digitos',
                    matches: 'Deben ser numeros',
                    not: 'No permitido',
                  }"
                />
              </div>

              <div
                class="flex w-auto text-gray-500 items-end justify-between px-2 pb-0.5 space-x-1"
              >
                <button
                  @click.prevent="editNroDocument(userData.id)"
                  class="ml-7 p-1 flex items-center transition ease-in duration-200 uppercase rounded-full border border-green-200 text-green-600 hover:border-green-600 hover:bg-green-600 hover:text-yellow-200 focus:outline-none"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
                    />
                  </svg>
                </button>
                <button
                  @click="editNroDocumentFlag = !editNroDocumentFlag"
                  class="flex items-center p-1 transition ease-in duration-200 uppercase rounded-full border border-red-300 text-red-500 hover:bg-red-800 hover:text-yellow-200 focus:outline-none"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </button>
              </div>
            </form>

            <div class="flex" v-if="!editNroDocumentFlag">
              <span
                v-if="userData.nroDocument"
                class="text-gray-700 capitalize mr-6 dark:text-white font-normal"
              >
                {{ userData.tipoDocument + " " + userData.nroDocument }}
              </span>
              <span v-else class="mr-4 font-bold text-gray-500">
                Información no registrada</span
              >
              <div class="hidden">
                <label v-if="userData.nroDocument" class="flex items-center">
                  <button
                    @click="editNroDocumentFlag = !editNroDocumentFlag"
                    class="flex items-center p-1 transition ease-in duration-200 uppercase rounded-full hover:bg-blue-800 hover:text-yellow-200 focus:outline-none"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-3.5 w-3.5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                      />
                    </svg>
                  </button>
                </label>
              </div>
            </div>
          </dd>
        </div>

        <div
          v-if="$store.state.userData"
          class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
        >
          <dt
            v-if="!editNacionalidadFlag"
            class="text-sm text-left font-medium text-gray-500"
          >
            Nacionalidad
          </dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex">
            <form
              v-if="editNacionalidadFlag"
              class="flex flex-row w-3/4 md:w-full max-w-sm md:space-x-3 space-y-3 md:space-y-0 justify-start"
            >
              <div class="">
                <label
                  for="price"
                  class="block text-xs font-medium text-gray-700 text-left ml-1"
                >
                  <strong>Editar Nacionalidad</strong>
                </label>
                <select
                  type="text"
                  id='"form-subscribe-Subscribe'
                  class="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-32 lg:w-60 px-2 py-2 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-xs focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                  v-model="data.nacionalidad"
                >
                  <option class="text-gray-300" disabled value="">
                    Seleccione
                  </option>
                  <option value="Venezuela">Venezuela</option>
                  <option value="Otro">Otro</option>
                </select>
              </div>

              <div
                class="flex w-auto text-gray-500 items-end justify-between mt-2 pb-0.5 gap-2 ml-2"
              >
                <button
                  @click.prevent="editNacionalidad(userData.id)"
                  class="p-1 flex items-center transition ease-in duration-200 uppercase rounded-full border border-green-200 text-green-600 hover:border-green-600 hover:bg-green-600 hover:text-yellow-200 focus:outline-none"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
                    />
                  </svg>
                </button>
                <button
                  @click="editNacionalidadFlag = !editNacionalidadFlag"
                  class="flex items-center p-1 transition ease-in duration-200 uppercase rounded-full border border-red-300 text-red-500 hover:bg-red-800 hover:text-yellow-200 focus:outline-none"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </button>
              </div>
            </form>

            <div class="flex" v-if="!editNacionalidadFlag">
              <span
                v-if="userData.nacionalidad"
                class="text-gray-700 capitalize mr-6 dark:text-white font-normal"
              >
                {{ userData.nacionalidad }}
              </span>
              <span v-else class="mr-4 font-bold text-gray-500"
                >Información no registrada</span
              >
              <label v-if="userData.nacionalidad" class="flex items-center">
                <button
                  @click="editNacionalidadFlag = !editNacionalidadFlag"
                  class="flex items-center p-1 transition ease-in duration-200 uppercase rounded-full hover:bg-blue-800 hover:text-yellow-200 focus:outline-none"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-3.5 w-3.5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                    />
                  </svg>
                </button>
              </label>
            </div>
          </dd>
        </div>

        <div
          v-if="$store.state.userData"
          class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
        >
          <dt
            v-if="!editEstadCivilFlag"
            class="text-sm text-left font-medium text-gray-500"
          >
            Estado Civil
          </dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex">
            <form
              v-if="editEstadCivilFlag"
              class="flex flex-row w-3/4 md:w-full max-w-sm md:space-x-3 space-y-3 md:space-y-0 justify-start"
            >
              <div class="">
                <label
                  for="price"
                  class="block text-xs font-medium text-gray-700 text-left ml-1"
                >
                  <strong> Editar Estado Civil </strong>
                </label>
                <select
                  type="text"
                  id='"form-subscribe-Subscribe'
                  class="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-32 lg:w-60 px-2 py-2 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-xs focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                  v-model="data.estadCivil"
                >
                  <option class="text-gray-300" disabled value="">
                    Seleccione
                  </option>
                  <option value="soltero">Soltero</option>
                  <option value="casado">Casado</option>
                  <option value="divorciado">Divorciado</option>
                  <option value="viudo">Viudo</option>
                </select>
              </div>
              <div
                class="flex w-auto text-gray-500 items-end justify-between px-2 pb-0.5 space-x-1"
              >
                <button
                  @click.prevent="editEstadCivil(userData.id)"
                  class="p-1 flex items-center transition ease-in duration-200 uppercase rounded-full border border-green-200 text-green-600 hover:border-green-600 hover:bg-green-600 hover:text-yellow-200 focus:outline-none"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
                    />
                  </svg>
                </button>
                <button
                  @click="editEstadCivilFlag = !editEstadCivilFlag"
                  class="flex items-center p-1 transition ease-in duration-200 uppercase rounded-full border border-red-300 text-red-500 hover:bg-red-800 hover:text-yellow-200 focus:outline-none"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </button>
              </div>
            </form>

            <div class="flex" v-if="!editEstadCivilFlag">
              <span
                v-if="userData.estadCivil"
                class="text-gray-700 capitalize mr-6 dark:text-white font-normal"
              >
                {{ userData.estadCivil }}
              </span>

              <span v-else class="mr-4 font-bold text-gray-500">
                Información no registrada</span
              >
              <label v-if="userData.estadCivil" class="flex items-center">
                <button
                  @click="editEstadCivilFlag = !editEstadCivilFlag"
                  class="flex items-center p-1 transition ease-in duration-200 uppercase rounded-full hover:bg-blue-800 hover:text-yellow-200 focus:outline-none"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-3.5 w-3.5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                    />
                  </svg>
                </button>
              </label>
            </div>
          </dd>
        </div>

        <div
          v-if="$store.state.userData"
          class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
        >
          <dt
            v-if="!editLicenciaFlag"
            class="text-sm text-left font-medium text-gray-500"
          >
            Licencia
          </dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex">
            <form
              v-if="editLicenciaFlag"
              class="flex flex-row w-3/4 md:w-full max-w-sm md:space-x-3 space-y-3 md:space-y-0 justify-start"
            >
              <div class="">
                <label
                  for="price"
                  class="block text-xs font-medium text-gray-700 text-left ml-1"
                >
                  <strong>Editar Licencia</strong>
                </label>
                <select
                  type="text"
                  id='"form-subscribe-Subscribe'
                  class="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-32 lg:w-60 px-2 py-2 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-xs focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                  v-model="data.licencia"
                >
                  <option class="text-gray-300" disabled value="">
                    Seleccione
                  </option>
                  <option value="Si">Si</option>
                  <option value="No">No</option>
                </select>
              </div>

              <div
                class="flex w-auto text-gray-500 items-end justify-between px-2 pb-0.5 space-x-1"
              >
                <button
                  @click.prevent="editLicencia(userData.id)"
                  class="p-1 flex items-center transition ease-in duration-200 uppercase rounded-full border border-green-200 text-green-600 hover:border-green-600 hover:bg-green-600 hover:text-yellow-200 focus:outline-none"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
                    />
                  </svg>
                </button>
                <button
                  @click="editLicenciaFlag = !editLicenciaFlag"
                  class="flex items-center p-1 transition ease-in duration-200 uppercase rounded-full border border-red-300 text-red-500 hover:bg-red-800 hover:text-yellow-200 focus:outline-none"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </button>
              </div>
            </form>

            <div class="flex" v-if="!editLicenciaFlag">
              <span
                v-if="userData.licencia"
                class="text-gray-700 capitalize mr-6 dark:text-white font-normal"
              >
                {{ userData.licencia }}
              </span>
              <span v-else class="mr-4 font-bold text-gray-500">
                Información no registrada</span
              >
              <label v-if="userData.licencia" class="flex items-center">
                <button
                  @click="editLicenciaFlag = !editLicenciaFlag"
                  class="flex items-center p-1 transition ease-in duration-200 uppercase rounded-full hover:bg-blue-800 hover:text-yellow-200 focus:outline-none"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-3.5 w-3.5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                    />
                  </svg>
                </button>
              </label>
            </div>
          </dd>
        </div>
      </dl>
    </div>

    <div class="lg:border-t border-gray-200">
      <dl>
        <div
          v-if="$store.state.userData"
          class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
        >
          <dt
            v-if="!editEmailUserFlag"
            class="text-sm text-left font-medium text-gray-500"
          >
            Correo electrónico
          </dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex">
            <form
              v-if="editEmailUserFlag"
              class="flex flex-col md:flex-row w-3/4 md:w-full max-w-sm md:space-x-3 space-y-3 md:space-y-0 justify-start"
            >
              <div class="">
                <label
                  for="price"
                  class="block text-xs font-medium text-gray-700 text-left ml-1"
                >
                  <strong>Editar Correo electrónico</strong>
                </label>
                <input
                  type="text"
                  id='"form-subscribe-Subscribe'
                  class="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-60 px-2 py-2 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-xs focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                  v-model="data.emailUser"
                />
              </div>

              <div
                class="flex w-auto text-gray-500 items-end justify-between px-2 pb-0.5 space-x-1"
              >
                <button
                  @click.prevent="editEmailUser(regUser.id)"
                  class="p-1 flex items-center transition ease-in duration-200 uppercase rounded-full border border-green-200 text-green-600 hover:border-green-600 hover:bg-green-600 hover:text-yellow-200 focus:outline-none"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
                    />
                  </svg>
                </button>
                <button
                  @click="editEmailUserFlag = !editEmailUserFlag"
                  class="flex items-center p-1 transition ease-in duration-200 uppercase rounded-full border border-red-300 text-red-500 hover:bg-red-800 hover:text-yellow-200 focus:outline-none"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </button>
              </div>
            </form>

            <div class="flex" v-if="!editEmailUserFlag">
              <span class="text-gray-700 mr-6 dark:text-white font-normal">
                {{ regUser.emailUser }}
              </span>
              <label class="flex items-center hidden">
                <button
                  @click="editEmailUserFlag = !editEmailUserFlag"
                  class="flex items-center p-1 transition ease-in duration-200 uppercase rounded-full hover:bg-blue-800 hover:text-yellow-200 focus:outline-none"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-3.5 w-3.5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                    />
                  </svg>
                </button>
              </label>
            </div>
          </dd>
        </div>

        <div
          v-if="$store.state.userData"
          class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
        >
          <dt
            v-if="!editFech_nacimntFlag"
            class="text-sm text-left font-medium text-gray-500"
          >
            Fecha nacimiento
          </dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex">
            <form
              v-if="editFech_nacimntFlag"
              class="flex flex-row w-auto md:w-full max-w-md md:space-x-3 space-y-3 md:space-y-0 justify-start"
            >
              <div class="">
                <label
                  for="price"
                  class="block text-xs font-medium text-gray-700 text-left ml-1"
                >
                  <strong>Editar Fecha nacimiento</strong>
                </label>
                <input
                  type="date"
                  id='"form-subscribe-Subscribe'
                  class="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-32 lg:w-60 px-2 py-2 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-xs focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                  onfocus="(this.type='date')"
                  onblur="(this.type='text')"
                  v-model.lazy.trim="data.fech_nacimnt"
                />
              </div>

              <div
                class="flex w-auto text-gray-500 items-end justify-between px-2 pb-0.5 space-x-1"
              >
                <button
                  @click.prevent="editFech_nacimnt(userData.id)"
                  class="p-1 flex items-center transition ease-in duration-200 uppercase rounded-full border border-green-200 text-green-600 hover:border-green-600 hover:bg-green-600 hover:text-yellow-200 focus:outline-none"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
                    />
                  </svg>
                </button>
                <button
                  @click="editFech_nacimntFlag = !editFech_nacimntFlag"
                  class="flex items-center p-1 transition ease-in duration-200 uppercase rounded-full border border-red-300 text-red-500 hover:bg-red-800 hover:text-yellow-200 focus:outline-none"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </button>
              </div>
            </form>

            <div class="flex" v-if="!editFech_nacimntFlag">
              <span
                v-if="userData.fech_nacimnt"
                class="text-gray-700 capitalize mr-6 dark:text-white font-normal"
              >
                {{ userData.fech_nacimnt }}
              </span>
              <span v-else class="mr-4 font-bold text-gray-500">
                Información no registrada</span
              >
              <label v-if="userData.fech_nacimnt" class="flex items-center">
                <button
                  @click="editFech_nacimntFlag = !editFech_nacimntFlag"
                  class="flex items-center p-1 transition ease-in duration-200 uppercase rounded-full hover:bg-blue-800 hover:text-yellow-200 focus:outline-none"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-3.5 w-3.5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                    />
                  </svg>
                </button>
              </label>
            </div>
          </dd>
        </div>

        <div
          v-if="$store.state.userData"
          class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
        >
          <dt
            v-if="!editGeneroFlag"
            class="text-sm text-left font-medium text-gray-500"
          >
            Género
          </dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex">
            <form
              v-if="editGeneroFlag"
              class="flex flex-row w-3/4 md:w-full max-w-sm md:space-x-3 space-y-3 md:space-y-0 justify-start"
            >
              <div class="">
                <label
                  for="price"
                  class="block text-xs font-medium text-gray-700 text-left ml-1"
                >
                  <strong>Editar Género</strong>
                </label>
                <select
                  type="text"
                  id='"form-subscribe-Subscribe'
                  class="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-32 lg:w-60 px-2 py-2 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-xs focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                  v-model="data.genero"
                >
                  <option class="text-gray-300" disabled value="">
                    Seleccione
                  </option>
                  <option value="Femenino">Femenino</option>
                  <option value="Masculino">Masculino</option>
                </select>
              </div>

              <div
                class="flex w-auto text-gray-500 items-end justify-between px-2 pb-0.5 space-x-1"
              >
                <button
                  @click.prevent="editGenero(userData.id)"
                  class="p-1 flex items-center transition ease-in duration-200 uppercase rounded-full border border-green-200 text-green-600 hover:border-green-600 hover:bg-green-600 hover:text-yellow-200 focus:outline-none"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
                    />
                  </svg>
                </button>
                <button
                  @click="editGeneroFlag = !editGeneroFlag"
                  class="flex items-center p-1 transition ease-in duration-200 uppercase rounded-full border border-red-300 text-red-500 hover:bg-red-800 hover:text-yellow-200 focus:outline-none"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </button>
              </div>
            </form>

            <div class="flex" v-if="!editGeneroFlag">
              <span
                v-if="userData.genero"
                class="text-gray-700 capitalize mr-6 dark:text-white font-normal"
              >
                {{ userData.genero }}
              </span>
              <span v-else class="mr-4 font-bold text-gray-500">
                Información no registrada</span
              >
              <label v-if="userData.genero" class="flex items-center">
                <button
                  @click="editGeneroFlag = !editGeneroFlag"
                  class="flex items-center p-1 transition ease-in duration-200 uppercase rounded-full hover:bg-blue-800 hover:text-yellow-200 focus:outline-none"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-3.5 w-3.5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                    />
                  </svg>
                </button>
              </label>
            </div>
          </dd>
        </div>

        <div
          v-if="$store.state.userData"
          class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
        >
          <dt
            v-if="!editDiscapacitadFlag"
            class="text-sm text-left font-medium text-gray-500"
          >
            Discapacidad
          </dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex">
            <form
              v-if="editDiscapacitadFlag"
              class="flex flex-row w-3/4 md:w-full max-w-sm md:space-x-3 space-y-3 md:space-y-0 justify-start"
            >
              <div class="">
                <label
                  for="price"
                  class="block text-xs font-medium text-gray-700 text-left ml-1"
                >
                  <strong>Editar Discapacidad</strong>
                </label>
                <select
                  type="text"
                  id='"form-subscribe-Subscribe'
                  class="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-32 lg:w-60 px-2 py-2 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-xs focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                  v-model="data.discapacitad"
                >
                  <option class="text-gray-300" disabled value="">
                    Seleccione
                  </option>
                  <option value="si">Si</option>
                  <option value="no">No</option>
                </select>
              </div>

              <div
                class="flex w-auto text-gray-500 items-end justify-between px-2 pb-0.5 space-x-1"
              >
                <button
                  @click.prevent="editDiscapacitad(userData.id)"
                  class="p-1 flex items-center transition ease-in duration-200 uppercase rounded-full border border-green-200 text-green-600 hover:border-green-600 hover:bg-green-600 hover:text-yellow-200 focus:outline-none"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
                    />
                  </svg>
                </button>
                <button
                  @click="editDiscapacitadFlag = !editDiscapacitadFlag"
                  class="flex items-center p-1 transition ease-in duration-200 uppercase rounded-full border border-red-300 text-red-500 hover:bg-red-800 hover:text-yellow-200 focus:outline-none"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </button>
              </div>
            </form>

            <div class="flex" v-if="!editDiscapacitadFlag">
              <span
                v-if="userData.discapacitad"
                class="text-gray-700 capitalize mr-6 dark:text-white font-normal"
              >
                {{ userData.discapacitad }}
              </span>
              <span v-else class="mr-4 font-bold text-gray-500">
                Información no registrada</span
              >
              <label v-if="userData.discapacitad" class="flex items-center">
                <button
                  @click="editDiscapacitadFlag = !editDiscapacitadFlag"
                  class="flex items-center p-1 transition ease-in duration-200 uppercase rounded-full hover:bg-blue-800 hover:text-yellow-200 focus:outline-none"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-3.5 w-3.5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                    />
                  </svg>
                </button>
              </label>
            </div>
          </dd>
        </div>

        <div
          v-if="$store.state.userData"
          class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
        >
          <dt
            v-if="!editDireccionFlag"
            class="text-sm text-left font-medium text-gray-500"
          >
            Dirección
          </dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex">
            <form
              v-if="editDireccionFlag"
              class="flex flex-row w-3/4 md:w-full max-w-sm md:space-x-3 space-y-3 md:space-y-0 justify-start"
            >
              <div class="">
                <label
                  for="price"
                  class="block text-xs font-medium text-gray-700 text-left ml-1"
                >
                  <strong>Editar Dirección</strong>
                </label>
                <input
                  type="text"
                  id='"form-subscribe-Subscribe'
                  class="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-32 lg:w-60 px-2 py-2 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-xs focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                  v-model="data.direccion"
                />
              </div>

              <div
                class="flex w-auto text-gray-500 items-end justify-between px-2 pb-0.5 space-x-1"
              >
                <button
                  @click.prevent="editDireccion(userData.id)"
                  class="p-1 flex items-center transition ease-in duration-200 uppercase rounded-full border border-green-200 text-green-600 hover:border-green-600 hover:bg-green-600 hover:text-yellow-200 focus:outline-none"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
                    />
                  </svg>
                </button>
                <button
                  @click="editDireccionFlag = !editDireccionFlag"
                  class="flex items-center p-1 transition ease-in duration-200 uppercase rounded-full border border-red-300 text-red-500 hover:bg-red-800 hover:text-yellow-200 focus:outline-none"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </button>
              </div>
            </form>

            <div class="flex" v-if="!editDireccionFlag">
              <span
                v-if="userData.direccion"
                class="text-gray-700 capitalize mr-6 dark:text-white font-normal"
              >
                {{ userData.direccion }}
              </span>
              <span v-else class="mr-4 font-bold text-gray-500">
                Información no registrada</span
              >
              <label v-if="userData.direccion" class="flex items-center">
                <button
                  @click="editDireccionFlag = !editDireccionFlag"
                  class="flex items-center p-1 transition ease-in duration-200 uppercase rounded-full hover:bg-blue-800 hover:text-yellow-200 focus:outline-none"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-3.5 w-3.5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                    />
                  </svg>
                </button>
              </label>
            </div>
          </dd>
        </div>
      </dl>
    </div>
    <loader v-if="isLoading">
      <template v-slot:msg>
        {{ msg }}
      </template>

      <template v-slot:msg1>
        {{ msg1 }}
      </template>
    </loader>
  </div>
</template>
<style scoped></style>
<script>
import axios from "axios";
import loader from "@/components/shared/loader.vue";

export default {
  name: "formPersonData",
  components: {
    loader,
  },
  props: {
    regUser: {},
    userData: {},
  },
  methods: {
    editarCampo() {
      this.editarFlag = !this.editarFlag;
    },
    editNombreUser(idRegPrimUser) {
      //CLG("el id es regPrimers", idRegPrimUser);
      let data = JSON.stringify({
        idRegPrimUser: idRegPrimUser,
        nombreUser: this.data.nombreUser,
      });
      //CLG("data es", data);
      axios({
        method: "put",
        url: `https://api.postula2.com/editPersonData/`,
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.myTokToApiComs,
        },
        data: data,
      })
        .then(() => {
          //CLG("campo editado con exito");
          this.editFinished();
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG("mission pelaste bolas in PersondataUser");
          //CLG(error);
        })
        .finally(() => {
          //Perform action in always
          this.editNombreUserFlag = !this.editNombreUserFlag;
          this.data.nombreUser = "";
        });
    },
    editApellidoUser(idRegPrimUser) {
      let data = JSON.stringify({
        idRegPrimUser: idRegPrimUser,
        apellidoUser: this.data.apellidoUser,
      });
      axios({
        method: "put",
        url: `https://api.postula2.com/editPersonData/`,
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.myTokToApiComs,
        },
        data: data,
      })
        .then(() => {
          //CLG("campo editado con exito");
          this.editFinished();
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG("mission pelaste bolas in PersondataUser");
          //CLG(error);
        })
        .finally(() => {
          //Perform action in always
          this.editApellidoUserFlag = !this.editApellidoUserFlag;
          this.editNombreUserFlag = !this.editNombreUserFlag;
          this.data.apellidoUser = "";
        });
    },
    editEmailUser(idRegPrimUser) {
      let data = JSON.stringify({
        idRegPrimUser: idRegPrimUser,
        emailUser: this.data.emailUser,
      });
      axios({
        method: "put",
        url: `https://api.postula2.com/editPersonData/`,
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.myTokToApiComs,
        },
        data: data,
      })
        .then(() => {
          //CLG("campo editado con exito");
          this.editFinished();
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG("mission pelaste bolas in PersondataUser");
          //CLG(error);
        })
        .finally(() => {
          //Perform action in always
          this.editEmailUserFlag = !this.editEmailUserFlag;
          this.data.emailUser = "";
        });
    },
    editTelf1(idRegPrimUser) {
      //CLG("id es", idRegPrimUser);
      this.data.telf1 = this.cod + this.numero;
      //CLG("telf1", this.data.telf1);
      let data = JSON.stringify({
        idRegPrimUser: idRegPrimUser.id,
        telf1: this.data.telf1,
      });
      axios({
        method: "put",
        url: `https://api.postula2.com/editPersonData/`,
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.myTokToApiComs,
        },
        data: data,
      })
        .then(() => {
          //CLG("campo editado con exito");
          this.editFinished();
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG("mission pelaste bolas in PersondataUser");
          //CLG(error);
        })
        .finally(() => {
          //Perform action in always
          this.editTelf1Flag = !this.editTelf1Flag;
          this.data.telf1 = "";
          this.cod = "";
          this.numero = "";
        });
    },
    ////////////////////////////////

    editFech_nacimnt(idPersonUser) {
      let data = JSON.stringify({
        idPersonUser: idPersonUser,
        fech_nacimnt: this.data.fech_nacimnt,
      });
      axios({
        method: "put",
        url: `https://api.postula2.com/editPersonData/`,
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.myTokToApiComs,
        },
        data: data,
      })
        .then(() => {
          //CLG("campo editado con exito");
          this.editFinished();
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG("mission pelaste bolas in idPersonUser");
          //CLG(error);
        })
        .finally(() => {
          //Perform action in always
          this.editFech_nacimntFlag = !this.editFech_nacimntFlag;
          this.data.fech_nacimnt = "";
        });
    },
    editGenero(idPersonUser) {
      let data = JSON.stringify({
        idPersonUser: idPersonUser,
        genero: this.data.genero,
      });
      axios({
        method: "put",
        url: `https://api.postula2.com/editPersonData/`,
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.myTokToApiComs,
        },
        data: data,
      })
        .then(() => {
          //CLG("campo editado con exito");
          this.editFinished();
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG("mission pelaste bolas in idPersonUser");
          //CLG(error);
        })
        .finally(() => {
          //Perform action in always
          this.editGeneroFlag = !this.editGeneroFlag;
          this.data.genero = "";
        });
    },
    editNacionalidad(idPersonUser) {
      //CLG("idpersonUsr es", idPersonUser);

      let data = JSON.stringify({
        idPersonUser: idPersonUser,
        nacionalidad: this.data.nacionalidad,
      });
      axios({
        method: "put",
        url: `https://api.postula2.com/editPersonData/`,
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.myTokToApiComs,
        },
        data: data,
      })
        .then(() => {
          //CLG("campo editado con exito");
          this.editFinished();
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG("mission pelaste bolas in idPersonUser");
          //CLG(error);
        })
        .finally(() => {
          //Perform action in always
          this.editNacionalidadFlag = !this.editNacionalidadFlag;
          this.data.nacionalidad = "";
        });
    },
    editTipoDocument(idPersonUser) {
      let data = JSON.stringify({
        idPersonUser: idPersonUser,
        tipoDocument: this.data.tipoDocument,
      });
      axios({
        method: "put",
        url: `https://api.postula2.com/editPersonData/`,
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.myTokToApiComs,
        },
        data: data,
      })
        .then(() => {
          //CLG("campo editado con exito");
          this.editFinished();
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG("mission pelaste bolas in idPersonUser");
          //CLG(error);
        })
        .finally(() => {
          //Perform action in always
          this.editTipoDocumentFlag = !this.editTipoDocumentFlag;
          this.data.tipoDocument = "";
        });
    },
    editNroDocument(idPersonUser) {
      let data = JSON.stringify({
        idPersonUser: idPersonUser,
        nroDocument: this.data.nroDocument,
        //tipoDocument:this.data.tipoDocument
      });
      axios({
        method: "put",
        url: `https://api.postula2.com/editPersonData/`,
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.myTokToApiComs,
        },
        data: data,
      })
        .then(() => {
          //CLG("campo editado con exito");
          this.editFinished();
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG("mission pelaste bolas in idPersonUser");
          //CLG(error);
        })
        .finally(() => {
          //Perform action in always
          this.editNroDocumentFlag = !this.editNroDocumentFlag;
          this.data.nroDocument = "";
        });
    },
    editDiscapacitad(idPersonUser) {
      let data = JSON.stringify({
        idPersonUser: idPersonUser,
        discapacitad: this.data.discapacitad,
      });
      axios({
        method: "put",
        url: `https://api.postula2.com/editPersonData/`,
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.myTokToApiComs,
        },
        data: data,
      })
        .then(() => {
          //CLG("campo editado con exito");
          this.editFinished();
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG("mission pelaste bolas in idPersonUser");
          //CLG(error);
        })
        .finally(() => {
          //Perform action in always
          this.editDiscapacitadFlag = !this.editDiscapacitadFlag;
          this.data.discapacitad = "";
        });
    },
    editLicencia(idPersonUser) {
      let data = JSON.stringify({
        idPersonUser: idPersonUser,
        licencia: this.data.licencia,
      });
      axios({
        method: "put",
        url: `https://api.postula2.com/editPersonData/`,
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.myTokToApiComs,
        },
        data: data,
      })
        .then(() => {
          //CLG("campo editado con exito");
          this.editFinished();
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG("mission pelaste bolas in idPersonUser");
          //CLG(error);
        })
        .finally(() => {
          //Perform action in always
          this.editLicenciaFlag = !this.editLicenciaFlag;
          this.data.licencia = "";
        });
    },
    editEstadCivil(idPersonUser) {
      let data = JSON.stringify({
        idPersonUser: idPersonUser,
        estadCivil: this.data.estadCivil,
      });
      axios({
        method: "put",
        url: `https://api.postula2.com/editPersonData/`,
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.myTokToApiComs,
        },
        data: data,
      })
        .then(() => {
          //CLG("campo editado con exito");
          this.editFinished();
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG("mission pelaste bolas in idPersonUser");
          //CLG(error);
        })
        .finally(() => {
          //Perform action in always
          this.editEstadCivilFlag = !this.editEstadCivilFlag;
          this.data.estadCivil = "";
        });
    },
    editTelf2(idPersonUser) {
      this.data.telf2 = this.cod + this.numero;

      let data = JSON.stringify({
        idPersonUser: idPersonUser,
        telf2: this.data.telf2,
      });
      axios({
        method: "put",
        url: `https://api.postula2.com/editPersonData/`,
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.myTokToApiComs,
        },
        data: data,
      })
        .then(() => {
          //CLG("campo editado con exito");
          this.editFinished();
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG("mission pelaste bolas in idPersonUser");
          //CLG(error);
        })
        .finally(() => {
          //Perform action in always
          this.editTelf2Flag = !this.editTelf2Flag;
          this.data.telf2 = "";
        });
    },
    editDireccion(idPersonUser) {
      let data = JSON.stringify({
        idPersonUser: idPersonUser,
        direccion: this.data.direccion,
      });
      axios({
        method: "put",
        url: `https://api.postula2.com/editPersonData/`,
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.myTokToApiComs,
        },
        data: data,
      })
        .then(() => {
          //CLG("campo editado con exito");
          this.editFinished();
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG("mission pelaste bolas in idPersonUser");
          //CLG(error);
        })
        .finally(() => {
          //Perform action in always
          this.editDireccionFlag = !this.editDireccionFlag;
          this.data.direccion = "";
        });
    },
    editImgPerfil(idPersonUser) {
      let data = JSON.stringify({
        idPersonUser: idPersonUser,
        imgPerfil: this.data.imgPerfil,
      });
      axios({
        method: "put",
        url: `https://api.postula2.com/editPersonData/`,
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.myTokToApiComs,
        },
        data: data,
      })
        .then(() => {
          //CLG("campo editado con exito");
          this.editFinished();
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG("mission pelaste bolas in idPersonUser");
          //CLG(error);
        })
        .finally(() => {
          //Perform action in always
          this.editImgPerfilFlag = !this.editImgPerfilFlag;
          this.data.imgPerfil = "";
        });
    },
    editCurrcVitArchiv(idPersonUser) {
      let data = JSON.stringify({
        idPersonUser: idPersonUser,
        currcVitArchiv: this.data.currcVitArchiv,
      });
      axios({
        method: "put",
        url: `https://api.postula2.com/editPersonData/`,
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.myTokToApiComs,
        },
        data: data,
      })
        .then(() => {
          //CLG("campo editado con exito");
          this.editFinished();
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG("mission pelaste bolas in idPersonUser");
          //CLG(error);
        })
        .finally(() => {
          //Perform action in always
          this.editCurrcVitArchivFlag = !this.editCurrcVitArchivFlag;
          this.data.currcVitArchiv = "";
        });
    },
    editar() {
      this.editing = true;
    },
    editFinished() {
      this.editing = false;
      //https://api.postula2.com/persona/624745e3e602450004288516/experncLabors
      //CLG("id persona,", this.$store.state.userData.id);
      //CLG("id Registro persona,", this.$store.state.regUser.id);

      axios({
        method: "POST",
        url: `https://api.postula2.com/personByOwnerId`,
        headers: {
          Authorization: this.$store.state.myTokToApiComs,
          //'X-CSRF-Token': '-s?T+H?w!u8qBJ7Lfvi/1rl8J-ITm2jyubx!qvCV3+Uv1zh5Jtz+JgmZUL6Fh3LN9MItahr49SY='
        },
        data: {
          ownerId: this.$store.state.regUser.id,
        },
      })
        .then((res) => {
          //CLG("RESPUESTA ", res.data);
          this.$store.commit("getUserData", res.data.persona[0]);
          this.$store.commit("getRegUser", res.data.persona[0].owner);

          //this.$store.state.userData = res.data;
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG(
          //   "mission pelaste bolas in FormpersonData consultando persona"
          // );
          //CLG(error);
        })
        .finally(() => {
          //Perform action in always
        });
    },
    onSubmit() {
      //let dataUser = this.data;
      this.isLoading = true;
      this.data.owner = this.$store.state.userData.id;
      axios
        .post("https://api.postula2.com/ReferncUsers", this.data)
        .then(() => {
          //Perform Success Action
          //CLG("response:", res);
          this.msg = "¡Localidad registrada satisfactoriamente...!";
          //this.$store.commit("getflagLogin", redFlag);
          this.$store.commit({
            type: "getflagLogin",
            greenFlag: true,
          });
          setTimeout(() => {
            this.editFinished();
            (this.data.nombRefer = ""),
              (this.data.apellidRefer = ""),
              (this.data.emailRefer = ""),
              (this.data.telefRefer = ""),
              (this.data.tipoRefer = ""),
              (this.data.relacnRefer = ""),
              (this.data.owner = null);
            //this.editing = false;
            this.isLoading = false;
            this.$store.commit({
              type: "getflagLogin",
              greenFlag: null,
            });
          }, 2000);
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG(error);
          this.msg1 =
            "¡Verifique la información ingresada e intente nuevamente!";
          //this.$store.commit("getflagLogin", redFlag);
          this.$store.commit({
            type: "getflagLogin",
            redFlag: true,
          });
          setTimeout(() => {
            this.isLoading = false;
            this.$store.commit({
              type: "getflagLogin",
              redFlag: null,
            });
          }, 3000);
        })
        .finally(() => {
          //Perform action in always
        });
    },
  },
  // created() {
  //   if (!this.$store.state.regUser.id) {
  //     alert("Debe iniciar sesion");
  //     this.$router.push({
  //       name: "login",
  //     });
  //   }
  // },
  data() {
    return {
      editarFlag: false,

      editNombreUserFlag: false,
      editApellidoUserFlag: false,
      editEmailUserFlag: false,
      editTelf1Flag: false,

      editFech_nacimntFlag: false,
      editGeneroFlag: false,
      editNacionalidadFlag: false,
      editTipoDocumentFlag: false,
      editNroDocumentFlag: false,
      editDiscapacitadFlag: false,
      editLicenciaFlag: false,
      editEstadCivilFlag: false,
      editTelf2Flag: false,
      editDireccionFlag: false,
      editImgPerfilFlag: false,
      editCurrcVitArchivFlag: false,

      editing: false,
      isLoading: false,
      msg: null,
      msg1: null,
      cod: "",
      numero: "",
      data: {
        idRegPrimUser: "",
        nombreUser: "",
        apellidoUser: "",
        emailUser: "",
        telf1: "",
        idPersonUser: "",
        fech_nacimnt: "",
        genero: "",
        nacionalidad: "",
        tipoDocument: "",
        nroDocument: "",
        discapacitad: "",
        licencia: "",
        estadCivil: "",
        telf2: "",
        direccion: "",
        imgPerfil: "",
        currcVitArchiv: "",
        owner: null,
      },
    };
  },
};
</script>
