import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import axios from "axios";

Vue.use(Vuex);

const store = new Vuex.Store({
  // ...
  state: {
    regUser: {
      // nombreUser: "",
      // apellidoUser: "",
      // emailUser: "",
      //telf1: "",
      // autenticacion: "1",
      // passwUser: "",
      // id: null
    },
    regUserEmprs: {
      // nombreUserEmpres: "",
      // apellidoUserEmpres: "",
      // emailUserEmpres: "",
      // autenticacion: "1",
      // passwUserEmpres: "",
      // id: null
    },
    regEmprs: {
      // nombreEmpr: "",
      // rifEmpr: "",
      // descEmpr: "",
      // cantEmpleads: "",
      // direccEmpr: "",
      // razonSocialEmpr: "",
      // owner: null,
    },
    analista: {
      // nombreAnalist,
      // apellidoAnalist,
      // emailAnalist,
      // cargoAnalist,
      // estado,
      // passwAnalist,
      // rolAnalist,
      // owner,
      // evaluacns,
      // analistaLogs,
      // anuncios,
      // anunciosActualizds
    },
    analistaToEdit: {
      // nombreAnalist,
      // apellidoAnalist,
      // emailAnalist,
      // cargoAnalist,
      // estado,
      // passwAnalist,
      // rolAnalist,
    },
    userData: {
      // nombreUser: "",
      // apellidoUser: "",
      // emailUser: "",
      // id: null
    },
    anuncs: {
      // id:"615b3d80a2ddd613d647637a"
      // anuncActivo:true
      // updatedAt:1633369472550
      // tituloAnunc:"asistente atenccion al cliente"
      // owner:Object
      //     nombreEmpr:"empresa1"
      //     rifEmpr:"v12352"
      // updatedAt:1633369472550
      // descrpAnunc:"loremloremloremloremloremlorem"
      // tipoempleo:"Pasantia"
      // modalidtrabj:"Desde casa"
      // ofertsalarl:"12332"
      // cvemailcopy:"true"
    },
    anuncsByPalabra: {
      searchQuery: "",
      responseQuery: [],
    },
    perfilados: {},
    perfiladosHoy: {},
    anuncsSinPostulads: {},
    anuncsByEmpresa: {},
    anuncsInPersona: {},
    userCount15Days: {},
    flagLogin: {
      redFlag: null,
      greenFlag: null,
      whiteSpinnerFlag: null,
      greenSpinnerFlag: null,
    },
    anuncsPublic: {
      anuncsActivs: {},
      anuncsInactivs: {},
    },
    myTokToApiComs: {},
    personaEval: {},
    profileImage: {
      flagImgUploaded: false,
      uploadedEmprsImgComponntValue: 0,
      uploadedUserImgComponntValue: 0,
      image: null,
    },
    fechaPostAnunc: null,
  },
  mutations: {
    getRegUser(state, payload) {
      state.regUser = payload;
    },
    getRegUserEmprs(state, payload) {
      state.regUserEmprs = payload;
    },
    getEmprs(state, payload) {
      state.regEmprs = payload;
    },
    getAnalist(state, payload) {
      state.analista = payload;
    },
    getAnalistToEdit(state, payload) {
      state.analistaToEdit = payload;
    },
    getAnuncs(state, payload) {
      state.anuncs = payload;
    },
    getAnuncsByEmpresa(state, payload) {
      state.anuncsByEmpresa = payload;
    },
    getAnuncsInPerson(state, payload) {
      state.anuncsInPersona = payload;
    },
    getAnuncsByPalabra(state, payload) {
      state.anuncsByPalabra.searchQuery = payload.searchQuery;
      state.anuncsByPalabra.responseQuery = payload.responseQuery;
    },
    getUserData(state, payload) {
      state.userData = payload;
    },
    getUserCount15Days(state, payload) {
      state.userCount15Days = payload;
    },
    getPerfilados(state, payload) {
      state.perfilados = payload;
    },
    getPerfiladosHoy(state, payload) {
      state.perfiladosHoy = payload;
    },
    getAnuncsSinPostulads(state, payload) {
      state.anuncsSinPostulads = payload;
    },
    // getAnuncsSinPostulads
    getflagLogin(state, payload) {
      state.flagLogin.redFlag = payload.redFlag;
      state.flagLogin.greenFlag = payload.greenFlag;
      state.flagLogin.whiteSpinnerFlag = payload.whiteSpinnerFlag;
      state.flagLogin.greenSpinnerFlag = payload.greenSpinnerFlag;
    },
    getAnuncsPublic(state, payload) {
      state.anuncsPublic.anuncsActivs = payload.anuncsActivs;
      state.anuncsPublic.anuncsInactivs = payload.anuncsInactivs;
    },
    getMyTokCredentApi(state, payload) {
      state.myTokToApiComs = payload;
    },
    getPersonEvaluacn(state, payload) {
      state.personaEval = payload;
    },
    getProfileImage(state, payload) {
      state.profileImage.image = payload.image;
      state.profileImage.flagImgUploaded = payload.flagImgUploaded;
    },
    incrementEmprsImgComponntValue(state) {
      state.profileImage.uploadedEmprsImgComponntValue++;
    },
    incrementUserImgComponntValue(state) {
      state.profileImage.uploadedUserImgComponntValue++;
    },
    updateEvaluacnAnalist(state, data) {
      // Actualiza el estado con los datos recibidos de la API
      state.personaEval = data; // Supongo que quieres almacenar los datos en la propiedad personaEval
    },
    setFechaPostAnunc(state, fecha) {
      state.fechaPostAnunc = fecha;
    }
  },
  getters: {},
  actions: {
    async fetchEvaluacnAnalist({ commit }) {
      try {
        const response = await axios.get(
          "https://api.postula2.com/findEvaluacnAnalist"
        );
        // Luego, puedes utilizar commit para llamar a una mutación que actualice el estado con los datos recibidos.
        commit("updateEvaluacnAnalist", response.data);
      } catch (error) {
        // Manejo de errores, puedes mostrar una notificación o hacer algo en caso de error.
        console.error("Error al obtener datos de la API", error);
      }
    },
  },
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
    }),
  ],
});
export default store;
