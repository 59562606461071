import Vue from "vue";
import VueRouter from "vue-router";
import Meta from "vue-meta";
import VueGtag from "vue-gtag";

import Home from "../views/Home.vue";
import Home2 from "@/components/Home2";
import Empresa from "@/components/Empresa";
import Anuncio from "@/components/Anuncio";
import Exp_laboral from "@/components/Exp_laboral";
import Prf_labor from "@/components/Pref_labor";
import Ref_labor from "@/components/Ref_labor";
import Idiom from "@/components/Idiom";
import Contact from "@/components/Contact";
import DatPerson from "@/components/DatPerson";
import CuadroAnuncio from "@/components/CuadroAnuncio";
import IndexGlob from "@/components/IndexGlob";
import EmpresaReg from "@/components/EmpresaReg";
import AnunReg from "@/components/AnunReg";
import PersonReg from "@/components/PersonReg";
import PersonReg2 from "@/components/PersonReg2";
import AnuncioCreado from "@/components/AnuncioCreado";
import cv from "@/components/cv";
import Educacion from "@/components/Educacion";
import login from "@/components/login";
import loginEmprs from "@/components/loginEmprs";
import RegistrarPerson from "@/components/RegistrarPerson";
import RegistrarEmprs from "@/components/RegistrarEmprs";
import HeaderHome from "@/components/HeaderHome";
import EmpleosHome from "@/components/EmpleosHome";
import AnuncioDataTable from "@/components/AnuncioDataTable";
import AnunciosLanding from "@/components/AnunciosLanding";
import AnunciosBusqueda from "@/components/AnunciosBusqueda";
import AnunciosbyEmprs from "@/components/AnunciosbyEmprs";
import anunciosSinPostulads from "@/components/AnunciosSinPostulads";
import AnunciosEditarByEmprs from "@/components/AnunciosEditarByEmprs";
import analistaEditar from "@/components/AnalistaEditar";
import AnuncioPostulds from "@/components/AnuncioPostulds";
import PersonaAnuncios from "@/components/PersonaAnuncios";
import sideBarMenu from "@/components/sideBarMenu";
import pivot from "@/components/pivot";
import personPerfil from "@/components/personPerfil";
import personLandin from "@/components/personLandin";
import personDatos from "@/components/personDatos";
import personsAccordeon from "@/components/personsAccordeon";
import testComponent from "@/components/testComponent";
import anuncioListPostulads from "@/components/AnuncioListPostulads";
import analistaCreate from "@/components/AnalistaCreate";
import analistaConsultaTodos from "@/components/AnalistaConsultaTods";
import loginAnalista from "@/components/loginAnalista";
import homeAnalista from "@/components/HomeAnalista";
import perfilEmpres from "@/components/perfilEmpres";
import perfilAnalis from "@/components/perfilAnalist";
import RegistrarPersonTESTER from "@/components/RegistrarPersonTESTER";
import reenvAuthMail from "@/components/reenvAuthMail";
import resetContra from "@/components/resetContra";
import misPostulados from "@/views/dashboard/PersonsPerfiladas.vue";
import misPostuladosHoy from "@/views/dashboard/PersonsPerfiladasHoy.vue";
import preProcessedData from "@/views/dashboard/PreprocessedData.vue";
import anuncioByUrlID from "@/components/AnunciobyUrlID";
import pageNotFound from "../views/pages/pageNotFound.vue";
import searchpersona from "@/components/layouts/searchPersonaWithBtn.vue";
import notificaciones from "@/components/notificaciones.vue";



Vue.use(VueRouter);
Vue.use(
  VueGtag,
  {
    // config: { id: "347656082" }
    config: { id: "G-BWTYHPQ97X" },
  },
  router
);
Vue.use(Meta); ///

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/pages/About.vue"),
  },
  {
    path: "/404",
    name: "pageNotFound",
    component: () =>
      import(/* webpackChunkName: "noFound" */ "../views/pages/pageNotFound"),
  },
  {
    path: "/home2",
    name: "Home2",
    component: Home2,
  },
  {
    path: "/empresa",
    component: Empresa,
    name: "Empresa",
    meta: {
      auth: true,
    },
  },

  {
    path: "/anuncio",
    component: Anuncio,
    name: "Anunciooo",
  },
  {
    path: "/testComponent",
    component: testComponent,
    name: "testComponent",
  },

  {
    path: "/experiencia",
    component: Exp_laboral,
    name: "exp_labor",
  },

  {
    path: "/prflaboral",
    component: Prf_labor,
    name: "prf_labor",
  },

  {
    path: "/reflaboral",
    component: Ref_labor,
    name: "ref_labor",
  },

  {
    path: "/idiom",
    component: Idiom,
    name: "idiom",
  },

  {
    path: "/contact",
    component: Contact,
    name: "contact",
  },

  {
    path: "/datoperson",
    component: DatPerson,
    name: "dat_person",
  },

  {
    path: "/cuadroanuncio",
    component: CuadroAnuncio,
    name: "cuadroanuncio",
  },

  {
    path: "/bench",
    component: IndexGlob,
    name: "bench",
  },
  {
    path: "/personReg",
    component: PersonReg,
    name: "personreg",
  },

  {
    path: "/personReq",
    component: PersonReg2,
    name: "personreg2",
  },

  {
    path: "/anucCreat",
    component: AnuncioCreado,
    name: "AnuncioCreado",
  },
  {
    path: "/anunciosLanding",
    component: AnunciosLanding,
    name: "AnunciosLanding",
  },
  {
    path: "/datosEducacion",
    component: Educacion,
    name: "DatosEducacion",
  },
  {
    path: "/personsAccordeon",
    component: personsAccordeon,
    name: "personsAccordeon",
  },
  {
    path: "/pivot",
    component: pivot,
    name: "pivot",
  },
  {
    path: "/sideBarMenu",
    component: sideBarMenu,
    name: "sideBarMenu",
  },
  {
    path: "/anuncioDaTable",
    component: AnuncioDataTable,
    name: "AnuncioDaTable",
  },
  {
    path: "/anuncioListPostulads",
    component: anuncioListPostulads,
    name: "anuncioListPostulads",
  },
  {
    path: "/personaAnuncios",
    component: PersonaAnuncios,
    name: "PersonaAnuncios",
  },
  {
    path: "/",
    name: "empresaLanding",
    component: () => import("@/views/emprsLanding.vue"),
    children: [
      {
        path: "/landingempresarial",
        name: "landing",
        component: () => import("@/views/dashboard/landing.vue"),
      },
      {
        path: "postulados",
        name: "postulados",
        component: () => import("@/views/dashboard/Postulds.vue"),
      },
      {
        path: "postuladosAnuncio",
        name: "postuladosEnAnuncio",
        component: () => import("@/views/dashboard/PostuldsEnAnuncio.vue"),
      },
      {
        path: "/mispostulados",
        component: misPostulados,
        name: "mispostulados",
      },
      {
        path: "/mispostuladoshoy",
        component: misPostuladosHoy,
        name: "mispostuladoshoy",
      },
      {
        path: "/searchpersona",
        component: searchpersona,
        name: "searchpersona",
      },
      {
        path: "/mydata",
        component: preProcessedData,
        name: "preProcessedData",
      },
      {
        path: "/anunciosbyEmprs",
        component: AnunciosbyEmprs,
        name: "AnunciosbyEmprs",
      },
      {
        path: "/anunciosSinPostulads",
        component: anunciosSinPostulads,
        name: "anunciosSinPostulads",
      },
      {
        path: "/anunReg",
        component: AnunReg,
        name: "anunreg",
      },
      {
        path: "/homeAnalista",
        component: homeAnalista,
        name: "homeAnalista",
      },
      {
        path: "/analistaCreate",
        component: analistaCreate,
        name: "AnalistaCrear",
      },
      {
        path: "/analistaConsultAll",
        component: analistaConsultaTodos,
        name: "AnalistaConsultaTodos",
      },
      {
        path: "/anuncioPostulds",
        component: AnuncioPostulds,
        name: "AnuncioPostulds",
      },
      {
        path: "/perfilEmpres",
        component: perfilEmpres,
        name: "perfilEmpres",
      },
      {
        path: "/perfilAnalist",
        component: perfilAnalis,
        name: "perfilAnalis",
      },
      {
        path: "/anunciosEditarByEmprs",
        component: AnunciosEditarByEmprs,
        name: "AnunciosEditarByEmprs",
      },
      {
        path: "/analistaEditar",
        component: analistaEditar,
        name: "analistaEditar",
      },
      {
        path: "/empresaReg",
        component: EmpresaReg,
        name: "empresareg",
      },
      // {
      //     path: '/card',
      //     name: 'card',
      //     component: () =>
      //         import ('@/views/Card.vue'),
      // },
    ],
  },
  {
    path: "/",
    name: "homec",
    component: () => import("@/views/HomeCard.vue"),
    children: [
      {
        path: "registrarPerson",
        component: RegistrarPerson,
        name: "registrarPerson",
      },
      {
        path: "registrarEmprs",
        component: RegistrarEmprs,
        name: "registrarEmprs",
      },
    ],
  },
  {
    path: "/",
    name: "regularLanding",
    component: () => import("@/views/regularLanding.vue"),
    children: [
      //{
      //     path: '/landingempresarial',
      //     name: 'landing',
      //     component: () =>
      //     import ('@/views/dashboard/landing.vue'),
      // },
      // {
      //   path: "/homec",
      //   component: Homec,
      //   name: "homec",
      // },
      {
        path: "/loginAnalista",
        component: loginAnalista,
        name: "loginAnalista",
      },
      {
        path: "/login",
        component: login,
        name: "Login",
      },
      {
        path: "/loginEmprs",
        component: loginEmprs,
        name: "loginEmprs",
      },
      {
        path: "/reenvAuthMail",
        component: reenvAuthMail,
        name: "reenvAuthMail",
      },
      {
        path: "/resetContra",
        component: resetContra,
        name: "resetContra",
      },
      // {
      //   path: "/RegistrarEmprs",
      //   component: RegistrarEmprs,
      //   name: "RegistrarEmprs",
      // },
      // {
      //   path: "/RegistroPerson",
      //   component: RegistrarPerson,
      //   name: "RegistrarPerson",
      // },
      {
        path: "/RegistroPersonTest",
        component: RegistrarPersonTESTER,
        name: "RegistrarPersonTester",
      },
      {
        path: "/mision",
        name: "Mision",
        component: () =>
          import(
            /* webpackChunkName: "mision" */ "../views/pages/MisionPages.vue"
          ),
      },
      {
        path: "/Terminoslegales",
        name: "Terminos",
        component: () =>
          import(
            /* webpackChunkName: "mision" */ "../views/pages/Terminos.vue"
          ),
      },
      {
        path: "/contacto",
        name: "Contacto",
        component: () =>
          import(
            /* webpackChunkName: "contacto" */ "../views/pages/contacto.vue"
          ),
      },
      // {
      //     path: '/card',
      //     name: 'card',
      //     component: () =>
      //         import ('@/views/Card.vue'),
      // },searchLanding
    ],
  },
  {
    path: "/",
    name: "searchLanding",
    component: () => import("@/views/searchLanding.vue"),
    children: [
      // {
      //   path: "/EmpleosHome",
      //   component: EmpleosHome,
      //   name: "EmpleosHome",
      // },
      {
        path: "/home",
        component: HeaderHome,
        name: "HeaderHome",
      },
      {
        path: "/anunciosBusqueda",
        component: AnunciosBusqueda,
        name: "AnunciosBusqueda",
      },

      {
        path: "/personPerfil",
        component: personPerfil,
        name: "personPerfil",
      },
      {
        path: "/personLandin",
        component: personLandin,
        name: "personLandin",
      },
      {
        path: "/personDatos",
        component: personDatos,
        name: "personDatos",
      },
      {
        path: "/notificaciones",
        component: notificaciones,
        name: "notificaciones",
      },
      {
        path: "/datoscv",
        component: cv,
        name: "DatosCv",
        meta: {
          auth: true,
        },
      },
      {
        path: "/contactanos",
        name: "contactanos",
        component: () => import("@/components/contactanos.vue"),
      },
      {
        path: "/anuncio/:id",
        name: "anuncioByUrlID",
        component: anuncioByUrlID,
      },

      // {
      //     path: '/card',
      //     name: 'card',
      //     component: () =>
      //         import ('@/views/Card.vue'),
      // },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    component: pageNotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  // Establecer la posición del scroll al principio de la página cada vez que cambia de ruta
  window.scrollTo(0, 0);
  next();
});

export default router;
