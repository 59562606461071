<template>
  <div class="border-b rounded-t">
    <label for="" class="text-base font-bold">Historial de revisiones</label>
    <div
      class="mt-2 bg-white dark:bg-gray-800 shadow overflow-hidden sm:rounded-md"
    >
      <ul class="divide-y divide-gray-200">
        <div v-if="this.$store.state.personaEval != 0">
          <div v-for="evalu in evaluacns" :key="evalu.id">
            <li>
              <a class="block hover:bg-gray-50 dark:hover:bg-gray-900">
                <div class="px-4 py-2 sm:px-6">
                  <div class="flex items-center justify-between text-xs">
                    <p class="text-gray-700 dark:text-white text-left">
                      <label class="text-xs font-semibold">Observación</label>
                      <br />
                      {{ evalu.comment }}
                    </p>
                    <div class="ml-2 flex-shrink-0 flex">
                      <p
                        v-if="evalu.dataRevisd == 'true'"
                        class="px-2 mx-1 inline-flex leading-5 font-semibold rounded-full bg-green-100 text-green-800"
                      >
                        {{ evalu.dataRevisd | reviewed }}
                      </p>
                      <p
                        v-if="evalu.dataEvaluacn == 'true'"
                        class="px-2 mx-1 inline-flex leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800"
                      >
                        {{ evalu.dataEvaluacn | evaluated }}
                      </p>
                      <p
                        v-if="evalu.dataContctdo == 'true'"
                        class="px-2 mx-1 inline-flex leading-5 font-semibold rounded-full bg-blue-100 text-blue-800"
                      >
                        {{ evalu.dataContctdo | contacted }}
                      </p>
                      <p
                        v-if="evalu.dataFaseFinal == 'true'"
                        class="px-2 mx-1 inline-flex leading-5 font-semibold rounded-full bg-red-100 text-red-800"
                      >
                        {{ evalu.dataFaseFinal | finished }}
                      </p>
                      <p class="text-gray-400 text-xs ml-2">
                        <svg
                          @click="deleteEval(evalu.id)"
                          width="17"
                          height="17"
                          title="Eliminar"
                          class="text-red-400 cursor-pointer"
                          fill="currentColor"
                          viewBox="0 0 1792 1792"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M704 1376v-704q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v704q0 14 9 23t23 9h64q14 0 23-9t9-23zm256 0v-704q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v704q0 14 9 23t23 9h64q14 0 23-9t9-23zm256 0v-704q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v704q0 14 9 23t23 9h64q14 0 23-9t9-23zm-544-992h448l-48-117q-7-9-17-11h-317q-10 2-17 11zm928 32v64q0 14-9 23t-23 9h-96v948q0 83-47 143.5t-113 60.5h-832q-66 0-113-58.5t-47-141.5v-952h-96q-14 0-23-9t-9-23v-64q0-14 9-23t23-9h309l70-167q15-37 54-63t79-26h320q40 0 79 26t54 63l70 167h309q14 0 23 9t9 23z"
                          ></path>
                        </svg>
                      </p>
                    </div>
                  </div>
                  <div
                    v-if="evalu.analist"
                    class="mt-2 sm:flex sm:justify-between"
                  >
                    <div class="sm:flex">
                      <label class="text-xs font-semibold" for=""
                        >Analista:</label
                      >
                      <p
                        class="mx-1 flex text-xs items-center font-light text-gray-500 dark:text-gray-300"
                      >
                        {{ getAnalistaName }}
                      </p>
                    </div>
                  </div>
                </div>
              </a>
            </li>
          </div>
        </div>
        <div v-else>
          <li>
            <p class="text-xs text-red-400">
              <strong> Sin información disponible </strong>
            </p>
          </li>
        </div>
      </ul>
    </div>
    <div class="w-full grid justify-items-end mt-1 mb-4">
      <button
        type="button"
        @click="onSubmit"
        class="p-2 flex justify-center items-center bg-white hover:bg-green-400 hover:border-green-600 focus:ring-green-500 focus:ring-offset-green-200 text-white border-solid border-2 border-green-400 transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 64 56.32"
          width="18"
          class=""
        >
          <defs></defs>
          <title>Actualizar</title>
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <path
                class="cls-1"
                d="M63.22,22.34a2.57,2.57,0,0,0-3.62.06l-3.39,3.52a28.13,28.13,0,1,0-7.94,21.95,2.56,2.56,0,0,0-3.66-3.58,23,23,0,1,1,6.47-18.45l-3.19-3.19a2.56,2.56,0,1,0-3.62,3.62L52,34a2.56,2.56,0,0,0,1.81.75h0a2.56,2.56,0,0,0,1.82-.79L63.28,26A2.57,2.57,0,0,0,63.22,22.34Z"
              />
            </g>
          </g>
        </svg>
      </button>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "evaluacionMostrar",
  data() {
    return {
      evaluacns: [],
    };
  },
  props: {
    evaluacions: {},
  },
  filters: {
    reviewed: function (value) {
      if (value == "false") return;
      else return "Revisado";
    },

    evaluated: function (value) {
      if (value == "false") return;
      else return "En evaluación";
    },

    contacted: function (value) {
      if (value == "false") return;
      else return "Contactado";
    },

    finished: function (value) {
      if (value == "false") return;
      else return "Finalizado";
    },
  },
  methods: {
    deleteEval(value) {
      //CLG("valor de id a eliminar value", value);
      //{{API_URL_STG}}/evaluacnAnalist/
      if (window.confirm("¿Seguro de eliminar esta evaluación?")) {
        axios({
          method: "post",
          url: `https://api.postula2.com/deleteEvaluacn`,
          headers: {
            "Content-Type": "application/json",
            Authorization: this.$store.state.myTokToApiComs,
          },
          data: { idEvaluacion: value },
        })
          .then((res) => {
            //Perform Success Action
            //alert("Usuario no encontrado, debe registrarse");
            //console.log("response ", res);
            let delet = res.data.resp[0].id;
            this.evaluacns = this.evaluacns.filter((item) => item.id !== delet);
          })
          .catch(() => {
            // error.response.status Check status code
            //CLG("mission pelaste bolas");
            //CLG(error);
          });
        //
      }
    },

    onSubmit() {
      console.log("algo de prueba");
      axios({
        method: "post",
        url: `https://api.postula2.com/findEvaluacnAnalist`,
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.myTokToApiComs,
        },
        data: {
          idRegistroPrimarioUser: this.$store.state.userData.owner.id,
          idAnuncio: this.$store.state.anuncs.id,
        },
      })
        .then((res) => {
          //Perform Success Action
          //alert("Usuario no encontrado, debe registrarse");
          //CLG("respuesta obtenida", res.data.evaluaciones);
          //this.isLoading = false;
          this.evaluacns = res.data.evaluaciones;
          //CLG("evaluaciones", this.evaluacns);
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG("mission pelaste bolas");
          //CLG(error);
        });
    },
  },
  mounted() {
    //console.log("hola mundo")
    //CLG("personaEval es ", this.$store.state.personaEval);
    this.evaluacns = this.$store.state.personaEval;
  },
  computed: {
    getAnalistaName() {
      const analist = this.$store.state.analista;
      if (analist && analist.nombreAnalist && analist.apellidoAnalist) {
        return `${analist.nombreAnalist} ${analist.apellidoAnalist}`;
      }
      return "Nombre del analista no disponible";
    },
  },
};
</script>
