<template>
  <div class="flex-col bg-gray-200 rounded-lg dark:bg-gray-800">
    <div v-if="editing == false" class="text-justify">
      <div class="bg-white max-w-full overflow-hidden sm:rounded-lg">
        <div class="grid grid-cols-2">
          <div
            class="flex items-center space-x-2 font-semibold text-gray-900 leading-8 m-2"
          >
            <span clas="text-green-500">
              <svg
                class="h-5 text-blue-postulados"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                />
              </svg>
            </span>
            <span class="tracking-wide pr-4 text-blue-postulados"
              >Datos personales</span
            >
          </div>
          <!-- si es primera vez entrando ya que autenticacion esta en 0 -->
          <div
            v-if="
              !$store.state.userData &&
              $store.state.regUser.id &&
              $store.state.regUser.autenticacion == 0
            "
            class="flex items-center justify-end"
          >
            <button
              type="button"
              @click="editar"
              class="max-w-min py-2 px-4 mr-4 flex justify-center items-center bg-green-500 hover:bg-green-700 focus:ring-green-500 focus:ring-offset-green-200 text-white w-full transition ease-in duration-200 text-center text-xs font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg"
            >
              <svg
                width="10"
                height="10"
                fill="currentColor"
                class="h-6 w-6 text-white mr-2"
                viewBox="0 0 1792 1792"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M491 1536l91-91-235-235-91 91v107h128v128h107zm523-928q0-22-22-22-10 0-17 7l-542 542q-7 7-7 17 0 22 22 22 10 0 17-7l542-542q7-7 7-17zm-54-192l416 416-832 832h-416v-416zm683 96q0 53-37 90l-166 166-416-416 166-165q36-38 90-38 53 0 91 38l235 234q37 39 37 91z"
                ></path>
              </svg>
              Editar
            </button>
          </div>
          <div
            v-if="
              !$store.state.userData &&
              $store.state.regUser.id &&
              typeof $store.state.regUser.userData === 'undefined'
            "
            class="flex items-center justify-end"
          >
            <button
              type="button"
              @click="editar"
              class="max-w-min py-2 px-4 mr-4 flex justify-center items-center bg-green-500 hover:bg-green-700 focus:ring-green-500 focus:ring-offset-green-200 text-white w-full transition ease-in duration-200 text-center text-xs font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg"
            >
              <svg
                width="10"
                height="10"
                fill="currentColor"
                class="h-6 w-6 text-white mr-2"
                viewBox="0 0 1792 1792"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M491 1536l91-91-235-235-91 91v107h128v128h107zm523-928q0-22-22-22-10 0-17 7l-542 542q-7 7-7 17 0 22 22 22 10 0 17-7l542-542q7-7 7-17zm-54-192l416 416-832 832h-416v-416zm683 96q0 53-37 90l-166 166-416-416 166-165q36-38 90-38 53 0 91 38l235 234q37 39 37 91z"
                ></path>
              </svg>
              Editar
            </button>
          </div>
          <div
            v-if="!$store.state.userData.id && $store.state.regUser.id"
            class="flex items-center justify-end"
          >
            <button
              type="button"
              @click="editar"
              class="max-w-min py-2 px-4 mr-4 flex justify-center items-center bg-green-500 hover:bg-green-700 focus:ring-green-500 focus:ring-offset-green-200 text-white w-full transition ease-in duration-200 text-center text-xs font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg"
            >
              <svg
                width="10"
                height="10"
                fill="currentColor"
                class="h-6 w-6 text-white mr-2"
                viewBox="0 0 1792 1792"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M491 1536l91-91-235-235-91 91v107h128v128h107zm523-928q0-22-22-22-10 0-17 7l-542 542q-7 7-7 17 0 22 22 22 10 0 17-7l542-542q7-7 7-17zm-54-192l416 416-832 832h-416v-416zm683 96q0 53-37 90l-166 166-416-416 166-165q36-38 90-38 53 0 91 38l235 234q37 39 37 91z"
                ></path>
              </svg>
              Agregar
            </button>
          </div>
        </div>
        <formPersonData
          :regUser="$store.state.regUser"
          :userData="$store.state.userData"
        ></formPersonData>
      </div>
    </div>

    <div v-if="editing" class="flex flex-col bg-white p-3 rounded-lg shadow-lg">
      <div
        class="bg-white self-center mb-5 text-xl font-bold text-gray-800 sm:text-2xl dark:text-white w-full text-center"
      >
        Datos personales
      </div>
      <form @submit.stop.prevent="onSubmit">
        <div
          class="self-center mb-2 font-semibold text-gray-800 sm:text-lg dark:text-white w-full"
        >
          Información de usuario
        </div>

        <hr class="mt-1 mb-1" />

        <div class="grid grid-rows-2 grid-flow-col max-w-full">
          <div class="row-span-full">
            <div class="p-2">
              <div class="flex-col flex justify-center items-center">
                <div class="flex-shrink-0">
                  <a href="#" class="block relative">
                    <img
                      alt="profil"
                      src="./img/profilepic.png"
                      class="mx-auto object-cover rounded-full h-20 w-20 border"
                    />
                  </a>
                </div>
                <div class="mt-1 text-center flex flex-col place-items-center">
                  <span
                    class="text-gray-900 dark:text-white text-md font-medium"
                  >
                    {{
                      $store.state.regUser.nombreUser +
                      " " +
                      $store.state.regUser.apellidoUser
                    }}
                  </span>

                  <button
                    type="button"
                    class="hidden py-1 px-2 flex justify-center items-center bg-green-500 hover:bg-green-700 focus:ring-green-400 focus:ring-offset-green-300 text-white w-32 transition ease-in duration-200 text-center text-xs font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg"
                  >
                    <svg
                      width="20"
                      height="20"
                      fill="currentColor"
                      class="mr-2"
                      viewBox="0 0 1792 1792"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1344 1472q0-26-19-45t-45-19-45 19-19 45 19 45 45 19 45-19 19-45zm256 0q0-26-19-45t-45-19-45 19-19 45 19 45 45 19 45-19 19-45zm128-224v320q0 40-28 68t-68 28h-1472q-40 0-68-28t-28-68v-320q0-40 28-68t68-28h427q21 56 70.5 92t110.5 36h256q61 0 110.5-36t70.5-92h427q40 0 68 28t28 68zm-325-648q-17 40-59 40h-256v448q0 26-19 45t-45 19h-256q-26 0-45-19t-19-45v-448h-256q-42 0-59-40-17-39 14-69l448-448q18-19 45-19t45 19l448 448q31 30 14 69z"
                      ></path>
                    </svg>
                    Subir imagen
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="row-span-auto p-1">
            <div class="flex gap-2 pt-4">
              <div class="w-full md:w-1/3 px-2 mb-4 md:mb-0">
                <label
                  for="price"
                  class="block text-xs font-medium text-gray-700 text-left ml-1"
                >
                  <strong> Nombres</strong>
                </label>
                <input
                  class="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 pr-8 text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:border-transparent bg-gray-200 font-semibold"
                  type="text"
                  name="nombre"
                  :placeholder="$store.state.regUser.nombreUser"
                  disabled
                />
              </div>
              <div class="w-full md:w-1/3 px-2 mb-4 md:mb-0">
                <label
                  for="price"
                  class="block text-xs font-medium text-gray-700 text-left ml-1"
                >
                  <strong> Apellidos</strong>
                </label>

                <input
                  class="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 pr-8 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:border-transparent bg-gray-200 font-semibold"
                  type="text"
                  name="apellidoUser"
                  :placeholder="$store.state.regUser.apellidoUser"
                  disabled
                />
              </div>
            </div>
          </div>

          <div class="row-span-auto p-1">
            <div class="flex gap-2">
              <div class="flex flex-wrap mb-1">
                <label
                  for="price"
                  class="block text-xs font-medium text-gray-700 text-left ml-2"
                >
                  <strong>Identificación</strong>
                  <select
                    class="block w-28 text-gray-700 py-2.5 px-2 border border-gray-300 bg-white rounded-md shadow-sm text-sm focus:outline-none focus:ring-2 focus:border-primary-500"
                    name="tipoID"
                    v-model.lazy.trim="data.tipoDocument"
                  >
                    <option disabled value="">Tipo ID</option>
                    <option value="cedula">Cédula</option>
                    <option value="pasaporte">Pasaporte</option>
                  </select>
                </label>
              </div>

              <div class="relative">
                <label
                  for="price"
                  class="block text-xs font-medium text-gray-700 text-left ml-1"
                >
                  <strong> Nro. de identidad</strong>
                </label>
                <input
                  class="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 pr-8 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:border-transparent"
                  type="text"
                  name="numeroID"
                  placeholder="Numero de identidad "
                  :maxlength="9"
                  v-on:keypress="numbersOnly"
                  v-model.lazy.trim="data.nroDocument"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-wrap -mx-3 mb-2">
          <div class="w-full md:w-1/3 px-2 mb-4 md:mb-0">
            <label
              for="price"
              class="block text-xs font-medium text-gray-700 text-left ml-1"
            >
              <strong> Fecha de nacimiento</strong>
            </label>
            <input
              class="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 pr-8 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:border-transparent"
              type="text"
              name="fechaNacimientUser"
              placeholder="Fecha de Nacimiento"
              onfocus="(this.type='date')"
              onblur="(this.type='text')"
              v-model.lazy.trim="data.fech_nacimnt"
            />
          </div>
          <div class="w-full md:w-1/3 px-2 mb-4 md:mb-0">
            <div class="relative">
              <label
                for="price"
                class="block text-xs font-medium text-gray-700 text-left ml-1"
              >
                <strong> Nacionalidad</strong>
                <select
                  class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:ring-2"
                  id="grid-state"
                  v-model.lazy.trim="data.nacionalidad"
                >
                  <option class="text-gray-300" disabled value="">
                    Nacionalidad
                  </option>

                  <option>Venezuela</option>
                </select>
              </label>
              <div
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center mt-4 px-2 text-gray-700"
              >
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div class="w-full md:w-1/3 px-2 mb-4 md:mb-0">
            <div class="relative">
              <label
                for="price"
                class="block text-xs font-medium text-gray-700 text-left ml-1"
              >
                <strong> Estado civil</strong>
                <select
                  class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:ring-2"
                  id="grid-state"
                  v-model.lazy.trim="data.estadCivil"
                >
                  <option disabled value="" selected>Estado civil</option>
                  <option>Soltero</option>
                  <option>Casado</option>
                  <option>Divorciado</option>
                  <option>Viudo</option>
                </select>
              </label>
              <div
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center mt-4 px-2 text-gray-700"
              >
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>

        <hr class="mt-1 mb-2" />

        <div class="flex flex-wrap -mx-3 mb-2">
          <div class="w-full md:w-1/3 px-2 mb-4 md:mb-0">
            <div class="relative">
              <label
                for="price"
                class="block text-xs font-medium text-gray-700 text-left ml-1"
              >
                <strong> Género</strong>
                <select
                  class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:ring-2"
                  id="grid-state"
                  v-model.lazy.trim="data.genero"
                >
                  <option disabled value="" selected>Genero</option>
                  <option>Femenino</option>
                  <option>Masculino</option>
                </select>
              </label>
              <div
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center mt-4 px-2 text-gray-700"
              >
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div class="w-full md:w-1/3 px-2 mb-4 md:mb-0">
            <div class="relative">
              <label
                for="price"
                class="block text-xs font-medium text-gray-700 text-left ml-1"
              >
                <strong> Discapacidad</strong>
                <select
                  class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:ring-2"
                  id="grid-state"
                  v-model.lazy.trim="data.discapacitad"
                >
                  <option disabled value="" selected>Discapacidad</option>
                  <option>Si</option>
                  <option>No</option>
                </select>
              </label>
              <div
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center mt-4 px-2 text-gray-700"
              >
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div class="w-full md:w-1/3 px-2 mb-4 md:mb-0">
            <div class="relative">
              <label
                for="price"
                class="block text-xs font-medium text-gray-700 text-left ml-1"
              >
                <strong> Licencia de conducir</strong>
                <select
                  class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:ring-2"
                  id="grid-state"
                  v-model.lazy.trim="data.licencia"
                >
                  <option disabled value="" selected>
                    Licencia de conducir
                  </option>
                  <option>Si</option>
                  <option>No</option>
                </select>
              </label>
              <div
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center mt-4 px-2 text-gray-700"
              >
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-col mb-2">
          <div class="relative">
            <label
              for="price"
              class="block text-xs font-medium text-gray-700 text-left ml-1"
            >
              <strong> Dirección</strong>
            </label>
            <textarea
              class="flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 rounded-lg text-base focus:outline-none focus:ring-2 focus:border-transparent"
              id="comment"
              placeholder="Dirección"
              name="comment"
              rows="2"
              cols="40"
              v-model.lazy.trim="data.direccion"
            >
            </textarea>
          </div>
        </div>
        <div class="flex flex-wrap justify-end mt-6">
          <div class="flex flex-wrap justify-end mt-1">
            <button
              type="submit"
              class="py-2 px-4 mr-4 bg-blue-500 hover:bg-blue-500 focus:ring-blue-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg"
            >
              Guardar
            </button>
          </div>
          <div class="flex flex-wrap justify-end mt-1">
            <button
              @click="editFinished"
              class="py-2 px-4 bg-green-500 hover:bg-green-500 focus:ring-green-500 focus:ring-offset-green-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg"
            >
              Cerrar
            </button>
          </div>
        </div>
      </form>
    </div>

    <loader v-if="isLoading">
      <template v-slot:msg>
        {{ msg }}
      </template>

      <template v-slot:msg1>
        {{ msg1 }}
      </template>
    </loader>
  </div>
</template>
<style scoped>
.bg-custom-postul2-blue {
  /*azul*/
  --tw-bg-opacity: 1;
  background-color: rgba(19, 73, 146, var(--tw-bg-opacity));
}
.bg-button-postul2-yellow {
  /*amarillo*/
  --tw-bg-opacity: 1;
  background-color: rgba(239, 224, 37, var(--tw-bg-opacity));
}
.hover\:bg-postuladosyellow-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(19, 73, 146, var(--tw-bg-opacity));
}
.bg-separador-postul2-yellow {
  /*amarillo*/
  --tw-bg-opacity: 1;
  border-color: rgba(239, 224, 37, var(--tw-bg-opacity));
}
.text-blue-postulados {
  --tw-text-opacity: 1;
  color: rgba(19, 73, 146, var(--tw-bg-opacity));
}
</style>

<script>
import axios from "axios";
import loader from "@/components/shared/loader.vue";
import formPersonData from "@/components/formPersonDataUser.vue";

export default {
  name: "datosPersonales",
  components: {
    loader,
    formPersonData,
  },

  methods: {
    numbersOnly(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    editarCampo() {
      this.editarFlag = !this.editarFlag;
    },
    editar() {
      this.editing = true;
    },
    editFinished() {
      this.editing = false;
      //https://api.postula2.com/persona/624745e3e602450004288516/experncLabors
      axios({
        method: "POST",
        url: `https://api.postula2.com/personByOwnerId`,
        headers: {
          Authorization: this.$store.state.myTokToApiComs,
          //'X-CSRF-Token': '-s?T+H?w!u8qBJ7Lfvi/1rl8J-ITm2jyubx!qvCV3+Uv1zh5Jtz+JgmZUL6Fh3LN9MItahr49SY='
        },
        data: {
          ownerId: this.$store.state.regUser.id,
        },
      })
        .then((res) => {
          this.$store.commit("getUserData", res.data.persona[0]);
          this.$store.commit("getRegUser", res.data.persona[0].owner);
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG(
          //   "mission pelaste bolas in consulta RegistroPrimarioUser in DatPerson"
          // );
          //CLG(error);
        })
        .finally(() => {
          //Perform action in always
        });
    },
    onSubmit() {
      //let dataUser = this.data;
      this.isLoading = true;
      this.data.owner = this.$store.state.regUser.id;
      //CLG("data: ", this.data);
      axios({
        method: "POST",
        url: `https://api.postula2.com/newPerson`,
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.myTokToApiComs,
        },
        data: this.data,
      })
        // axios
        //   .post("https://api.postula2.com/persona", this.data)
        .then((res) => {
          //Perform Success Action
          //CLG("response:", res.data);
          this.msg = "¡Persona registrada satisfactoriamente...!";
          this.$store.commit("getUserData", res.data.data);

          //this.$store.commit("getflagLogin", redFlag);
          this.$store.commit({
            type: "getflagLogin",
            greenFlag: true,
          });
          setTimeout(() => {
            this.editFinished();
            // this.data.nombreUser = "";
            // this.data.apellidoUser = "";
            // this.data.emailUser = "";
            // this.data.telf1 = "";

            this.data.fech_nacimnt = "";
            this.data.genero = "";
            this.data.nacionalidad = "";
            this.data.tipoDocument = "";
            this.data.nroDocument = "";
            this.data.discapacitad = "";
            this.data.estadCivil = "";
            this.data.telf2 = "";
            this.data.direccion = "";
            this.data.imgPerfil = "";
            this.data.currcVitArchiv = "";
            this.data.owner = null;
            //this.editing = false;
            this.isLoading = false;
            this.$store.commit({
              type: "getflagLogin",
              greenFlag: null,
            });
          }, 2000);
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG(error);
          this.msg1 =
            "¡Verifique la información ingresada e intente nuevamente!";
          //this.$store.commit("getflagLogin", redFlag);
          this.$store.commit({
            type: "getflagLogin",
            redFlag: true,
          });
          setTimeout(() => {
            this.isLoading = false;
            this.$store.commit({
              type: "getflagLogin",
              redFlag: null,
            });
          }, 3000);
        })
        .finally(() => {
          //Perform action in always
        });
    },
  },
  created() {
    if (!this.$store.state.regUser.id) {
      this.$router.push({
        name: "homec",
      });

      ///////////ENCIENDE NOTIFICACION VIA SLOT/////Datos incorrectos//////
      this.msg1 = "¡No pueden existir campos vacios!";
      //this.$store.commit("getflagLogin", redFlag);
      this.$store.commit({
        type: "getflagLogin",
        redFlag: true,
      });
      setTimeout(() => {
        this.isLoading = false;
        this.$store.commit({
          type: "getflagLogin",
          redFlag: null,
        });
      }, 2000);
      //////////////////////////////////////////////////
    }
  },
  data() {
    return {
      tieneDiscp: false,
      tieneLicencia: false,

      editarFlag: false,
      editing: false,
      isLoading: false,
      msg: null,
      msg1: null,

      data: {
        // idRegPrimUser: "",
        // nombreUser: "",
        // apellidoUser: "",
        // emailUser: "",
        // telf1: "",
        // idPersonUser: "",
        fech_nacimnt: "",
        genero: "",
        nacionalidad: "",
        tipoDocument: "",
        nroDocument: "",
        discapacitad: "",
        estadCivil: "",
        telf2: "",
        direccion: "",
        imgPerfil: "",
        currcVitArchiv: "",
        owner: null,
      },
    };
  },
};
</script>
