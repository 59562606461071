<template>
  <div>
    <main class="bg-gray-50 dark:bg-gray-800 overflow-y-auto h-auto">
      <div class="flex items-start justify-between">
        <div class="flex flex-col w-full md:space-y-4">
          <div
            class="overflow-visible pb-24 px-4 md:px-6 md:pt-4 md:mb-8 lg:pt-6 border-dashed border-red-500"
          >
            <div
              class="pt-2 flex items-center space-x-4 font-light w-full text-center text-xl sm:text-md md:text-2xl dark:text-white text-gray-800 border-b border-gray-200"
            >
              Perfil de empresa
            </div>
            <div
              class="flex items-center mt-0.5 font-light w-1/6 text-center text-xs sm:text-xs md:text-xs dark:text-white text-blue-500"
            >
              <span @click="volver" class="cursor-pointer">⇤ Volver</span>
            </div>
            <div class="grid sm:grid-cols-1 lg:grid-cols-1 gap-4 mt-2 mb-4">
              <!--Contenido-->

              <div class="lg:flex no-wrap md:-mx-2 md:px-12">
                <!-- Left Side -->
                <div class="w-full lg:w-1/5 md:mx-2">
                  <!-- Profile Card -->
                  <ImagenPerfilyStatus
                    :key="
                      $store.state.profileImage.uploadedEmprsImgComponntValue
                    "
                  ></ImagenPerfilyStatus>
                  <!-- End of profile card -->
                  <div class="my-4"></div>
                </div>
                <!-- Right Side -->
                <div class="w-full lg:w-4/5 md:mx-2 h-64 bg-white">
                  <!-- Profile tab -->
                  <!-- About Section -->
                  <div class="bg-white px-6 py-4 shadow rounded-md text-left">
                    <div
                      class="flex items-center space-x-2 font-semibold text-gray-900 leading-8"
                    >
                      <span clas="text-green-500">
                        <svg
                          class="h-5"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                          />
                        </svg>
                      </span>
                      <span class="tracking-wide">Información de perfil</span>
                    </div>
                    <div class="text-gray-700">
                      <div class="grid lg:grid-cols-2 text-sm">
                        <div class="grid grid-cols-3">
                          <div class="px-1 py-2 font-semibold">Nombre</div>
                          <div
                            v-if="
                              !nombreUserEmprs.editing &&
                              !apellidoUserEmprs.editing
                            "
                            class="px-1 py-2"
                          >
                            {{
                              nombreUserEmprs.value +
                              " " +
                              apellidoUserEmprs.value
                            }}
                          </div>
                          <div
                            v-if="
                              !nombreUserEmprs.editing &&
                              !apellidoUserEmprs.editing
                            "
                            class="px-4 py-2"
                          >
                            <button
                              v-if="!nombreUserEmprs.editing"
                              @click.prevent="
                                enableEditingNombreUserEmprs(),
                                  enableEditingApellidoUserEmprs()
                              "
                              class="flex items-center p-1 transition ease-in duration-200 uppercase rounded-full hover:bg-blue-800 hover:text-yellow-200 focus:outline-none"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="h-3.5 w-3.5"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                stroke-width="2"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                                />
                              </svg>
                            </button>
                          </div>
                          <div class="grid-cols-1 space-y-1">
                            <FormulateForm #default="{ hasErrors }">
                              <div
                                v-if="nombreUserEmprs.editing"
                                class="flex w-44 lg:w-44"
                              >
                                <FormulateInput
                                  type="text"
                                  name="nombre"
                                  :value="nombreUserEmprs.tempValue"
                                  v-model="nombreUserEmprs.tempValue"
                                  placeholder="Nombre"
                                  validation-behavior="value"
                                  validation="required|min:4|matches:/^[A-Za-z\s*\á\é\í\ó\ú\ñ]+$/"
                                  class="rounded-lg appearance-none px-2 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-sm"
                                  :validation-messages="{
                                    min: 'Debe tener al menos 4 caracteres',
                                    matches: 'Este campo solo acepta letras',
                                    required: 'Este campo es obligatorio',
                                  }"
                                />
                                <button
                                  :disabled="hasErrors"
                                  class="h-8 align-middle ml-1 p-1 items-center transition ease-in duration-200 uppercase rounded-full border border-green-200 text-green-600 hover:border-green-600 hover:bg-green-600 hover:text-yellow-200 focus:outline-none"
                                  @click="saveEditNombreUserEmprs"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="h-5 w-5"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    stroke-width="2"
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
                                    />
                                  </svg>
                                </button>
                                <button
                                  class="h-8 ml-2 align-middle items-center p-1 transition ease-in duration-200 uppercase rounded-full border border-red-300 text-red-500 hover:bg-red-800 hover:text-yellow-200 focus:outline-none"
                                  @click="disableEditingNombreUserEmprs"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="h-5 w-5"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    stroke-width="2"
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                                    />
                                  </svg>
                                </button>
                              </div>
                              <div
                                v-if="apellidoUserEmprs.editing"
                                class="flex w-44 lg:w-44"
                              >
                                <FormulateInput
                                  type="text"
                                  name="apellido"
                                  :value="apellidoUserEmprs.tempValue"
                                  v-model="apellidoUserEmprs.tempValue"
                                  validation-behavior="live"
                                  placeholder="Apellido"
                                  validation="required|min:4|matches:/^[A-Za-z\s*\á\é\í\ó\ú\ñ]+$/"
                                  class="rounded-lg appearance-none px-2 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-sm"
                                  :validation-messages="{
                                    min: 'Debe tener al menos 4 caracteres',
                                    matches: 'Este campo solo acepta letras',
                                    required: 'Este campo es obligatorio',
                                  }"
                                />
                                <button
                                  :disabled="hasErrors"
                                  class="h-8 align-middle ml-1 p-1 items-center transition ease-in duration-200 uppercase rounded-full border border-green-200 text-green-600 hover:border-green-600 hover:bg-green-600 hover:text-yellow-200 focus:outline-none"
                                  @click="saveEditApellidoUserEmprs"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="h-5 w-5"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    stroke-width="2"
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
                                    />
                                  </svg>
                                </button>
                                <button
                                  class="h-8 ml-2 align-middle items-center p-1 transition ease-in duration-200 uppercase rounded-full border border-red-300 text-red-500 hover:bg-red-800 hover:text-yellow-200 focus:outline-none"
                                  @click="disableEditingApellidoUserEmprs"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="h-5 w-5"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    stroke-width="2"
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                                    />
                                  </svg>
                                </button>
                              </div>
                            </FormulateForm>
                          </div>
                        </div>
                        <div class="grid grid-cols-3">
                          <div class="px-1 py-2 font-semibold">
                            Nombre de empresa
                          </div>
                          <div v-if="!nombreEmpr.editing" class="px-1 py-2">
                            {{ nombreEmpr.value }}
                          </div>
                          <div v-if="!nombreEmpr.editing" class="px-4 py-2">
                            <button
                              v-if="!nombreEmpr.editing"
                              @click.prevent="enableEditingNombreEmpr()"
                              class="flex items-center p-1 transition ease-in duration-200 uppercase rounded-full hover:bg-blue-800 hover:text-yellow-200 focus:outline-none"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="h-3.5 w-3.5"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                stroke-width="2"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                                />
                              </svg>
                            </button>
                          </div>
                          <FormulateForm
                            #default="{ hasErrors }"
                            v-if="nombreEmpr.editing"
                            class="flex w-44 lg:w-44"
                          >
                            <FormulateInput
                              type="text"
                              name="nombre"
                              :value="nombreEmpr.tempValue"
                              v-model="nombreEmpr.tempValue"
                              placeholder="Nombre"
                              validation-behavior="value"
                              validation="required|min:4|matches:/^[A-Za-z0-9\s*\á\é\í\ó\ú\ñ]+$/"
                              class="rounded-lg appearance-none w-96 px-2 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-sm"
                              :validation-messages="{
                                min: 'Debe tener al menos 4 caracteres',
                                matches:
                                  'Este campo solo acepta letras y numeros',
                                required: 'Este campo es obligatorio',
                              }"
                            /><button
                              :disabled="hasErrors"
                              class="h-8 align-middle ml-1 p-1 items-center transition ease-in duration-200 uppercase rounded-full border border-green-200 text-green-600 hover:border-green-600 hover:bg-green-600 hover:text-yellow-200 focus:outline-none"
                              @click="saveEditNombreEmpr"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="h-5 w-5"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                stroke-width="2"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
                                />
                              </svg>
                            </button>
                            <button
                              class="h-8 ml-2 align-middle items-center p-1 transition ease-in duration-200 uppercase rounded-full border border-red-300 text-red-500 hover:bg-red-800 hover:text-yellow-200 focus:outline-none"
                              @click="disableEditingNombreEmpr"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="h-5 w-5"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                stroke-width="2"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                                />
                              </svg>
                            </button>
                          </FormulateForm>
                        </div>
                        <div class="grid grid-cols-3">
                          <div class="px-1 py-2 font-semibold">
                            Correo electrónico
                          </div>
                          <div v-if="!emailUserEmprs.editing" class="px-1 py-2">
                            {{ emailUserEmprs.value }}
                          </div>
                          <div v-if="!emailUserEmprs.editing" class="px-7 py-2">
                            <button
                              v-if="!emailUserEmprs.editing"
                              @click="enableEditingEmailUserEmprs()"
                              class="flex items-center p-1 transition ease-in duration-200 uppercase rounded-full hover:bg-blue-800 hover:text-yellow-200 focus:outline-none"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="h-3.5 w-3.5"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                stroke-width="2"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                                />
                              </svg>
                            </button>
                          </div>
                          <FormulateForm
                            #default="{ hasErrors }"
                            v-if="emailUserEmprs.editing"
                            class="flex w-44"
                          >
                            <FormulateInput
                              type="text"
                              name="Correo"
                              :value="emailUserEmprs.tempValue"
                              v-model="emailUserEmprs.tempValue"
                              placeholder="Nombre"
                              validation-behavior="value"
                              error-behavior="live"
                              validation="required|email"
                              class="rounded-lg appearance-none bg-white text-gray-700 placeholder-gray-400 shadow-sm text-sm"
                              :validation-messages="{
                                email: 'No es un correo electrónico valido',
                                required: 'Este campo es obligatorio',
                              }"
                            /><button
                              :disabled="hasErrors"
                              class="h-8 align-middle ml-1 p-1 items-center transition ease-in duration-200 uppercase rounded-full border border-green-200 text-green-600 hover:border-green-600 hover:bg-green-600 hover:text-yellow-200 focus:outline-none"
                              @click="saveEditEmailUserEmprs"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="h-5 w-5"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                stroke-width="2"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
                                />
                              </svg>
                            </button>
                            <button
                              class="h-8 ml-2 align-middle items-center p-1 transition ease-in duration-200 uppercase rounded-full border border-red-300 text-red-500 hover:bg-red-800 hover:text-yellow-200 focus:outline-none"
                              @click="disableEditingEmailUserEmprs"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="h-5 w-5"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                stroke-width="2"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                                />
                              </svg>
                            </button>
                          </FormulateForm>
                        </div>
                        <div class="grid grid-cols-3">
                          <div class="px-1 py-2 font-semibold">
                            Razón social
                          </div>
                          <div class="px-1 py-2">
                            {{
                              $store.state.regUserEmprs.empresa[0]
                                .razonSocialEmpr
                            }}
                          </div>
                          <div class="px-4 py-2 hidden">
                            <button
                              class="flex items-center p-1 transition ease-in duration-200 uppercase rounded-full hover:bg-blue-800 hover:text-yellow-200 focus:outline-none"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="h-3.5 w-3.5"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                stroke-width="2"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                                />
                              </svg>
                            </button>
                          </div>
                        </div>
                        <div class="grid grid-cols-3">
                          <div class="px-1 py-2 font-semibold">Dirección</div>
                          <div v-if="!direccEmpr.editing" class="px-1 py-2">
                            {{ direccEmpr.value }}
                          </div>
                          <div v-if="!direccEmpr.editing" class="px-4 py-2">
                            <button
                              v-if="!direccEmpr.editing"
                              @click="enableEditingDireccEmpr()"
                              class="flex items-center p-1 transition ease-in duration-200 uppercase rounded-full hover:bg-blue-800 hover:text-yellow-200 focus:outline-none"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="h-3.5 w-3.5"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                stroke-width="2"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                                />
                              </svg>
                            </button>
                          </div>
                          <div v-if="direccEmpr.editing" class="flex my-2 w-44">
                            <FormulateForm
                              #default="{ hasErrors }"
                              class="flex"
                            >
                              <FormulateInput
                                type="textarea"
                                v-model="direccEmpr.tempValue"
                                class="bg-white"
                                :value="direccEmpr.tempValue"
                                rows="2"
                                cols="50"
                                validation="required|max:200,length|matches:/^[A-Za-z0-9\s\á\é\í\ó\ú\ñ\-\.]+$/"
                                validation-name="comment"
                                error-behavior="value"
                                :help="`Maximo 200 caracteres. ${
                                  200 - direccEmpr.tempValue.length
                                } restantes.`"
                                :validation-messages="{
                                  required: 'Debes rellenar este campo',
                                  max: 'Texto debe ser menor a 200 caracteres',
                                  matches:
                                    'Este campo solo acepta letras y numeros',
                                }"
                                errors-class="text-left ml-1"
                                help-class="text-left ml-1 text-xs font-semibold"
                              />
                              <FormulateInput
                                type="submit"
                                :disabled="hasErrors"
                                @click="saveEditDireccEmpr"
                                outer-class="
                    mx-1
                      flex
                    items-center
                    p-1
                    pt-2
                    transition
                    ease-in
                    duration-200
                    uppercase
                    rounded-full
                    border border-green-300
                    text-green-500
                    hover:bg-green-800 hover:text-yellow-200
                    focus:outline-none
                    h-8"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  class="h-5 w-5"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                  stroke-width="2"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
                                  />
                                </svg>
                              </FormulateInput>
                              <FormulateInput
                                type="submit"
                                @click="disableEditingDireccEmpr"
                                outer-class="
                      flex
                    items-center
                    p-1
                    pt-2
                    transition
                    ease-in
                    duration-200
                    uppercase
                    rounded-full
                    border border-red-300
                    text-red-500
                    hover:bg-red-800 hover:text-yellow-200
                    focus:outline-none
                    h-8"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  class="h-5 w-5"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                  stroke-width="2"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                                  />
                                </svg>
                              </FormulateInput>
                            </FormulateForm>
                          </div>
                        </div>
                        <div class="grid grid-cols-3">
                          <div class="px-1 py-2 font-semibold">
                            Registro de Identificación Fiscal (RIF)
                          </div>
                          <div class="px-1 py-2">
                            {{ $store.state.regUserEmprs.empresa[0].rifEmpr }}
                          </div>
                          <div class="px-4 py-2 hidden">
                            <button
                              class="flex items-center p-1 transition ease-in duration-200 uppercase rounded-full hover:bg-blue-800 hover:text-yellow-200 focus:outline-none"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="h-3.5 w-3.5"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                stroke-width="2"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                                />
                              </svg>
                            </button>
                          </div>
                        </div>
                        <div class="grid grid-cols-3">
                          <div class="px-1 py-2 font-semibold">Empleados</div>
                          <div v-if="!cantEmpleads.editing" class="px-1 py-2">
                            {{ cantEmpleads.value }}
                          </div>
                          <div v-if="!cantEmpleads.editing" class="px-4 py-2">
                            <button
                              v-if="!cantEmpleads.editing"
                              @click="enableEditingCantEmpleads()"
                              class="flex items-center p-1 transition ease-in duration-200 uppercase rounded-full hover:bg-blue-800 hover:text-yellow-200 focus:outline-none"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="h-3.5 w-3.5"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                stroke-width="2"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                                />
                              </svg>
                            </button>
                          </div>

                          <div v-if="cantEmpleads.editing" class="flex h-8">
                            <input
                              v-model="cantEmpleads.tempValue"
                              type="number"
                              class="w-24 lg:w-28 rounded-lg border-transparent appearance-none border border-gray-300 py-1 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-sm focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                            /><button
                              class="align-middle ml-1 p-1 items-center transition ease-in duration-200 uppercase rounded-full border border-green-200 text-green-600 hover:border-green-600 hover:bg-green-600 hover:text-yellow-200 focus:outline-none"
                              @click="saveEditCantEmpleads"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="h-5 w-5"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                stroke-width="2"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
                                />
                              </svg>
                            </button>
                            <button
                              class="ml-2 align-middle items-center p-1 transition ease-in duration-200 uppercase rounded-full border border-red-300 text-red-500 hover:bg-red-800 hover:text-yellow-200 focus:outline-none"
                              @click="disableEditingCantEmpleads"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="h-5 w-5"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                stroke-width="2"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                                />
                              </svg>
                            </button>
                          </div>
                        </div>

                        <div class="grid grid-cols-3">
                          <div class="px-1 py-2 font-semibold">ID</div>
                          <div class="px-1 py-2">
                            {{ $store.state.regUserEmprs.empresa[0].id }}
                          </div>
                        </div>
                      </div>
                      <div class="grid grid-cols-1 text-sm">
                        <div class="flex">
                          <div class="px-1 py-2 font-semibold">
                            Descripción de empresa
                          </div>
                          <div v-if="!descEmpr.editing" class="px-4 py-2">
                            <button
                              v-if="!descEmpr.editing"
                              @click="enableEditingDescEmpr()"
                              class="flex items-center p-1 transition ease-in duration-200 uppercase rounded-full hover:bg-blue-800 hover:text-yellow-200 focus:outline-none"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="h-3.5 w-3.5"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                stroke-width="2"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                                />
                              </svg>
                            </button>
                          </div>
                        </div>
                        <div v-if="!descEmpr.editing" class="px-1 py-2">
                          {{ displayTextActivs }}
                        </div>
                        <div class="flex">
                          <a
                            href="javascript:void(0)"
                            class="ml-auto md:block text-xs text-gray-500 dark:text-gray-300. underline"
                            @click="toggle"
                          >
                            Ver {{ toggled ? "menos" : "más" }}
                          </a>
                        </div>

                        <div
                          v-if="descEmpr.editing"
                          class="flex w-60 lg:w-60 md:w-32"
                        >
                          <FormulateForm #default="{ hasErrors }" class="flex">
                            <FormulateInput
                              type="textarea"
                              v-model="descEmpr.tempValue"
                              class="bg-white"
                              :value="descEmpr.tempValue"
                              rows="4"
                              cols="50"
                              validation="required|max:200,length|matches:/^[A-Za-z0-9\s\á\é\í\ó\ú\ñ\-\.]+$/"
                              validation-name="comment"
                              error-behavior="value"
                              :help="`Maximo 200 caracteres. ${
                                200 - descEmpr.tempValue.length
                              } restantes.`"
                              :validation-messages="{
                                required: 'Debes rellenar este campo',
                                max: 'Texto debe ser menor a 200 caracteres',
                                matches:
                                  'Este campo solo acepta letras y numeros',
                              }"
                              errors-class="text-left ml-1"
                              help-class="text-left ml-1 text-xs font-semibold"
                            />
                            <FormulateInput
                              type="submit"
                              :disabled="hasErrors"
                              @click="saveEditDescEmpr"
                              outer-class="
                            mx-1
                              flex
                            items-center
                            p-1
                            pt-2
                            transition
                            ease-in
                            duration-200
                            uppercase
                            rounded-full
                            border border-green-300
                            text-green-500
                            hover:bg-green-800 hover:text-yellow-200
                            focus:outline-none
                            h-8"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="h-5 w-5"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                stroke-width="2"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
                                />
                              </svg>
                            </FormulateInput>
                            <FormulateInput
                              type="submit"
                              @click="disableEditingDescEmpr"
                              outer-class="
                            flex
                          items-center
                          p-1
                          pt-2
                          transition
                          ease-in
                          duration-200
                          uppercase
                          rounded-full
                          border border-red-300
                          text-red-500
                          hover:bg-red-800 hover:text-yellow-200
                          focus:outline-none
                          h-8"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="h-5 w-5"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                stroke-width="2"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                                />
                              </svg>
                            </FormulateInput>
                          </FormulateForm>
                        </div>
                      </div>
                      <!--Final Empresa-->
                    </div>

                    <button
                      class="hidden block w-full text-blue-800 text-sm font-semibold rounded-lg hover:bg-gray-100 focus:outline-none focus:shadow-outline focus:bg-gray-100 hover:shadow-xs p-3 my-4"
                    >
                      Show Full Information
                    </button>
                  </div>
                  <!-- End of about section -->

                  <div class="my-4"></div>

                  <!-- Experience and education -->
                  <div class="bg-white hidden p-3 shadow-sm rounded-sm">
                    <div class="grid grid-cols-2">
                      <div>
                        <div
                          class="flex items-center space-x-2 font-semibold text-gray-900 leading-8 mb-3"
                        >
                          <span clas="text-green-500">
                            <svg
                              class="h-5"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                              />
                            </svg>
                          </span>
                          <span class="tracking-wide">Experience</span>
                        </div>
                        <ul class="list-inside space-y-2">
                          <li>
                            <div class="text-teal-600">
                              Owner at Her Company Inc.
                            </div>
                            <div class="text-gray-500 text-xs">
                              March 2020 - Now
                            </div>
                          </li>
                          <li>
                            <div class="text-teal-600">
                              Owner at Her Company Inc.
                            </div>
                            <div class="text-gray-500 text-xs">
                              March 2020 - Now
                            </div>
                          </li>
                          <li>
                            <div class="text-teal-600">
                              Owner at Her Company Inc.
                            </div>
                            <div class="text-gray-500 text-xs">
                              March 2020 - Now
                            </div>
                          </li>
                          <li>
                            <div class="text-teal-600">
                              Owner at Her Company Inc.
                            </div>
                            <div class="text-gray-500 text-xs">
                              March 2020 - Now
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div>
                        <div
                          class="flex items-center space-x-2 font-semibold text-gray-900 leading-8 mb-3"
                        >
                          <span clas="text-green-500">
                            <svg
                              class="h-5"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path fill="#fff" d="M12 14l9-5-9-5-9 5 9 5z" />
                              <path
                                fill="#fff"
                                d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z"
                              />
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"
                              />
                            </svg>
                          </span>
                          <span class="tracking-wide">Education</span>
                        </div>
                        <ul class="list-inside space-y-2">
                          <li>
                            <div class="text-teal-600">
                              Masters Degree in Oxford
                            </div>
                            <div class="text-gray-500 text-xs">
                              March 2020 - Now
                            </div>
                          </li>
                          <li>
                            <div class="text-teal-600">
                              Bachelors Degreen in LPU
                            </div>
                            <div class="text-gray-500 text-xs">
                              March 2020 - Now
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <!-- End of Experience and education grid -->
                  </div>
                  <!-- End of profile tab -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>

    <!-- pendiente -->
  </div>
</template>

<style scoped>
:root {
  --main-color: #4a76a8;
}

.bg-main-color {
  background-color: var(--main-color);
}

.text-main-color {
  color: var(--main-color);
}

.border-main-color {
  border-color: var(--main-color);
}
</style>

<script>
import axios from "axios";
import ImagenPerfilyStatus from "@/components/empresa/ImagenPerfil&Status.vue";
//src/components/empresa/ImagenPerfil&Status.vue
export default {
  name: "perfilEmpresa",
  components: {
    ImagenPerfilyStatus,
  },
  data() {
    return {
      //Variables: toggled y limit --> utilizadas en methods, toggle y truncate.
      toggled: false,
      limit: 138,
      nombreUserEmprs: {
        value: this.$store.state.regUserEmprs.nombreUserEmprs,
        tempValue: null,
        editing: false,
      },
      apellidoUserEmprs: {
        value: this.$store.state.regUserEmprs.apellidoUserEmprs,
        tempValue: null,
        editing: false,
      },
      emailUserEmprs: {
        value: this.$store.state.regUserEmprs.emailUserEmprs,
        tempValue: null,
        editing: false,
      },

      nombreEmpr: {
        value: this.$store.state.regUserEmprs.empresa[0].nombreEmpr,
        tempValue: null,
        editing: false,
      },
      razonSocialEmpr: {
        value: this.$store.state.regUserEmprs.empresa[0].razonSocialEmpr,
        tempValue: null,
        editing: false,
      },
      rifEmpr: {
        value: this.$store.state.regUserEmprs.empresa[0].rifEmpr,
        tempValue: null,
        editing: false,
      },
      descEmpr: {
        value: this.$store.state.regUserEmprs.empresa[0].descEmpr,
        tempValue: null,
        editing: false,
      },
      cantEmpleads: {
        value: this.$store.state.regUserEmprs.empresa[0].cantEmpleads,
        tempValue: null,
        editing: false,
      },
      imgEmpr: {
        value: this.$store.state.regUserEmprs.empresa[0].imgEmpr,
        tempValue: null,
        editing: false,
      },
      direccEmpr: {
        value: this.$store.state.regUserEmprs.empresa[0].direccEmpr,
        tempValue: null,
        editing: false,
      },
      uploadedImgComponntValue: 0,
      fileRecords: [],
      uploadUrl: "",
      uploadHeaders: { Authorization: this.$store.state.myTokToApiComs },
      fileRecordsForUpload: [], // maintain an upload queue
      imageFileLocation: "",
      flag: false,
      flagImgUploaded: false,
      imgUploadedName: "",
    };
  },
  watch: {
    imageFileLocation: function () {
      this.flag = true;
    },
  },
  methods: {
    volver() {
      this.$router.go(-1);
    },
    forceRerender() {
      this.uploadedImgComponntValue += 1;
    },
    test: async function () {
      var axios = require("axios");

      var config = {
        method: "get",
        maxBodyLength: Infinity,
        url: "https://api.postula2.com/imagesTiEmprs",
        headers: {
          Authorization: this.$store.state.myTokToApiComs,
        },
        params: {
          id: this.$store.state.regUserEmprs.empresa[0].id,
        },
      };

      axios(config).then(function (response) {
        console.log("respuesta", response);
        console.log(JSON.stringify(response.data));
        // eslint-disable-next-line no-unused-vars
        const image = JSON.stringify(response.data);
      });
    },

    // upload() {                   :src="`https://api.postula2.com/imagesTiEmprs?id=${this.$store.state.regUserEmprs.empresa[0].id}`"

    // upload() {
    //   const formData = new FormData();
    //   formData.append("photo", document.getElementById("upload").files[0]);

    //   axios
    //     .post("https://api.postula2.com/uploadImageEmprs", formData, {
    //       headers: {
    //         Authorization: this.$store.state.myTokToApiComs,
    //       },
    //       params: {
    //         id: this.$store.state.regUserEmprs.empresa[0].id,
    //       },
    //     })
    //     .then((response) => {
    //       console.log("response ", response.data);
    //       this.imageFileLocation = response.data.savedAt;
    //       this.$store.commit("getProfileImage", response.data.savedAt);
    //       console.log(this.imageFileLocation);
    //     });
    // },
    upload() {
      //  var data = new FormData(this.$refs.form);
      // // data.append("id", this.$store.state.regUser.id);

      // const file = this.$refs.form;
      // var id = this.$store.state.regUser.id;
      // data.append("photo", file);
      // data.append("id", id);

      // console.log("data ", data);
      // axios
      //   .post("https://api.postula2.com/uploadImage2", data, {
      //     Headers: this.$store.state.myTokToApiComs,
      //   })
      //   .then((response) => {
      //     console.log("holi ", response.data);
      //   });

      // let file = document.querySelector("#upload").files[0];
      // var data = new FormData();
      // data.append("id", this.$store.state.regUser.id);
      // data.append("photo", this.$refs.form);

      // axios
      //   .post("https://api.postula2.com/uploadImage2", data, {
      //     headers: {
      //       "Content-Type": "multipart/form-data",
      //       Authorization: this.$store.state.myTokToApiComs,
      //     },
      //   })
      const formData = new FormData();
      formData.append("file", document.getElementById("upload").files[0]);

      axios
        .post("https://api.postula2.com/uploadImage", formData, {
          headers: {
            Authorization: this.$store.state.myTokToApiComs,
          },
          params: {
            // eslint-disable-next-line no-undef
            id: $store.state.regUserEmprs.empresa[0].id,
          },
        })
        .then((response) => {
          //console.log("holi ", response.data);
          this.imageFileLocation = response.data.savedAt;
          this.$store.commit("getProfileImage", response.data.savedAt);
          console.log(this.imageFileLocation);
        });

      // const options = {
      //   method: "POST",
      //   body: data,
      //   Headers: this.$store.state.myTokToApiComs,
      // };
      // fetch("https://api.postula2.com/uploadImage2", options)
      //   .then((res) => res.json())
      //   .then((resp) => console.log("holi ", resp));

      // const formData = new FormData();
      // const fileField = this.$refs.form
      // formData.append("reg", this.$store.state.regUser.id);
      // formData.append("avatar", fileField);
      // fetch("https://api.postula2.com/uploadImage", {
      //   method: "POST",
      //   body: formData,
      // })
      //   .then((response) => response.json())
      //   .then((result) => {
      //     console.log("Success:", result);
      //   })
      //   .catch((error) => {
      //     console.error("Error:", error);
      //   });
    },
    uploadFiles: function () {
      this.uploadUrl =
        "https://api.postula2.com/uploadImageEmprs?id=" +
        this.$store.state.regUserEmprs.empresa[0].id;
      console.log("url es: ", this.uploadUrl);
      // Using the default uploader. You may use another uploader instead.
      this.$refs.vueFileAgent
        .upload(
          this.uploadUrl,
          this.uploadHeaders,
          this.fileRecordsForUpload,
          this.$store.state.userData.id
        )
        .then((response) => {
          console.log("holi ", response[0].data);
          console.log("img nombre ", response[0].data.encryptedName);
          this.imgUploadedName = response[0].data.encryptedName;
          this.flagImgUploaded = true;
          this.$store.commit({
            type: "getProfileImage",
            flagImgUploaded: true,
            image: response[0].data,
          });
          //this.$store.commit("getProfileImage", response[0].data);
          // this.imageFileLocation = response.data.savedAt;
          // console.log(this.imageFileLocation);
        })
        .finally(() => {
          // location.reload();
          //forceRerender();

          this.$store.commit("incrementUploadedImgComponntValue");
        });
      this.fileRecordsForUpload = [];
      console.log("fonalmente");
      this.imgUploaded =
        "https://api.postula2.com/imagesTiEmprs?id=" +
        this.$store.state.regUserEmprs.empresa[0].id;
    },
    deleteUploadedFile: function (fileRecord) {
      // Using the default uploader. You may use another uploader instead.
      this.$refs.vueFileAgent.deleteUpload(
        this.uploadUrl,
        this.uploadHeaders,
        fileRecord
      );
    },
    filesSelected: function (fileRecordsNewlySelected) {
      var validFileRecords = fileRecordsNewlySelected.filter(
        (fileRecord) => !fileRecord.error
      );
      this.fileRecordsForUpload =
        this.fileRecordsForUpload.concat(validFileRecords);
    },
    onBeforeDelete: function (fileRecord) {
      var i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        // queued file, not yet uploaded. Just remove from the arrays
        this.fileRecordsForUpload.splice(i, 1);
        var k = this.fileRecords.indexOf(fileRecord);
        if (k !== -1) this.fileRecords.splice(k, 1);
      } else {
        if (confirm("Are you sure you want to delete?")) {
          this.$refs.vueFileAgent.deleteFileRecord(fileRecord); // will trigger 'delete' event
        }
      }
    },
    fileDeleted: function (fileRecord) {
      var i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        this.fileRecordsForUpload.splice(i, 1);
      } else {
        this.deleteUploadedFile(fileRecord);
      }
    },
    // /////////////////////////////////////////////

    enableEditingNombreUserEmprs() {
      this.nombreUserEmprs.tempValue = this.nombreUserEmprs.value;
      this.nombreUserEmprs.editing = true;
    },
    disableEditingNombreUserEmprs() {
      this.nombreUserEmprs.tempValue = null;
      this.nombreUserEmprs.editing = false;
    },
    saveEditNombreUserEmprs() {
      // However we want to save it to the database
      this.nombreUserEmprs.value = this.nombreUserEmprs.tempValue;
      let data = JSON.stringify({
        idRegPrimEmpresa: this.$store.state.regUserEmprs.id,
        nombreUserEmprs: this.nombreUserEmprs.value,
      });
      axios({
        method: "put",
        url: `https://api.postula2.com/editEmpresaData/`,
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.myTokToApiComs,
        },
        data: data,
      })
        .then(() => {
          //CLG("campo editado con exito");
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG("Error en AnuncioEditable");
          //CLG(error);
        });
      this.disableEditingNombreUserEmprs();
    },
    // /////////////////////////////////////////////

    // /////////////////////////////////////////////

    enableEditingApellidoUserEmprs() {
      this.apellidoUserEmprs.tempValue = this.apellidoUserEmprs.value;
      this.apellidoUserEmprs.editing = true;
    },
    disableEditingApellidoUserEmprs() {
      this.apellidoUserEmprs.tempValue = null;
      this.apellidoUserEmprs.editing = false;
    },
    saveEditApellidoUserEmprs() {
      // However we want to save it to the database
      this.apellidoUserEmprs.value = this.apellidoUserEmprs.tempValue;
      let data = JSON.stringify({
        idRegPrimEmpresa: this.$store.state.regUserEmprs.id,
        apellidoUserEmprs: this.apellidoUserEmprs.value,
      });
      axios({
        method: "put",
        url: `https://api.postula2.com/editEmpresaData/`,
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.myTokToApiComs,
        },
        data: data,
      })
        .then(() => {
          //CLG("campo editado con exito");
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG("Error en AnuncioEditable");
          //CLG(error);
        });
      this.disableEditingApellidoUserEmprs();
    },
    // /////////////////////////////////////////////

    // /////////////////////////////////////////////

    enableEditingEmailUserEmprs() {
      this.emailUserEmprs.tempValue = this.emailUserEmprs.value;
      this.emailUserEmprs.editing = true;
    },
    disableEditingEmailUserEmprs() {
      this.emailUserEmprs.tempValue = null;
      this.emailUserEmprs.editing = false;
    },
    saveEditEmailUserEmprs() {
      // However we want to save it to the database
      this.emailUserEmprs.value = this.emailUserEmprs.tempValue;
      let data = JSON.stringify({
        idRegPrimEmpresa: this.$store.state.regUserEmprs.id,
        emailUserEmprs: this.emailUserEmprs.value,
      });
      axios({
        method: "put",
        url: `https://api.postula2.com/editEmpresaData/`,
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.myTokToApiComs,
        },
        data: data,
      })
        .then(() => {
          //CLG("campo editado con exito");
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG("Error en AnuncioEditable");
          //CLG(error);
        });
      this.disableEditingEmailUserEmprs();
    },
    // /////////////////////////////////////////////

    // /////////////////////////////////////////////

    enableEditingNombreEmpr() {
      this.nombreEmpr.tempValue = this.nombreEmpr.value;
      this.nombreEmpr.editing = true;
    },
    disableEditingNombreEmpr() {
      this.nombreEmpr.tempValue = null;
      this.nombreEmpr.editing = false;
    },
    saveEditNombreEmpr() {
      // However we want to save it to the database
      this.nombreEmpr.value = this.nombreEmpr.tempValue;
      let data = JSON.stringify({
        idEmpresaUser: this.$store.state.regUserEmprs.empresa[0].id,
        nombreEmpr: this.nombreEmpr.value,
      });
      axios({
        method: "put",
        url: `https://api.postula2.com/editEmpresaData/`,
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.myTokToApiComs,
        },
        data: data,
      })
        .then(() => {
          //CLG("campo editado con exito");
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG("Error en AnuncioEditable");
          //CLG(error);
        });
      this.disableEditingNombreEmpr();
      this.$store.state.regUserEmprs.empresa[0].nombreEmpr =
        this.nombreEmpr.value;
    },
    // /////////////////////////////////////////////

    // /////////////////////////////////////////////

    enableEditingDireccEmpr() {
      this.direccEmpr.tempValue = this.direccEmpr.value;
      this.direccEmpr.editing = true;
    },
    disableEditingDireccEmpr() {
      this.direccEmpr.tempValue = null;
      this.direccEmpr.editing = false;
    },
    saveEditDireccEmpr() {
      // However we want to save it to the database
      this.direccEmpr.value = this.direccEmpr.tempValue;
      let data = JSON.stringify({
        idEmpresaUser: this.$store.state.regUserEmprs.empresa[0].id,
        direccEmpr: this.direccEmpr.value,
      });
      axios({
        method: "put",
        url: `https://api.postula2.com/editEmpresaData/`,
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.myTokToApiComs,
        },
        data: data,
      })
        .then(() => {
          //CLG("campo editado con exito");
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG("Error en AnuncioEditable");
          //CLG(error);
        });
      this.disableEditingDireccEmpr();
    },
    // /////////////////////////////////////////////

    // /////////////////////////////////////////////

    enableEditingCantEmpleads() {
      this.cantEmpleads.tempValue = this.cantEmpleads.value;
      this.cantEmpleads.editing = true;
    },
    disableEditingCantEmpleads() {
      this.cantEmpleads.tempValue = null;
      this.cantEmpleads.editing = false;
    },
    saveEditCantEmpleads() {
      // However we want to save it to the database
      this.cantEmpleads.value = this.cantEmpleads.tempValue;
      let data = JSON.stringify({
        idEmpresaUser: this.$store.state.regUserEmprs.empresa[0].id,
        cantEmpleads: this.cantEmpleads.value,
      });
      axios({
        method: "put",
        url: `https://api.postula2.com/editEmpresaData/`,
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.myTokToApiComs,
        },
        data: data,
      })
        .then(() => {
          //CLG("campo editado con exito");
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG("Error en AnuncioEditable");
          //CLG(error);
        });
      this.disableEditingCantEmpleads();
    },
    // /////////////////////////////////////////////

    // /////////////////////////////////////////////

    enableEditingDescEmpr() {
      this.descEmpr.tempValue = this.descEmpr.value;
      this.descEmpr.editing = true;
    },
    disableEditingDescEmpr() {
      this.descEmpr.tempValue = null;
      this.descEmpr.editing = false;
    },
    saveEditDescEmpr() {
      // However we want to save it to the database
      this.descEmpr.value = this.descEmpr.tempValue;
      let data = JSON.stringify({
        idEmpresaUser: this.$store.state.regUserEmprs.empresa[0].id,
        descEmpr: this.descEmpr.value,
      });
      axios({
        method: "put",
        url: `https://api.postula2.com/editEmpresaData/`,
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.myTokToApiComs,
        },
        data: data,
      })
        .then(() => {
          //CLG("campo editado con exito");
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG("Error en AnuncioEditable");
          //CLG(error);
        });
      this.disableEditingDescEmpr();
    },
    toggle() {
      //CLG("tog");
      this.toggled = !this.toggled;
    },
    truncate(text) {
      if (text.length > this.limit) {
        for (let i = this.limit; i > 0; i--) {
          const currChar = text.charAt(i);
          const prevChar = text.charAt(i - 1);
          const prevCharNotPuncuation =
            prevChar != "," || prevChar != "." || prevChar != ".";
          if (currChar === " " && prevCharNotPuncuation) {
            return text.substring(0, i) + "...";
          }
        }
      } else {
        return text;
      }
    },
    // /////////////////////////////////////////////
  },
  computed: {
    displayTextActivs() {
      return this.toggled
        ? this.descEmpr.value
        : this.truncate(this.descEmpr.value);
    },
  },
  filters: {
    formatedTime: function (value) {
      var date = new Date(value);

      return (
        date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear() // +
        // " " +
        //date.getHours() +
        //":" +
        // date.getMinutes() +
        //":" +
        //date.getSeconds()
      );
      ////CLG(formatedTime);
    },
  },
};
</script>
