<template>
  <div class="">
    <loader v-if="isLoading" />
    <div
      v-show="!isLoading"
      v-for="anuncio in assets"
      :key="anuncio.id"
      class="my-4 bg-red-400"
    >
      <AnuncioCreado class="" :assets="anuncio" :key="anuncio.id" />
    </div>
  </div>
</template>

<script>
import AnuncioCreado from "@/components/AnuncioCreado.vue";
import loader from "@/components/shared/loader.vue";
import axios from "axios";

export default {
  name: "anunciosLandinRender",
  components: {
    AnuncioCreado,
    loader,
  },
  data() {
    return {
      assets: [],

      isLoading: true,
    };
  },
  created() {
    this.isLoading = true;
    axios
      .get(
        `https://api.postula2.com/anuncio?anuncEstado=activo`
      )
      .then((res) => {
        // JSON responses are automatically parsed.
        this.isLoading = false;
        this.assets = res.data.reverse();
        ////CLG(this.assets);
        // id
        // anuncActivo
        // updatedAt
        // tituloAnunc
        // owner
        //     nombreEmpr
        //     rifEmpr
        // updatedAt
        // descrpAnunc
        // tipoempleo
        // modalidtrabj
        // ofertsalarl
        // cvemailcopy
        this.$store.commit("getAnuncs", res.data);
      })
      .catch(() => {
        //this.errors.push(e);
        //CLG("mission pelaste bolas");
        //CLG(error);
      });
  },
};
</script>

<style></style>
