<template>
  <div class="flex rounded-lg justify-center">
    <div>
      <div
        class="bg-white max-w-full shadow overflow-auto sm:rounded-lg"
        id="element-to-print"
      >
        <div class="px-2 py-2 sm:px-6 text-left">
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            <span class="text-xs text-gray-500">
              Nombre(s) y Apellido (s):
            </span>
            <span
              v-if="
                $store.state.userData.owner.nombreUser &&
                $store.state.userData.owner.apellidoUser
              "
            >
              {{
                $store.state.userData.owner.nombreUser +
                " " +
                $store.state.userData.owner.apellidoUser
              }}
            </span>
            <span v-else> Sin información disponible</span>
          </h3>
          <p class="mt-0.5 max-w-3xl text-xs text-gray-500">
            Género:
            <span class="text-xs text-gray-900">
              {{ $store.state.userData.genero }}</span
            >
          </p>
          <p class="mt-0.5 max-w-3xl text-xs text-gray-500">
            Documento:
            <span class="uppercase text-xs text-gray-900">
              {{ $store.state.userData.tipoDocument }}
              {{ $store.state.userData.nroDocument }}</span
            >
          </p>
        </div>
        <div class="border-t border-gray-200 text-justify">
          <dl>
            <div
              class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-xs font-medium text-gray-500">Teléfono 1</dt>
              <dd
                v-if="$store.state.userData.owner"
                class="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2"
              >
                {{ $store.state.userData.owner.telf1 }}
              </dd>
              <dd
                v-else
                class="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2"
              >
                Sin información disponible
              </dd>
            </div>
            <div
              class="bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="hidden text-xs font-medium text-gray-500">
                Teléfono 2
              </dt>
              <dd class="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                {{ $store.state.userData.telf2 }}
              </dd>
            </div>
            <div
              class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-xs font-medium text-gray-500">Nacionalidad</dt>
              <dd class="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                {{ $store.state.userData.nacionalidad }}
              </dd>
            </div>
            <div
              class="bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-xs font-medium text-gray-500">Correo</dt>
              <dd
                v-if="$store.state.userData.owner"
                class="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2"
              >
                {{ $store.state.userData.owner.emailUser }}
              </dd>
              <dd
                v-else
                class="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2"
              >
                Sin información disponible
              </dd>
            </div>
            <div
              class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-xs font-medium text-gray-500">
                Fecha de nacimiento
              </dt>
              <dd class="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                {{ $store.state.userData.fech_nacimnt }}
              </dd>
            </div>
            <div
              class="bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-xs font-medium text-gray-500">Licencia</dt>
              <dd class="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                {{ $store.state.userData.licencia }}
              </dd>
            </div>
            <div
              class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-xs font-medium text-gray-500">Edo. civil</dt>
              <dd class="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                {{ $store.state.userData.estadCivil }}
              </dd>
            </div>
            <div
              class="bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
              v-if="
                $store.state.userData &&
                $store.state.userData.localidad.length != 0
              "
            >
              <dt class="text-xs font-medium text-gray-500">Localidad</dt>
              <dd
                v-if="this.$store.state.userData.localidad"
                class="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2"
              >
                <strong>País</strong>:
                {{ $store.state.userData.localidad[0].idPais }} <br />
                <strong>Ciudad</strong>:
                {{ $store.state.userData.localidad[0].idCiudad }} <br />
                <strong>Estado</strong>:
                {{ $store.state.userData.localidad[0].idEstado }} <br />
                <strong>Municipio</strong>:
                {{ $store.state.userData.localidad[0].idMunicp }} <br />
                <strong>Parroquia</strong>:
                {{ $store.state.userData.localidad[0].idParroq }} <br />
                <!-- "idPais": "Venezuela", "idEstado": "Distrito Capital", "idCiudad": "Caracas", "idMunicp": "Libertador", "idParroq" -->
              </dd>
            </div>
            <div
              class="bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
              v-else
            >
              <dt class="text-xs font-medium text-gray-500">Localidad</dt>
              <dd class="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                <strong>Sin datos disponibles</strong
                ><!-- "idPais": "Venezuela", "idEstado": "Distrito Capital", "idCiudad": "Caracas", "idMunicp": "Libertador", "idParroq" -->
              </dd>
            </div>
            <div
              class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-xs font-medium text-gray-500">Dirección</dt>
              <dd class="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                {{ $store.state.userData.direccion }}
              </dd>
            </div>
          </dl>
          <dl>
            <div
              class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
              v-if="
                $store.state.userData &&
                $store.state.userData.educacnUsers.length != 0
              "
            >
              <dt class="text-xs font-medium text-gray-500">Educación</dt>
              <dd class="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                <strong>Título</strong>:{{
                  $store.state.userData.educacnUsers[0].tituloGrado
                }}
                <br />
                <strong>Nivel estudio</strong>:{{
                  $store.state.userData.educacnUsers[0].nvlEstudio
                }}
                <br />
                <strong>Estado</strong>:{{
                  $store.state.userData.educacnUsers[0].estado
                }}
                <br />
                <strong>Pais</strong>:{{
                  $store.state.userData.educacnUsers[0].pais
                }}
                <br />
                <strong>Área</strong>:{{
                  $store.state.userData.educacnUsers[0].areaEstud
                }}
              </dd>
            </div>
            <div
              class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
              v-else
            >
              <dt class="text-xs font-medium text-gray-500">Educación</dt>
              <dd class="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                <strong>Sin información disponible</strong>
              </dd>
            </div>
            <div
              class="bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
              v-if="
                $store.state.userData &&
                $store.state.userData.experncLabors.length != 0
              "
            >
              <dt class="text-xs font-medium text-gray-500">Exp. Laboral</dt>
              <dd class="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                <strong>Empresa</strong>:{{
                  $store.state.userData.experncLabors[0].nombEmprs
                }}
                <br />
                <strong>Rubro</strong>:{{
                  $store.state.userData.experncLabors[0].idActvdEmprs
                }}
                <br />
                <strong>Cargo</strong>:{{
                  $store.state.userData.experncLabors[0].cargo
                }}
                <br />
                <strong>País</strong>:{{
                  $store.state.userData.experncLabors[0].idPais
                }}
                <br />
                <strong>Área</strong>:{{
                  $store.state.userData.experncLabors[0].areaCargo
                }}
                <br />
                <strong>Experiencia</strong>:{{
                  $store.state.userData.experncLabors[0].nivlExpernc
                }}
                <br />
                <!-- nombEmprs": "La Galia", "idActvdEmprs": "La Galia", "cargo": "Programador", "idPais": "Venezuela", "fechDesd": "2021-12-29", "fechHast": "2022-01-30", "alPresnt": "", "areaCargo": "Gerencia", "nivlExpernc": -->
              </dd>
            </div>
            <div
              class="bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
              v-else
            >
              <dt class="text-xs font-medium text-gray-500">Exp. Laboral</dt>
              <dd class="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                <strong>Sin información disponible</strong>
              </dd>
            </div>
            <div
              class="hidden bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-xs font-medium text-gray-500">Ref. personales</dt>
              <dd class="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                {{ $store.state.userData.nacionalidad }}
              </dd>
            </div>
            <div
              class="bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
              v-if="
                $store.state.userData &&
                $store.state.userData.preferncLabor.length != 0
              "
            >
              <dt class="text-xs font-medium text-gray-500">
                Preferencias laborales
              </dt>
              <dd class="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                <strong>Exptv. salarial:</strong>
                {{ $store.state.userData.preferncLabor[0].expectSalar }} <br />
                <strong>Estado: </strong
                >{{ $store.state.userData.preferncLabor[0].estatsActual }}
                <br />
                <strong>Viajar:</strong>
                {{ $store.state.userData.preferncLabor[0].viajar }} <br />
                <strong>Cmb. Resd:</strong>
                {{ $store.state.userData.preferncLabor[0].cambResidenc }} <br />

                <!-- "expectSalar": "500", "viajar": "si", "estatsActual": "contratado", "cambResidenc": "si", -->
              </dd>
            </div>
            <div
              class="bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
              v-else
            >
              <dt class="text-xs font-medium text-gray-500">
                Preferencias laborales
              </dt>
              <dd class="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                Sin información disponible
                <!-- "expectSalar": "500", "viajar": "si", "estatsActual": "contratado", "cambResidenc": "si", -->
              </dd>
            </div>
            <div
              class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-xs2 font-medium text-gray-500">
                Datos verificados el
              </dt>
              <dd class="mt-1 text-xs2 text-gray-900 sm:mt-0 sm:col-span-2">
                {{ $store.state.userData.updatedAt | formatedTime }}
              </dd>
            </div>
          </dl>
        </div>
        <!-- <button
        class="w-36 place-self-center flex-shrink-0 px-4 py-2 my-4 text-sm font-semibold text-white bg-purple-600 rounded-lg shadow-md hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-purple-200"
        @click="pdfExport"
      >
        Exportar PDF
      </button> -->
      </div>
      <div>
        <button
          class="w-36 place-self-center flex-shrink-0 px-4 py-2 my-4 text-sm font-semibold text-white bg-purple-600 rounded-lg shadow-md hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-purple-200"
          @click="pdfExport"
        >
          Exportar PDF
        </button>
      </div>
    </div>

    <div
      v-if="
        this.$store.state.regEmprs.id || this.$store.state.regUserEmprs.empresa
      "
      class="ml-2 bg-white max-w-xl shadow overflow-hidden sm:rounded-lg"
    >
      <evaluacionMostrar
        :evaluacions="this.$store.state.personaEval"
      ></evaluacionMostrar>

      <personNotasEmprs></personNotasEmprs>
    </div>
  </div>
</template>

<script>
import html2pdf from "html2pdf.js";

//import jsPDF from "jspdf";
// import "jspdf-autotable";

//import evaluacion from "@/components/EvaluacionCrear.vue";
import personNotasEmprs from "@/components/personNotasEmprs.vue";
import evaluacionMostrar from "@/components/EvaluacnMostrar.vue";

export default {
  name: "personDataCvInEmpresa",
  components: {
    personNotasEmprs,
    evaluacionMostrar,
  },
  props: {},
  data() {
    return {
      userData: {},

      uri: "data:application/vnd.ms-excel;base64,",
      template:
        '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
      base64: function (s) {
        return window.btoa(unescape(encodeURIComponent(s)));
      },
      format: function (s, c) {
        return s.replace(/{(\w+)}/g, function (m, p) {
          return c[p];
        });
      },
    };
  },
  methods: {
    tableToExcel(table, name) {
      if (!table.nodeType) table = this.$refs.table;
      var ctx = { worksheet: name || "Prueba1", table: table.innerHTML };
      window.location.href =
        this.uri + this.base64(this.format(this.template, ctx));
    },
    //exportPDF() {
    // var vm = this;

    // var doc = new jsPDF("p", "pt", "letter");
    // doc.setFontSize(18);
    // doc.text("Información personal", 40, 40);
    // Nombre y apellido
    // doc.setFontSize(12);
    // doc.text("Nombre y Apellido: ", 40, 60);
    // doc.setFontSize(10);
    // doc.text(
    //   `${vm.userData.owner.nombreUser}` +
    //    " " +
    //    `${vm.userData.owner.apellidoUser}`,
    //   40,
    //  80
    // );
    // Fecha de nacimiento
    // doc.setFontSize(12);
    //doc.text("Fecha de nacimiento", 40, 100);
    //doc.setFontSize(10);
    //doc.text(`${vm.userData.fech_nacimnt}`, 40, 120);
    // Cédula
    // doc.setFontSize(12);
    // doc.text("Cédula:", 40, 140);
    //doc.setFontSize(10);
    // doc.text(`${vm.userData.nroDocument}`, 40, 160);
    // Correo
    // doc.setFontSize(12);
    // doc.text("Correo:", 40, 180);
    // doc.setFontSize(10);
    //doc.text(`${vm.userData.owner.emailUser}`, 40, 200);
    // Teléfono
    // doc.setFontSize(12);
    //doc.text("Teléfono:", 40, 220);
    // doc.setFontSize(10);
    // doc.text(`${vm.userData.owner.telf1}`, 40, 240);
    // Dirección
    //doc.setFontSize(12);
    // doc.text("Dirección:", 40, 260);
    // doc.setFontSize(10);
    // doc.text(`${vm.userData.direccion}`, 40, 280);
    // Estado
    // doc.setFontSize(12);
    // doc.text("Estado:", 40, 310);
    // doc.setFontSize(10);
    // doc.text(`${vm.userData.localidad[0].idEstado}`, 40, 320);
    // if (vm.userData.localidad && vm.userData.localidad.length > 0) {
    // Si el campo no está vacío, muestra la información
    //   doc.text(`${vm.userData.localidad[0].idEstado}`, 40, 320);
    // } else {
    // Si el campo está vacío, muestra el mensaje por defecto
    //  doc.text("No hay información", 40, 320);
    // }
    // Municipio
    //doc.setFontSize(12);
    //doc.text("Localidad:", 40, 340);
    //doc.setFontSize(10);
    // doc.text(`${vm.userData.localidad[0].idCiudad}`, 40, 320);
    // if (vm.userData.localidad && vm.userData.localidad.length > 0) {
    // Si el campo no está vacío, muestra la información
    // doc.text(`${vm.userData.localidad[0].idCiudad}`, 40, 360);
    // } else {
    // Si el campo está vacío, muestra el mensaje por defectfo
    // doc.text("No hay información", 40, 360);
    // }
    // Estado
    //doc.setFontSize(12);
    //doc.text("Municipio:", 40, 380);
    // doc.setFontSize(10);
    // doc.text(`${vm.userData.localidad[0].idMunicp}`, 40, 320);
    // if (vm.userData.localidad && vm.userData.localidad.length > 0) {
    // Si el campo no está vacío, muestra la información
    // doc.text(`${vm.userData.localidad[0].idMunicp}`, 40, 400);
    //} else {
    // Si el campo está vacío, muestra el mensaje por defecto
    // doc.text("No hay información", 40, 400);
    // }

    //Educación

    //Experiencia Laboral

    //doc.output("dataurlnewwindow");
    //doc.save("CvPersona.pdf");
    //},
    pdfExport() {
      //console.log("Eto es una prueba");

      var element = document.getElementById("element-to-print");

      var opt = {
        margin: 0.5,
        filename: "documento.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 3 },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      };
      html2pdf().set(opt).from(element).save();
    },
  },
  filters: {
    formatedTime: function (value) {
      var date = new Date(value);

      return (
        date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
        // " " +
        //date.getHours() +
        //":" +
        // date.getMinutes() +
        //":" +
        //date.getSeconds()
      );
      ////CLG(formatedTime);
    },
  },
  created() {
    this.userData = this.$store.state.userData;
    //console.log("Datos de prueba: ", this.userData);
  },
};
</script>

<style scoped>
.text-xs2 {
  font-size: 0.62rem;
}
</style>
