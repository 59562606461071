<template>
  <div>
    <hr />
    <div class="flex-col bg-white rounded-lg dark:bg-gray-800">
      <loader v-if="isLoading">
        <template v-slot:msg>
          {{ msg }}
        </template>

        <template v-slot:msg1>
          {{ msg1 }}
        </template>
      </loader>
       <div v-if="editing == false" class="text-justify">
        <div class="bg-white max-w-full overflow-hidden sm:rounded-lg">
          <div class="mx-auto">
            <div class="flex flex-row">
              <div
                class="md:w-3/4 py-2 pb-2 md:pb-0 md:px-3 flex flex-col md:flex-row md:items-center justify-center md:justify-start"
              >
                <div class="flex flex-row items-center justify-start">
                  <!-- div 1 -->
                  <div class="p-2">
                    <span class="text-green-500">
                      <svg
                        class="h-5"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                        />
                      </svg>
                    </span>
                  </div>
                  <!-- div 2 -->
                  <div class="px-2">
                    <span
                      class="pr-4 leading-3 font-semibold text-blue-postulados"
                      >Localidad
                    </span>
                  </div>
                </div>

                <!-- div 3 -->
                <div class="p-2">
                  <span
                    class="md:flex md:items-center border-green-500 border-l-2 h-8 pl-3 md:pl-5 text-xs inline-block align-middle text-blue-postulados font-semibold"
                  >
                    Detalles e información de dirección local.
                  </span>
                </div>
              </div>
              <div
                class="md:w-1/4 flex items-center justify-center md:justify-end"
              >
                <div>
                  <button
                    @click="editar"
                    type="button"
                    class="uppercase py-1.5 px-3 mr-4 rounded-md flex justify-center items-center focus:ring-postul2 focus:ring-offset-color-postul2 transition ease-in duration-200 text-center text-sm text-blue-postulados font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 border-postul2 hover:border-postul2 hover:text-postul2 hover:border-transparent hover:text-white border-2"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-plus-circle h-7 w-7 mr"
                    >
                      <circle cx="12" cy="12" r="10"></circle>
                      <line x1="12" y1="8" x2="12" y2="16"></line>
                      <line x1="8" y1="12" x2="16" y2="12"></line>
                    </svg>
                    <span class="hidden md:block xl:block pl-1.5">
                      Agregar</span
                    >
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div
            v-for="localidad in $store.state.userData.localidad"
            :key="localidad.id"
            class="grid grid-cols-1 border"
          >
            <formLocaliddDataUser
              :localidad="localidad"
              :key="localidad.id"
            ></formLocaliddDataUser>
          </div>
        </div>
      </div>

      <div
        v-if="editing"
        class="flex flex-col bg-white p-3 rounded-lg shadow-lg"
      >
        <div
          class="self-center mb-5 text-xl font-bold text-gray-800 sm:text-2xl dark:text-white w-full text-center"
        >
          Localidad
        </div>

        <div class="flex flex-col" id="contenedor">
          <FormulateForm @submit="onSubmit" outer-class="flex">
            <div class="flex flex-wrap -mx-3 mb-2">
              <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label
                  for="price"
                  class="block text-xs font-medium text-gray-700 text-left ml-1"
                >
                  <strong> Pais</strong>
                </label>
                <div class="relative">
                  <select
                    class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:ring-2"
                    id="grid-state"
                    placeholder="País"
                    v-model.lazy.trim="data.idPais"
                  >
                    <option class="text-gray-300" disabled value="">
                      Seleccione
                    </option>
                    <option>Venezuela</option>
                  </select>
                  <div
                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                  >
                    <svg
                      class="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path
                        d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                      />
                    </svg>
                  </div>
                </div>
              </div>

              <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label
                  for="price"
                  class="block text-xs font-medium text-gray-700 text-left ml-1"
                >
                  <strong> Estado</strong>
                </label>
                <div class="relative">
                  <select
                    class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:ring-2"
                    id="grid-state"
                    v-model.lazy.trim="data.idEstado"
                  >
                    <option class="text-gray-300" disabled value="">
                      Seleccione
                    </option>
                    <option>Distrito Capital</option>
                    <option>Miranda</option>
                    <option>La Guaira</option>
                  </select>
                  <div
                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                  >
                    <svg
                      class="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path
                        d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>

            <div class="flex flex-wrap -mx-3 mb-2">
              <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label
                  for="price"
                  class="block text-xs font-medium text-gray-700 text-left ml-1"
                >
                  <strong> Ciudad</strong>
                </label>
                <div class="relative">
                  <FormulateInput
                    type="text"
                    v-model.lazy.trim="data.idCiudad"
                    validation="required|min:4|matches:/^[A-Za-z\s*\á\é\í\ó\ú\ñ]+$/"
                    :validation-messages="{
                      min: 'Debe tener al menos 4 caracteres',
                      matches: 'Este campo solo acepta letras',
                      required: 'Este campo es obligatorio',
                    }"
                    id="city"
                    input-class="
                      rounded-lg
                      border-transparent
                      flex-1
                      appearance-none
                      border border-gray-300
                      w-full
                      py-2
                      px-2
                      bg-white
                      text-gray-700
                      placeholder-gray-400
                      shadow-sm
                      text-base
                      focus:outline-none focus:ring-2 focus:border-transparent
                    "
                    name="tlf2"
                    placeholder="Ciudad"
                    errors-class="text-left ml-1"
                  />
                </div>
                <div class="hidden relative">
                  <select
                    class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:ring-2"
                    id="grid-state"
                    placeholder="Ciudad"
                  >
                    <option>Ciudad</option>
                    <option>Missouri</option>
                    <option>Texas</option>
                  </select>
                  <div
                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                  >
                    <svg
                      class="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path
                        d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                      />
                    </svg>
                  </div>
                </div>
              </div>

              <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label
                  for="price"
                  class="block text-xs font-medium text-gray-700 text-left ml-1"
                >
                  <strong> Municipio</strong>
                </label>
                <div class="relative">
                  <FormulateInput
                    type="text"
                    v-model.lazy.trim="data.idMunicp"
                    validation="required|min:4|matches:/^[A-Za-z\s*\á\é\í\ó\ú\ñ]+$/"
                    :validation-messages="{
                      min: 'Debe tener al menos 4 caracteres',
                      matches: 'Este campo solo acepta letras',
                      required: 'Este campo es obligatorio',
                    }"
                    id="municipio"
                    input-class="
                      rounded-lg
                      border-transparent
                      flex-1
                      appearance-none
                      border border-gray-300
                      w-full
                      py-2
                      px-4
                      bg-white
                      text-gray-700
                      placeholder-gray-400
                      shadow-sm
                      text-base
                      focus:outline-none focus:ring-2 focus:border-transparent
                    "
                    errors-class="text-left ml-1"
                    placeholder="Municipio"
                  />
                </div>
              </div>

              <div class="w-full md:w-1/2 px-3 mb-6 mt-2 md:mb-0">
                <label
                  for="price"
                  class="block text-xs font-medium text-gray-700 text-left ml-1"
                >
                  <strong> Parroquia</strong>
                </label>
                <div class="relative">
                  <FormulateInput
                    type="text"
                    v-model.lazy.trim="data.idParroq"
                    validation="required|min:4|matches:/^[A-Za-z\s*\á\é\í\ó\ú\ñ]+$/"
                    :validation-messages="{
                      min: 'Debe tener al menos 4 caracteres',
                      matches: 'Este campo solo acepta letras',
                      required: 'Este campo es obligatorio',
                    }"
                    id="parroquia"
                    input-class="
                      rounded-lg
                      border-transparent
                      flex-1
                      appearance-none
                      border border-gray-300
                      w-full
                      py-2
                      px-4
                      bg-white
                      text-gray-700
                      placeholder-gray-400
                      shadow-sm
                      text-base
                      focus:outline-none focus:ring-2 focus:border-transparent
                    "
                    errors-class="text-left ml-1"
                    placeholder="Parroquia"
                  />
                </div>
              </div>
            </div>
            <div class="flex flex-wrap justify-end mt-6">
              <div class="flex flex-wrap justify-end mt-1">
                <button
                  type="submit"
                  class="flex py-2 px-4 mr-4 color_Postula2 hover:color_Postula2 focus:color_Postula2 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg"
                >
                  Guardar

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="ml-2 h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
                    />
                  </svg>
                </button>
              </div>
              <div class="flex flex-wrap justify-end mt-1">
                <button
                  @click="editFinished"
                  class="py-2 px-4 text-red-500 bg-transparent border border-solid border-red-500 hover:bg-red-500 hover:text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg"
                >
                  Cerrar
                </button>
              </div>
            </div>
          </FormulateForm>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.bg-custom-postul2-blue {
  /*azul*/
  --tw-bg-opacity: 1;
  background-color: rgba(19, 73, 146, var(--tw-bg-opacity));
}
.bg-button-postul2-yellow {
  /*amarillo*/
  --tw-bg-opacity: 1;
  background-color: rgba(239, 224, 37, var(--tw-bg-opacity));
}
.hover\:bg-postuladosyellow-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(19, 73, 146, var(--tw-bg-opacity));
}
.bg-separador-postul2-yellow {
  /*amarillo*/
  --tw-bg-opacity: 1;
  border-color: rgba(239, 224, 37, var(--tw-bg-opacity));
}
.text-blue-postulados {
  --tw-text-opacity: 1;
  color: rgba(19, 73, 146, var(--tw-bg-opacity));
}
.color_Postula2 {
  background-color: #134992;
}
</style>

<script>
import axios from "axios";
import loader from "@/components/shared/loader.vue";
import formLocaliddDataUser from "@/components/formLocaliddDataUser.vue";

export default {
  name: "Localidad",
  components: {
    loader,
    formLocaliddDataUser,
  },
  methods: {
    editarCampo() {
      this.editarFlag = !this.editarFlag;
    },
    editField(idEduc) {
      //this.editarFlag = false;
      ////CLG("id es: ", idEduc, "data es: ", dataToSend);
      ///editEducacn
      let data = JSON.stringify({
        id: idEduc,
        idPais: this.data.idPais,
        idEstado: this.data.idEstado,
        idCiudad: this.data.idCiudad,
        idMunicp: this.data.idMunicp,
        idParroq: this.data.idParroq,
      });
      axios({
        method: "get",
        url: `https://api.postula2.com/editLocalidd/`,
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.myTokToApiComs,
        },
        data: data,
      })
        .then(() => {
          //CLG("campo editado con exito");
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG("mission pelaste bolas in educacion");
          //CLG(error);
        })
        .finally(() => {
          //Perform action in always
        });
    },
    editar() {
      this.editing = true;
    },
    editFinished() {
      this.editing = false;
      //https://api.postula2.com/persona/624745e3e602450004288516/experncLabors
      axios({
        method: "get",
        url: `https://api.postula2.com/persona/${this.$store.state.userData.id}/localidad`,
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.myTokToApiComs,
        },
      })
        .then((res) => {
          this.$store.state.userData.localidad = res.data;
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG("mission pelaste bolas in formLocalidad");
          //CLG(error);
        })
        .finally(() => {
          //Perform action in always
        });
    },
    onSubmit() {
      //let dataUser = this.data; //newLocalidd
      this.isLoading = true;
      this.data.idOwner = this.$store.state.userData.id;
      //CLG("data localidad ",this.data)
      axios({
        method: "POST",
        url: `https://api.postula2.com/newLocalidd`,
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.myTokToApiComs,
        },
        data: this.data,
      })
        .then(() => {
          //Perform Success Action
          //CLG("response create localidad:", res.data);
          this.msg = "¡Localidad registrada satisfactoriamente...!";
          //this.$store.commit("getflagLogin", redFlag);
          this.$store.commit({
            type: "getflagLogin",
            greenFlag: true,
          });
          setTimeout(() => {
            this.editFinished();
            this.data.idPais = "";
            this.data.idEstado = "";
            this.data.idCiudad = "";
            this.data.idMunicp = "";
            this.data.idParroq = "";
            this.data.idOwner = null;
            //this.editing = false;
            this.isLoading = false;
            this.$store.commit({
              type: "getflagLogin",
              greenFlag: null,
            });
          }, 2000);
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG(error);
          this.msg1 =
            "¡Verifique la información ingresada e intente nuevamente!";
          //this.$store.commit("getflagLogin", redFlag);
          this.$store.commit({
            type: "getflagLogin",
            redFlag: true,
          });
          setTimeout(() => {
            this.isLoading = false;
            this.$store.commit({
              type: "getflagLogin",
              redFlag: null,
            });
          }, 3000);
        })
        .finally(() => {
          //Perform action in always
        });
    },
  },
  data() {
    return {
      editarFlag: false,
      editing: false,
      isLoading: false,
      msg: null,
      msg1: null,
      data: {
        idPais: "",
        idEstado: "",
        idCiudad: "",
        idMunicp: "",
        idParroq: "",
        idOwner: null,
      },
    };
  },
};
</script>
