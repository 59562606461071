<template>
  <div class="container flex-wrap px-5 py-4 mx-auto items-center">
    <pre class="hidden text-red-500 font-semibold underline p-4 m-4">
¡Hola, nuestro proceso de registro de Empresas no ha sido abierto. 
Te invitamos a contactarnos para formar parte de la prueba piloto!</pre
    >
    <div
      class="overflow-hidden shadow-lg rounded-lg h-90 w-full md:w-12/12 cursor-pointer m-auto"
    >
      <div class="w-full block h-full">
        <img
          alt="blog photo"
          src="./img/BANNER8.png"
          class="max-h-40 md:max-h-48 w-full object-cover"
        />
        <div class="bg-gray-300 dark:bg-gray-800 w-full">
          <div
            class="w-full max-w-md mx-auto overflow-hidden bg-gray-300 rounded-lg dark:bg-gray-800"
          >
            <div class="px-3 md:px-8 py-6">
              <h2
                class="text-2xl font-bold text-center text-gray-700 dark:text-white"
              >
                Registro de Empresas
              </h2>

              <p
                class="mt-1 text-center text-lg text-gray-500 dark:text-gray-400"
              >
                Crea una cuenta empresarial y consigue a tu candidato ideal.
              </p>

              <FormulateForm @submit="onSubmit">
                <div class="w-full mt-4">
                  <label
                    for="price"
                    class="block text-xs font-medium text-gray-700 text-left ml-1"
                  >
                    <strong> Nombre(s) del titular </strong>
                  </label>
                  <FormulateInput
                    type="text"
                    name="nombre"
                    v-model.lazy="data.nombreUserEmprs"
                    placeholder="Nombre"
                    validation="required|min:4|matches:/^[A-Za-z\s*\á\é\í\ó\ú\ñ]+$/"
                    class="text-left bg-white rounded-lg"
                    :validation-messages="{
                      min: 'Debe tener al menos 4 caracteres',
                      matches: 'Este campo solo acepta letras',
                      required: 'Este campo es obligatorio',
                    }"
                  />
                </div>
                <div class="w-full mt-4">
                  <label
                    for="price"
                    class="block text-xs font-medium text-gray-700 text-left ml-1"
                  >
                    <strong> Apellido(s) del titular </strong>
                  </label>

                  <FormulateInput
                    type="text"
                    placeholder="Apellido"
                    name="apellido"
                    v-model.lazy="data.apellidoUserEmprs"
                    validation="required|min:4|matches:/^[A-Za-z\s*\á\é\í\ó\ú\ñ]+$/"
                    class="text-left bg-white rounded-lg"
                    error-behavior="value"
                    :validation-messages="{
                      min: 'Debe tener al menos 4 caracteres',
                      alpha: 'Este campo no acepta numeros',
                      required: 'Este campo es obligatorio',
                      matches: 'Este campo solo acepta letras',
                    }"
                  />
                </div>
                <div class="w-full mt-4">
                  <label
                    for="price"
                    class="block text-xs font-medium text-gray-700 text-left ml-1"
                  >
                    <strong> Correo electrónico del titular </strong>
                  </label>
                  <FormulateInput
                    type="email"
                    name="email"
                    v-model.lazy.trim="data.emailUserEmprs"
                    placeholder="Correo electrónico"
                    validation="required|required|email|min:4|matches:/^[A-Za-z\s*\á\é\í\ó\ú\0-9\@\.]+$/"
                    class="text-left bg-white rounded-lg"
                    error-behavior="value"
                    :validation-messages="{
                      required: 'Debe ingresar un email valido',
                      min: 'Debe tener al menos 4 caracteres',
                      matches: 'Solo puede contener letras y/o numeros',
                      email: 'Debe ingresar un email valido',
                    }"
                  />
                </div>

                <div class="w-full mt-4">
                  <label
                    for="price"
                    class="block text-xs font-medium text-gray-700 text-left ml-1"
                  >
                    <strong> Contraseña </strong>
                  </label>
                  <FormulateInput
                    type="password"
                    name="password"
                    placeholder="Contraseña"
                    validation="required"
                    class="text-left bg-white rounded-lg"
                  />
                  <label
                    for="price"
                    class="block text-xs font-medium text-gray-700 text-left ml-1"
                  >
                    <strong> Confirmar contraseña </strong>
                  </label>
                  <FormulateInput
                    type="password"
                    placeholder="Confirmar contraseña"
                    name="password_confirm"
                    v-model.lazy.trim="data.passwUserEmprs"
                    class="text-left bg-white rounded-lg"
                    validation="required|confirm"
                    :validation-messages="{
                      required: 'Debes rellenar este campo',
                      confirm: 'Contraseña no coincide',
                    }"
                    validation-name="Password confirmation es"
                  />
                </div>

                <div class="w-full mt-4">
                  <label
                    for="price"
                    class="block text-xs font-medium text-gray-700 text-left ml-1"
                  >
                    <strong> Pregunta de seguridad </strong>
                  </label>
                  <FormulateInput
                    v-model.lazy.trim="data.secQuest"
                    class="text-left bg-white rounded-lg"
                    :options="{
                      'Nombre de tu primera mascota':
                        'Nombre de tu primera mascota',
                      'Nombre de tu primer jefe': 'Nombre de tu primer jefe',
                      'Lugar de nacimiento de tu padre':
                        'Lugar de nacimiento de tu padre',
                      'Nombra tu comida favorita': 'Nombra tu comida favorita',
                      'Marca de vehiculo favorita':
                        'Marca de vehiculo favorita',
                      'Nombre de tu hermano/a': 'Nombre de tu hermano/a',
                      'Nombre de tu primer maestro':
                        'Nombre de tu primer maestro',
                    }"
                    validation="required"
                    :validation-messages="{
                      required: 'Debes rellenar este campo',
                    }"
                    type="select"
                    placeholder="Seleccione"
                  />
                  <label
                    for="price"
                    class="block text-xs font-medium text-gray-700 text-left ml-1"
                  >
                    <strong> Respuesta </strong>
                  </label>
                  <FormulateInput
                    type="text"
                    placeholder="Respuesta de seguridad"
                    name="password_confirm"
                    v-model.lazy.trim="data.resSecQuest"
                    class="text-left bg-white rounded-lg"
                    validation="required|min:4|matches:/^[A-Za-z\s*\á\é\í\ó\ú\0-9\@\.]+$/"
                    :validation-messages="{
                      required: 'Debes rellenar este campo',
                      min: 'Debe tener al menos 4 caracteres',
                      matches: 'Solo puede contener letras y/o numeros',
                    }"
                    validation-name="Password confirmation es"
                  />
                </div>
                <div class="w-full p-2 mt-4 bg-gray-100 rounded-md">
                  <FormulateInput
                    v-model.lazy.trim="data.autorizationUsageInfo"
                    class="formulate-input-decorator text-left text-sm px-1 rounded-lg mb-2"
                    label="Autorizo el uso de mi información para fines adicionales"
                    validation="required"
                    :validation-messages="{
                      required: 'Debes rellenar este campo',
                    }"
                    type="checkbox"
                    placeholder="Seleccione"
                  />
                  <FormulateInput
                    v-model.lazy.trim="data.autorizationNewsProms"
                    class="formulate-input-decorator text-left text-sm px-1 rounded-lg mb-2"
                    label="Quiero recibir Novedades y Promociones"
                    type="checkbox"
                    placeholder="Seleccione"
                  />
                </div>
                <div class="flex items-center justify-center mt-6">
                  <button
                    class="py-2 px-4 bg-postul2 hover:bg-blue-800 hover:ring-offset-blue-200 focus:ring-blue-600 focus:ring-offset-blue-200 text-postul2 text-white w-2/6 transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg"
                    type="submit"
                  >
                    Enviar
                  </button>
                  <button
                    @click="tester"
                    class="hidden flex-shrink-0 px-4 py-2 text-base font-semibold text-white backasista rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2"
                  >
                    Enviar2
                  </button>
                </div>
              </FormulateForm>

              <loader v-if="isLoading">
                <template v-slot:msg>
                  {{ msg }}
                </template>

                <template v-slot:msg1>
                  {{ msg1 }}
                </template>
              </loader>
            </div>
          </div>
        </div>
        <div
          class="flex items-center justify-center py-1.5 text-xs text-center bg-gray-200 dark:bg-gray-700"
        >
          <span class="text-gray-600 dark:text-gray-200"
            >¿Ya estás registrado?
          </span>

          <a
            href="#"
            class="mx-2 font-bold text-blue-600 dark:text-blue-400 hover:text-blue-500"
            @click="$router.push('loginEmprs')"
            >Iniciar sesión</a
          >
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.text-xs2 {
  font-size: 0.5rem /* 12px */;
  line-height: 0.6rem /* 16px */;
}
.bg-postul2 {
  --tw-border-opacity: 1;
  background-color: rgba(19, 73, 146, var(--tw-bg-opacity));
}
.border-postul2 {
  --tw-border-opacity: 1;
  border-color: rgba(25, 73, 146, var(--tw-bg-opacity));
}
.hover\:border-postul2:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(19, 73, 146, var(--tw-bg-opacity));
}
.text-postul2 {
  --tw-text-opacity: 1;
  color: rgba(239, 224, 37, var(--tw-text-opacity));
}
.hover\:text-postul2:hover {
  --tw-text-opacity: 1;
  color: rgba(239, 224, 37, var(--tw-text-opacity));
}
.hover\:bgg-postul2:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(23, 73, 146, var(--tw-bg-opacity));
}
</style>

<script>
import axios from "axios";
import loader from "@/components/shared/loader.vue";
import { HTTP } from "@/services/api";

export default {
  name: "registrarEmpres",
  components: { loader },
  methods: {
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[A-Za-z]+$/.test(char)) return true;
      else e.preventDefault();
    },
    tester() {
      let test = HTTP;
      console.log("test api res", test);
      HTTP.get("registroprimariouser")
        .then(() => {
          //CLG("mission acomplished");
          //CLG("respuesta post test", res.data);
        })
        .catch((error) => {
          console.log("error es ", error);
        });
    },
    onSubmit() {
      let dataUser = this.data;
      this.isLoading = true;

      axios
        .post("https://api.postula2.com/createEmprs", this.data)
        //.post("https://api.postula2.com/createEmprs",this.data)
        // .post(
        //   "https://api.postula2.com/registroPrimarioEmprs",
        //   this.data
        // )
        .then((res) => {
          //Perform Success Action
          //CLG("mission acomplished");
          // CLG("respuesta post id objeto registrad", res.data);
          if (res.data.error) {
            //error 201-->api responde, datos ya existentes
            if (res.data.error.code == 201) {
              this.msg1 =
                "¡Verifique la información ingresada e intente nuevamente!";
              //this.$store.commit("getflagLogin", redFlag);
              this.$store.commit({
                type: "getflagLogin",
                redFlag: true,
              });
              setTimeout(() => {
                this.isLoading = false;
                this.$store.commit({
                  type: "getflagLogin",
                  redFlag: null,
                });
              }, 3000);
            }
          } else if (res.data.success.code == 200) {
            this.$store.commit("getRegUserEmprs", res.data.success.data);
            let myTok = "Bearer " + res.data.success.token;
            this.$store.commit("getMyTokCredentApi", myTok);
            //console.log("toke",res.data.success.token)
            // alert(
            //   "Registrado satisfactoriamente, debe completar fase inicial de registro"
            // );
            this.msg =
              "Registrado satisfactoriamente.. debe completar la siguiente información de su empresa!";
            //this.$store.commit("getflagLogin", redFlag);
            this.$store.commit({
              type: "getflagLogin",
              greenFlag: true,
            });
            setTimeout(() => {
              this.isLoading = false;
              this.$store.commit({
                type: "getflagLogin",
                greenFlag: null,
              });
              this.$router.push({
                name: "empresareg",
                params: { dataUser },
              });
            }, 2500);

            //CLG("llego hasta aquilani");
          }
          //CLG("llego hasta aquilani");
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG("mission pelaste bolas");
          //CLG(error);
          this.msg1 =
            "¡Verifique la información ingresada e intente nuevamente!";
          //this.$store.commit("getflagLogin", redFlag);
          this.$store.commit({
            type: "getflagLogin",
            redFlag: true,
          });
          setTimeout(() => {
            this.isLoading = false;
            this.$store.commit({
              type: "getflagLogin",
              redFlag: null,
            });
          }, 3000);
        })
        .finally(() => {
          //Perform action in always
          // CLG hola
        });
    },
  },
  data() {
    return {
      disabled: true,
      isLoading: false,
      msg: null,
      msg1: null,
      data: {
        nombreUserEmprs: "",
        apellidoUserEmprs: "",
        emailUserEmprs: "",
        passwUserEmprs: "",
        secQuest: "",
        resSecQuest: "",
        autorizationUsageInfo: "",
        autorizationNewsProms: false,
      },
    };
  },
};
</script>
