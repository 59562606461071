<template>
  <div>
    <loader v-if="isLoading" />

    <main
      class="
        bg-gray-50
        dark:bg-gray-800
        h-full
        max-h-full
        overflow-y-auto
        relative
        mb-10
        pb-16
      "
    >
      <div class="flex items-start justify-between">
        <div class="flex flex-col w-full md:space-y-4">
          <div
            class="
              overflow-visible
              h-screen
              pb-24
              px-4
              md:px-6 md:mt-4 md:mb-8
              lg:mt-6
            "
          >
            <div
              class="
                flex
                items-center
                space-x-4
                font-light
                w-full
                text-center text-xl
                sm:text-md
                md:text-2xl
                dark:text-white
                text-gray-800
                border-b border-gray-200
              "
            >
              <p>Anuncios con <strong>0</strong> postulados</p>
            </div>
            <div
              class="
                flex
                items-center
                mt-0.5
                font-light
                w-1/6
                text-center text-xs
                sm:text-xs
                md:text-xs
                dark:text-white
                text-blue-500
              "
            >
              <span
                v-if="!this.$store.state.analista.id"
                @click="volver"
                class="cursor-pointer"
                >⇤ Volver</span
              >
              <span v-else @click="volverAnalista" class="cursor-pointer"
                >⇤ Volver</span
              >
            </div>

            <div
              class="
                grid
                sm:grid-cols-1
                md:grid-cols-2
                lg:grid-cols-3
                gap-4
                mt-2
                mb-4
                ml-2
              "
            >
              <div
                class="w-full"
                v-show="!isLoading"
                v-for="anuncio in this.$store.state.anuncsSinPostulads"
                :key="anuncio.id"
              >
                <p
                  class="
                    hidden
                    text-sm
                    w-max
                    text-gray-700
                    dark:text-white
                    font-semibold
                    border-b border-gray-200
                  "
                >
                  Project Reffered
                </p>
                <AnuncioActivo :anuncsActivs="anuncio" :key="anuncio.id" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
//import api from '@/services/api.js'
import AnuncioActivo from "@/components/AnuncioActivo.vue";

import loader from "@/components/shared/loader.vue";
import axios from "axios";

export default {
  name: "anunciosSinPostulads",
  components: {
    AnuncioActivo,
    loader,
  },
  methods: {
    volver() {
      this.$router.push({
        name: "landing",
      });
    },
    volverAnalista() {
      this.$router.push({
        name: "homeAnalista",
      });
    },
  },
  data() {
    return {
      assets: [],
      anuncsActivs: [],
      anuncsInactivs: [],
      isLoading: false,
    };
  },
  mounted() {
    this.isLoading = true;
    if (this.$store.state.regEmprs.id) {
      axios({
        method: "GET",
        url: `https://api.postula2.com/empresa/${this.$store.state.regEmprs.id}/anuncios`,
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.myTokToApiComs,
        },
      })
        .then((res) => {
          //Perform Success Action
          //alert("Usuario no encontrado, debe registrarse");
          //CLG("respuesta obtenida", res.data);
          this.isLoading = false;
          this.assets = res.data.reverse();

          this.assets.forEach((anuncio) => {
            ////CLG("wtf", anuncio.anuncEstado);
            if (anuncio.anuncEstado == "activo") {
              this.anuncsActivs.push(anuncio);
            } else this.anuncsInactivs.push(anuncio);
          });

          //CLG("assets", this.assets);
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG("mission pelaste bolas");
          //CLG(error);
        })
        .finally(() => {
          //Perform action in always
        });
    } else {
      if (this.$store.state.regUserEmprs.empresa) {
          axios({
          method: "GET",
          url: `https://api.postula2.com/empresa/${this.$store.state.regUserEmprs.empresa[0].id}/anuncios`,
          headers: {
            'Content-Type': "application/json",
            'Authorization' : this.$store.state.myTokToApiComs
          },
        }) //https://api.postula2.com/empresa/${this.$store.state.regUserEmprs.empresa[0].id}/anuncios}
          .then((res) => {
            //Perform Success Action
            //alert("Usuario no encontrado, debe registrarse");
            ////CLG("respuesta obtenida", res.data);

            this.isLoading = false;
            this.assets = res.data.reverse();

            this.assets.forEach((anuncio) => {
              ////CLG("wtf", anuncio.anuncEstado);
              if (anuncio.anuncEstado == "activo") {
                this.anuncsActivs.push(anuncio);
              } else this.anuncsInactivs.push(anuncio);
            });

            ////CLG("assets", this.assets);
          })
          .catch(() => {
            // error.response.status Check status code
            //CLG("mission pelaste bolas");
            //CLG(error);
          })
          .finally(() => {
            //Perform action in always
          });
      } else {
        alert("Debe iniciar sesión o registrarse para ver esta información");
        this.$router.push({
          name: "loginEmprs",
        });
      }
    }
  },
};
</script>

<style></style>
