<template>
  <div class="h-screen">
    <div
      class="
        flex
        items-center
        text-left text-xl
        sm:text-md
        md:text-2xl
        dark:text-white
        text-gray-800
        border-b border-gray-200
        mt-4
        mx-6
      "
    >
      <p class="">Datos preprocesados: &nbsp;</p>
      <span class="sm:text-sm md:text-xs mt-2 dark:text-white text-gray-800">
        Información preprocesada segun datos solicitados</span
      >
    </div>
    <div
      class="
        mx-6
        flex
        items-center
        font-light
        w-3/6
        text-center text-xs
        sm:text-xs
        md:text-xs
        dark:text-white
        text-blue-500
      "
    >
      <span @click="volver" class="cursor-pointer">⇤ Volver</span>
    </div>
    <loader v-if="isLoading"></loader>

    <div class="bg-gray-50 m-2 p-2 rounded-lg">
      <table
        ref="table"
        id="postulados"
        summary="Postulados"
        rules="groups"
        frame="hsides"
        border="2"
        class="min-w-full leading-normal rounded-lg"
      >
        <caption>
          <p class="hidden text-left text-sm mx-2 mb-1" v-show="idAnuncio">
            <strong>Postulados al anuncio:</strong>
            <strong>"</strong>{{ idAnuncio }}<strong>"</strong>
          </p>
        </caption>

        <thead>
          <tr>
            <th
              scope="col"
              class="
                px-5
                py-3
                bg-white
                border-b border-gray-200
                text-gray-800 text-center text-xs
                uppercase
                font-normal
                hidden
              "
            >
              #
            </th>
            <th
              scope="col"
              class="
                px-5
                py-3
                bg-white
                border-b border-gray-200
                text-gray-800 text-left text-xs
                uppercase
                font-normal
              "
            >
              Query
            </th>
            <th
              scope="col"
              class="
                px-14
                py-3
                bg-white
                border-b border-gray-200
                text-gray-800 text-left text-xs
                uppercase
                font-normal
              "
            >
              Cantidad
            </th>
          </tr>
        </thead>
        <tbody class="text-left">
          <tr>
            <td class="px-6 py-3 bg-white text-sm border-b border-gray-200">
              Cantidad de personas registradas con
              <strong> datos primarios </strong> en los ultimos 15 días
              <span class="text-xs px-0.5"
                >(Desde: {{ hace15dias }} T00:00am)</span
              >
            </td>
            <td class="px-20 py-5 bg-white text-sm border-b border-gray-200">
              {{$store.state.userCount15Days}}
            </td>
          </tr>
          <tr>
            <td class="px-6 py-3 bg-white text-xs border-b border-gray-200">
              X
            </td>
            <td class="px-20 py-5 bg-white text-sm border-b border-gray-200">
              X
            </td>
          </tr>
          <!-- <tr>
            <td class="px-6 py-3 bg-white text-xs border-b border-gray-200">
              X
            </td>
            <td class="px-20 py-5 bg-white text-sm border-b border-gray-200">
              Y
            </td>
          </tr> -->
        </tbody>
      </table>
      <button
        class="
          flex-shrink-0
          px-4
          py-2
          mx-4
          my-3
          text-base
          font-semibold
          text-white
          bg-green-600
          rounded-lg
          shadow-md
          hover:bg-green-700
          focus:outline-none
          focus:ring-2
          focus:ring-green-500
          focus:ring-offset-2
          focus:ring-offset-green-200
        "
        @click="tableToExcel('table', 'postulados')"
      >
        Exportar XLS
      </button>
      <button
        class="
          flex-shrink-0
          px-4
          py-2
          text-base
          font-semibold
          text-white
          bg-purple-600
          rounded-lg
          shadow-md
          hover:bg-purple-700
          focus:outline-none
          focus:ring-2
          focus:ring-purple-500
          focus:ring-offset-2
          focus:ring-offset-purple-200
        "
        @click="exportPDF"
      >
        Exportar PDF
      </button>
    </div>
  </div>
</template>

<script>
import jsPDF from "jspdf";
import "jspdf-autotable";

import loader from "@/components/shared/loader.vue";

import axios from "axios";
//import func from 'vue-editor-bridge';

export default {
  name: "personasPerfiladasHoy",
  components: {
    loader,
    //  sideBarMenu,
  },
  data() {
    return {
      hidePane2: "",
      assets: [],
      postulads: [],
      idAnuncio: "",
      anuncio: {},
      fecha: "",
      showModalVerMs: false,
      showModalVerNots: false,
      showModalCrearNots: false,

      isLoading: true,
      uri: "data:application/vnd.ms-excel;base64,",
      template:
        '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
      base64: function (s) {
        return window.btoa(unescape(encodeURIComponent(s)));
      },
      format: function (s, c) {
        return s.replace(/{(\w+)}/g, function (m, p) {
          return c[p];
        });
      },
    };
  },
  methods: {
    prueba: function (value) {
      ////CLG("persona Id:", value);
      this.consultPerson(value);
    },
    volver() {
      this.$router.go(-1);
    },
    tableToExcel(table, name) {
      if (!table.nodeType) table = this.$refs.table;
      var ctx = { worksheet: name || "Prueba1", table: table.innerHTML };
      window.location.href =
        this.uri + this.base64(this.format(this.template, ctx));
    },
    exportPDF() {
      var vm = this;
      var columns = [
        { title: "nombre", dataKey: "nombreUser" },
        { title: "apellido", dataKey: "apellidoUser" },
      ];
      var doc = new jsPDF("p", "pt");
      doc.text("To Do List", 40, 40);
      doc.autoTable(columns, vm.postulads, {
        margin: { top: 60 },
      });
      doc.save("todos.pdf");
    },
    consultPerson: function (value) {
      axios({
        method: "get",
        url: `https://api.postula2.com/persona/?owner=${value}`,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          this.$store.commit("getUserData", res.data[0]);
          //Perform Success Action
          //CLG("mandado al commit store", res.data[0]);
          // //CLG("id de owner es: ", this.$store.state.userData.owner.id);
          // //CLG("id de anuncio es: ", this.$store.state.anuncs.id);
          // //CLG("id de analista es: ", this.$store.state.analista.id);
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG("mission pelaste bolas");
          //CLG(error);
        });
    },
    cantPerson: function async(fecha) {
      console.log("entrando fecha", fecha);

      axios({
        method: "post",
        //url: `https://api.postula2.com/persona/?owner=${value}`,personByOwnerId
        url: `https://api.postula2.com/ago15days`,
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.myTokToApiComs,
        },
        data: {
          fecha: fecha,
        },
      })
        .then((res) => {
          console.log("resp", res.data);
          fecha = res.data.data;
          this.$store.commit("getUserCount15Days", res.data.data);
          //   this.$store.commit("getUserData", res.data.persona[0]);
          //   this.$store.commit("getPersonEvaluacn", res.data.evaluacions);
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG("mission pelaste bolas");
          //CLG(error);
        });
    },
  },
  computed: {
    hace15dias() {
      const day = 86400000;
      const myDateToday = Date.now();
      var myDate15DaysAgo = myDateToday - 15 * day;
      const date2 = new Date(myDate15DaysAgo);

      return date2.toLocaleDateString();
    },
    registradosEn15Dias() {
      const myDateToday = new Date();
      const year = myDateToday.getFullYear();

      var hace15dias = this.hace15dias.split("/"); //deconstruye la fecha de la funcion hace15dias() separada por /
      //   console.log(hace15dias[0]);
      //   console.log(hace15dias[1]);
      //   console.log(year.toString());

      const fecha =
        year.toString() +
        "-" +
        hace15dias[1].toString() +
        "-" +
        hace15dias[0].toString() +
        "T00:00:00";
      var finalDate = Date.parse(fecha);
      console.log("fecha final es:", finalDate);
      console.log(this.cantPerson(finalDate));
      
      //console.log("date", Date.parse("2019-09-01T00:00:00"));
      // console.log("fecha es", Date.parse(fecha));
      //console.log("fecha es2", fecha.toString()); ago15days

      return Date.parse(fecha);
    },
  },
  filters: {
    formatedTime: function (value) {
      var date = new Date(value);

      return (
        date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate() // +
        // " " +
        //date.getHours() +
        //":" +
        // date.getMinutes() +
        //":" +
        //date.getSeconds()
      );
      ////CLG(formatedTime);
    },
    formatedAnuncActivo: function (value) {
      //var date = new Date(value);
      if (value == true) {
        return "Activo";
      } else {
        return "Inactivo";
      }
      //res.data[0].nombreUser+res.data[0].apellidoUser //CLG(formatedTime) https://api.postula2.com/registroPrimarioUser?id=;
    },
  },
  created() {
    this.postulads = this.$store.state.perfiladosHoy;
  },

  mounted() {
    if (this.$store.state.regEmprs.id) {
      this.isLoading = false;
    } else {
      if (this.$store.state.regUserEmprs.empresa) {
        this.isLoading = false;
      } else {
        alert("Debe iniciar sesión o registrarse para ver esta información");
        this.$router.push({
          name: "loginEmprs",
        });
      }
    }
  },
};
</script>

