<!-- eslint-disable prettier/prettier -->
<template>
  <div
    @mouseleave="sugerencia = false"
    class="font-sans bg-gray-100 antialiased font-bold shadow-xl sticky top-0 z-50"
    id="app"
  >
    <nav
      class=" flex items-center justify-between flex-wrap bg-teal pt-4 pb-4 px-4 uppercase lg:h-20"
    >
      <!-- Logo -->
      <div class="flex items-center flex-no-shrink text-white rounded-sm">
        <a class="flex-shrink-0">
          <img
            @click="$router.push({ path: '/home' })"
            src="../img/135x35-LOGO-POSTULADOS.png"
            alt="Logo"
            class="cursor-pointer h-6 lg:h-8 w-auto"
          />
        </a>
      </div>
      <!-- Boton modal TLF algolia -->
      <div class="block hidden">
        <button
          v-if="!this.$store.state.regUserEmprs.id"
          @click="isOpen = !isOpen"
          class="postula2Primary text-white px-4 py-2 rounded-full"
        >
          <div class="flex justify-between items-center w-24">
            <img src="../img/lupa.png" alt="Lupa Buscador" class="h-4" />
            <p class="font-semibold text-xs">Buscar oferta</p>
          </div>
        </button>
      </div>

      <!-- Final Buscador modal algolia -->

      <!-- MODAL NUEVO -->

      <!-- Modal contenido algolia -->
      <div v-if="isOpen" class="fixed z-50">
        <div class="flex pt-4 px-0 pb-20 text-center sm:block sm:p-0">
          <div class="fixed inset-0 transition-opacity" aria-hidden="true">
            <div class="absolute inset-0 bg-black opacity-70"></div>
            <div class="absolute w-full top-0 flex items-center justify-center">
              <div
                v-if="isOpen"
                class="bg-transparent rounded-xl shadow-lg lg:w-11/12 lg:mt-4 overflow-y-auto"
              >
                <div class="absolute top-0 right-1 z-50  transform -translate-x-1/2">
                  <button
                    @click="isOpen = false"
                    class="mt-8 px-2 py-2 font-bold postula2Primary rounded focus:shadow-outline z-50 border border-white"
                  >
                    <img src="../img/close.png" alt="close" class="h-3" />
                  </button>
                </div>
                <div class="flex justify-center">
                  <the-searchbar-algolia class="h-screen-p p-2" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Fin Modal -->

      <!-- boton menu -->
      <div class="relative lg:hidden">
        <button
          @click="open = !open"
          class="flex items-center px-3 py-2 border-2 rounded text-teal-lighter border-postul2 hover:text-postul2 hover:border-postul2 transition duration-700 ease-in-out"
        >
          <svg
            class="fill-current h-3 w-3"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>
      </div>
      <!-- Contenido del menu -->
      <div
        :class="[-right-2/3, open ? 'fixed' : 'hidden']"
        class="flex-grow lg:flex lg:items-center w-5/12  lg:w-auto fixed lg:static z-20 top-0 right-0 bg-white lg:bg-transparent shadow lg:shadow-none rounded-lg"
      >
        <div
          class="text-sm lg:flex-grow m overflow-auto transition duration-700 ease-in-out"
        >
          <button
            @click="open = !open"
            class="flex items-center border-2 rounded text-teal-lighter border-postul2 hover:text-postul2 hover:border-postul2 lg:hidden"
          >
            <svg width="24" height="24" viewBox="0 0 24 24">
              <path
                d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12z"
              />
            </svg>
          </button>

          <a
            @click="$router.push({ path: '/home' })"
            class="text-center cursor-pointer no-underline block pt-1 pb-0.5 px-4 lg:inline-block lg:mt-0 text-teal-lighter hover:text-gray-500 lg:mr-4"
          >
            Inicio
          </a>

          <a
          
            v-if="
              !this.$store.state.regUserEmprs.empresa &&
              !this.$store.state.regUser.nombreUser &&
              !this.$store.state.analista.nombreAnalist
            "
            @click="$router.push({ path: '/registrarPerson' })"
            type="button"
            class="inline-block uppercase py-1 px-4 rounded-md justify-center items-center focus:ring-postul2 focus:ring-offset-color-postul2 transition ease-in duration-200 text-center text-sm text-teal-lighter cursor-pointer font-semibold focus:outline-none focus:ring-2 focus:ring-offset-2 hover:border-postul2 hover:text-postul2 hover:border-transparent hover:text-teal"
          >
            Regístrate
            <span class="ml-1 align-bottom hidden">
              <svg
                class="-mr-1 ml-2 h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                />
              </svg>
            </span>
          </a>
          <a
            v-if="
              this.$store.state.regUserEmprs.id || this.$store.state.regEmprs.id
            "
            @click="$router.push({ path: '/landingempresarial' })"
            class="hidden cursor-pointer no-underline mt-4 sm:mt-0 text-teal-lighter hover:text-gray-500 md: mr-5"
          >
            Empresas
          </a>
          <a
            v-if="
              this.$store.state.regUserEmprs.id || this.$store.state.regEmprs.id
            "
            @click="$router.push({ path: '/anunReg' })"
            class="text-center cursor-pointer no-underline block px-4 lg:px-0 lg:ml-6 lg:inline-block sm:mt-0 text-teal-lighter hover:text-gray-500"
          >
            Publicar
          </a>
        </div>

        <!-- Buscador modal  algolia escritorio-->
        <div class=" block hidden">
          <button
            v-if="!this.$store.state.regUserEmprs.id"
            @click="isOpen = !isOpen"
            class="postula2Primary text-white px-8 py-2 rounded-full "
          >
            <div class="flex justify-between items-center w-32">
              <img src="../img/lupa.png" alt="Lupa Buscador" class="h-4" />
              <p class="font-semibold">Buscar oferta</p>
            </div>
          </button>
        </div>
        <!-- Final Buscador modal -->
        <hr class="mt-2 border-gray-300 border-1 lg:border-none" />

        <div class="m-0 lg:my-0 border-1 border-red-600">
          <!-- Seccion Header Usuarios -->
          <div v-if="this.$store.state.regUser.nombreUser">
            <UserProfileRight
              class="m-0 lg:my-0 border-1 border-red-600 w-64"
              :key="$store.state.profileImage.uploadedUserImgComponntValue"
            ></UserProfileRight>
          </div>
          <!-- // -->
          <!-- Seccion Header Empresas -->
          <div v-if="this.$store.state.regUserEmprs.empresa">
            <EmprsProfileRight
              class="my-3 lg:my-0"
              :key1="$store.state.profileImage.uploadedEmprsImgComponntValue"
            ></EmprsProfileRight>
          </div>
        </div>
        <!-- // -->

        <!-- Seccion Header Analistas -->
        <div v-if="
              this.$store.state.analista.nombreAnalist &&
              !this.$store.state.regUserEmprs.empresa
            " class="m-4 lg:my-0">
          <div
            
            class="flex flex-col lg:flex-row items-center justify-evenly w-full lg:w-56 mx-auto gap-2"
          >
            <!-- Imagen -->
            <div class="w-full lg:w-1/4 flex items-center justify-center">
              <a
                v-if="!this.$store.state.regUserEmprs.empresa"
                @click="goTo3"
                class="block"
              >
                <img
                  alt="profil"
                  src="../img/edificio.png"
                  class="mx-auto object-cover rounded-full h-10 w-auto cursor-pointer"
                />
              </a>
            </div>
            <div class="w-full lg:w-1/3 items-center ml-1">
              <!-- Nombre -->
              <div @click="goTo3" class="px-0 flex flex-col cursor-pointer">
                <span
                  class="text-gray-600 dark:text-white text-xxs font-semibold my-0.5"
                >
                  {{ this.$store.state.analista.nombreAnalist }}
                </span>
                <span
                  v-if="!this.$store.state.regUserEmprs.empresa"
                  class="text-gray-400 text-xxxs"
                >
                  Analista
                  <span
                    v-if="this.$store.state.analista.rolAnalist == 'junior'"
                  >
                    JR
                  </span>
                  <span
                    v-else-if="
                      this.$store.state.analista.rolAnalist == 'senior'
                    "
                  >
                    SR
                  </span>
                  <span v-else> ADMIN </span>
                </span>
              </div>
              <div
                v-if="
                  this.$store.state.analista.id &&
                  !this.$store.state.regUserEmprs.empresa
                "
                class="flex-row gap-2 ml-0 flex justify-center items-center"
              >
                <a
                  v-if="this.$store.state.regUserEmprs.empresa"
                  @click="goTo2"
                  class="block relative"
                >
                  <img
                    alt="profil"
                    src="../img/edificio.png"
                    class="mx-auto object-cover rounded-full h-10 w-auto cursor-pointer"
                  />
                </a>
              </div>
            </div>
            <!-- Boton -->
            <div class="w-full lg:w-1/4 lg:flex lg:items-center lg:py-0">
              <button
                @click="reloaded()"
                class="p-1.5 px-2 bg-red-300 normal-case lg:uppercase hover:bg-red-600 focus:ring-red-500 focus:ring-offset-red-200 text-white transition ease-in duration-200 text-center text-xs font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg lg:mx-auto"
              >
                Salir
              </button>
            </div>
          </div>
        </div>

        <!-- // -->
        <div v-if="this.$store.state.analista" class="relative text-right ">
          <loader v-if="isLoading" class="">
            <template v-slot:msg>
              {{ msg }}
            </template>

            <template v-slot:msg1>
              {{ msg1 }}
            </template>
          </loader>
        </div>
        <!-- This example requires Tailwind CSS v2.0+ -->
        <div class="inline-block text-left"  v-if="
              !this.$store.state.regUser.nombreUser &&
              !this.$store.state.regUserEmprs.empresa &&
              !this.$store.state.analista.nombreAnalist
            ">
          <div
            class="flex flex-col items-center justify-center m-6 lg:m-0 lg:mx-6"
           
          >
            <button
              v-if="
                !this.$store.state.regUser.nombreUser &&
                !this.$store.state.regUserEmprs.empresa &&
                !this.$store.state.analista.nombreAnalist
              "
              @click="menu = !menu"
              type="button"
              class="hidden lg:flex justify-center items-center uppercase py-1.5 px-2 mb-3 lg:mb-0 rounded-md focus:ring-postul2 focus:ring-offset-color-postul2 transition ease-in duration-200 text-center text-xs font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 border-postul2 hover:border-postul2 hover:text-postul2 hover:border-transparent hover:text-teal border-2"
            >
              Ingresar
              <span class="ml-1">
                <svg
                  class="-mr-1 ml-2 h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
              </span>
            </button>

            <div
              class="flex lg:hidden flex-col items-center justify-center pb-0 mt-0 lg:mt-0"
            >
              <p class="text-xxs normal-case mb-1">Ingresar como:</p>
              <a
                @click="$router.push({ path: '/loginEmprs' })"
                class="cursor-pointer rounded-md block px-4 py-1 text-sm hover:border-postul2 hover:text-postul2 z-10 hover:border-transparent hover:text-teal"
                role="menuitem"
                tabindex="-1"
                id="menu-item-0"
                >Empresas</a
              >
              <a
                @click="$router.push({ path: '/Login' })"
                class="cursor-pointer rounded-md block px-4 py-1 text-sm hover:border-postul2 hover:text-postul2 z-10 hover:border-transparent hover:text-teal"
                role="menuitem"
                tabindex="-1"
                id="menu-item-1"
                >Postulados</a
              >
            </div>
          </div>

          <div
            v-if="menu"
            class="origin-top-right absolute z-10 right-0 mt-2 w-44 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none transition ease-out duration-100 transform opacity-100 scale-100"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="menu-button"
            tabindex="-1"
          >
            <div
              @mouseleave="menu = !menu"
              class="flex flex-col p-2 items-center justify-center"
              role="none"
            >
              <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" -->
              <a
                @click="$router.push({ path: '/loginEmprs' })"
                class="cursor-pointer rounded-md block px-4 py-1 text-sm hover:border-postul2 hover:text-postul2 z-10 hover:border-transparent hover:text-teal"
                role="menuitem"
                tabindex="-1"
                id="menu-item-0"
                >Empresas</a
              >
              <a
                @click="$router.push({ path: '/Login' })"
                class="cursor-pointer rounded-md block px-4 py-1 text-sm hover:border-postul2 hover:text-postul2 z-10 hover:border-transparent hover:text-teal"
                role="menuitem"
                tabindex="-1"
                id="menu-item-1"
                >Postulados</a
              >
            </div>
            <div class="py-1 hidden" role="none">
              <a
                href="#"
                class="text-gray-700 block px-4 py-2 text-sm"
                role="menuitem"
                tabindex="-1"
                id="menu-item-2"
                >Archive</a
              >
              <a
                href="#"
                class="text-gray-700 block px-4 py-2 text-sm"
                role="menuitem"
                tabindex="-1"
                id="menu-item-3"
                >Move</a
              >
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>
<script>
import axios from "axios";
import loader from "@/components/shared/loader.vue";
import EmprsProfileRight from "@/components/layouts/headerBar/EmprsProfileRight.vue";
import UserProfileRight from "@/components/layouts/headerBar/UserProfileRight.vue";
import TheSearchbarAlgolia from "@/components/layouts/searchbar/newSearchBar.vue";

export default {
  name: "headerbar",
  data() {
    return {
      menu: false,
      isLoading: false,
      msg: "",
      msg1: "",
      sugerencia: false,
      searchQuery: "",
      responseQuery: [],
      isActive: true,
      open: false,
      menuOpen: false,
      rutaUploadedImg: "ts/s",
      rutaDefaultImg: "./img/picture1.jpg",
      isOpen: false,
      right: false,
    };
  },
  components: {
    loader,
    EmprsProfileRight,
    UserProfileRight,
    TheSearchbarAlgolia,
  },
  created() {
    if (this.$store.state.userData) {
      if (
        this.$store.state.userData.length > 0 &&
        this.$store.state.userData.imageProfileInfo.length > 0
      ) {
        this.rutaUploadedImg =
          // eslint-disable-next-line no-undef
          $store.state.userData.imageProfileInfo[0].imageEncryptName;
      }
    }
  },
  methods: {
    toggleMenuD() {
      this.menuOpen = !this.menuOpen;
    },
    goTo: function () {
      this.$router.push({
        name: "personLandin",
      });
    },
    goTo2: function () {
      this.$router.push({
        name: "landing",
      });
    },
    goTo3: function () {
      this.$router.push({
        name: "homeAnalista",
      });
    },
    reloaded: function () {
      this.isLoading = true;
      sessionStorage.clear();
      setTimeout(() => {
        this.$router.push({ name: "HeaderHome" });

        setTimeout(() => {
          this.$router.go();
        }, 500);
      }, 1500);
      //location.reload();
    },
    borradorQuery: function () {
      this.sugerencia = false;
      this.searchQuery = null;
      this.responseQuery = null;
    },
    anuncioId: function () {
      this.sugerencia = false;
      //CLG("anuncio es: ", elId);
      this.$store.commit({
        type: "getAnuncsByPalabra",
        searchQuery: this.searchQuery,
        responseQuery: this.responseQuery,
      });
      this.$router.push({
        name: "pivot",
      });

      this.searchQuery = null;
      this.responseQuery = [];
    },
  },
  watch: {
    searchQuery: function () {
      if (this.searchQuery) {
        if (this.searchQuery.length == 0) this.sugerencia = false;
        else this.sugerencia = true;
      } else {
        this.sugerencia = false;
      }
      axios
        .get(
          `https://api.postula2.com/searchAnuncio?palabra=${this.searchQuery}`
          // https://api.postula2.com/searchAnuncio?palabra=${this.searchQuery}
          //`https://api.postula2.com/empresa/${this.$store.state.regUserEmprs.empresa[0].id}/anuncios`
        )
        .then((res) => {
          //Perform Success Action
          //alert("Usuario no encontrado, debe registrarse");
          this.sugerencia = true;
          this.responseQuery = res.data.anunciosBypalabra;
          //CLG("query buscado", this.searchQuery);
          //CLG("respuesta obtenida", this.responseQuery);
          //this.isLoading = false;
          //this.assets = res.data;
          ////CLG("assets", this.assets);
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG("mission pelaste bolas");
          //CLG(error);
        })
        .finally(() => {
          //Perform action in always
        });
    },
    search() {
      //CLG("busqueda por barra es: ", this.searchQuery);
    },
    toggle() {
      this.open = !this.open;
    },
  },
};
</script>
<style scoped>
.h-screen-p {
  height: 47rem !important;
}
.text-xxs {
  font-size: 0.6rem;
  line-height: 0.75rem;
}
.h-22 {
  height: 5.5rem;
}

.postula2Primary {
  background-color: #134992;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 500ms ease-out;
}

.slide-right-enter-active,
.slide-right-leave-active {
  transition: transform 0.5s;
}
.slide-right-enter,
.slide-right-leave-to {
  transform: translateX(100%);
}
</style>
