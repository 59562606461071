<template>
  <div class="container flex-wrap px-5 py-24 mx-auto items-center">
    <h2 class="bg-red-300">login TESTER</h2>
    <div
      class="w-full max-w-sm mx-auto overflow-hidden bg-gray-300 rounded-lg shadow-md dark:bg-gray-800"
    >
      <div class="px-6 py-4">
        <h2
          class="text-3xl font-bold text-center text-gray-700 dark:text-white"
        >
          Ingresar
        </h2>

        <p class="mt-1 text-center text-gray-500 dark:text-gray-400">
          Inicia sesión o crea una cuenta
        </p>

        <form @submit.stop.prevent="onSubmit">
          <div class="w-full mt-4">
            <input
              class="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-500 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
              type="email"
              placeholder="Email Address"
              aria-label="Email Address"
              name="emailUser"
              v-model.lazy.trim="data.emailUser"
            />
          </div>

          <div class="w-full mt-4">
            <input
              class="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-500 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
              type="password"
              placeholder="Password"
              aria-label="Password"
              name="passwUser"
              v-model.lazy.trim="data.passwUser"
            />
          </div>

          <div class="flex items-center justify-between mt-4">
            <a
              href="#"
              class="text-sm text-gray-600 dark:text-gray-200 hover:text-gray-500"
              >¿Contraseña olvidada?</a
            >

            <button
              class="flex-shrink-0 px-4 py-2 text-base font-semibold text-white backasista rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2"
              type="submit"
            >
              Enviar
            </button>
            <button
              @click="tester"
              class="flex-shrink-0 px-4 py-2 text-base font-semibold text-white backasista rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2"
            >
              Enviar2
            </button>
          </div>
        </form>
      </div>
      <loader v-if="isLoading">
        <template v-slot:msg>
          {{ msg }}
        </template>

        <template v-slot:msg1>
          {{ msg1 }}
        </template>
      </loader>

      <div
        class="flex items-center justify-center py-4 text-center bg-gray-200 dark:bg-gray-700"
      >
        <span class="text-sm text-gray-600 dark:text-gray-200"
          >¿No tienes una cuenta?
        </span>

        <a
          href="#"
          class="mx-2 text-sm font-bold text-blue-600 dark:text-blue-400 hover:text-blue-500"
          @click="$router.push('RegistroPerson')"
          >Registrarse</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import loader from "@/components/shared/loader.vue";
import { HTTP } from "@/services/api";

//import CryptoJS from 'crypto-js';

export default {
  name: "login",
  components: { loader },
  methods: {
    tester() {
      // let test = $api;
      ////CLG("test api res", test);
      HTTP.post(this.data)
        .then(() => {
          //CLG("mission acomplished");
          //CLG("respuesta post test", res.data);
        })
        .catch(() => {
          //CLG("error es ", error);
        });
    },
    onSubmit() {
      this.isLoading = true;

      //CLG("respuesta get", this.data.emailUser);

      if (this.data.emailUser && this.data.passwUser) {
        axios
          .post("https://api.postula2.com/loginEncr", this.data)
          // .get(
          //   `https://api.postula2.com/registroPrimarioUser?emailUser=${this.data.emailUser}`)
          //loginEncr         .post("https://api.postula2.com/registroPrimarioUser", this.data)
          .then((res) => {
            //CLG("infor usuari", res.data);
            //Perform Success Action //res.data.success.data
            if (res.data.error) {
              ///////////ENCIENDE NOTIFICACION VIA SLOT/////No existe user//////
              this.msg1 =
                "¡Verifique la información ingresada e intente nuevamente!";
              //this.$store.commit("getflagLogin", redFlag);
              this.$store.commit({
                type: "getflagLogin",
                redFlag: true,
              });
              setTimeout(() => {
                this.isLoading = false;
                this.$store.commit({
                  type: "getflagLogin",
                  redFlag: null,
                });
              }, 3000);
              //////////////////////////////////////////////////
            } else if (res.data.success) {
              /////SI EXISTE DATA PRIMARIA CARGA INFO EN STORE Y TRAE SUS DATA//////

              this.$store.commit({
                type: "getRegUser",
                id: res.data.success.data.id,
                nombreUser: res.data.success.data.nombreUser,
                apellidoUser: res.data.success.data.apellidoUser,
                emailUser: res.data.success.data.emailUser,
                telf1: res.data.success.data.telf1,
              });
              axios
                .get(
                  `https://api.postula2.com/persona?owner=${this.$store.state.regUser.id}` //TRAE DATA REGISTRADA DE PERSONA
                )
                .then((res) => {
                  this.$store.commit("getUserData", res.data[0]);
                  ////CLG("respuesta obtenida del req", res.status);
                  //alert("DATA DEL USUARIO CARGADA"); //res.data[0].id
                })
                .catch(() => {
                  // error.response.status Check status code
                  //CLG(
                  //   "mission pelaste bolas llegando al registro de persona"
                  // );
                  //CLG(error);
                })
                .finally(() => {
                  //Perform action in always
                });
              //CLG("respuesta obtenida", res.data[0]);
              //alert("¡¡¡ACCESS GRANTED!!!");

              ///////////ENCIENDE NOTIFICACION VIA SLOT///////////
              this.msg = "¡Verificado.. redireccionando!";
              //this.$store.commit("getflagLogin", redFlag);
              this.$store.commit({
                type: "getflagLogin",
                greenFlag: true,
              });
              setTimeout(() => {
                this.isLoading = false;
                this.assets = null;
                axios
                  .get(
                    `https://api.postula2.com/registroPrimarioUser/${this.$store.state.userData.owner.id}/anuncios` //TRAE ANUNCIOS DE ESTA PERSONA
                  ) //https://api.postula2.com/empresa/${this.$store.state.regUserEmprs.empresa[0].id}/anuncios}
                  .then((res) => {
                    //Perform Success Action
                    //alert("Usuario no encontrado, debe registrarse");
                    //CLG("respuesta obtenida", res.data);
                    this.isLoading = false;
                    this.assets = res.data;
                    //CLG("assets", this.assets);
                    this.$store.commit("getAnuncsInPerson", this.assets);
                  })
                  .catch(() => {
                    // error.response.status Check status code
                    //CLG("mission pelaste bolas en login");
                    //CLG(error);
                  })
                  .finally(() => {
                    //Perform action in always
                  });
                this.$store.commit({
                  type: "getflagLogin",
                  greenFlag: null,
                });
                this.$router.push({
                  name: "EmpleosHome",
                });
              }, 2500);
              //////////////////////////////////////////////////
              setTimeout(() => {
                this.$router.push({
                  name: "EmpleosHome",
                });
              }, 3000);
            }
          })
          .catch(() => {
            // error.response.status Check status code
            //CLG("mission pelaste bolas");
            //CLG(error);
          })
          .finally(() => {
            //Perform action in always
          });
      } else {
        if (!this.data.emailUser || !this.data.passwUser)
          //alert("No pueden existir campos vacios");
          ///////////ENCIENDE NOTIFICACION VIA SLOT/////Datos incorrectos//////
          this.msg1 = "¡No pueden existir campos vacios!";
        //this.$store.commit("getflagLogin", redFlag);
        this.$store.commit({
          type: "getflagLogin",
          redFlag: true,
        });
        setTimeout(() => {
          this.isLoading = false;
          this.$store.commit({
            type: "getflagLogin",
            redFlag: null,
          });
        }, 2000);
        //////////////////////////////////////////////////
      }
    },
  },
  data() {
    return {
      isLoading: false,
      msg: null,
      msg1: null,
      data: {
        emailUser: "",
        telf1: "",
        autenticacion: "1",
        passwUser: "",
      },
    };
  },
};
</script>
