<template>
  <div class="container flex-wrap mx-auto items-center">
    <div
      class="w-full max-w-max mx-auto overflow-hidden bg-gray-100 rounded-lg shadow-md dark:bg-gray-800"
    >
      <div class="container mx-auto px-4 sm:px-8 max-w-4xl">
        <div class="py-8">
          <div class="flex flex-row mb-1 sm:mb-0 justify-between w-full">
            <h2 class="text-2xl leading-tight">Analistas</h2>
            <div class="text-end hidden">
              <form
                class="flex flex-col md:flex-row w-3/4 md:w-full max-w-sm md:space-x-3 space-y-3 md:space-y-0 justify-center"
              >
                <div class="relative">
                  <input
                    type="text"
                    id='"form-subscribe-Filter'
                    class="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                    placeholder="Buscar analista"
                  />
                </div>
                <button
                  class="flex-shrink-0 px-4 py-2 text-base font-semibold text-white bg-purple-600 rounded-lg shadow-md hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-purple-200"
                  type="submit"
                >
                  Buscar
                </button>
              </form>
            </div>
          </div>
          <div class="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
            <div class="m-2 p-2 rounded-lg grid overflow-auto">
              <table class="min-w-full leading-normal">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      class="px-5 py-3 bg-white border-b border-gray-200 text-gray-800 text-left text-sm uppercase font-normal"
                    >
                      Analista
                    </th>
                    <th
                      scope="col"
                      class="px-5 py-3 bg-white border-b border-gray-200 text-gray-800 text-left text-sm uppercase font-normal"
                    >
                      Correo
                    </th>
                    <th
                      scope="col"
                      class="px-5 py-3 bg-white border-b border-gray-200 text-gray-800 text-left text-sm uppercase font-normal"
                    >
                      Cargo | Rol
                    </th>
                    <th
                      scope="col"
                      class="px-5 py-3 bg-white border-b border-gray-200 text-gray-800 text-left text-sm uppercase font-normal"
                    >
                      Estado
                    </th>
                    <th
                      scope="col"
                      class="px-5 py-3 bg-white border-b border-gray-200 text-gray-800 text-left text-sm uppercase font-normal"
                    >
                      Acciones
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="analista in assets" :key="analista.id">
                    <td
                      class="px-5 py-5 border-b border-gray-200 bg-white text-sm"
                    >
                      <div class="flex items-center">
                        <div class="flex-shrink-0"></div>
                        <div class="ml-3">
                          <p class="text-gray-900 whitespace-no-wrap">
                            {{
                              analista.nombreAnalist +
                              " " +
                              analista.apellidoAnalist
                            }}
                          </p>
                        </div>
                      </div>
                    </td>

                    <td
                      class="px-5 py-5 border-b border-gray-200 bg-white text-sm"
                    >
                      <p class="text-gray-900 whitespace-no-wrap">
                        {{ analista.emailAnalist }}
                      </p>
                    </td>
                    <td
                      class="px-5 py-5 border-b border-gray-200 bg-white text-xs"
                    >
                      <p class="text-gray-900 whitespace-no-wrap uppercase">
                        {{
                          analista.cargoAnalist + " | " + analista.rolAnalist
                        }}
                      </p>
                    </td>
                    <td
                      class="px-5 py-5 border-b border-gray-200 bg-white text-sm"
                    >
                      <span
                        class="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight text-xs uppercase"
                      >
                        <span
                          aria-hidden="true"
                          class="absolute inset-0 bg-green-200 opacity-50 rounded-full"
                        >
                        </span>
                        <span class="relative"> {{ analista.estado }} </span>
                      </span>
                    </td>
                    <td
                      v-if="analista.id != $store.state.analista.id"
                      class="px-5 py-5 border-b border-gray-200 bg-white text-sm"
                    >
                      <div class="ml-2 flex-shrink-0 flex">
                        <a
                          @click="editAnalist(analista.id)"
                          href="#"
                          class="text-indigo-600 hover:text-indigo-900"
                        >
                          Modificar
                        </a>
                        <span
                          class="pr-2 border-r-2 h-6 align-middle border-gray-200"
                        ></span>
                        <p class="text-gray-400 text-xs ml-2">
                          <svg
                            @click="deleteEval(analista.id)"
                            width="17"
                            height="17"
                            title="Eliminar"
                            class="text-red-400 cursor-pointer"
                            fill="currentColor"
                            viewBox="0 0 1792 1792"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M704 1376v-704q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v704q0 14 9 23t23 9h64q14 0 23-9t9-23zm256 0v-704q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v704q0 14 9 23t23 9h64q14 0 23-9t9-23zm256 0v-704q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v704q0 14 9 23t23 9h64q14 0 23-9t9-23zm-544-992h448l-48-117q-7-9-17-11h-317q-10 2-17 11zm928 32v64q0 14-9 23t-23 9h-96v948q0 83-47 143.5t-113 60.5h-832q-66 0-113-58.5t-47-141.5v-952h-96q-14 0-23-9t-9-23v-64q0-14 9-23t23-9h309l70-167q15-37 54-63t79-26h320q40 0 79 26t54 63l70 167h309q14 0 23 9t9 23z"
                            ></path>
                          </svg>
                        </p>
                        <loader v-if="isLoading" class="">
                          <template v-slot:msg>
                            {{ msg }}
                          </template>

                          <template v-slot:msg1>
                            {{ msg1 }}
                          </template>
                        </loader>
                      </div>
                    </td>
                    <td
                      v-else
                      class="px-5 py-5 border-b border-gray-200 bg-white"
                    >
                      <p class="text-xxs text-red-400 w-20 font-semibold">
                        Consulte al administrador
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>

              <!-- paginador -->
              <div
                class="hidden px-5 bg-white py-5 flex flex-col xs:flex-row items-center xs:justify-between"
              >
                <div class="flex items-center">
                  <button
                    type="button"
                    class="w-full p-4 border text-base rounded-l-xl text-gray-600 bg-white hover:bg-gray-100"
                  >
                    <svg
                      width="9"
                      fill="currentColor"
                      height="8"
                      class=""
                      viewBox="0 0 1792 1792"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1427 301l-531 531 531 531q19 19 19 45t-19 45l-166 166q-19 19-45 19t-45-19l-742-742q-19-19-19-45t19-45l742-742q19-19 45-19t45 19l166 166q19 19 19 45t-19 45z"
                      ></path>
                    </svg>
                  </button>
                  <button
                    type="button"
                    class="w-full px-4 py-2 border-t border-b text-base text-indigo-500 bg-white hover:bg-gray-100"
                  >
                    1
                  </button>
                  <button
                    type="button"
                    class="w-full px-4 py-2 border text-base text-gray-600 bg-white hover:bg-gray-100"
                  >
                    2
                  </button>
                  <button
                    type="button"
                    class="w-full px-4 py-2 border-t border-b text-base text-gray-600 bg-white hover:bg-gray-100"
                  >
                    3
                  </button>
                  <button
                    type="button"
                    class="w-full px-4 py-2 border text-base text-gray-600 bg-white hover:bg-gray-100"
                  >
                    4
                  </button>
                  <button
                    type="button"
                    class="w-full p-4 border-t border-b border-r text-base rounded-r-xl text-gray-600 bg-white hover:bg-gray-100"
                  >
                    <svg
                      width="9"
                      fill="currentColor"
                      height="8"
                      class=""
                      viewBox="0 0 1792 1792"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1363 877l-742 742q-19 19-45 19t-45-19l-166-166q-19-19-19-45t19-45l531-531-531-531q-19-19-19-45t19-45l166-166q19-19 45-19t45 19l742 742q19 19 19 45t-19 45z"
                      ></path>
                    </svg>
                  </button>
                </div>
              </div>
              <!-- FIn de páginador -->
            </div>
          </div>
        </div>
      </div>

      <div
        class="hidden flex items-center justify-center py-4 text-center bg-gray-200 dark:bg-gray-700"
      >
        <span class="text-sm text-gray-600 dark:text-gray-200"
          >¿Ya estás registrado?
        </span>

        <a
          href="#"
          class="mx-2 text-sm font-bold text-blue-600 dark:text-blue-400 hover:text-blue-500"
          @click="$router.push('loginEmprs')"
          >Iniciar sesión</a
        >
      </div>
    </div>
  </div>
</template>
<style>
.text-xxs {
  font-size: 0.7rem /* 12px */;
  line-height: 0.8rem /* 16px */;
}
.text-xxxs {
  font-size: 0.7rem /* 12px */;
  line-height: 0.6rem /* 16px */;
}
.-my-2\.5 {
  margin-top: -0.475rem /* -6px */;
  margin-bottom: -0.475rem /* -6px */;
}
</style>
<script>
import axios from "axios";
import loader from "@/components/shared/loader.vue";

export default {
  name: "analistaFormLista",
  components: {
    loader,
  },
  methods: {
    editAnalist(val) {
      axios({
        method: "post",
        //url: `https://api.postula2.com/persona/?owner=${value}`,personByOwnerId
        url: `https://api.postula2.com/dataAnalistById`,
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.myTokToApiComs,
        },
        data: { idAnalist: val },
      })
        .then((res) => {
          //Perform Success Action
          //CLG("mission acomplished");
          this.$store.commit("getAnalistToEdit", res.data.resp.data.analista);
          // this.$store.commit("getRegUserEmprs", res.data);data.resp.data.analista
          //CLG("llego hasta aquilani en AnalistaFormReg");
        })
        .catch((error) => {
          // error.response.status Check status code
          //CLG("mission pelaste bolas");
          //CLG(error);
        })
        .finally(() => {
          this.$router.push({
            name: "analistaEditar",
          });
        });
    },
    deleteEval(val) {
      console.log("id", val);

      //console.log(this.$store.state.regEmprs.id);
      //console.log(this.$store.state.regUserEmprs.empresa[0].id);

      axios({
        method: "post",
        //url: `https://api.postula2.com/persona/?owner=${value}`,personByOwnerId
        url: `https://api.postula2.com/deleteAnalistById`,
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.myTokToApiComs,
        },
        data: { idAnalist: val },
      })
        .then((res) => {
          console.log("respuesta", res);
          //Perform Success Action
          //CLG("mission acomplished");
          //this.$store.commit("getAnalistToEdit", res.data.resp.data.analista);
          // this.$store.commit("getRegUserEmprs", res.data);data.resp.data.analista
          //CLG("llego hasta aquilani en AnalistaFormReg");
        })
        .catch((error) => {
          //error.response.status Check status code
          //CLG("mission pelaste bolas");
          console.log(error);
        })
        .finally(() => {
          console.log(this.$store.state.regEmprs.id);
          //console.log(this.$store.state.regUserEmprs.empresa[0].id);
          axios({
            method: "get",
            //url: `https://api.postula2.com/persona/?owner=${value}`,personByOwnerId
            url: `https://api.postula2.com/empresa/${
              this.$store.state.regEmprs.id ||
              this.$store.state.regUserEmprs.empresa[0].id
            }/analistas`,
            headers: {
              "Content-Type": "application/json",
              Authorization: this.$store.state.myTokToApiComs,
            },
            //data: { ownerId: value },
          })
            .then((res) => {
              //Perform Success Action
              this.isLoading = false;
              this.assets = res.data;
              //CLG("assets analistas 1", this.assets);
            })
            .catch(() => {
              this.$store.state.regUserEmprs.empresa[0].id;
            });
        });
    },
    //   onSubmit() {
    //     //let dataUser = this.data;
    //     this.data.owner = this.$store.state.regUserEmprs.empresa[0].id;
    //     alert("owner es:", this.data.owner);
    //     axios
    //       .post("https://api.postula2.com/analista", this.data)
    //   },
  },
  mounted() {
    this.isLoading = true;
    if (this.$store.state.regEmprs.id) {
      axios({
        method: "get",
        //url: `https://api.postula2.com/persona/?owner=${value}`,personByOwnerId
        url: `https://api.postula2.com/empresa/${this.$store.state.regEmprs.id}/analistas`,
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.myTokToApiComs,
        },
        //data: { ownerId: value },
      })
        // axios
        //   .get(
        //     `https://api.postula2.com/empresa/${this.$store.state.regEmprs.id}/analistas`
        //     //`https://api.postula2.com/empresa/${this.$store.state.regEmprs.id}/anuncios`
        //   ) //https://api.postula2.com/empresa/${this.$store.state.regEmprs.id}/analistas
        .then((res) => {
          //Perform Success Action
          this.isLoading = false;
          this.assets = res.data;
          //CLG("assets analistas 1", this.assets);
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG("mission pelaste bolas");
          //CLG(error);
        })
        .finally(() => {
          //Perform action in always
        });
    } else {
      if (this.$store.state.regUserEmprs.empresa) {
        axios({
          method: "get",
          //url: `https://api.postula2.com/persona/?owner=${value}`,personByOwnerId
          url: `https://api.postula2.com/empresa/${this.$store.state.regUserEmprs.empresa[0].id}/analistas`,
          headers: {
            "Content-Type": "application/json",
            Authorization: this.$store.state.myTokToApiComs,
          },
          // data: { ownerId: value },
        })
          // axios
          //   .get(
          //     `https://api.postula2.com/empresa/${this.$store.state.regUserEmprs.empresa[0].id}/analistas`
          //     //   `https://api.postula2.com/empresa/${this.$store.state.regUserEmprs.empresa[0].id}/anuncios`
          //   ) //https://api.postula2.com/empresa/${this.$store.state.regUserEmprs.empresa[0].id}/analistas
          .then((res) => {
            //Perform Success Action
            //alert("Usuario no encontrado, debe registrarse");
            ////CLG("respuesta obtenida", res.data);
            this.isLoading = false;
            this.assets = res.data;
            //CLG("assets analistas 2", this.assets);
          })
          .catch(() => {
            // error.response.status Check status code
            //CLG("mission pelaste bolas");
            //CLG(error);
          })
          .finally(() => {
            //Perform action in always
          });
      } else {
        alert("Debe iniciar sesión o registrarse para ver esta información");
        this.$router.push({
          name: "loginEmprs",
        });
      }
    }
  },
  data() {
    return {
      assets: [],
      editing: false,
      msg: "null",
      msg1: "null",
      isLoading: true,
    };
  },
};
</script>
