<template>
  <div class="home"></div>
</template>

<script>
// @ is an alias to /src
export default {
  name: "Home",
  components: {},
  created() {
    //CLG("href es ", window.location.href);
    // //CLG("reqst es ",req.headers['x-forwarded-proto'])
    // process.env.NODE_ENV === 'production' process.env.BASE_URL
    //CLG("ambientes es", process.env.NODE_ENV);
    //CLG("base es", process.env.BASE_URL);
    // http://www.postula2.com https://www.postula2.com
    // if (
    //   window.location.href == "http://www.postula2.com/" &&
    //   process.env.NODE_ENV == "production"
    // ) {
    //   this.$router.go('https://www.postula2.com/');
    //   //CLG("evento activado true elmio");
    // }
    this.$router.push({
      name: "HeaderHome",
    });
  },
};
</script>
