<template>
  <div class="flex flex-col min-h-screen">
    <the-header />

    <section class="bg-white flex-grow md:mt-8 md:mb-8">
      <div class="flex items-center justify-center h-screen">
        <div class="mx-auto text-center">
          <h1
            class="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-blue-900 dark:text-primary-500"
          >
            404
          </h1>
          <p
            class="mb-4 text-3xl tracking-tight font-bold text-gray-500 md:text-4xl dark:text-white"
          >
            Ups! Página no encontrada
          </p>
          <p class="mb-4 text-lg font-light text-gray-500 dark:text-gray-400">
            Lo sentimos, no podemos encontrar esa página. Encontrará mucho para
            explorar en la página de inicio.
          </p>
          <button class="mt-1">
            <a
              class="relative inline-block text-sm font-medium text-blue-900 group active:text-yellow-500 focus:outline-none focus:ring"
            >
              <span
                class="absolute inset-0 transition-transform translate-x-0.5 translate-y-0.5 bg-yellow-400 hover:translate-y-0 hover:translate-x-0 rounded-lg"
              >
              </span>

              <span
                class="relative block px-8 py-3 border-2 rounded-lg border-blue-900 shadow-xl"
              >
                <router-link to="/" class="font-semibold">Regresar</router-link>
              </span>
            </a>
          </button>
        </div>
      </div>
    </section>
    <the-footer />
  </div>
</template>

<script>
import TheHeader from "@/components/layouts/headerBar.vue";
import TheFooter from "@/components/layouts/FooterGlob.vue";

export default {
  components: {
    TheHeader,
    TheFooter,
  },
};
</script>
