<template>
  <div>
    <div
      class="shadow-md rounded-xl bg-gray-100 dark:bg-gray-700 text-center w-full"
    >
      <p
        class="font-bold text-md pt-2 pb-1 text-black dark:text-white border-b-2 border-gray-300"
      >
        Anuncios
        <span
          class="hidden text-sm text-gray-500 dark:text-gray-300 dark:text-white ml-2"
        >
          (05)
        </span>
      </p>
      <ul class="">
        <li
          class="hidden flex items-center text-gray-600 dark:text-gray-200 justify-between py-1.5 border-b-2 border-gray-200 dark:border-gray-800"
        >
          <div class="flex items-center justify-start text-sm">
            <span class="ml-3 mr-2">
              <svg
                width="20"
                height="20"
                viewBox="0 0 30 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M23.0924 8.41818C23.0921 8.41639 23.0921 8.4143 23.0918 8.41251C23.0843 8.374 23.0707 8.33788 23.0542 8.30355C23.0502 8.2952 23.0458 8.28714 23.0412 8.27878C23.0224 8.24475 23.0008 8.21281 22.9742 8.18505C22.9739 8.18475 22.9736 8.18415 22.9733 8.18385L18.3617 3.41699C18.3614 3.41669 18.3611 3.41669 18.3608 3.41639C18.334 3.38893 18.3028 3.36654 18.2698 3.34684C18.262 3.34206 18.2542 3.33758 18.2461 3.33341C18.2129 3.31639 18.1782 3.30236 18.141 3.2946C18.1387 3.294 18.1367 3.2943 18.1343 3.294C18.1086 3.28923 18.0823 3.28564 18.0552 3.28564H6.22014C5.98066 3.28564 5.78682 3.48624 5.78682 3.73341V26.1164C5.78682 26.3636 5.98066 26.5642 6.22014 26.5642H22.6668C22.9063 26.5642 23.1002 26.3636 23.1002 26.1164V8.49997C23.1004 8.47251 23.0973 8.44505 23.0924 8.41818ZM18.4888 4.8152L21.6211 8.05311H18.4888V4.8152ZM6.65374 25.6692V4.18176H17.6222V8.50087C17.6222 8.74803 17.816 8.94863 18.0555 8.94863H22.2338V25.6695L6.65374 25.6692Z"
                  fill="black"
                />
                <path
                  d="M13.9424 9.52148H8.52675C8.28727 9.52148 8.09344 9.72208 8.09344 9.96925C8.09344 10.2164 8.28727 10.417 8.52675 10.417H13.9424C14.1818 10.417 14.3757 10.2164 14.3757 9.96925C14.3757 9.72208 14.1818 9.52148 13.9424 9.52148Z"
                  fill="black"
                />
                <path
                  d="M13.9424 11.646H8.52675C8.28727 11.646 8.09344 11.8466 8.09344 12.0938C8.09344 12.3409 8.28727 12.5415 8.52675 12.5415H13.9424C14.1818 12.5415 14.3757 12.3409 14.3757 12.0938C14.3757 11.8466 14.1818 11.646 13.9424 11.646Z"
                  fill="black"
                />
                <path
                  d="M13.9424 13.7708H8.52675C8.28727 13.7708 8.09344 13.9713 8.09344 14.2185C8.09344 14.4657 8.28727 14.6663 8.52675 14.6663H13.9424C14.1818 14.6663 14.3757 14.4657 14.3757 14.2185C14.3757 13.9713 14.1818 13.7708 13.9424 13.7708Z"
                  fill="black"
                />
                <path
                  d="M18.1849 18.6322C16.7841 18.6322 15.6448 19.8089 15.6448 21.2558C15.6448 22.7029 16.7844 23.88 18.1849 23.88C19.5854 23.88 20.7247 22.7029 20.7247 21.2558C20.7247 19.8089 19.5854 18.6322 18.1849 18.6322ZM18.1849 22.9844C17.2622 22.9844 16.5114 22.2089 16.5114 21.2558C16.5114 20.3029 17.2622 19.5277 18.1849 19.5277C19.1073 19.5277 19.8581 20.3029 19.8581 21.2558C19.8581 22.2092 19.1076 22.9844 18.1849 22.9844Z"
                  fill="black"
                />
              </svg>
            </span>
            <span class="mx-3 font-medium text-lg text-red-500 dark:text-white">
              NaN
            </span>

            <span class="font-medium text-md text-black dark:text-white">
              Disponibles
            </span>
          </div>
          <router-link to="/anunReg">
            <button
              type="button"
              class="py-1 px-3 mx-4 flex justify-center items-center bg-green-500 hover:bg-green-700 focus:ring-green-500 focus:ring-offset-green-200 text-white transition ease-in duration-200 text-center text-sm font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-2xl w-14"
            >
              Nuevo
            </button>
          </router-link>
        </li>

        <li
          class="flex items-center text-gray-800 dark:text-gray-200 justify-between py-1.5 border-b-2 border-gray-200 dark:border-gray-800"
        >
          <div class="flex items-center justify-start text-sm">
            <span class="ml-3 mr-2"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z"
                />
              </svg>
            </span>
            <span
              v-if="this.assets.anunciosLength"
              class="mx-3 font-medium text-xl text-red-500 dark:text-white"
            >
              {{ this.assets.anunciosLength }}
            </span>
            <span
              v-else-if="this.assets.anunciosLength == 0"
              class="mx-3 font-medium text-xl text-red-500 dark:text-white"
            >
              0
            </span>
            <span
              v-else
              class="mx-3 font-medium text-xl text-red-500 dark:text-white"
            >
              <loadermini>
                <template v-slot:msg>
                  {{ msg }}
                </template>

                <template v-slot:msg1>
                  {{ msg1 }}
                </template>
              </loadermini>
            </span>

            <span class="font-medium text-md- text-black dark:text-white">
              Publicados
            </span>
          </div>
          <router-link to="/anunciosbyEmprs">
            <button
              type="button"
              class="py-1 px-5 mx-4 flex justify-center items-center text-white transition ease-in duration-200 text-center text-sm font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-2xl w-14"
              v-bind:disabled="btnDisable"
              v-bind:class="{
                'cursor-not-allowed hover:disabled bg-gray-400 border':
                  btnDisable,
                'postulado-color hover:bg-green-700 focus:ring-green-500 focus:ring-offset-green-200':
                  !btnDisable,
              }"
            >
              Ver
            </button>
          </router-link>
        </li>

        <li
          class="flex items-center text-gray-600 dark:text-gray-200 justify-between py-1.5 dark:border-gray-800"
        >
          <div class="flex items-center justify-start text-sm">
            <span class="ml-3.5 mr-2">
              <svg
                width="21"
                height="21"
                viewBox="0 0 26 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0)">
                  <path
                    d="M2.4375 8.9375H23.5625"
                    stroke="black"
                    stroke-width="2"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M5.6875 7.3125C5.44375 7.3125 5.28125 7.23125 5.11875 7.06875C4.95625 6.90625 4.875 6.74375 4.875 6.5C4.875 6.25625 4.95625 6.09375 5.11875 5.93125L5.2 5.85C5.28125 5.85 5.28125 5.76875 5.3625 5.76875C5.44375 5.6875 5.44375 5.6875 5.525 5.6875C5.60625 5.6875 5.76875 5.6875 5.85 5.6875C5.93125 5.6875 5.93125 5.6875 6.0125 5.76875C6.09375 5.76875 6.09375 5.85 6.175 5.85L6.25625 5.93125C6.3375 6.0125 6.41875 6.09375 6.41875 6.175C6.5 6.25625 6.5 6.41875 6.5 6.5C6.5 6.58125 6.5 6.74375 6.41875 6.825C6.41875 6.90625 6.3375 6.9875 6.25625 7.06875C6.09375 7.23125 5.93125 7.3125 5.6875 7.3125Z"
                    fill="black"
                  />
                  <path
                    d="M8.125 7.3125C7.88125 7.3125 7.71875 7.23125 7.55625 7.06875C7.39375 6.90625 7.3125 6.74375 7.3125 6.5C7.3125 6.41875 7.3125 6.25625 7.39375 6.175C7.475 6.09375 7.475 6.0125 7.55625 5.93125C7.6375 5.85 7.71875 5.76875 7.8 5.76875C8.125 5.60625 8.45 5.6875 8.69375 5.93125C8.775 6.0125 8.85625 6.09375 8.85625 6.175C8.9375 6.25625 8.9375 6.41875 8.9375 6.5C8.9375 6.74375 8.85625 6.90625 8.69375 7.06875C8.53125 7.23125 8.36875 7.3125 8.125 7.3125Z"
                    fill="black"
                  />
                  <path
                    d="M10.5625 7.3125C10.4812 7.3125 10.3188 7.3125 10.2375 7.23125C10.1563 7.15 10.075 7.15 9.99375 7.06875C9.9125 6.9875 9.83125 6.90625 9.83125 6.825C9.75 6.74375 9.75 6.58125 9.75 6.5C9.75 6.41875 9.75 6.25625 9.83125 6.175C9.9125 6.09375 9.9125 6.0125 9.99375 5.93125C10.3188 5.60625 10.8062 5.60625 11.1312 5.93125C11.2125 6.0125 11.2937 6.09375 11.2937 6.175C11.375 6.25625 11.375 6.41875 11.375 6.5C11.375 6.58125 11.375 6.74375 11.2937 6.825C11.2125 6.90625 11.2125 6.9875 11.1312 7.06875C10.9687 7.23125 10.8062 7.3125 10.5625 7.3125Z"
                    fill="black"
                  />
                  <path
                    d="M8.9375 12.1875H17.0625"
                    stroke="black"
                    stroke-width="2"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8.9375 25.1875H17.0625"
                    stroke="black"
                    stroke-width="2"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M9.75 12.1875C9.75 14.7062 10.9688 17.1438 13 18.6875C15.0312 17.1438 16.25 14.7062 16.25 12.1875"
                    stroke="black"
                    stroke-width="2"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M16.25 25.1875C16.25 22.6688 15.0312 20.2312 13 18.6875C10.9688 20.2312 9.75 22.6688 9.75 25.1875"
                    stroke="black"
                    stroke-width="2"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M15.6 21.9375H21.9375C22.8313 21.9375 23.5625 21.2063 23.5625 20.3125V5.6875C23.5625 4.79375 22.8313 4.0625 21.9375 4.0625H4.0625C3.16875 4.0625 2.4375 4.79375 2.4375 5.6875V20.3125C2.4375 21.2063 3.16875 21.9375 4.0625 21.9375H10.4"
                    stroke="black"
                    stroke-width="2"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0">
                    <rect width="26" height="26" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </span>
            <span
              v-if="this.assets.anunciosSinPostuladosLengt"
              class="mx-3 font-medium text-xl text-red-500 dark:text-white"
            >
              {{ this.assets.anunciosSinPostuladosLengt }}
            </span>
            <span
              v-else-if="this.assets.anunciosSinPostuladosLengt == 0"
              class="mx-3 font-medium text-xl text-red-500 dark:text-white"
            >
              0
            </span>
            <span
              v-else
              class="mx-3 font-medium text-xl text-red-500 dark:text-white"
            >
              <loadermini>
                <template v-slot:msg>
                  {{ msg }}
                </template>

                <template v-slot:msg1>
                  {{ msg1 }}
                </template>
              </loadermini>
            </span>

            <span
              class="font-medium text-md- text-black dark:text-white text-left"
            >
              Anuncios con<strong> 0 postulados</strong>
              <a href="" class="underline hidden">paquete</a>
            </span>
          </div>

          <button
            type="button"
            @click="$router.push('anunciosSinPostulads')"
            class="py-1 px-5 mx-4 flex justify-center items-center text-white transition ease-in duration-200 text-center text-sm font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-2xl w-14"
            v-bind:disabled="btnDisable"
            v-bind:class="{
              'cursor-not-allowed hover:disabled bg-gray-400 border':
                btnDisable,
              'postulado-color hover:bg-green-700 focus:ring-green-500 focus:ring-offset-green-200':
                !btnDisable,
            }"
          >
            Ver
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import loadermini from "@/components/shared/loadermini.vue";

export default {
  name: "CuadroAnunciosEmpresa",

  components: { loadermini },

  data() {
    return {
      assets: [],
      btnDisable: true,
    };
  },
  created() {
    if (this.$store.state.regEmprs.id) {
      // axios
      //   .get(
      //     `https://api.postula2.com/empresa/${this.$store.state.regEmprs.id}/anuncios`
      //   )
      let data = JSON.stringify({
        idOwner: this.$store.state.regEmprs.id,
      });
      // console.log("IF1 - data a enviar id es", data);
      //CLG("token1 - ", this.$store.state.myTokToApiComs);
      axios({
        method: "POST",
        url: `https://api.postula2.com/postuladstotals/`,
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.myTokToApiComs,
        },
        data: data,
      })
        .then((res) => {
          //Perform Success Action
          //alert("Usuario no encontrado, debe registrarse");
          //CLG("respuesta obtenida", res.data);
          this.btnDisable = false;
          this.assets = res.data.resp;

          //CLG("assetsCuadro", this.assets);
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG("mission pelaste bolas");
          // console.log("se cago laika ->",error);
        })
        .finally(() => {
          //Perform action in always
        });
    } else {
      if (this.$store.state.regUserEmprs.empresa) {
        // axios
        //   .get(
        //     `https://api.postula2.com/empresa/${this.$store.state.regUserEmprs.empresa[0].id}/anuncios`

        //   ) //https://api.postula2.com/empresa/${this.$store.state.regUserEmprs.empresa[0].id}/anuncios}
        let data = JSON.stringify({
          idOwner: this.$store.state.regUserEmprs.empresa[0].id,
        });
        //console.log("IF2 - data a enviar id es", data);
        //console.log("token2 - ", this.$store.state.myTokToApiComs);

        axios({
          method: "POST",
          url: `https://api.postula2.com/postuladstotals/`,
          headers: {
            "Content-Type": "application/json",
            Authorization: this.$store.state.myTokToApiComs,
          },
          data: data,
        })
          .then((res) => {
            //Perform Success Action
            //alert("Usuario no encontrado, debe registrarse");
            //console.log("respuesta obtenida", res.data);
            this.assets = res.data.resp;
            this.btnDisable = false;
            //console.log("assetsCuadro", this.assets);
          })
          .catch((error) => {
            // error.response.status Check status code
            //CLG("mission pelaste bolas");
          //  console.log("se cago laika ",error);
          })
          .finally(() => {
            //Perform acton in always
          });
      }
    }
  },
};
</script>

<style scoped>
.postulado-color {
  background-color: #134992;
}
</style>
