<template>
  <div>
    <div class="h-8 block" :class="className">
      <ins
        class="adsbygoogle"
        :style="{ display: 'block' }"
        :data-ad-client="googleAdId"
        data-ad-slot="slotter"
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins>
    </div>
  </div>
</template>
<script>
export default {
  // mounted() {
  //     (adsbygoogle = window.adsbygoogle || []).push({});
  // }
  name: "adsense",
  props: {
    //timeout: Number,
    className: String,
    slotter: String,
  },
  data() {
    return {
      googleInit: null,
      googleAdId: "ca-pub-9746332939044071",
    };
  },

  mounted() {
    // this.googleInit = setTimeout(() => {
    //   if (typeof window !== "undefined")
    //     (window.adsbygoogle = window.adsbygoogle || []).push({});
    // }, 200);
  },
  destroyed() {
    if (this.googleInit) clearTimeout(this.googleInit);
  },
};
</script>
<style scoped>
.ad-border {
  padding: 10px;
  border: 1px solid #eee;
}

.ad-noborder {
  padding: 10px;
}
</style>
