<template>
  <div>
    <main
      class="bg-gray-100 dark:bg-gray-800 h-full max-h-full overflow-y-auto relative mb-10 pb-16"
    >
      <div class="flex items-start justify-between">
        <div>
          <side-bar-menu class="h-screen"></side-bar-menu>
        </div>
        <div class="flex flex-col w-full md:space-y-4">
          <div
            class="overflow-visible h-screen pb-24 px-4 md:px-6 md:mt-4 md:mb-8 lg:mt-6"
          >
            <div
              class="flex items-center space-x-4 font-light w-full text-center text-xl sm:text-md md:text-2xl dark:text-white text-gray-800 border-b border-gray-200"
            >
              Mi perfil
            </div>

            <div
              class="flex items-center mt-0.5 font-light w-1/6 text-center text-xs sm:text-xs md:text-xs dark:text-white text-blue-500"
            >
              <span @click="volver" class="cursor-pointer">⇤ Volver</span>
            </div>
            <section class="text-gray-600 body-font">
              <div class="container flex-wrap px-5 py-4 mx-auto items-center">
                <div class="container flex flex-wrap">
                  <div class="w-full">
                    <ul
                      class="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row"
                    >
                      <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
                        <a
                          class="cursor-pointer text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal"
                          v-on:click="toggleTabs(1)"
                          v-bind:class="{
                            'colorazul bg-white': openTab !== 1,
                            'text-white backasista': openTab === 1,
                          }"
                        >
                          Datos Personales
                        </a>
                      </li>
                      <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
                        <a
                          class="cursor-pointer text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal"
                          v-on:click="toggleTabs(2)"
                          v-bind:class="{
                            'colorazul bg-white': openTab !== 2,
                            'text-white backasista': openTab === 2,
                          }"
                        >
                          Datos de Localidad
                        </a>
                      </li>
                      <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
                        <a
                          class="cursor-pointer text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal"
                          v-on:click="toggleTabs(3)"
                          v-bind:class="{
                            'colorazul bg-white': openTab !== 3,
                            'text-white backasista': openTab === 3,
                          }"
                        >
                          Referencias Laborales
                        </a>
                      </li>
                      <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
                        <a
                          class="cursor-pointer text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal"
                          v-on:click="toggleTabs(4)"
                          v-bind:class="{
                            'colorazul bg-white': openTab !== 4,
                            'text-white backasista': openTab === 4,
                          }"
                        >
                          Experiencia Laboral
                        </a>
                      </li>
                      <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
                        <a
                          class="cursor-pointer text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal"
                          v-on:click="toggleTabs(5)"
                          v-bind:class="{
                            'colorazul bg-white': openTab !== 5,
                            'text-white backasista': openTab === 5,
                          }"
                        >
                          Educación
                        </a>
                      </li>
                      <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
                        <a
                          class="cursor-pointer text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal"
                          v-on:click="toggleTabs(6)"
                          v-bind:class="{
                            'colorazul bg-white': openTab !== 6,
                            'text-white backasista': openTab === 6,
                          }"
                        >
                          Preferencias Laborales
                        </a>
                      </li>
                    </ul>
                    <div
                      class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded"
                    >
                      <div class="flex-auto">
                        <div class="tab-content tab-space">
                          <div
                            v-bind:class="{
                              hidden: openTab !== 1,
                              block: openTab === 1,
                            }"
                          >
                            <div @datasend="getData">
                              <DatPerson></DatPerson>
                            </div>
                          </div>
                        </div>
                        <div
                          v-bind:class="{
                            hidden: openTab !== 2,
                            block: openTab === 2,
                          }"
                        >
                          <div>
                            <Contact></Contact>
                          </div>
                        </div>
                        <div
                          v-bind:class="{
                            hidden: openTab !== 3,
                            block: openTab === 3,
                          }"
                        >
                          <div>
                            <Ref_labor></Ref_labor>
                          </div>
                        </div>

                        <div
                          v-bind:class="{
                            hidden: openTab !== 4,
                            block: openTab === 4,
                          }"
                        >
                          <div>
                            <Exp_laboral></Exp_laboral>
                          </div>
                        </div>
                        <div
                          v-bind:class="{
                            hidden: openTab !== 5,
                            block: openTab === 5,
                          }"
                        >
                          <div>
                            <Educacion></Educacion>
                          </div>
                        </div>
                        <div
                          v-bind:class="{
                            hidden: openTab !== 6,
                            block: openTab === 6,
                          }"
                        >
                          <div>
                            <Pref_labor></Pref_labor>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import SideBarMenu from "@/components/sideBarMenu.vue";

import DatPerson from "./DatPerson";
import Contact from "./Contact.vue";
import Ref_labor from "./Ref_labor";
import Exp_laboral from "./Exp_laboral";
import Educacion from "./Educacion";
import Pref_labor from "./Pref_labor";
export default {
  components: {
    DatPerson,
    Contact,
    Ref_labor,
    Exp_laboral,
    Educacion,
    Pref_labor,
    SideBarMenu,
  },

  name: "datosPerfil",
  data() {
    return {
      isLoading: false,
      msg: "",
      msg1: "",
      openTab: 1,
    };
  },
  methods: {
    toggleTabs: function (tabNumber) {
      this.openTab = tabNumber;
    },
    getData() {
      //CLG("llegue al cv");
    },
    volver() {
      this.$router.push({
        name: "personLandin",
      });
    },
  },
  created() {
    if (typeof this.$store.state.userData === "undefined") {
      location.reload();
    }
    if (!this.$store.state.regUser.id) {
      alert("Redireccionando a fase de registro inicial");
      this.$router.push({
        name: "Login",
      });
    }
  },
};
</script>
