<template>
  <!-- component -->

  <div
    v-if="this.$store.state.userData"
    class="bg-gray-100 max-h-full py-0 md:px-3 rounded-lg"
  >
    <div class="md:container mx-0 md:mx-auto my-0">
      <div class="flex flex-col md:flex-row">
        <!-- Left Side -->
        <div class="w-full md:w-3/12 md:mx-2">
          <!-- Profile Card -->
          <PerfilUsuario
            :key="$store.state.profileImage.uploadedUserImgComponntValue"
          ></PerfilUsuario>
          <!-- End of profile card -->
        </div>
        <!-- Right Side -->
        <div class="w-full md:w-9/12 mt-2 md:mt-0 md:mx-2 h-auto">
          <!-- Profile tab -->
          <!-- About Section -->

          <DatPerson></DatPerson>

          <div class="text-gray-700 text-left hidden">
            <div class="grid md:grid-cols-2 text-sm">
              <div class="grid grid-cols-2">
                <div class="px-4 py-2 font-semibold">Nombre(s)</div>
                <div class="px-4 py-2">
                  {{ $store.state.userData.owner.nombreUser }}
                </div>
              </div>
              <div class="grid grid-cols-2">
                <div class="px-4 py-2 font-semibold">Apellido(s)</div>
                <div class="px-4 py-2">
                  {{ $store.state.userData.owner.apellidoUser }}
                </div>
              </div>
              <div class="grid grid-cols-2">
                <div class="px-4 py-2 font-semibold">Nacionalidad</div>
                <div class="px-4 py-2">
                  {{ $store.state.userData.nacionalidad }}
                </div>
              </div>
              <div class="grid grid-cols-2">
                <div class="px-4 py-2 font-semibold">
                  Documento de identidad
                </div>
                <div class="px-4 py-2 capitalize">
                  {{ $store.state.userData.tipoDocument }}
                  {{ $store.state.userData.nroDocument }}
                </div>
              </div>
              <div class="grid grid-cols-2">
                <div class="px-4 py-2 font-semibold">Género</div>
                <div class="px-4 py-2">
                  {{ $store.state.userData.genero }}
                </div>
              </div>
              <div class="grid grid-cols-2">
                <div class="px-4 py-2 font-semibold">Teléfono 1</div>
                <div class="px-4 py-2">
                  {{ $store.state.userData.owner.telf1 }}
                </div>
              </div>
              <div class="grid grid-cols-2">
                <div class="px-4 py-2 font-semibold">Dirección</div>
                <div class="px-4 py-2 capitalize">
                  {{ $store.state.userData.direccion }}
                </div>
              </div>
              <div class="grid grid-cols-2">
                <div class="px-4 py-2 font-semibold">Correo electrónico</div>
                <div class="px-4 py-2">
                  <a class="text-blue-800" href="mailto:jane@example.com">
                    {{ $store.state.userData.owner.emailUser }}
                  </a>
                </div>
              </div>
              <div class="grid grid-cols-2">
                <div class="px-4 py-2 font-semibold">Licencia</div>
                <div class="px-4 py-2">
                  {{ $store.state.userData.licencia }}
                </div>
              </div>
              <div class="grid grid-cols-2">
                <div class="px-4 py-2 font-semibold">Edo. civil</div>
                <div class="px-4 py-2">
                  {{ $store.state.userData.estadCivil }}
                </div>
              </div>
            </div>
          </div>
          <!-- End of about section -->
        </div>
      </div>
    </div>
    <div class="md:flex no-wrap md:-mx-2">
      <div class="w-full md:w-12/12 md:mx-2 text-justify">
        <div class="my-3"></div>

        <!-- Experiencia Laboral -->
        <div class="grid grid-cols-1 text-left">
          <ExpLaboral></ExpLaboral>
        </div>
        <!-- End of profile tab -->
        <div class="my-5"></div>

        <!-- Educacion -->
        <div class="grid grid-cols-1 text-left">
          <div>
            <Educacion></Educacion>
          </div>
        </div>
        <!-- End of Experience and education grid -->

        <!-- End of profile tab -->
        <div class="my-4"></div>

        <!-- Preferencias Laborales -->
        <div class="grid grid-cols-1 text-left">
          <div>
            <PrefLabor></PrefLabor>
          </div>
        </div>
        <!-- End of Experience and education grid -->
        <!-- End of profile tab -->
        <div class="my-4"></div>

        <!-- Referencias -->
        <div class="grid grid-cols-1 text-left">
          <div>
            <RefLabor></RefLabor>
          </div>
          <!-- End of Experience and education grid -->
        </div>
        <!-- End of profile tab -->
        <div class="my-4"></div>

        <!-- Localidad -->
        <div class="grid grid-cols-1 text-left">
          <div>
            <Contact></Contact>
          </div>
        </div>
        <!-- End of Experience and education grid -->
        <!-- End of profile tab -->
      </div>
    </div>
  </div>
  <div v-else class="my-4">
    <div
      class="sm:w-60 md:w-72 lg:w-80 xl:w-96 p-4 m-auto bg-white shadow-lg rounded-2xl dark:bg-gray-800"
    >
      <div class="w-full h-full text-center">
        <div class="flex flex-col justify-between h-full">
          <svg
            class="w-12 h-12 m-auto mt-4 text-red-500"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 24 24"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="currentColor"
              class="w-6 h-6 mr-2"
              viewBox="0 0 1792 1792"
            >
              <path
                d="M1024 1375v-190q0-14-9.5-23.5t-22.5-9.5h-192q-13 0-22.5 9.5t-9.5 23.5v190q0 14 9.5 23.5t22.5 9.5h192q13 0 22.5-9.5t9.5-23.5zm-2-374l18-459q0-12-10-19-13-11-24-11h-220q-11 0-24 11-10 7-10 21l17 457q0 10 10 16.5t24 6.5h185q14 0 23.5-6.5t10.5-16.5zm-14-934l768 1408q35 63-2 126-17 29-46.5 46t-63.5 17h-1536q-34 0-63.5-17t-46.5-46q-37-63-2-126l768-1408q17-31 47-49t65-18 65 18 47 49z"
              ></path>
            </svg>
          </svg>
          <p class="px-2 py-2 text-gray-600 dark:text-gray-100 text-md">
            No has cargado tu información personal basica
            <span class="font-bold text-gray-800 dark:text-white">
              {{ this.$store.state.regUser.nombreUser }},
            </span>
            te invitamos a completar tu perfil para recibir las mejores ofertas
            de empleo.
          </p>
          <div class="flex items-center justify-between w-full gap-4 mt-4">
            <button
              @click="misDatos"
              type="button"
              class="py-2 px-4 bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg"
            >
              Cargar mis datos
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style type="text/css">
.bentblocks_een {
  fill: #190b0e;
}
</style>
<style scoped>
.py-n4 {
  padding-top: 1.12rem /* 16px */;
  padding-bottom: 1.12rem /* 16px */;
}

:root {
  --main-color: #4a76a8;
}

.bg-main-color {
  background-color: var(--main-color);
}

.text-main-color {
  color: var(--main-color);
}

.border-main-color {
  border-color: var(--main-color);
}
</style>

<script>
import axios from "axios";

import DatPerson from "./DatPerson";
import ExpLaboral from "./Exp_laboral";
import Educacion from "./Educacion";
import RefLabor from "./Ref_labor";
import PrefLabor from "./Pref_labor";
import Contact from "./Contact.vue";
import PerfilUsuario from "@/components/persona/ImagenPerfil&Status.vue";

export default {
  name: "perfilPersonaV2",
  components: {
    DatPerson,
    ExpLaboral,
    Educacion,
    RefLabor,
    PrefLabor,
    Contact,
    PerfilUsuario,
  },
  filters: {
    formatedTime: function (value) {
      var date = new Date(value);

      return (
        date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
        // " " +
        //date.getHours() +
        //":" +
        // date.getMinutes() +
        //":" +
        //date.getSeconds()
      );
      ////CLG(formatedTime);
    },
  },
  data: function () {
    return {
      fileRecords: [],
      uploadUrl: "",
      uploadHeaders: { Authorization: this.$store.state.myTokToApiComs },
      fileRecordsForUpload: [], // maintain an upload queue
      imageFileLocation: "",
      flag: false,
      flagImgUploaded: false,
      imgUploadedName: "",
    };
  },
  watch: {
    imageFileLocation: function () {
      this.flag = true;
    },
  },
  methods: {
    upload() {
      //  var data = new FormData(this.$refs.form);
      // // data.append("id", this.$store.state.regUser.id);

      // const file = this.$refs.form;
      // var id = this.$store.state.regUser.id;
      // data.append("photo", file);
      // data.append("id", id);

      // console.log("data ", data);
      // axios
      //   .post("https://api.postula2.com/uploadImage2", data, {
      //     Headers: this.$store.state.myTokToApiComs,
      //   })
      //   .then((response) => {
      //     console.log("holi ", response.data);
      //   });

      // let file = document.querySelector("#upload").files[0];
      // var data = new FormData();
      // data.append("id", this.$store.state.regUser.id);
      // data.append("photo", this.$refs.form);

      // axios
      //   .post("https://api.postula2.com/uploadImage2", data, {
      //     headers: {
      //       "Content-Type": "multipart/form-data",
      //       Authorization: this.$store.state.myTokToApiComs,
      //     },
      //   })
      const formData = new FormData();
      formData.append("file", document.getElementById("upload").files[0]);

      axios
        .post(
          "https://api.postula2.com/uploadImage",
          formData,
          {
            headers: {
              Authorization: this.$store.state.myTokToApiComs,
            },
            params: {
              id: this.$store.state.userData.id,
            },
          }
        )
        .then((response) => {
          //console.log("holi ", response.data);
          this.imageFileLocation = response.data.savedAt;
          this.$store.commit("getProfileImage", response.data.savedAt);
          console.log(this.imageFileLocation);
        });

      // const options = {
      //   method: "POST",
      //   body: data,
      //   Headers: this.$store.state.myTokToApiComs,
      // };
      // fetch("https://api.postula2.com/uploadImage2", options)
      //   .then((res) => res.json())
      //   .then((resp) => console.log("holi ", resp));

      // const formData = new FormData();
      // const fileField = this.$refs.form
      // formData.append("reg", this.$store.state.regUser.id);
      // formData.append("avatar", fileField);
      // fetch("https://api.postula2.com/uploadImage", {
      //   method: "POST",
      //   body: formData,
      // })
      //   .then((response) => response.json())
      //   .then((result) => {
      //     console.log("Success:", result);
      //   })
      //   .catch((error) => {
      //     console.error("Error:", error);
      //   });
    },
    uploadFiles: function () {
      this.uploadUrl =
        "https://api.postula2.com/uploadImage?id=" +
        this.$store.state.userData.id;
      console.log("url es: ", this.uploadUrl);
      // Using the default uploader. You may use another uploader instead.
      this.$refs.vueFileAgent
        .upload(
          this.uploadUrl,
          this.uploadHeaders,
          this.fileRecordsForUpload,
          this.$store.state.userData.id
        )
        .then((response) => {
          console.log("holi ", response[0].data);
          console.log("img nombre ", response[0].data.encryptedName);
          this.imgUploadedName = response[0].data.encryptedName;
          this.flagImgUploaded = true;
          this.$store.commit({
            type: "getProfileImage",
            flagImgUploaded: true,
            image: response[0].data,
          });
          //this.$store.commit("getProfileImage", response[0].data);
          // this.imageFileLocation = response.data.savedAt;
          // console.log(this.imageFileLocation);
        });
      this.fileRecordsForUpload = [];
    },
    deleteUploadedFile: function (fileRecord) {
      // Using the default uploader. You may use another uploader instead.
      this.$refs.vueFileAgent.deleteUpload(
        this.uploadUrl,
        this.uploadHeaders,
        fileRecord
      );
    },
    filesSelected: function (fileRecordsNewlySelected) {
      var validFileRecords = fileRecordsNewlySelected.filter(
        (fileRecord) => !fileRecord.error
      );
      this.fileRecordsForUpload =
        this.fileRecordsForUpload.concat(validFileRecords);
    },
    onBeforeDelete: function (fileRecord) {
      var i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        // queued file, not yet uploaded. Just remove from the arrays
        this.fileRecordsForUpload.splice(i, 1);
        var k = this.fileRecords.indexOf(fileRecord);
        if (k !== -1) this.fileRecords.splice(k, 1);
      } else {
        if (confirm("Are you sure you want to delete?")) {
          this.$refs.vueFileAgent.deleteFileRecord(fileRecord); // will trigger 'delete' event
        }
      }
    },
    fileDeleted: function (fileRecord) {
      var i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        this.fileRecordsForUpload.splice(i, 1);
      } else {
        this.deleteUploadedFile(fileRecord);
      }
    },
    misDatos() {
      this.$router.push({
        name: "DatosCv",
      });
    },
  },
};
</script>
