var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('main',{staticClass:"bg-gray-100 dark:bg-gray-800 h-full max-h-full overflow-y-auto relative mb-10 pb-16"},[_c('div',{staticClass:"flex items-start justify-between"},[_c('div',[_c('side-bar-menu',{staticClass:"h-screen"})],1),_c('div',{staticClass:"flex flex-col w-full md:space-y-4"},[_c('div',{staticClass:"overflow-visible h-screen pb-24 px-4 md:px-6 md:mt-4 md:mb-8 lg:mt-6"},[_c('div',{staticClass:"flex items-center space-x-4 font-light w-full text-center text-xl sm:text-md md:text-2xl dark:text-white text-gray-800 border-b border-gray-200"},[_vm._v(" Mi perfil ")]),_c('div',{staticClass:"flex items-center mt-0.5 font-light w-1/6 text-center text-xs sm:text-xs md:text-xs dark:text-white text-blue-500"},[_c('span',{staticClass:"cursor-pointer",on:{"click":_vm.volver}},[_vm._v("⇤ Volver")])]),_c('section',{staticClass:"text-gray-600 body-font"},[_c('div',{staticClass:"container flex-wrap px-5 py-4 mx-auto items-center"},[_c('div',{staticClass:"container flex flex-wrap"},[_c('div',{staticClass:"w-full"},[_c('ul',{staticClass:"flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row"},[_c('li',{staticClass:"-mb-px mr-2 last:mr-0 flex-auto text-center"},[_c('a',{staticClass:"cursor-pointer text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal",class:{
                          'colorazul bg-white': _vm.openTab !== 1,
                          'text-white backasista': _vm.openTab === 1,
                        },on:{"click":function($event){return _vm.toggleTabs(1)}}},[_vm._v(" Datos Personales ")])]),_c('li',{staticClass:"-mb-px mr-2 last:mr-0 flex-auto text-center"},[_c('a',{staticClass:"cursor-pointer text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal",class:{
                          'colorazul bg-white': _vm.openTab !== 2,
                          'text-white backasista': _vm.openTab === 2,
                        },on:{"click":function($event){return _vm.toggleTabs(2)}}},[_vm._v(" Datos de Localidad ")])]),_c('li',{staticClass:"-mb-px mr-2 last:mr-0 flex-auto text-center"},[_c('a',{staticClass:"cursor-pointer text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal",class:{
                          'colorazul bg-white': _vm.openTab !== 3,
                          'text-white backasista': _vm.openTab === 3,
                        },on:{"click":function($event){return _vm.toggleTabs(3)}}},[_vm._v(" Referencias Laborales ")])]),_c('li',{staticClass:"-mb-px mr-2 last:mr-0 flex-auto text-center"},[_c('a',{staticClass:"cursor-pointer text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal",class:{
                          'colorazul bg-white': _vm.openTab !== 4,
                          'text-white backasista': _vm.openTab === 4,
                        },on:{"click":function($event){return _vm.toggleTabs(4)}}},[_vm._v(" Experiencia Laboral ")])]),_c('li',{staticClass:"-mb-px mr-2 last:mr-0 flex-auto text-center"},[_c('a',{staticClass:"cursor-pointer text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal",class:{
                          'colorazul bg-white': _vm.openTab !== 5,
                          'text-white backasista': _vm.openTab === 5,
                        },on:{"click":function($event){return _vm.toggleTabs(5)}}},[_vm._v(" Educación ")])]),_c('li',{staticClass:"-mb-px mr-2 last:mr-0 flex-auto text-center"},[_c('a',{staticClass:"cursor-pointer text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal",class:{
                          'colorazul bg-white': _vm.openTab !== 6,
                          'text-white backasista': _vm.openTab === 6,
                        },on:{"click":function($event){return _vm.toggleTabs(6)}}},[_vm._v(" Preferencias Laborales ")])])]),_c('div',{staticClass:"relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded"},[_c('div',{staticClass:"flex-auto"},[_c('div',{staticClass:"tab-content tab-space"},[_c('div',{class:{
                            hidden: _vm.openTab !== 1,
                            block: _vm.openTab === 1,
                          }},[_c('div',{on:{"datasend":_vm.getData}},[_c('DatPerson')],1)])]),_c('div',{class:{
                          hidden: _vm.openTab !== 2,
                          block: _vm.openTab === 2,
                        }},[_c('div',[_c('Contact')],1)]),_c('div',{class:{
                          hidden: _vm.openTab !== 3,
                          block: _vm.openTab === 3,
                        }},[_c('div',[_c('Ref_labor')],1)]),_c('div',{class:{
                          hidden: _vm.openTab !== 4,
                          block: _vm.openTab === 4,
                        }},[_c('div',[_c('Exp_laboral')],1)]),_c('div',{class:{
                          hidden: _vm.openTab !== 5,
                          block: _vm.openTab === 5,
                        }},[_c('div',[_c('Educacion')],1)]),_c('div',{class:{
                          hidden: _vm.openTab !== 6,
                          block: _vm.openTab === 6,
                        }},[_c('div',[_c('Pref_labor')],1)])])])])])])])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }