<template>
  <div>
    <main
      class="bg-gray-100 dark:bg-gray-800 h-full max-h-full overflow-y-auto relative"
    >
      <div class="flex items-start justify-between">
        <div class="grid h-screen">
          <side-bar-menu class="h-screen"></side-bar-menu>
        </div>
        <div class="flex flex-col w-full md:space-y-4">
          <div
            class="overflow-visible px-4 md:px-6 md:mt-4 lg:mt-6 border-dashed border-red-500"
          >
            <div
              class="flex items-center space-x-4 font-light w-full text-center text-xl sm:text-md md:text-2xl dark:text-white text-gray-800 border-b border-gray-200"
            >
              Mis datos personales
            </div>
            <div
              class="flex items-center mt-0.5 font-light w-1/6 text-center text-xs sm:text-xs md:text-xs dark:text-white text-blue-500"
            >
              <span @click="volver" class="cursor-pointer">⇤ Volver</span>
            </div>
            <div
              class="grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4 mt-2 mb-4"
            >
              <personProfilev2></personProfilev2>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import SideBarMenu from "@/components/sideBarMenu.vue";
//import personDataCvParaEmprs from "@/components/personDataCvParaEmprs.vue";
import personProfilev2 from "@/components/personProfilev2.vue";

import axios from "axios";

export default {
  name: "personDatos",
  components: {
    SideBarMenu,
    ///personDataCvParaEmprs,
    personProfilev2,
  },
  data() {
    return {
      assets: [],
      isLoading: false,
    };
  },
  mounted() {
    // this.isLoading = true;
    if (this.$store.state.regUser.id) {
      axios({
        method: "get",
        url: `https://api.postula2.com/registroPrimarioUser/${this.$store.state.regUser.id}/anuncios`,
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.myTokToApiComs,
        },
      })
        .then((res) => {
          //Perform Success Action
          //alert("Usuario no encontrado, debe registrarse");
          //CLG("respuesta obtenida", res.data);
          this.isLoading = false;
          this.assets = res.data;
          //CLG("assets", this.assets);
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG("mission pelaste bolas");
          //CLG(error);
        })
        .finally(() => {
          //Perform action in always
        });
    } else {
      if (this.$store.state.userData.id) {
        axios
          .get(
            `https://api.postula2.com/registroPrimarioUser/${this.$store.state.userData.owner.id}/anuncios`
          ) //https://api.postula2.com/empresa/${this.$store.state.regUserEmprs.empresa[0].id}/anuncios}
          .then((res) => {
            //Perform Success Action
            //alert("Usuario no encontrado, debe registrarse");
            //CLG("respuesta obtenida", res.data);
            this.isLoading = false;
            this.assets = res.data;
            //CLG("assets", this.assets);
          })
          .catch(() => {
            // error.response.status Check status code
            //CLG("mission pelaste bolas");
            //CLG(error);
          })
          .finally(() => {
            //Perform action in always
          });
      } else {
        alert("Debe iniciar sesión o registrarse para ver esta información");
        this.$router.push({
          name: "Login",
        });
      }
    }
  },
  methods: {
    volver() {
      this.$router.push({
        name: "personLandin",
      });
    },
  },
};
</script>

<style></style>
