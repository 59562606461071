<template>
  <div class="grid grid-cols-2 border border-b-8">
    <div class="border-t border-gray-200">
      <dl>
        <div class="hidden">
          {{ experncLabors.id }}
        </div>

        <div
          v-if="$store.state.userData"
          class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
        >
          <dt
            v-if="!editNombEmprsFlag"
            class="text-sm text-left font-medium text-gray-500"
          >
            Nombre de la empresa
          </dt>
          <dd
            class="
              mt-1
              text-sm text-gray-900
              sm:mt-0 sm:col-span-2
              capitalize
              flex
            "
          >
            <div
              v-if="editNombEmprsFlag"
              class="
                flex flex-col
                md:flex-row
                w-3/4
                md:w-full
                max-w-sm
                md:space-x-3
                space-y-3
                md:space-y-0
                justify-start
              "
            >
              <div class="">
                <label
                  for="price"
                  class="block text-xs font-medium text-gray-700 text-left ml-1"
                >
                  <strong> Editar Nombre de la empresa </strong>
                </label>
                <FormulateForm #default="{ hasErrors }" class="flex">
                  <FormulateInput
                    type="text"
                    v-model="data.nombEmprs"
                    class="bg-white"
                    :value="experncLabors.nombEmprs"
                    validation="required|min:4|matches:/^[A-Za-z\s*\á\é\í\ó\ú\ñ]+$/"
                    validation-behavior="value"
                    :validation-messages="{
                      min: 'Debe tener al menos 4 caracteres',
                      matches: 'Este campo solo acepta letras',
                      required: 'Este campo es obligatorio',
                    }"
                  />
                  <FormulateInput
                    type="submit"
                    :disabled="hasErrors"
                    @click.prevent="editNombEmprs(experncLabors.id)"
                    outer-class="
                    mx-1
                      flex
                    items-center
                    p-1
                    pt-2
                    transition
                    ease-in
                    duration-200
                    uppercase
                    rounded-full
                    border border-green-300
                    text-green-500
                    hover:bg-green-800 hover:text-yellow-200
                    focus:outline-none
                    h-8"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
                      />
                    </svg>
                  </FormulateInput>
                  <FormulateInput
                    type="submit"
                    @click="editNombEmprsFlag = !editNombEmprsFlag"
                    outer-class="
                    
                      flex
                    items-center
                    p-1
                    pt-2
                    transition
                    ease-in
                    duration-200
                    uppercase
                    rounded-full
                    border border-red-300
                    text-red-500
                    hover:bg-red-800 hover:text-yellow-200
                    focus:outline-none
                    h-8"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </FormulateInput>
                </FormulateForm>
              </div>
            </div>

            <div class="flex" v-if="!editNombEmprsFlag">
              <span class="text-gray-700 mr-6 dark:text-white font-normal">
                {{ experncLabors.nombEmprs }}
              </span>
              <label class="flex items-center">
                <button
                  @click="editNombEmprsFlag = !editNombEmprsFlag"
                  class="
                    flex
                    items-center
                    p-1
                    transition
                    ease-in
                    duration-200
                    uppercase
                    rounded-full
                    hover:bg-blue-800 hover:text-yellow-200
                    focus:outline-none
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-3.5 w-3.5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                    />
                  </svg>
                </button>
              </label>
            </div>
          </dd>
        </div>

        <div
          v-if="$store.state.userData"
          class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
        >
          <dt
            v-if="!editIdActvdEmprsFlag"
            class="text-sm text-left font-medium text-gray-500"
          >
            Actividad de la empresa
          </dt>
          <dd
            class="
              mt-1
              text-sm text-gray-900
              sm:mt-0 sm:col-span-2
              capitalize
              flex
            "
          >
            <div
              v-if="editIdActvdEmprsFlag"
              class="
                flex flex-col
                md:flex-row
                w-3/4
                md:w-full
                max-w-sm
                md:space-x-3
                space-y-3
                md:space-y-0
                justify-start
              "
            >
              <div class="">
                <label
                  for="price"
                  class="block text-xs font-medium text-gray-700 text-left ml-1"
                >
                  <strong> Editar Actividad de la empresa </strong>
                </label>
                <FormulateForm #default="{ hasErrors }" class="flex">
                  <FormulateInput
                    type="text"
                    v-model="data.idActvdEmprs"
                    class="bg-white"
                    :value="experncLabors.idActvdEmprs"
                    validation="required|min:4|matches:/^[A-Za-z\s*\á\é\í\ó\ú\ñ]+$/"
                    validation-behavior="value"
                    :validation-messages="{
                      min: 'Debe tener al menos 4 caracteres',
                      matches: 'Este campo solo acepta letras',
                      required: 'Este campo es obligatorio',
                    }"
                  />
                  <FormulateInput
                    type="submit"
                    :disabled="hasErrors"
                    @click.prevent="editIdActvdEmprs(experncLabors.id)"
                    outer-class="
                    mx-1
                      flex
                    items-center
                    p-1
                    pt-2
                    transition
                    ease-in
                    duration-200
                    uppercase
                    rounded-full
                    border border-green-300
                    text-green-500
                    hover:bg-green-800 hover:text-yellow-200
                    focus:outline-none
                    h-8"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
                      />
                    </svg>
                  </FormulateInput>
                  <FormulateInput
                    type="submit"
                    @click="editIdActvdEmprsFlag = !editIdActvdEmprsFlag"
                    outer-class="
                      flex
                    items-center
                    p-1
                    pt-2
                    transition
                    ease-in
                    duration-200
                    uppercase
                    rounded-full
                    border border-red-300
                    text-red-500
                    hover:bg-red-800 hover:text-yellow-200
                    focus:outline-none
                    h-8"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </FormulateInput>
                </FormulateForm>
              </div>
            </div>

            <div class="flex" v-if="!editIdActvdEmprsFlag">
              <span class="text-gray-700 mr-6 dark:text-white font-normal">
                {{ experncLabors.idActvdEmprs }}
              </span>
              <label class="flex items-center">
                <button
                  @click="editIdActvdEmprsFlag = !editIdActvdEmprsFlag"
                  class="
                    flex
                    items-center
                    p-1
                    transition
                    ease-in
                    duration-200
                    uppercase
                    rounded-full
                    hover:bg-blue-800 hover:text-yellow-200
                    focus:outline-none
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-3.5 w-3.5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                    />
                  </svg>
                </button>
              </label>
            </div>
          </dd>
        </div>

        <div
          v-if="$store.state.userData"
          class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
        >
          <dt
            v-if="!editCargoFlag"
            class="text-sm text-left font-medium text-gray-500"
          >
            Ocupación o Cargo
          </dt>
          <dd
            class="
              mt-1
              text-sm text-gray-900
              sm:mt-0 sm:col-span-2
              capitalize
              flex
            "
          >
            <div
              v-if="editCargoFlag"
              class="
                flex flex-col
                md:flex-row
                w-3/4
                md:w-full
                max-w-sm
                md:space-x-3
                space-y-3
                md:space-y-0
                justify-start
              "
            >
              <div class="">
                <label
                  for="price"
                  class="block text-xs font-medium text-gray-700 text-left ml-1"
                >
                  <strong> Editar Ocupación o Cargo</strong>
                </label>
                <FormulateForm
                  v-if="editCargoFlag"
                  #default="{ hasErrors }"
                  class="flex"
                >
                  <FormulateInput
                    type="text"
                    v-model="data.cargo"
                    class="bg-white"
                    :value="experncLabors.cargo"
                    validation="required|min:4|matches:/^[A-Za-z\s*\á\é\í\ó\ú\ñ]+$/"
                    validation-behavior="value"
                    :validation-messages="{
                      min: 'Debe tener al menos 4 caracteres',
                      matches: 'Este campo solo acepta letras',
                      required: 'Este campo es obligatorio',
                    }"
                  />
                  <FormulateInput
                    type="submit"
                    :disabled="hasErrors"
                    @click.prevent="editCargo(experncLabors.id)"
                    outer-class="
                    mx-1
                      flex
                    items-center
                    p-1
                    pt-2
                    transition
                    ease-in
                    duration-200
                    uppercase
                    rounded-full
                    border border-green-300
                    text-green-500
                    hover:bg-green-800 hover:text-yellow-200
                    focus:outline-none
                    h-8"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
                      />
                    </svg>
                  </FormulateInput>
                  <FormulateInput
                    type="submit"
                    @click="editCargoFlag = !editCargoFlag"
                    outer-class="
                    
                      flex
                    items-center
                    p-1
                    pt-2
                    transition
                    ease-in
                    duration-200
                    uppercase
                    rounded-full
                    border border-red-300
                    text-red-500
                    hover:bg-red-800 hover:text-yellow-200
                    focus:outline-none
                    h-8"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </FormulateInput>
                </FormulateForm>
              </div>
            </div>

            <div class="flex" v-if="!editCargoFlag">
              <span class="text-gray-700 mr-6 dark:text-white font-normal">
                {{ experncLabors.cargo }}
              </span>
              <label class="flex items-center">
                <button
                  @click="editCargoFlag = !editCargoFlag"
                  class="
                    flex
                    items-center
                    p-1
                    transition
                    ease-in
                    duration-200
                    uppercase
                    rounded-full
                    hover:bg-blue-800 hover:text-yellow-200
                    focus:outline-none
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-3.5 w-3.5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                    />
                  </svg>
                </button>
              </label>
            </div>
          </dd>
        </div>

        <div
          v-if="$store.state.userData"
          class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
        >
          <dt
            v-if="!editIdPaisFlag"
            class="text-sm text-left font-medium text-gray-500"
          >
            País del ejercicio
          </dt>
          <dd
            class="
              mt-1
              text-sm text-gray-900
              sm:mt-0 sm:col-span-2
              capitalize
              flex
            "
          >
            <form
              v-if="editIdPaisFlag"
              class="
                flex flex-col
                md:flex-row
                w-3/4
                md:w-full
                max-w-sm
                md:space-x-3
                space-y-3
                md:space-y-0
                justify-start
              "
            >
              <div class="">
                <label
                  for="price"
                  class="block text-xs font-medium text-gray-700 text-left ml-1"
                >
                  <strong>Editar País del ejercicio</strong>
                </label>
                <select
                  type="text"
                  id='"form-subscribe-Subscribe'
                  class="
                    rounded-lg
                    border-transparent
                    flex-1
                    appearance-none
                    border border-gray-300
                    w-60
                    px-2
                    py-2
                    bg-white
                    text-gray-700
                    placeholder-gray-400
                    shadow-sm
                    text-xs
                    focus:outline-none
                    focus:ring-2
                    focus:ring-purple-600
                    focus:border-transparent
                  "
                  v-model="data.idPais"
                >
                  <option class="text-gray-300" disabled value="">
                    Seleccione
                  </option>
                  <option value="Venezuela">Venezuela</option>
                  <option value="Otro">Otro</option>
                </select>
              </div>

              <div
                class="
                  flex
                  w-auto
                  text-gray-500
                  items-end
                  justify-between
                  px-2
                  pb-0.5
                  space-x-1
                "
              >
                <button
                  @click.prevent="editIdPais(experncLabors.id)"
                  class="
                    p-1
                    flex
                    items-center
                    transition
                    ease-in
                    duration-200
                    uppercase
                    rounded-full
                    border border-green-200
                    text-green-600
                    hover:border-green-600
                    hover:bg-green-600
                    hover:text-yellow-200
                    focus:outline-none
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
                    />
                  </svg>
                </button>
                <button
                  @click="editIdPaisFlag = !editIdPaisFlag"
                  class="
                    flex
                    items-center
                    p-1
                    transition
                    ease-in
                    duration-200
                    uppercase
                    rounded-full
                    border border-red-300
                    text-red-500
                    hover:bg-red-800 hover:text-yellow-200
                    focus:outline-none
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </button>
              </div>
            </form>

            <div class="flex" v-if="!editIdPaisFlag">
              <span class="text-gray-700 mr-6 dark:text-white font-normal">
                {{ experncLabors.idPais }}
              </span>
              <label class="flex items-center">
                <button
                  @click="editIdPaisFlag = !editIdPaisFlag"
                  class="
                    flex
                    items-center
                    p-1
                    transition
                    ease-in
                    duration-200
                    uppercase
                    rounded-full
                    hover:bg-blue-800 hover:text-yellow-200
                    focus:outline-none
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-3.5 w-3.5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                    />
                  </svg>
                </button>
              </label>
            </div>
          </dd>
        </div>

        <div
          v-if="$store.state.userData"
          class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
        >
          <dt
            v-if="!editFechDesdFlag"
            class="text-sm text-left font-medium text-gray-500"
          >
            Fecha de ingreso
          </dt>
          <dd
            class="
              mt-1
              text-sm text-gray-900
              sm:mt-0 sm:col-span-2
              capitalize
              flex
            "
          >
            <form
              v-if="editFechDesdFlag"
              class="
                flex flex-col
                md:flex-row
                w-3/4
                md:w-full
                max-w-sm
                md:space-x-3
                space-y-3
                md:space-y-0
                justify-start
              "
            >
              <div class="">
                <label
                  for="price"
                  class="block text-xs font-medium text-gray-700 text-left ml-1"
                >
                  <strong> Editar Fecha de ingreso </strong>
                </label>
                <input
                  type="date"
                  id='"form-subscribe-Subscribe'
                  class="
                    rounded-lg
                    border-transparent
                    flex-1
                    appearance-none
                    border border-gray-300
                    w-60
                    px-2
                    py-2
                    bg-white
                    text-gray-700
                    placeholder-gray-400
                    shadow-sm
                    text-xs
                    focus:outline-none
                    focus:ring-2
                    focus:ring-purple-600
                    focus:border-transparent
                  "
                  placeholder="Fecha de ingreso"
                  onfocus="(this.type='date')"
                  onblur="(this.type='text')"
                  v-model="data.fechDesd"
                />
              </div>
              <div
                class="
                  flex
                  w-auto
                  text-gray-500
                  items-end
                  justify-between
                  px-2
                  pb-0.5
                  space-x-1
                "
              >
                <button
                  @click.prevent="editFechDesd(experncLabors.id)"
                  class="
                    p-1
                    flex
                    items-center
                    transition
                    ease-in
                    duration-200
                    uppercase
                    rounded-full
                    border border-green-200
                    text-green-600
                    hover:border-green-600
                    hover:bg-green-600
                    hover:text-yellow-200
                    focus:outline-none
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
                    />
                  </svg>
                </button>
                <button
                  @click="editFechDesdFlag = !editFechDesdFlag"
                  class="
                    flex
                    items-center
                    p-1
                    transition
                    ease-in
                    duration-200
                    uppercase
                    rounded-full
                    border border-red-300
                    text-red-500
                    hover:bg-red-800 hover:text-yellow-200
                    focus:outline-none
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </button>
              </div>
            </form>

            <div class="flex" v-if="!editFechDesdFlag">
              <span class="text-gray-700 mr-6 dark:text-white font-normal">
                {{ experncLabors.fechDesd }}
              </span>
              <label class="flex items-center">
                <button
                  @click="editFechDesdFlag = !editFechDesdFlag"
                  class="
                    flex
                    items-center
                    p-1
                    transition
                    ease-in
                    duration-200
                    uppercase
                    rounded-full
                    hover:bg-blue-800 hover:text-yellow-200
                    focus:outline-none
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-3.5 w-3.5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                    />
                  </svg>
                </button>
              </label>
            </div>
          </dd>
        </div>

        <div
          v-if="$store.state.userData"
          class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
        >
          <dt
            v-if="!editFechHastFlag"
            class="text-sm text-left font-medium text-gray-500"
          >
            Fecha de egreso
          </dt>
          <dd
            class="
              mt-1
              text-sm text-gray-900
              sm:mt-0 sm:col-span-2
              capitalize
              flex
            "
          >
            <form
              v-if="editFechHastFlag"
              class="
                flex flex-col
                md:flex-row
                w-3/4
                md:w-full
                max-w-sm
                md:space-x-3
                space-y-3
                md:space-y-0
                justify-start
              "
            >
              <div class="">
                <label
                  for="price"
                  class="block text-xs font-medium text-gray-700 text-left ml-1"
                >
                  <strong>Editar Fecha de egreso</strong>
                </label>
                <input
                  type="date"
                  class="
                    rounded-lg
                    border-transparent
                    flex-1
                    appearance-none
                    border border-gray-300
                    w-60
                    px-2
                    py-2
                    bg-white
                    text-gray-700
                    placeholder-gray-400
                    shadow-sm
                    text-xs
                    focus:outline-none
                    focus:ring-2
                    focus:ring-purple-600
                    focus:border-transparent
                  "
                  placeholder="Fecha de egreso"
                  onfocus="(this.type='date')"
                  onblur="(this.type='text')"
                  v-model="data.fechHast"
                />
              </div>

              <div
                class="
                  flex
                  w-auto
                  text-gray-500
                  items-end
                  justify-between
                  px-2
                  pb-0.5
                  space-x-1
                "
              >
                <button
                  @click.prevent="editFechHast(experncLabors.id)"
                  class="
                    p-1
                    flex
                    items-center
                    transition
                    ease-in
                    duration-200
                    uppercase
                    rounded-full
                    border border-green-200
                    text-green-600
                    hover:border-green-600
                    hover:bg-green-600
                    hover:text-yellow-200
                    focus:outline-none
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
                    />
                  </svg>
                </button>
                <button
                  @click="editFechHastFlag = !editFechHastFlag"
                  class="
                    flex
                    items-center
                    p-1
                    transition
                    ease-in
                    duration-200
                    uppercase
                    rounded-full
                    border border-red-300
                    text-red-500
                    hover:bg-red-800 hover:text-yellow-200
                    focus:outline-none
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </button>
              </div>
            </form>

            <div class="flex" v-if="!editFechHastFlag">
              <span class="text-gray-700 mr-6 dark:text-white font-normal">
                {{ experncLabors.fechHast }}
              </span>
              <label class="flex items-center">
                <button
                  @click="editFechHastFlag = !editFechHastFlag"
                  class="
                    flex
                    items-center
                    p-1
                    transition
                    ease-in
                    duration-200
                    uppercase
                    rounded-full
                    hover:bg-blue-800 hover:text-yellow-200
                    focus:outline-none
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-3.5 w-3.5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                    />
                  </svg>
                </button>
              </label>
            </div>
          </dd>
        </div>
      </dl>
    </div>

    <div class="border-t border-gray-200">
      <dl>
        <div
          v-if="$store.state.userData"
          class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
        >
          <dt
            v-if="!editAreaCargoFlag"
            class="text-sm text-left font-medium text-gray-500"
          >
            Área del puesto
          </dt>
          <dd
            class="
              mt-1
              text-sm text-gray-900
              sm:mt-0 sm:col-span-2
              capitalize
              flex
            "
          >
            <form
              v-if="editAreaCargoFlag"
              class="
                flex flex-col
                md:flex-row
                w-3/4
                md:w-full
                max-w-sm
                md:space-x-3
                space-y-3
                md:space-y-0
                justify-start
              "
            >
              <div class="">
                <label
                  for="price"
                  class="block text-xs font-medium text-gray-700 text-left ml-1"
                >
                  <strong>Editar Área del puesto</strong>
                </label>
                <select
                  type="input"
                  id='"form-subscribe-Subscribe'
                  class="
                    rounded-lg
                    border-transparent
                    flex-1
                    appearance-none
                    border border-gray-300
                    w-60
                    px-2
                    py-2
                    bg-white
                    text-gray-700
                    placeholder-gray-400
                    shadow-sm
                    text-xs
                    focus:outline-none
                    focus:ring-2
                    focus:ring-purple-600
                    focus:border-transparent
                  "
                  v-model.lazy.trim="data.areaCargo"
                >
                  <option class="text-gray-400" disabled value="">
                    Área del puesto
                  </option>
                  <option value="Gerencia">Gerencia</option>
                  <option value="Administrativo">Administrativo</option>
                  <option value="Operaciones">Operaciones</option>
                </select>
              </div>

              <div
                class="
                  flex
                  w-auto
                  text-gray-500
                  items-end
                  justify-between
                  px-2
                  pb-0.5
                  space-x-1
                "
              >
                <button
                  @click.prevent="editAreaCargo(experncLabors.id)"
                  class="
                    p-1
                    flex
                    items-center
                    transition
                    ease-in
                    duration-200
                    uppercase
                    rounded-full
                    border border-green-200
                    text-green-600
                    hover:border-green-600
                    hover:bg-green-600
                    hover:text-yellow-200
                    focus:outline-none
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
                    />
                  </svg>
                </button>
                <button
                  @click="editAreaCargoFlag = !editAreaCargoFlag"
                  class="
                    flex
                    items-center
                    p-1
                    transition
                    ease-in
                    duration-200
                    uppercase
                    rounded-full
                    border border-red-300
                    text-red-500
                    hover:bg-red-800 hover:text-yellow-200
                    focus:outline-none
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </button>
              </div>
            </form>

            <div class="flex" v-if="!editAreaCargoFlag">
              <span class="text-gray-700 mr-6 dark:text-white font-normal">
                {{ experncLabors.areaCargo }}
              </span>
              <label class="flex items-center">
                <button
                  @click="editAreaCargoFlag = !editAreaCargoFlag"
                  class="
                    flex
                    items-center
                    p-1
                    transition
                    ease-in
                    duration-200
                    uppercase
                    rounded-full
                    hover:bg-blue-800 hover:text-yellow-200
                    focus:outline-none
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-3.5 w-3.5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                    />
                  </svg>
                </button>
              </label>
            </div>
          </dd>
        </div>

        <div
          v-if="$store.state.userData"
          class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
        >
          <dt
            v-if="!editNivlExperncFlag"
            class="text-sm text-left font-medium text-gray-500"
          >
            Tiempo en el cargo
          </dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex">
            <form
              v-if="editNivlExperncFlag"
              class="
                flex flex-col
                md:flex-row
                w-3/4
                md:w-full
                max-w-sm
                md:space-x-3
                space-y-3
                md:space-y-0
                justify-start
              "
            >
              <div class="">
                <label
                  for="price"
                  class="block text-xs font-medium text-gray-700 text-left ml-1"
                >
                  <strong>Editar Nivel de experiencia </strong>
                </label>
                <select
                  type="text"
                  id='"form-subscribe-Subscribe'
                  class="
                    rounded-lg
                    border-transparent
                    flex-1
                    appearance-none
                    border border-gray-300
                    w-60
                    px-2
                    py-2
                    bg-white
                    text-gray-700
                    placeholder-gray-400
                    shadow-sm
                    text-xs
                    focus:outline-none
                    focus:ring-2
                    focus:ring-purple-600
                    focus:border-transparent
                  "
                  v-model="data.nivlExpernc"
                >
                  <option class="text-gray-400" disabled value="">
                    Tiempo en el cargo
                  </option>
                  <option value="-1 año o menos">Menos de un año</option>
                  <option value="1 año">1 año</option>
                  <option value="1 año o más">Más de 1 año</option>
                  <option value="2 años">2 años</option>
                  <option value="2 años o más">Más de 2 años</option>
                  <option value="3 años">3 años</option>
                  <option value="3 años o mas">Más de 3 años</option>
                  <option value="4 años">4 años</option>
                  <option value="5 años">5 años</option>
                  <option value="6 años">6 años</option>
                  <option value="7 años">7 años o más</option>
                </select>
              </div>

              <div
                class="
                  flex
                  w-auto
                  text-gray-500
                  items-end
                  justify-between
                  px-2
                  pb-0.5
                  space-x-1
                "
              >
                <button
                  @click.prevent="editNivlExpernc(experncLabors.id)"
                  class="
                    p-1
                    flex
                    items-center
                    transition
                    ease-in
                    duration-200
                    uppercase
                    rounded-full
                    border border-green-200
                    text-green-600
                    hover:border-green-600
                    hover:bg-green-600
                    hover:text-yellow-200
                    focus:outline-none
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
                    />
                  </svg>
                </button>
                <button
                  @click="editNivlExperncFlag = !editNivlExperncFlag"
                  class="
                    flex
                    items-center
                    p-1
                    transition
                    ease-in
                    duration-200
                    uppercase
                    rounded-full
                    border border-red-300
                    text-red-500
                    hover:bg-red-800 hover:text-yellow-200
                    focus:outline-none
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </button>
              </div>
            </form>

            <div class="flex" v-if="!editNivlExperncFlag">
              <span class="text-gray-700 mr-6 dark:text-white font-normal">
                {{ experncLabors.nivlExpernc }}
              </span>
              <label class="flex items-center">
                <button
                  @click="editNivlExperncFlag = !editNivlExperncFlag"
                  class="
                    flex
                    items-center
                    p-1
                    transition
                    ease-in
                    duration-200
                    uppercase
                    rounded-full
                    hover:bg-blue-800 hover:text-yellow-200
                    focus:outline-none
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-3.5 w-3.5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                    />
                  </svg>
                </button>
              </label>
            </div>
          </dd>
        </div>

        <div
          v-if="$store.state.userData"
          class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
        >
          <dt
            v-if="!editDescrpRespnsabldFlag"
            class="text-sm text-left font-medium text-gray-500"
          >
            Descripción del cargo / responsabilidades
          </dt>
          <dd
            class="
              mt-1
              text-sm text-gray-900
              sm:mt-0 sm:col-span-2
              capitalize
              flex
            "
          >
            <div
              v-if="editDescrpRespnsabldFlag"
              class="
                flex flex-col
                md:flex-row
                w-3/4
                md:w-full
                max-w-sm
                md:space-x-3
                space-y-3
                md:space-y-0
                justify-start
              "
            >
              <div class="">
                <label
                  for="price"
                  class="block text-xs font-medium text-gray-700 text-left ml-1"
                >
                  <strong
                    >Editar Descripción del cargo / responsabilidades</strong
                  >
                </label>

                <FormulateForm
                  #default="{ hasErrors }"
                  class="flex w-44 lg:w-80 md:w-64 px-2"
                >
                  <FormulateInput
                    type="textarea"
                    v-model="data.descrpRespnsabld"
                    class="bg-white"
                    :value="experncLabors.descrpRespnsabld"
                    rows="4"
                    cols="70"
                    validation="required|max:400,length"
                    validation-name="comment"
                    error-behavior="value"
                    :help="`Maximo 400 caracteres. ${
                      400 - data.descrpRespnsabld.length
                    } restantes.`"
                    :validation-messages="{
                      required: 'Debes rellenar este campo',
                      max: 'Texto debe ser menor a 400 caracteres',
                    }"
                    errors-class="text-left ml-1"
                    help-class="text-left ml-1 text-xs font-semibold"
                  />
                  <FormulateInput
                    type="submit"
                    :disabled="hasErrors"
                    @click.prevent="editDescrpRespnsabld(experncLabors.id)"
                    outer-class="
                    mx-1
                      flex
                    items-center
                    p-1
                    pt-2
                    transition
                    ease-in
                    duration-200
                    uppercase
                    rounded-full
                    border border-green-300
                    text-green-500
                    hover:bg-green-800 hover:text-yellow-200
                    focus:outline-none
                    h-8"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
                      />
                    </svg>
                  </FormulateInput>
                  <FormulateInput
                    type="submit"
                    @click="
                      editDescrpRespnsabldFlag = !editDescrpRespnsabldFlag
                    "
                    outer-class="
                      flex
                    items-center
                    p-1
                    pt-2
                    transition
                    ease-in
                    duration-200
                    uppercase
                    rounded-full
                    border border-red-300
                    text-red-500
                    hover:bg-red-800 hover:text-yellow-200
                    focus:outline-none
                    h-8"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </FormulateInput>
                </FormulateForm>
              </div>
            </div>

            <div class="flex" v-if="!editDescrpRespnsabldFlag">
              <span
                class="text-gray-700 mr-6 dark:text-white font-normal w-2/3"
              >
                {{ experncLabors.descrpRespnsabld }}
              </span>
              <label class="flex items-center">
                <button
                  @click="editDescrpRespnsabldFlag = !editDescrpRespnsabldFlag"
                  class="
                    flex
                    items-center
                    p-1
                    transition
                    ease-in
                    duration-200
                    uppercase
                    rounded-full
                    hover:bg-blue-800 hover:text-yellow-200
                    focus:outline-none
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-3.5 w-3.5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                    />
                  </svg>
                </button>
              </label>
            </div>
          </dd>
        </div>

        <div
          v-if="$store.state.userData"
          class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
        >
          <dt
            v-if="!editPersnsaCargoFlag"
            class="text-sm text-left font-medium text-gray-500"
          >
            Personas a cargo
          </dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex">
            <div
              v-if="editPersnsaCargoFlag"
              class="
                flex flex-col
                md:flex-row
                w-3/4
                md:w-full
                max-w-sm
                md:space-x-3
                space-y-3
                md:space-y-0
                justify-start
              "
            >
              <div class="">
                <label
                  for="price"
                  class="block text-xs font-medium text-gray-700 text-left ml-1"
                >
                  <strong>Editar Personas a cargo</strong>
                </label>

                <FormulateForm #default="{ hasErrors }" class="flex">
                  <FormulateInput
                    type="number"
                    v-model="data.persnsaCargo"
                    class="bg-white"
                    :value="experncLabors.persnsaCargo"
                    validation="required|max:5,length|min:1,length|not:/^.|matches:/^[0-9]/|"
                    :validation-messages="{
                      required: 'Debes rellenar este campo',
                      max: 'Maximo 5 digitos',
                      min: 'Al menos 1 digito',
                      matches: 'Deben ser numeros',
                      not: 'No permitido',
                    }"
                    errors-class="text-left ml-1"
                    validation-behavior="value"
                  />
                  <FormulateInput
                    type="submit"
                    :disabled="hasErrors"
                    @click.prevent="editPersnsaCargo(experncLabors.id)"
                    outer-class="
                    mx-1
                      flex
                    items-center
                    p-1
                    pt-2
                    transition
                    ease-in
                    duration-200
                    uppercase
                    rounded-full
                    border border-green-300
                    text-green-500
                    hover:bg-green-800 hover:text-yellow-200
                    focus:outline-none
                    h-8"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
                      />
                    </svg>
                  </FormulateInput>
                  <FormulateInput
                    type="submit"
                    @click="editPersnsaCargoFlag = !editPersnsaCargoFlag"
                    outer-class="
                      flex
                    items-center
                    p-1
                    pt-2
                    transition
                    ease-in
                    duration-200
                    uppercase
                    rounded-full
                    border border-red-300
                    text-red-500
                    hover:bg-red-800 hover:text-yellow-200
                    focus:outline-none
                    h-8"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </FormulateInput>
                </FormulateForm>
              </div>
            </div>

            <div class="flex" v-if="!editPersnsaCargoFlag">
              <span
                class="
                  capitalize
                  text-gray-700
                  mr-6
                  dark:text-white
                  font-normal
                "
              >
                {{ experncLabors.persnsaCargo }}
              </span>
              <label class="flex items-center">
                <button
                  @click="editPersnsaCargoFlag = !editPersnsaCargoFlag"
                  class="
                    flex
                    items-center
                    p-1
                    transition
                    ease-in
                    duration-200
                    uppercase
                    rounded-full
                    hover:bg-blue-800 hover:text-yellow-200
                    focus:outline-none
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-3.5 w-3.5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                    />
                  </svg>
                </button>
              </label>
            </div>
          </dd>
        </div>
      </dl>
    </div>
    <loader v-if="isLoading">
      <template v-slot:msg>
        {{ msg }}
      </template>

      <template v-slot:msg1>
        {{ msg1 }}
      </template>
    </loader>
  </div>
</template>
<style scoped></style>
<script>
import axios from "axios";
import loader from "@/components/shared/loader.vue";

export default {
  name: "formExpLaborl",
  components: {
    loader,
  },
  props: {
    experncLabors: {},
  },
  methods: {
    editarCampo() {
      this.editarFlag = !this.editarFlag;
    },
    editNombEmprs(idExpLabrl) {
      let data = JSON.stringify({
        id: idExpLabrl,
        nombEmprs: this.data.nombEmprs,
      });
      axios({
        method: "put",
        url: `https://api.postula2.com/editExperncLaborl/`,
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.myTokToApiComs,
        },
        data: data,
      })
        .then(() => {
          //CLG("campo editado con exito");
          this.editFinished();
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG("mission pelaste bolas in editExperncLaborl");
          //CLG(error);
        })
        .finally(() => {
          //Perform action in always
          this.editNombEmprsFlag = !this.editNombEmprsFlag;
          this.data.nombEmprs = "";
        });
    },
    editIdActvdEmprs(idExpLabrl) {
      let data = JSON.stringify({
        id: idExpLabrl,
        idActvdEmprs: this.data.idActvdEmprs,
      });
      axios({
        method: "put",
        url: `https://api.postula2.com/editExperncLaborl/`,
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.myTokToApiComs,
        },
        data: data,
      })
        .then(() => {
          //CLG("campo editado con exito");
          this.editFinished();
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG("mission pelaste bolas in editExperncLaborl");
          //CLG(error);
        })
        .finally(() => {
          //Perform action in always
          this.editIdActvdEmprsFlag = !this.editIdActvdEmprsFlag;
          this.data.idActvdEmprs = "";
        });
    },
    editCargo(idExpLabrl) {
      let data = JSON.stringify({
        id: idExpLabrl,
        cargo: this.data.cargo,
      });
      axios({
        method: "put",
        url: `https://api.postula2.com/editExperncLaborl/`,

        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.myTokToApiComs,
        },
        data: data,
      })
        .then(() => {
          //CLG("campo editado con exito");
          this.editFinished();
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG("mission pelaste bolas in editExperncLaborl");
          //CLG(error);
        })
        .finally(() => {
          //Perform action in always
          this.editCargoFlag = !this.editCargoFlag;
          this.data.cargo = "";
        });
    },
    editIdPais(idExpLabrl) {
      let data = JSON.stringify({
        id: idExpLabrl,
        idPais: this.data.idPais,
      });
      axios({
        method: "put",
        url: `https://api.postula2.com/editExperncLaborl/`,
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.myTokToApiComs,
        },
        data: data,
      })
        .then(() => {
          //CLG("campo editado con exito");
          this.editFinished();
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG("mission pelaste bolas in editExperncLaborl");
          //CLG(error);
        })
        .finally(() => {
          //Perform action in always
          this.editIdPaisFlag = !this.editIdPaisFlag;
          this.data.idPais = "";
        });
    },
    editFechDesd(idExpLabrl) {
      let data = JSON.stringify({
        id: idExpLabrl,
        fechDesd: this.data.fechDesd,
      });
      axios({
        method: "put",
        url: `https://api.postula2.com/editExperncLaborl/`,
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.myTokToApiComs,
        },
        data: data,
      })
        .then(() => {
          //CLG("campo editado con exito");
          this.editFinished();
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG("mission pelaste bolas in editExperncLaborl");
          //CLG(error);
        })
        .finally(() => {
          //Perform action in always
          this.editFechDesdFlag = !this.editFechDesdFlag;
          this.data.fechDesd = "";
        });
    },
    editFechHast(idExpLabrl) {
      let data = JSON.stringify({
        id: idExpLabrl,
        fechHast: this.data.fechHast,
      });
      axios({
        method: "put",
        url: `https://api.postula2.com/editExperncLaborl/`,
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.myTokToApiComs,
        },
        data: data,
      })
        .then(() => {
          //CLG("campo editado con exito");
          this.editFinished();
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG("mission pelaste bolas in editExperncLaborl");
          //CLG(error);
        })
        .finally(() => {
          //Perform action in always
          this.editFechHastFlag = !this.editFechHastFlag;
          this.data.fechHast = "";
        });
    },
    editAlPresnt(idExpLabrl) {
      let data = JSON.stringify({
        id: idExpLabrl,
        alPresnt: this.data.alPresnt,
      });
      axios({
        method: "put",
        url: `https://api.postula2.com/editExperncLaborl/`,
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.myTokToApiComs,
        },
        data: data,
      })
        .then(() => {
          //CLG("campo editado con exito");
          this.editFinished();
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG("mission pelaste bolas in editExperncLaborl");
          //CLG(error);
        })
        .finally(() => {
          //Perform action in always
          this.editAlPresntFlag = !this.editAlPresntFlag;
          this.data.alPresnt = "";
        });
    },
    editAreaCargo(idExpLabrl) {
      let data = JSON.stringify({
        id: idExpLabrl,
        areaCargo: this.data.areaCargo,
      });
      axios({
        method: "put",
        url: `https://api.postula2.com/editExperncLaborl/`,
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.myTokToApiComs,
        },
        data: data,
      })
        .then(() => {
          //CLG("campo editado con exito");
          this.editFinished();
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG("mission pelaste bolas in editExperncLaborl");
          //CLG(error);
        })
        .finally(() => {
          //Perform action in always
          this.editAreaCargoFlag = !this.editAreaCargoFlag;
          this.data.areaCargo = "";
        });
    },
    editNivlExpernc(idExpLabrl) {
      let data = JSON.stringify({
        id: idExpLabrl,
        nivlExpernc: this.data.nivlExpernc,
      });
      axios({
        method: "put",
        url: `https://api.postula2.com/editExperncLaborl/`,
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.myTokToApiComs,
        },
        data: data,
      })
        .then(() => {
          //CLG("campo editado con exito");
          this.editFinished();
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG("mission pelaste bolas in editExperncLaborl");
          //CLG(error);
        })
        .finally(() => {
          //Perform action in always
          this.editNivlExperncFlag = !this.editNivlExperncFlag;
          this.data.nivlExpernc = "";
        });
    },
    editDescrpRespnsabld(idExpLabrl) {
      let data = JSON.stringify({
        id: idExpLabrl,
        descrpRespnsabld: this.data.descrpRespnsabld,
      });
      axios({
        method: "put",
        url: `https://api.postula2.com/editExperncLaborl/`,
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.myTokToApiComs,
        },
        data: data,
      })
        .then(() => {
          //CLG("campo editado con exito");
          this.editFinished();
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG("mission pelaste bolas in editExperncLaborl");
          //CLG(error);
        })
        .finally(() => {
          //Perform action in always
          this.editDescrpRespnsabldFlag = !this.editDescrpRespnsabldFlag;
          this.data.descrpRespnsabld = "";
        });
    },
    editPersnsaCargo(idExpLabrl) {
      let data = JSON.stringify({
        id: idExpLabrl,
        persnsaCargo: this.data.persnsaCargo,
      });
      axios({
        method: "put",
        url: `https://api.postula2.com/editExperncLaborl/`,
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.myTokToApiComs,
        },
        data: data,
      })
        .then(() => {
          //CLG("campo editado con exito");
          this.editFinished();
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG("mission pelaste bolas in editExperncLaborl");
          //CLG(error);
        })
        .finally(() => {
          //Perform action in always
          this.editPersnsaCargoFlag = !this.editPersnsaCargoFlag;
          this.data.persnsaCargo = "";
        });
    },
    editar() {
      this.editing = true;
    },
    editFinished() {
      this.editing = false;
      //https://api.postula2.com/persona/624745e3e602450004288516/experncLabors
      axios({
        method: "get",
        url: `https://api.postula2.com/persona/${this.$store.state.userData.id}/ExperncLabors`,
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.myTokToApiComs,
        },
      })
        .then((res) => {
          //CLG("exito en ExperncLabor",res.data)
          this.$store.state.userData.experncLabors = res.data;
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG("mission pelaste bolas in editExperncLaborl EditFinished");
          //CLG(error);
        })
        .finally(() => {
          //Perform action in always
        });
    },
    onSubmit() {
      //let dataUser = this.data;
      this.isLoading = true;
      this.data.owner = this.$store.state.userData.id;
      axios
        .post("https://api.postula2.com/ExperncLabor", this.data)
        .then(() => {
          //Perform Success Action
          //CLG("response:", res);
          this.msg = "Experiencian Laboral registrada satisfactoriamente...!";
          //this.$store.commit("getflagLogin", redFlag);
          this.$store.commit({
            type: "getflagLogin",
            greenFlag: true,
          });
          setTimeout(() => {
            this.editFinished();
            (this.data.nombEmprs = ""),
              (this.data.idActvdEmprs = ""),
              (this.data.cargo = ""),
              (this.data.idPais = ""),
              (this.data.fechDesd = ""),
              (this.data.fechHast = ""),
              (this.data.alPresnt = ""),
              (this.data.areaCargo = ""),
              (this.data.nivlExpernc = ""),
              (this.data.descrpRespnsabld = ""),
              (this.data.persnsaCargo = ""),
              (this.data.owner = null);
            //this.editing = false;
            this.isLoading = false;
            this.$store.commit({
              type: "getflagLogin",
              greenFlag: null,
            });
          }, 2000);
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG(error);
          this.msg1 =
            "¡Verifique la información ingresada e intente nuevamente!";
          //this.$store.commit("getflagLogin", redFlag);
          this.$store.commit({
            type: "getflagLogin",
            redFlag: true,
          });
          setTimeout(() => {
            this.isLoading = false;
            this.$store.commit({
              type: "getflagLogin",
              redFlag: null,
            });
          }, 3000);
        })
        .finally(() => {
          //Perform action in always
        });
    },
  },
  // created() {
  //   if (!this.$store.state.regUser.id) {
  //     alert("Debe iniciar sesion");
  //     this.$router.push({
  //       name: "login",
  //     });
  //   }
  // },
  data() {
    return {
      //experncLabors
      editarFlag: false,

      editNombEmprsFlag: false,
      editIdActvdEmprsFlag: false,
      editCargoFlag: false,
      editIdPaisFlag: false,
      editFechDesdFlag: false,
      editFechHastFlag: false,
      editAlPresntFlag: false,
      editAreaCargoFlag: false,
      editNivlExperncFlag: false,
      editDescrpRespnsabldFlag: false,
      editPersnsaCargoFlag: false,

      //////////experncLabors//////////

      editing: false,
      isLoading: false,
      msg: null,
      msg1: null,
      data: {
        nombEmprs: "",
        idActvdEmprs: "",
        cargo: "",
        idPais: "",
        fechDesd: "",
        fechHast: "",
        alPresnt: "",
        areaCargo: "",
        nivlExpernc: "",
        descrpRespnsabld: "",
        persnsaCargo: "",
        owner: null,
      },
    };
  },
};
</script>
