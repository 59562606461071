var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-white p-3 border-t-8 py-4 rounded-lg border-blue-postula2 shadow-md"},[(this.flagImgUploaded === true)?_c('div',{staticClass:"image overflow-hidden"},[(_vm.$store.state.profileImage.image.encryptedName)?_c('img',{staticClass:"h-auto w-44 rounded-full mx-auto border-4 border-red-800",attrs:{"src":this.imgUploaded,"alt":""}}):(this.$store.state.userData.genero === 'Femenino')?_c('img',{staticClass:"h-auto w-44 rounded-full mx-auto border-4 border-green-800",attrs:{"src":require("@/components/img/profileFemale.png"),"alt":""}}):_c('img',{staticClass:"h-auto w-44 rounded-full mx-auto border-4 border-indigo-800",attrs:{"src":require("@/components/img/picture1.jpg"),"alt":"profil"}})]):_c('div',{staticClass:"image overflow-hidden relative"},[(this.imgUploadedName)?_c('img',{staticClass:"h-auto w-44 rounded-full mx-auto border-2 border-yellow-500",attrs:{"src":`https://api.postula2.com/imagesTi?id=${this.$store.state.userData.imageProfileInfo[0].imageName}`,"alt":""}}):(this.$store.state.profileImage.flagImgUploaded)?_c('img',{staticClass:"h-36 w-36 rounded-full mx-auto border-2 border-blue-postula2 object-cover",attrs:{"src":`https://api.postula2.com/imagesTi?id=${this.$store.state.profileImage.image.imageName}`,"alt":""}}):(this.$store.state.userData.imageProfileInfo.length > 0)?_c('img',{staticClass:"h-36 w-36 rounded-full mx-auto object-cover",class:{
        'border-2 border-dotted border-green-500': _vm.debg,
        'border-2 border-blue-postula2': !_vm.debg,
      },attrs:{"src":`https://api.postula2.com/imagesTi?id=${this.$store.state.userData.imageProfileInfo[0].imageName}`,"alt":""}}):(this.$store.state.profileImage.flagImgUploaded === true)?_c('img',{staticClass:"h-auto w-44 rounded-full mx-auto border-2 border-red-700",attrs:{"src":`https://api.postula2.com/imagesTi?id=${this.$store.state.userData.id}`,"alt":""}}):(this.$store.state.userData.genero === 'Femenino')?_c('img',{staticClass:"h-auto w-44 rounded-full mx-auto border-2 border-pink-400",attrs:{"src":require("@/components/img/profileFemale.png"),"alt":""}}):_c('img',{staticClass:"h-auto w-44 rounded-full mx-auto border-2 border-red-500",attrs:{"src":require("@/components/img/picture1.jpg"),"alt":"profil"}}),_c('a',{staticClass:"relative block my-3 cursor-pointer hidden"},[_c('span',{staticClass:"absolute w-7 h-7 my-6 mx-3 transform -translate-x-1/2 bg-white border-2 border-blue-postula2 rounded-full left-2/3 -bottom-2",class:{
          'border-2 border-dotted border-green-500': _vm.debg,
          'border-2 border-blue-postula2': !_vm.debg,
        }},[_c('svg',{staticClass:"w-6 h-6",attrs:{"width":"20","height":"20","fill":"currentColor","viewBox":"0 0 1792 1792","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M491 1536l91-91-235-235-91 91v107h128v128h107zm523-928q0-22-22-22-10 0-17 7l-542 542q-7 7-7 17 0 22 22 22 10 0 17-7l542-542q7-7 7-17zm-54-192l416 416-832 832h-416v-416zm683 96q0 53-37 90l-166 166-416-416 166-165q36-38 90-38 53 0 91 38l235 234q37 39 37 91z"}})])])])]),_c('h1',{staticClass:"text-gray-900 font-bold text-xl leading-8 my-1 capitalize"},[_vm._v(" "+_vm._s(_vm.$store.state.userData.owner.nombreUser + " " + _vm.$store.state.userData.owner.apellidoUser)+" ")]),_c('h3',{staticClass:"text-gray-600 font-lg text-semibold leading-6 break-words"},[_vm._v(" "+_vm._s(_vm.$store.state.userData.owner.emailUser)+" ")]),(_vm.activateUpload)?_c('div',{staticClass:"flex-wrap w-52 vfa-demo bg-light pt-3",class:{
      'border-2 border-green-400': _vm.debg,
    }},[_c('VueFileAgent',{ref:"vfaDemoRef",staticClass:"upload-block relative w-24",attrs:{"uploadHeaders":{},"accept":'image/*',"multiple":false,"progress":true,"deletable":true,"theme":'list',"maxSize":'0,5MB',"maxFiles":1,"helpText":'Drag an image file here',"errorText":{
        size: 'This file is too large to be attached',
      }},scopedSlots:_vm._u([{key:"file-preview",fn:function(slotProps){return [_c('div',{key:slotProps.index,staticClass:"file-row text-sm w-48"},[_c('button',{staticClass:"close remove",class:{
              'border-2 border-red-400': _vm.debg,
            },attrs:{"type":"button","aria-label":"Remove"},on:{"click":function($event){return _vm.removePic()}}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_c('strong',[_vm._v(_vm._s(slotProps.fileRecord.name()))]),_c('span',{staticClass:"text-muted"},[_vm._v("("+_vm._s(slotProps.fileRecord.size())+")")])])]}},{key:"file-preview-new",fn:function(){return [_c('div',{key:"new",staticClass:"text-left my-3"},[_c('a',{attrs:{"href":""}})])]},proxy:true},{key:"after-outer",fn:function(){return [_c('div',{staticClass:"w-40"},[_c('button',{staticClass:"mt-1.5 py-1 px-3 flex w-auto justify-center items-center bg-green-500 hover:bg-green-700 focus:ring-green-500 focus:ring-offset-green-200 text-white w- transition ease-in duration-200 text-center text-sm font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-full",attrs:{"type":"button"},on:{"click":function($event){return _vm.uploadFiles()}}},[_c('img',{staticClass:"w-7 h-7",attrs:{"src":require("../img/new-file-front-colorr.png"),"alt":""}}),_c('span',{staticClass:"ml-2 font-semibold"},[_vm._v(" Guardar ")])])])]},proxy:true}],null,false,1124709252),model:{value:(_vm.fileRecord),callback:function ($$v) {_vm.fileRecord=$$v},expression:"fileRecord"}})],1):_vm._e(),_c('p',{staticClass:"hidden text-sm text-gray-500 hover:text-gray-600 leading-6"}),_c('ul',{staticClass:"bg-gray-100 text-gray-600 hover:text-gray-700 hover:shadow py-2 px-3 mt-3 divide-y rounded shadow-sm"},[_vm._m(0),_c('li',{staticClass:"flex items-center py-3"},[_c('span',{staticClass:"text-sm"},[_vm._v("Miembro desde")]),_c('span',{staticClass:"ml-auto text-sm"},[_vm._v(" "+_vm._s(_vm._f("formatedTime")(_vm.$store.state.userData.createdAt))+" ")])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"flex items-center py-3"},[_c('span',{staticClass:"text-sm"},[_vm._v("Estatus")]),_c('span',{staticClass:"ml-auto"},[_c('span',{staticClass:"bg-green-500 py-1 px-2 rounded text-white text-sm font-semibold"},[_vm._v("Activo")])])])
}]

export { render, staticRenderFns }