<template>
  <div class="bg-white p-3 border-t-8 py-4 rounded-lg border-blue-postula2 shadow-md">
    <div v-if="this.flagImgUploaded === true" class="image overflow-hidden">
      <img
        v-if="$store.state.profileImage.image.encryptedName"
        class="h-auto w-44 rounded-full mx-auto border-2 border-black"
        :src="this.imgUploaded"
        alt=""
      />
      <img
        v-else-if="this.$store.state.userData.genero === 'Femenino'"
        class="h-auto w-44 rounded-full mx-auto border-4 border-green-800"
        src="./img/profileFemale.png"
        alt=""
      />
      <img
        v-else
        src="./img/edificio.png"
        alt="profil"
        class="h-36 w-36 rounded-full mx-auto object-cover"
      />
    </div>
    <div v-else class="image overflow-hidden ">
      <img
        v-if="this.imgUploadedName"
        class="h-36 w-36 rounded-full mx-auto object-cover"
        v-bind:class="{
          'border-2 border-red-700': debg,
        }"
        :src="`https://api.postula2.com/imagesTiEmprs?id=${this.$store.state.profileImage.image.imageName}`"
        alt=""
      />
      <!-- imagen que se renderiza actualmente si ya hay una imagen en BD-->

      <img
        v-else-if="!this.$store.state.profileImage.flagImgUploaded"
        class="h-36 w-36 rounded-full mx-auto border-2 border-blue-postula2 object-cover"
        v-bind:class="{
          'border-4 border-purple-700': debg,
        }"
        :src="`https://api.postula2.com/imagesTiEmprs?id=${this.$store.state.regUserEmprs.empresa[0].imgEmpr}`"
        @error="handleImageError"
        alt=""
      />
      <!-- ///imagen que se renderiza actualmente si ya hay una imagen en BD-->
      <img
        v-else-if="
          this.$store.state.regUserEmprs.empresa[0].imgEmpr.length > 0 && !imageError
        "
        class="h-36 w-36 rounded-full mx-auto border-2 border-blue-postula2 object-cover"
        v-bind:class="{
          'border-2 border-red-600': debg,
        }"
        :src="`https://api.postula2.com/imagesTiEmprs?id=${this.$store.state.profileImage.image.imageName}`"
        alt=""
      />

      <img
        v-else-if="this.$store.state.regUserEmprs.empresa[0].imgEmpr.length > 0"
        class="h-36 w-36 rounded-full mx-auto border-2 border-blue-postula2 object-cover"
        v-bind:class="{
          'border-2 border-red-600': debg,
        }"
        :src="`https://api.postula2.com/imagesTiEmprs?id=${this.$store.state.profileImage.image.imageName}`"
        alt=""
      />
      <img
        v-else-if="this.$store.state.profileImage.flagImgUploaded === true"
        class="h-auto w-44 rounded-full mx-auto"
        v-bind:class="{
          'border-2 border-blue-700': debg,
        }"
        :src="`https://api.postula2.com/imagesTiEmprs?id=${this.$store.state.profileImage.image.imageName}`"
        alt=""
      />
      <img
        v-else-if="this.$store.state.userData.genero === 'Femenino'"
        class="h-auto w-44 rounded-full mx-auto"
        v-bind:class="{
          'border-2 border-pink-600': debg,
        }"
        src="./img/profileFemale.png"
        alt=""
      />
      <img
        v-else
        src="./img/edificio.png"
        alt="profil"
        class="h-auto w-44 rounded-full mx-auto"
        v-bind:class="{
          'border-2 border-blue-400': debg,
        }"
      />
      <a
        @click="activateUpload = !activateUpload"
        class="relative block my-3 cursor-pointer"
      >
        <span
          class="absolute w-7 h-7 my-6 mx-6 transform -translate-x-1/2 bg-white border-2 border-blue-postula2 rounded-full left-2/3 -bottom-2"
          ><svg
            width="20"
            height="20"
            fill="currentColor"
            class="w-6 h-6"
            viewBox="0 0 1792 1792"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M491 1536l91-91-235-235-91 91v107h128v128h107zm523-928q0-22-22-22-10 0-17 7l-542 542q-7 7-7 17 0 22 22 22 10 0 17-7l542-542q7-7 7-17zm-54-192l416 416-832 832h-416v-416zm683 96q0 53-37 90l-166 166-416-416 166-165q36-38 90-38 53 0 91 38l235 234q37 39 37 91z"
            ></path>
          </svg>
        </span>
      </a>
    </div>
    <h1 class="text-gray-900 font-bold text-xl leading-8 my-1 capitalize">
      {{ nombreEmpr.value }}
    </h1>
    <h3 class="text-gray-600 font-semibold text-md leading-6">
      {{ nombreUserEmprs.value + " " + apellidoUserEmprs.value }}
    </h3>
    <h3 class="text-gray-600 font-semibold text-xs leading-6">
      {{ emailUserEmprs.value }}
    </h3>
    <!-- <div class="fixed  w-10 h-11 border-2 border-red-500">
      <VueFileAgent
        :uploadUrl="'https://www.mocky.io/v2/5d4fb20b3000005c111099e3'"
        :uploadHeaders="{}"
        :multiple="true"
        :deletable="true"
        :editable="true"
        :meta="true"
        :accept="'image/*,video/*,.pdf,.zip'"
        :maxSize="'14MB'"
        :maxFiles="8"
        :helpText="'Select files'"
        :errorText="{
          type: 'Please select images, videos, pdf or zip files',
          size: 'You selected a larger file!',
        }"
        :thumbnailSize="120"
        :theme="'list'"
        v-model="fileRecord"
      ></VueFileAgent>
    </div> -->
    <div
      v-if="activateUpload"
      v-bind:class="{
        'border-2 border-green-400': debg,
      }"
      class="flex-wrap w-52 vfa-demo bg-light pt-3"
    >
      <VueFileAgent
        class="upload-block relative w-24"
        ref="vfaDemoRef"
        :uploadHeaders="{}"
        :accept="'image/*'"
        :multiple="false"
        :progress="true"
        :deletable="true"
        :theme="'list'"
        :maxSize="'0,5MB'"
        :maxFiles="1"
        :helpText="'Drag an image file here'"
        :errorText="{
          size: 'This file is too large to be attached',
        }"
        v-model="fileRecord"
      >
        <template v-slot:file-preview="slotProps">
          <div :key="slotProps.index" class="file-row text-sm w-48">
            <button
            v-bind:class="{
        'border-2 border-red-400': debg,
      }"
              type="button"
              class="close remove"
              aria-label="Remove"
              @click="removePic()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <!-- <div
            class="progress"
            :class="{ completed: slotProps.fileRecord.progress() == 100 }"
          >
            <div
              class="progress-bar"
              role="progressbar"
              :style="{ width: slotProps.fileRecord.progress() + '%' }"
            ></div>
          </div> -->
            <!-- <p>{{ slotProps.fileRecord }}</p> -->
            <strong>{{ slotProps.fileRecord.name() }}</strong>
            <span class="text-muted">({{ slotProps.fileRecord.size() }})</span>
          </div>
        </template>
        <template v-slot:file-preview-new>
          <div class="text-left my-3" key="new">
            <a href="" class=""></a>
          </div>
        </template>
        <!--       <template v-slot:after-inner>
        <div class="text-left pt-1">
          <a href="#" class="">Select files</a> or drag & drop here
        </div>
      </template > -->
        <template v-slot:after-outer>
          <div class="w-40">
            <button
              @click="uploadFiles()"
              type="button"
              class="mt-1.5 py-1 px-3 flex w-auto justify-center items-center bg-green-500 hover:bg-green-700 focus:ring-green-500 focus:ring-offset-green-200 text-white w- transition ease-in duration-200 text-center text-sm font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-full"
            >
              <img class="w-7 h-7" src="./img/new-file-front-colorr.png" alt="" />
              <span class="ml-2 font-semibold"> Guardar </span>
            </button>
          </div>
        </template>
      </VueFileAgent>
    </div>
    <!-- <div class="flex container border-2 border-green-700">
      <div
        id="profile-pic-demo"
        v-if="activateUpload"
        class="relative border-4 border-purple-500 w-10 h-11"
      >
        <VueFileAgent
          class="border-2 border-blue-500 ml-16"
          ref="vueFileAgent"
          :theme="'list'"
          :multiple="false"
          :deletable="true"
          :meta="true"
          :accept="'image/*'"
          :maxSize="'5MB'"
          :maxFiles="1"
          :helpText="'Seleccione la imagen a subir'"
          :errorText="{
            type: 'Invalid file type. Only images Allowed',
            size: 'Files should not exceed 5MB in size',
          }"
          @select="filesSelected($event)"
          @beforedelete="onBeforeDelete($event)"
          @delete="fileDeleted($event)"
          v-model="fileRecord"
        >
        </VueFileAgent>
        <div>
          <button
            :disabled="!fileRecordsForUpload.length"
            @click="uploadFiles()"
            type="button"
            class="mt-1.5 py-1 px-3 flex w-auto justify-center items-center bg-green-500 hover:bg-green-700 focus:ring-green-500 focus:ring-offset-green-200 text-white w- transition ease-in duration-200 text-center text-sm font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-full"
            v-bind:class="{ hidden: !fileRecordsForUpload.length }"
          >
            <img class="w-7 h-7" src="./img/new-file-front-colorr.png" alt="" />
            <span class="ml-2 font-semibold"> Guardar </span>
          </button>
        </div>
      </div>
    </div> -->
    <p class="hidden text-sm text-gray-500 hover:text-gray-600 leading-6"></p>
    <ul
      class="bg-gray-100 text-gray-600 hover:text-gray-700 hover:shadow py-2 px-3 mt-3 divide-y rounded shadow-sm"
    >
      <li class="flex items-center py-3">
        <span class="text-sm">Estatus</span>
        <span class="ml-auto"
          ><span class="bg-green-500 py-1 px-2 rounded text-white text-sm font-semibold"
            >Activo</span
          ></span
        >
      </li>
      <li class="flex items-center py-3">
        <span class="text-sm">Miembro desde</span>
        <span class="ml-auto text-sm">
          {{ $store.state.regUserEmprs.empresa[0].createdAt | formatedTime }}
        </span>
      </li>
    </ul>
  </div>
</template>
<style scoped>
.vfa-demo {
  position: relative;
}

.vfa-demo .file-preview-wrapper::before {
  background: transparent;
}

.vfa-demo .file-row {
  position: relative;
  z-index: 15;
  line-height: 24px;
  text-align: left;
  background: #eee;
  margin-bottom: 5px;
  padding: 2px 5px;
}

.vfa-demo .remove {
  float: right;
  margin-top: -3px;
}

.vfa-demo .progress {
  float: right;
  width: 85px;
  height: 10px;
  margin-top: 7px;
  margin-right: 10px;
  background: #fff;
  border: 1px solid #aaa;
}

.vfa-demo .progress.completed {
  display: none;
}

.vfa-demo .drop-help-text {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 2px;
  background: rgba(255, 255, 255, 0.75);
  z-index: 1200;
  font-size: 32px;
  font-weight: bold;
  color: #888;
  align-items: center;
  justify-content: center;
  display: none;
}

.vfa-demo .is-drag-over .drop-help-text {
  display: flex;
}

.vfa-demo .upload-block {
  border: 2px dashed transparent;
  padding: 20px;
  padding-top: 0;
}

.vfa-demo .is-drag-over.upload-block {
  border-color: #aaa;
}

.vfa-demo .vue-file-agent {
  border: 0 !important;
  box-shadow: none !important;
}
</style>
<script>
import axios from "axios";

export default {
  name: "ImagenPerfilyStatusEmpresa",
  data() {
    return {
      //Variables: toggled y limit --> utilizadas en methods, toggle y truncate.
      toggled: false,
      limit: 138,
      nombreEmpr: {
        value: this.$store.state.regUserEmprs.empresa[0].nombreEmpr,
        tempValue: null,
        editing: false,
      },
      nombreUserEmprs: {
        value: this.$store.state.regUserEmprs.nombreUserEmprs,
        tempValue: null,
        editing: false,
      },
      apellidoUserEmprs: {
        value: this.$store.state.regUserEmprs.apellidoUserEmprs,
        tempValue: null,
        editing: false,
      },
      emailUserEmprs: {
        value: this.$store.state.regUserEmprs.emailUserEmprs,
        tempValue: null,
        editing: false,
      },
      fileRecord: [],
      uploadUrl: "",
      uploadHeaders: { Authorization: this.$store.state.myTokToApiComs },
      fileRecordsForUpload: [], // maintain an upload queue
      imageFileLocation: "",
      flag: false,
      flagImgUploaded: false,
      imgUploadedName: "",
      imgUploaded: "",
      activateUpload: false,
      debg: false,
      errorCount: 0,
      imageError: false,
    };
  },
  watch: {
    imageFileLocation: function () {
      this.flag = true;
    },
    flagImgUploaded: function () {
      var axios = require("axios");

      var config = {
        method: "get",
        maxBodyLength: Infinity,
        url: "https://api.postula2.com/imagesTiEmprs",
        headers: {
          Authorization: this.$store.state.myTokToApiComs,
        },
        params: {
          id: this.$store.state.profileImage.image.imageName,
        },
      };

      axios(config).then(function (response) {
        // console.log("respuesta", response);
        // console.log(JSON.stringify(response.data));
        const image = JSON.stringify(response.data);
      });
    },
  },
  methods: {
    removePic: function(){
      // var profilePic = this.fileRecord;
      this.$refs.vfaDemoRef.deleteUpload(this.uploadUrl, this.uploadHeaders, [this.fileRecord]);
      this.fileRecord = [];
      // this.uploaded = false;
    },
    removeFileRecord: function (fileRecord) {
      console.log("llega ",fileRecord)
      return this.$refs.vfaDemoRef.removeFileRecord(fileRecord);
    },
    handleImageError(event) {
      if (event.target.naturalWidth === 0) {
        this.imageError = true;
      }
    },
    volver() {
      this.$router.go(-1);
    },
    forceRerender() {
      this.uploadedImgComponntValue += 1;
    },
    test: async function () {
      var axios = require("axios");

      var config = {
        method: "get",
        maxBodyLength: Infinity,
        url: "https://api.postula2.com/imagesTiEmprs",
        headers: {
          Authorization: this.$store.state.myTokToApiComs,
        },
        params: {
          id: this.$store.state.profileImage.image.encryptedName,
        },
      };

      axios(config).then(function (response) {
        console.log("respuesta", response);
        console.log(JSON.stringify(response.data));
        const image = JSON.stringify(response.data);
      });
    },

    // upload() {
    //   const formData = new FormData();
    //   formData.append("file", document.getElementById("upload").files[0]);

    //   axios
    //     .post("https://api.postula2.com/uploadImage", formData, {
    //       headers: {
    //         Authorization: this.$store.state.myTokToApiComs,
    //       },
    //       params: {
    //         id: $store.state.regUserEmprs.empresa[0].id,
    //       },
    //     })
    //     .then((response) => {
    //       //console.log("holi ", response.data);
    //       this.imageFileLocation = response.data.savedAt;
    //       this.$store.commit("getProfileImage", response.data.savedAt);
    //     });
    // },
    uploadFiles: function () {
      this.uploadUrl =
        "https://api.postula2.com/uploadImageEmpresa?id=" +
        this.$store.state.regUserEmprs.empresa[0].id;
      // Using the default uploader. You may use another uploader instead.
      this.$refs.vfaDemoRef
        .upload(
          this.uploadUrl,
          this.uploadHeaders,
          this.fileRecord,
          this.$store.state.userData.id
        )
        .then((response) => {
          // console.log("respuesta es --> ",response[0].data)
          this.imgUploadedName = response[0].data.encryptedName;
          this.flagImgUploaded = true;
          this.$store.commit({
            type: "getProfileImage",
            flagImgUploaded: true,
            image: response[0].data,
          });
          this.activateUpload = false;
          //this.$store.commit("getProfileImage", response[0].data);
          // this.imageFileLocation = response.data.savedAt;
          // console.log(this.imageFileLocation);
        })
        .finally(() => {
          //   forceRerender();
          this.$store.commit("incrementEmprsImgComponntValue");
        });
      this.fileRecordsForUpload = [];
      // this.imgUploaded =
      //   "https://api.postula2.com/imagesTiEmprs?id=" +
      //   this.$store.state.regUserEmprs.empresa[0].id;
    },
    deleteUploadedFile: function (fileRecord) {
      // Using the default uploader. You may use another uploader instead.
      this.$refs.vfaDemoRef.deleteUpload(this.uploadUrl, this.uploadHeaders, fileRecord);
    },
    filesSelected: function (fileRecordsNewlySelected) {
      var validFileRecords = fileRecordsNewlySelected.filter(
        (fileRecord) => !fileRecord.error
      );
      this.fileRecordsForUpload = this.fileRecordsForUpload.concat(validFileRecords);
    },
    onBeforeDelete: function (fileRecord) {
      var i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        // queued file, not yet uploaded. Just remove from the arrays
        this.fileRecordsForUpload.splice(i, 1);
        var k = this.fileRecord.indexOf(fileRecord);
        if (k !== -1) this.fileRecord.splice(k, 1);
      } else {
        if (confirm("Are you sure you want to delete?")) {
          this.$refs.vfaDemoRef.deleteFileRecord(fileRecord); // will trigger 'delete' event
        }
      }
    },
    fileDeleted: function (fileRecord) {
      var i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        this.fileRecordsForUpload.splice(i, 1);
      } else {
        this.deleteUploadedFile(fileRecord);
      }
    },
    toggle() {
      //CLG("tog");
      this.toggled = !this.toggled;
    },
    truncate(text) {
      if (text.length > this.limit) {
        for (let i = this.limit; i > 0; i--) {
          const currChar = text.charAt(i);
          const prevChar = text.charAt(i - 1);
          const prevCharNotPuncuation =
            prevChar != "," || prevChar != "." || prevChar != ".";
          if (currChar === " " && prevCharNotPuncuation) {
            return text.substring(0, i) + "...";
          }
        }
      } else {
        return text;
      }
    },
    // /////////////////////////////////////////////
  },
  computed: {
    displayTextActivs() {
      return this.toggled ? this.descEmpr.value : this.truncate(this.descEmpr.value);
    },
  },
  filters: {
    formatedTime: function (value) {
      var date = new Date(value);

      return (
        date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear() // +
        // " " +
        //date.getHours() +
        //":" +
        // date.getMinutes() +
        //":" +
        //date.getSeconds()
      );
      ////CLG(formatedTime);
    },
  },
};
</script>

<style scoped>
.border-blue-postula2 {
  --tw-border-opacity: 1;
  border-color: rgba(19, 73, 146, var(--tw-border-opacity));
}
</style>
