<template>
  <div>
    <div
      class="shadow-md rounded-xl bg-gray-100 dark:bg-gray-700 text-center w-full"
    >
      <p
        class="font-bold text-md pt-2 pb-1 text-black dark:text-white border-b-2 border-gray-300"
      >
        Postulados
        <span
          class="text-sm text-gray-500 dark:text-gray-300 dark:text-white ml-2 hidden"
        >
          (05)
        </span>
      </p>
      <ul class="">
        <li
          class="flex items-center text-gray-600 dark:text-gray-200 justify-between py-1.5 border-b-2 border-gray-200 dark:border-gray-800"
        >
          <div class="flex items-center justify-start text-sm">
            <span class="ml-3 mr-2">
              <svg
                width="24"
                height="24"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0)">
                  <path
                    d="M1.75 25.375C1.75 24.6925 3.43 23.8875 4.585 23.625H4.68125L11.6813 21C11.8575 20.9346 12.0079 20.8142 12.1103 20.6566C12.2128 20.499 12.2618 20.3126 12.25 20.125V17.7712C12.2495 17.6063 12.2025 17.4449 12.1142 17.3056C12.026 17.1662 11.9002 17.0547 11.7513 16.9837C10.8549 16.5589 10.0972 15.8887 9.56605 15.0509C9.03488 14.2131 8.75194 13.242 8.75 12.25C8.75 12.0179 8.65781 11.7954 8.49372 11.6313C8.32962 11.4672 8.10706 11.375 7.875 11.375V9.625C8.10706 9.625 8.32962 9.53281 8.49372 9.36872C8.65781 9.20462 8.75 8.98206 8.75 8.75V7C8.75 5.60761 9.30312 4.27226 10.2877 3.28769C11.2723 2.30312 12.6076 1.75 14 1.75C15.3924 1.75 16.7277 2.30312 17.7123 3.28769C18.6969 4.27226 19.25 5.60761 19.25 7V8.75C19.25 8.98206 19.3422 9.20462 19.5063 9.36872C19.6704 9.53281 19.8929 9.625 20.125 9.625V11.375H21.875V9.625C21.8747 9.31812 21.7937 9.01671 21.6401 8.75102C21.4865 8.48533 21.2658 8.26469 21 8.11125V7C21 5.14348 20.2625 3.36301 18.9497 2.05025C17.637 0.737498 15.8565 0 14 0C12.1435 0 10.363 0.737498 9.05025 2.05025C7.7375 3.36301 7 5.14348 7 7V8.11125C6.73423 8.26469 6.51348 8.48533 6.3599 8.75102C6.20633 9.01671 6.12531 9.31812 6.125 9.625V11.375C6.12223 11.6848 6.20177 11.9899 6.3555 12.2589C6.50924 12.5279 6.73165 12.7513 7 12.9062C7.1154 14.0175 7.49282 15.0855 8.10127 16.0225C8.70971 16.9595 9.53181 17.7388 10.5 18.2962V19.5125L4.1125 21.875C3.31625 22.0763 0 23.0737 0 25.375V27.125C0 27.3571 0.0921872 27.5796 0.256282 27.7437C0.420376 27.9078 0.642936 28 0.875 28H13.125V26.25H1.75V25.375Z"
                    fill="black"
                  />
                  <path
                    d="M21 14C19.6155 14 18.2622 14.4105 17.111 15.1797C15.9599 15.9489 15.0627 17.0421 14.5328 18.3212C14.003 19.6003 13.8644 21.0078 14.1345 22.3656C14.4046 23.7235 15.0713 24.9708 16.0503 25.9497C17.0292 26.9287 18.2765 27.5954 19.6344 27.8655C20.9922 28.1356 22.3997 27.997 23.6788 27.4672C24.9579 26.9373 26.0511 26.0401 26.8203 24.889C27.5895 23.7378 28 22.3845 28 21C28 19.1435 27.2625 17.363 25.9498 16.0503C24.637 14.7375 22.8565 14 21 14ZM21 26.25C19.9617 26.25 18.9466 25.9421 18.0833 25.3652C17.2199 24.7883 16.547 23.9684 16.1496 23.0091C15.7523 22.0498 15.6483 20.9942 15.8509 19.9758C16.0535 18.9574 16.5535 18.0219 17.2877 17.2877C18.0219 16.5535 18.9574 16.0534 19.9758 15.8509C20.9942 15.6483 22.0498 15.7523 23.0091 16.1496C23.9684 16.547 24.7883 17.2199 25.3652 18.0833C25.9421 18.9466 26.25 19.9616 26.25 21C26.25 22.3924 25.6969 23.7277 24.7123 24.7123C23.7277 25.6969 22.3924 26.25 21 26.25Z"
                    fill="black"
                  />
                  <path d="M21.875 17.5H20.125V21H21.875V17.5Z" fill="black" />
                  <path
                    d="M21.875 22.75H20.125V24.5H21.875V22.75Z"
                    fill="black"
                  />
                </g>
                <defs>
                  <clipPath id="clip0">
                    <rect width="28" height="28" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </span>
            <span
              v-if="this.assets.postuladosLength"
              class="mx-3 font-medium text-xl text-red-500 dark:text-white"
            >
              {{ this.assets.postuladosLength.global }}
            </span>
            <span
              v-else
              class="mx-3 font-medium text-xl text-red-500 dark:text-white"
            >
              <loadermini>
                <template v-slot:msg>
                  {{ msg }}
                </template>

                <template v-slot:msg1>
                  {{ msg1 }}
                </template>
              </loadermini>
            </span>

            <span class="font-medium text-md- text-black dark:text-white">
              Personas perfiladas
            </span>
          </div>

          <button
            type="button"
            @click="$router.push('mispostulados')"
            class="py-1 px-5 mx-4 flex justify-center items-center text-white transition ease-in duration-200 text-center text-sm font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-2xl w-14"
            v-bind:disabled="btnDisable"
            v-bind:class="{
              'cursor-not-allowed hover:disabled bg-gray-400 border':
                btnDisable,
              'postulado-color hover:bg-green-700 focus:ring-green-500 focus:ring-offset-green-200':
                !btnDisable,
            }"
          >
            Ver
          </button>
        </li>

        <li
          class="flex items-center text-gray-600 dark:text-gray-200 justify-between py-1.5 border-b-2 border-gray-200 dark:border-gray-800"
        >
          <div class="flex items-center justify-start text-sm">
            <span class="ml-3 mr-2">
              <svg
                id="Layer_1"
                enable-background="new 0 0 511.24 511.24"
                width="25px"
                height="25px"
                viewBox="0 0 511.24 511.24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g>
                  <g>
                    <path
                      d="m374.957 144.77h-15v281.29h-281.73v-411.06h154.1v-15h-169.1v441.06h311.73z"
                    />
                    <path
                      d="m374.957 106.694-45.586-45.587-10.607 10.606 35.557 35.557h-84.494v-84.483l38.327 38.326 10.606-10.606-50.506-50.507h-13.427v122.27h120.13z"
                    />
                    <path
                      d="m447.702 70.17h-69.955v15h54.966l.289 411.07h-281.715v-32.68h-15v47.68h311.726z"
                    />
                    <path
                      d="m180.679 248.859-2.531-7.111-14.131 5.031 10.733 30.149 30.148-10.734-5.031-14.131-4.806 1.712 24.06-45.006 15.552 28.82 13.201-7.123-28.814-53.399z"
                    />
                    <path
                      d="m210.795 319.957-4.523 4.522 10.607 10.607 22.629-22.629-22.629-22.628-10.607 10.606 4.522 4.522h-43.638l11.439-21.2-13.201-7.123-23.377 43.323z"
                    />
                    <path
                      d="m273.74 244.627-31.207-7.09-7.09 31.206 14.627 3.323.988-4.349 19.996 37.24h-28.993v15h54.074l-33.55-62.483 7.832 1.78z"
                    />
                    <path
                      d="m341.597 265.35c0-67.547-54.958-122.5-122.51-122.5-67.547 0-122.5 54.953-122.5 122.5 0 37.41 16.771 72.286 46.014 95.686l9.372-11.712c-25.666-20.538-40.386-51.146-40.386-83.974 0-59.276 48.224-107.5 107.5-107.5 59.281 0 107.51 48.224 107.51 107.5s-48.229 107.5-107.51 107.5c-19.582 0-38.748-5.319-55.426-15.382l-7.749 12.844c19.017 11.474 40.862 17.538 63.175 17.538 67.553 0 122.51-54.953 122.51-122.5z"
                    />
                  </g>
                  <g>
                    <path d="m175.581 464.672h15v15h-15z" />
                  </g>
                  <g>
                    <path d="m394.875 464.672h15v15h-15z" />
                  </g>
                  <g>
                    <path d="m394.875 124.672h15v15h-15z" />
                  </g>
                  <g>
                    <path d="m129.49 257.497h15v15h-15z" />
                  </g>
                  <g>
                    <path d="m293.49 257.497h15v15h-15z" />
                  </g>
                  <g>
                    <path
                      d="m206.175 52.375h-15v-15h15zm-45 0h-15v-15h15zm-45 0h-15v-15h15z"
                    />
                  </g>
                </g>
              </svg>
            </span>
            <span
              v-if="this.assets.postuladosLength"
              class="mx-3 font-medium text-xl text-red-500 dark:text-white"
            >
              {{ this.assets.postuladosLength.hoy }}
            </span>
            <span
              v-else
              class="mx-3 font-medium text-xl text-red-500 dark:text-white"
            >
              <loadermini>
                <template v-slot:msg>
                  {{ msg }}
                </template>

                <template v-slot:msg1>
                  {{ msg1 }}
                </template>
              </loadermini>
            </span>
            <span class="font-medium text-md- text-black dark:text-white">
              Personas perfiladas hoy
            </span>
          </div>

          <button
            type="button"
            @click="$router.push('mispostuladoshoy')"
            class="py-1 px-5 mx-4 flex justify-center items-center text-white transition ease-in duration-200 text-center text-sm font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-2xl w-14"
            v-bind:disabled="btnDisable"
            v-bind:class="{
              'cursor-not-allowed hover:disabled bg-gray-400 border':
                btnDisable,
              'postulado-color hover:bg-green-700 focus:ring-green-500 focus:ring-offset-green-200':
                !btnDisable,
            }"
          >
            Ver
          </button>
        </li>

        <li
          class="hidden flex items-center text-gray-600 dark:text-gray-200 justify-between py-1.5 dark:border-gray-800"
        >
          <div class="flex items-center justify-start text-sm">
            <span class="ml-3 mr-2">
              <svg
                width="25px"
                height="19px"
                viewBox="0 0 25 19"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <!-- Generator: Sketch 52.5 (67469) - http://www.bohemiancoding.com/sketch -->
                <title>contact_phone</title>
                <desc>Created with Sketch.</desc>
                <g
                  id="Icons"
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <g
                    id="Rounded"
                    transform="translate(-236.000000, -1307.000000)"
                  >
                    <g
                      id="Communication"
                      transform="translate(100.000000, 1162.000000)"
                    >
                      <g
                        id="-Round-/-Communication-/-contact_phone"
                        transform="translate(136.000000, 142.000000)"
                      >
                        <g>
                          <polygon
                            id="Path"
                            points="0 0 24 0 24 24 0 24"
                          ></polygon>
                          <path
                            d="M22,3 L2,3 C0.9,3 0,3.9 0,5 L0,19 C0,20.1 0.9,21 2,21 L22,21 C23.1,21 23.99,20.1 23.99,19 L24,5 C24,3.9 23.1,3 22,3 Z M8,6 C9.66,6 11,7.34 11,9 C11,10.66 9.66,12 8,12 C6.34,12 5,10.66 5,9 C5,7.34 6.34,6 8,6 Z M14,18 L2,18 L2,17 C2,15 6,13.9 8,13.9 C10,13.9 14,15 14,17 L14,18 Z M17.85,14 L19.24,14 C19.4,14 19.54,14.07 19.64,14.2 L20.74,15.65 C20.89,15.85 20.87,16.13 20.69,16.3 L19.33,17.66 C19.15,17.84 18.85,17.86 18.66,17.7 C17.53,16.74 16.69,15.45 16.28,13.99 C16.1,13.36 16,12.69 16,12 C16,11.31 16.1,10.64 16.28,10 C16.69,8.53 17.53,7.25 18.66,6.29 C18.86,6.12 19.15,6.15 19.33,6.33 L20.69,7.69 C20.87,7.87 20.89,8.15 20.74,8.34 L19.64,9.79 C19.55,9.92 19.4,9.99 19.24,9.99 L17.85,9.99 C17.63,10.62 17.5,11.29 17.5,11.99 C17.5,12.69 17.63,13.37 17.85,14 Z"
                            id="🔹Icon-Color"
                            fill="#1D1D1D"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </span>
            <span class="mx-3 font-medium text-xl text-red-500 dark:text-white">
              NaN
            </span>

            <span class="font-medium text-md- text-black dark:text-white">
              Seleccionado por contactar
            </span>
          </div>

          <button
            type="button"
            class="py-1 px-2.5 mx-4 flex justify-center items-center postulado-color hover:bg-green-700 focus:ring-green-500 focus:ring-offset-green-200 text-white transition ease-in duration-200 text-center text-sm font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-2xl w-14"
          >
            Ver
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import loadermini from "@/components/shared/loadermini.vue";

export default {
  name: "CuadroPostulados",

  components: { loadermini },

  data() {
    return {
      isLoading: true,
      assets: [],
      btnDisable: true,
    };
  },
  mounted() {
    if (this.$store.state.regEmprs.id) {
      // axios
      //   .get(
      //     `https://api.postula2.com/empresa/${this.$store.state.regEmprs.id}/anuncios`
      //   )
      let data = JSON.stringify({
        idOwner: this.$store.state.regEmprs.id,
      });
      // console.log("IF1 - data a enviar id es", data);
      axios({
        method: "POST",
        url: `https://api.postula2.com/postuladstotals/`,
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.myTokToApiComs,
        },
        data: data,
      })
        .then((res) => {
          //Perform Success Action
          //alert("Usuario no encontrado, debe registrarse");
          //console.log("respuesta obtenida", res.data);
          this.assets = res.data.resp;
          this.$store.commit("getPerfilados", res.data.resp.postulados.global);
          this.$store.commit("getPerfiladosHoy", res.data.resp.postulados.hoy);
          this.$store.commit(
            "getAnuncsSinPostulads",
            res.data.resp.anunciosSinPostulados
          );
          //  console.log("assetsCuadro", this.assets);
          this.btnDisable = false;
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG("mission pelaste bolas");
          //CLG(error);
        })
        .finally(() => {
          //Perform action in always
        });
    } else {
      if (this.$store.state.regUserEmprs.empresa) {
        // axios
        //   .get(
        //     `https://api.postula2.com/empresa/${this.$store.state.regUserEmprs.empresa[0].id}/anuncios`

        //   ) //https://api.postula2.com/empresa/${this.$store.state.regUserEmprs.empresa[0].id}/anuncios}
        let data = JSON.stringify({
          idOwner: this.$store.state.regUserEmprs.empresa[0].id,
        });
        //console.log("IF2 - data a enviar id es", data);
        axios({
          method: "POST",
          url: `https://api.postula2.com/postuladstotals/`,
          headers: {
            "Content-Type": "application/json",
            Authorization: this.$store.state.myTokToApiComs,
          },
          data: data,
        })
          .then((res) => {
            //Perform Success Action
            //alert("Usuario no encontrado, debe registrarse");
            //console.log("respuesta obtenida", res.data);
            this.$store.commit(
              "getPerfilados",
              res.data.resp.postulados.global
            );
            this.$store.commit(
              "getPerfiladosHoy",
              res.data.resp.postulados.hoy
            );
            this.$store.commit(
              "getAnuncsSinPostulads",
              res.data.resp.anunciosSinPostulados
            );
            this.assets = res.data.resp;
            this.btnDisable = false;
            //console.log("assetsCuadro", this.assets); resp.anunciosSinPostulados
          })
          .catch(() => {
            // error.response.status Check status code
            //CLG("mission pelaste bolas");
            //CLG(error);
          })
          .finally(() => {
            //Perform acton in always
          });
      }
    }
  },
};
</script>

<style scoped>
.postulado-color {
  background-color: #134992;
}
</style>
