<template>
  <div class="flex-col bg-gray-200 rounded-lg dark:bg-gray-800">
    <div
      class="self-center mb-4 text-gray-800 sm:text-md dark:text-white w-full text-left"
    >
      <span class="text-base font-bold"> Nombre del anuncio: </span>
      <span class="text-lg font-semibold">{{
        this.dataAnuncio.tituloAnunc
      }}</span>
    </div>

    <div class="flex flex-col">
      <FormulateForm @submit="onSubmit">
        <div class="flex flex-col md:flex-row md:space-x-4">
          <div class="mb-1 flex-1 pt-1 pb-1">
            <label
              for="price"
              class="block text-xs font-medium text-gray-700 text-left ml-1"
            >
              <strong> Nombre del anuncio</strong>
            </label>
            <FormulateInput
              type="text"
              name="nombre"
              v-model.lazy="dataAnuncio.tituloAnunc"
              placeholder="Puesto / Titulo del aviso"
              validation="required|min:4|matches:/[A-Za-z0-9\s\á\é\í\ó\ú\-]+$/"
              class="text-left bg-white rounded-lg w-full lg:w-72 xl:w-96"
              :validation-messages="{
                min: 'Debe tener al menos 4 caracteres',
                matches: 'Este campo solo acepta letras',
                required: 'Este campo es obligatorio',
              }"
            />
          </div>
          <div class="relative">
            <input
              hidden
              type="text"
              class="rounded-lg border-transparent appearance-none border border-gray-300 w-96 py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-sm focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
              name="vacantes"
              placeholder="Vacantes disponibles"
            />
          </div>
          <div class="mb-1 flex-1 pt-1 pb-1">
            <label
              for="price"
              class="block text-xs font-medium text-gray-700 text-left ml-1"
            >
              <strong>Fecha de finalización</strong>
            </label>
            <FormulateInput
              v-model.lazy.trim="dataAnuncio.fechFinalzcn"
              type="date"
              class="bg-white rounded-lg text-left w-full lg:w-42"
              name="sample"
              placeholder="Fecha"
              validation="required|after:2022-07"
              min="2022-07"
              :validation-messages="{
                after: 'Debe escoger un mes dentro del rango',
                required: 'Este campo es obligatorio',
              }"
            />
          </div>
        </div>

        <div class="flex flex-col mb-2">
          <div class="relative">
            <label
              for="price"
              class="block text-xs font-medium text-gray-700 text-left ml-1"
            >
              <strong> Descripción del cargo </strong>
            </label>

            <FormulateInput
              type="textarea"
              v-model.lazy="dataAnuncio.descrpAnunc"
              class="bg-white"
              :value="dataAnuncio.descrpAnunc"
              rows="8"
              cols="50"
              validation="required|max:1200,length"
              validation-name="comment"
              error-behavior="value"
              :help="`Maximo 1200 caracteres. ${
                1200 - dataAnuncio.descrpAnunc.length
              } restantes.`"
              :validation-messages="{
                required: 'Debes rellenar este campo',
                max: 'Texto debe ser menor a 1200 caracteres',
              }"
              errors-class="text-left ml-1"
              help-class="text-left ml-1 text-xs font-semibold"
            />
          </div>
        </div>

        <div class="hidden flex flex-wrap -mx-3 mt-5 mb-2">
          <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
            <div class="relative">
              <select
                class="block appearance-none w-full bg-gray-100 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-sm"
                name="pais"
                placeholder="País"
                v-model.lazy="dataLocalidadAnunc.pais"
              >
                <option disabled value="">País</option>
                <option>Venezuela</option>
              </select>
              <div
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
              >
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  />
                </svg>
              </div>
            </div>
          </div>

          <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
            <div class="relative">
              <select
                class="block appearance-none w-full bg-gray-100 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-sm"
                name="estado"
                aria-placeholder="Estado"
                v-model.lazy="dataLocalidadAnunc.estado"
              >
                <option disabled value="">Estado</option>
                <option>Dtto Capital</option>
                <option>Miranda</option>
              </select>
              <div
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
              >
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
            <div class="relative">
              <select
                class="block appearance-none w-full bg-gray-100 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-sm"
                name="ciudad"
                v-model.lazy="dataLocalidadAnunc.ciudad"
              >
                <option disabled value="">Ciudad</option>
                <option>Dtto Capital</option>
                <option>Miranda</option>
              </select>
              <div
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
              >
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>

        <div class="hidden flex flex-col mb-1">
          <div class="relative">
            <label class="text-gray-700" for="name">
              <textarea
                class="flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 rounded-lg text-sm focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                placeholder="Dirección"
                v-model.lazy="dataLocalidadAnunc.direccion"
                name="comment"
                rows="2"
                cols="40"
              >
              </textarea>
            </label>
          </div>
        </div>

        <div class="flex flex-wrap -mx-3 mt-2 mb-2">
          <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
            <div class="relative">
              <label
                for="price"
                class="block text-xs font-medium text-gray-700 text-left ml-1"
              >
                <strong> Area </strong>
              </label>
              <select
                class="block appearance-none w-full bg-gray-100 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-lg leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-sm"
                name="areapuesto"
                v-model.lazy="dataAnuncio.area"
              >
                <option disabled value="">Area</option>
                <option>Abastecimiento y Logística</option>
                <option>Administración, Contabilidad y Finanzas</option>
                <option>Aduana y Comercio Exterior</option>
                <option>
                  Atención al Cliente, Call Center y Telemarketing
                </option>
                <option>Comercial, Ventas y Negocios</option>
                <option>
                  Comunicación, Relaciones Institucionales y Públicas
                </option>
                <option>Diseño</option>
                <option>Educación, Docencia e Investigación</option>
                <option>Enfermería</option>
                <option>Gastronomía y Turismo</option>
                <option>Gerencia y Dirección General</option>
                <option>Ingeniería Civil y Construcción</option>
                <option>Ingenierías</option>
                <option>Jóvenes Profesionales</option>
                <option>Legales</option>
                <option>Marketing y Publicidad</option>
                <option>Minería, Petróleo y Gas</option>
                <option>Oficios y Otros</option>
                <option>Pasantía / Trainee</option>
                <option>Producción y Manufactura</option>
                <option>Recursos Humanos y Capacitación</option>
                <option>Salud, Medicina y Farmacia</option>
                <option>Secretarias y Recepción</option>
                <option>Seguros</option>
                <option>Tecnología, Sistemas y Telecomunicaciones</option>
              </select>
              <div
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center pt-4 px-2 text-gray-700"
              >
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  />
                </svg>
              </div>
            </div>
          </div>

          <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
            <div class="relative">
              <label
                for="price"
                class="block text-xs font-medium text-gray-700 text-left ml-1"
              >
                <strong> Jerarquía </strong>
              </label>
              <select
                class="block appearance-none w-full bg-gray-100 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-lg leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-sm"
                name="jerarquia"
                v-model.lazy="dataAnuncio.jerarquia"
              >
                <option disabled value="" class="text-gray-400">
                  Jerarquía
                </option>

                <option value="Senior / Semi-Senior">
                  Senior / Semi-Senior
                </option>

                <option value="Junior">Junior</option>

                <option value="Gerencia/Alta Gerencia/Dirección">
                  Gerencia / Alta Gerencia / Dirección
                </option>

                <option value="Jefe / Supervisor / Responsable">
                  Jefe / Supervisor / Responsable
                </option>

                <option value="Otro">Otro</option>

                <option value="Primer Empleo">Primer Empleo</option>
              </select>
              <div
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center pt-4 px-2 text-gray-700"
              >
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
            <label
              for="price"
              class="block text-xs font-medium text-gray-700 text-left ml-1"
            >
              <strong> Jornada </strong>
            </label>
            <div class="relative">
              <select
                class="block appearance-none w-full bg-gray-100 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-lg leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-sm"
                id="Jornada"
                v-model.lazy="dataAnuncio.modalidtrabj"
              >
                <option disabled value="" class="text-gray-400">Jornada</option>
                <option value="Tiempo completo">Tiempo completo</option>
                <option value="Desde casa">Desde casa</option>
                <option value="Por horas">Por horas</option>
                <option value="Medio tiempo">Medio tiempo</option>
                <option value="Beca/Prácticas">Beca/Prácticas</option>
                <option value="Diurno">Diurno</option>
                <option value="Mixto">Mixto</option>
                <option value="Nocturno">Nocturno</option>
              </select>
              <div
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
              >
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-wrap -mx-3 mb-2 mt-4">
          <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
            <div class="relative">
              <label
                for="price"
                class="block text-xs font-medium text-gray-700 text-left ml-1"
              >
                <strong> Tipo de empleo </strong>
              </label>
              <select
                class="block appearance-none w-full bg-gray-100 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-lg leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-sm"
                name="tipoempleo"
                v-model.lazy="dataAnuncio.tipoempleo"
              >
                <option disabled value="">Tipo de empleo</option>
                <option value="Contrato a tiempo indefinido">
                  Contrato a tiempo indefinido
                </option>
                <option value="Contrato a tiempo determinado">
                  Contrato a tiempo determinado
                </option>
                <option value="Contrato de obra o labor">
                  Contrato de obra o labor
                </option>
                <option value="Otro tipo de contrato">
                  Otro tipo de contrato
                </option>
              </select>
              <div
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center pt-4 px-2 text-gray-700"
              >
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  />
                </svg>
              </div>
            </div>
          </div>

          <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
            <div class="relative">
              <label
                for="price"
                class="block text-xs font-medium text-gray-700 text-left ml-1"
              >
                <strong> Oferta salarial </strong>
              </label>
              <input
                type="text"
                class="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-lg leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-sm"
                name="ofertsalarl"
                v-on:keypress="numbersOnly"
                v-model="monto"
                placeholder="Oferta Salarial"
              />
            </div>
          </div>
          <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
            <div class="relative">
              <label
                for="price"
                class="block text-xs font-medium text-gray-700 text-left ml-1"
              >
                <strong> Moneda </strong>
              </label>
              <select
                class="block appearance-none w-full bg-gray-100 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-lg leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-sm"
                name="moneda"
                v-model="moneda"
              >
                <option disabled value="">Moneda</option>
                <option value="Bs">(Bs) Bolivar</option>
                <option value="$">($) Dolar</option>
              </select>
              <div
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center pt-4 px-2 text-gray-700"
              >
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div class="grid grid-cols-3 grid-flow-row-dense gap-4 ml-1 mt-2">
          <div>
            <div class="text-left">
              <p class="text-xs font-semibold">
                Trabajo apto para discapacitados
              </p>
            </div>
            <div class="relative mr-2 select-none text-left mt-2">
              <label class="inline-flex items-center mx-4">
                <input
                  type="radio"
                  value="true"
                  name="discap"
                  v-model="dataAnuncio.pDiscapacitds"
                  class="h-4 w-4 text-red-600"
                />
                <span class="ml-2 text-gray-700 text-sm"> Si </span>
              </label>
              <label class="inline-flex items-center mx-4">
                <input
                  type="radio"
                  value="false"
                  name="discap"
                  v-model="dataAnuncio.pDiscapacitds"
                  class="h-4 w-4 text-red-600"
                />
                <span class="ml-2 text-gray-700 text-sm"> No </span>
              </label>
            </div>
          </div>
          <!-- columna que completa el grid -->
          <div class="col-span-3">
            <div>
              <p class="text-left text-xs font-semibold">Tipo de anuncio</p>
            </div>
            <div class="text-left mx-4 mt-2">
              <label class="inline-flex items-center">
                <input
                  type="radio"
                  value="normal"
                  name="tipoAnunc"
                  v-model="dataAnuncio.tipoAnunc"
                  class="h-4 w-4 text-red-600"
                />
                <span class="mr-4 ml-2 text-gray-700 text-sm"> Normal </span>
              </label>
              <label class="inline-flex items-center">
                <input
                  type="radio"
                  value="destacado"
                  name="tipoAnunc"
                  v-model="dataAnuncio.tipoAnunc"
                  class="h-4 w-4 md:ml-2 text-red-600"
                />
                <span class="mr-4 ml-2 text-gray-700 text-sm"> Destacado </span>
              </label>
              <label class="inline-flex items-center">
                <input
                  type="radio"
                  value="premium"
                  name="tipoAnunc"
                  v-model="dataAnuncio.tipoAnunc"
                  class="h-4 w-4 md:ml-2 text-red-600"
                />
                <span class="mr-4 ml-2 text-gray-700 text-sm"> Premium </span>
              </label>
            </div>

          </div>
        </div>

        <!-- columna que completa el grid -->
        <!-- ... -->
        <div class="text-left">
          <div class="text-left md:mt-3 ml-1 pt-3 md:pt-0">
            <p class="text-xs font-semibold">
              Ocultar <u>nombre de Empresa</u>
            </p>
          </div>
          <div
            class="relative inline-block w-10 mr-2 align-middle select-none border-2 border-gray-400 rounded-full px-5 mx-4 mt-2 md:mt-3"
            v-bind:class="{
              'border-2 border-green-500':
                dataAnuncio.empleadorAnonimo == 'true',
            }"
          >
            <input
              v-model="dataAnuncio.empleadorAnonimo"
              type="checkbox"
              true-value="true"
              false-value="false"
              name="toggle"
              id="empleadorAnonimo"
              class="checked:bg-green-500 outline-none focus:outline-none right-4 checked:right-0 duration-200 ease-in absolute block w-6 h-6 rounded-full bg-gray-400 border-4 appearance-none cursor-pointer"
            />
            <label
              for="Activado"
              class="block h-6 overflow-hidden bg-gray-300 rounded-full cursor-pointer"
            >
            </label>
          </div>
          <!-- columna que completa el grid -->
          <div></div>
          <!-- ... -->
          <div class="text-left mt-2 md:mt-3 ml-1 pt-3 md:pt-0">
            <p class="text-xs font-semibold">
              Mostrar <u> oferta salarial </u>
            </p>
          </div>
          <div
            class="relative inline-block w-10 mr-2 align-middle select-none border-2 border-gray-400 rounded-full px-5 mx-4 mt-2 md:mt-3"
            v-bind:class="{
              'border-2 border-green-500': dataAnuncio.salarioVisible == 'true',
            }"
          >
            <input
              v-model="dataAnuncio.salarioVisible"
              type="checkbox"
              true-value="true"
              false-value="false"
              name="toggle"
              id="salarioVisible"
              class="checked:bg-green-500 outline-none focus:outline-none right-4 checked:right-0 duration-200 ease-in absolute block w-6 h-6 rounded-full bg-gray-400 border-4 appearance-none cursor-pointer"
            />
            <label
              for="Activado"
              class="block h-6 overflow-hidden bg-gray-300 rounded-full cursor-pointer"
            >
            </label>
          </div>
        </div>

        <div class="mb-3 hidden">
          <div class="relative mr-2">
            <div class="flex items-center gap-8">
              <p class="text-sm">Recibir copia de CV via email</p>

              <label class="inline-flex items-center">
                <input
                  type="radio"
                  value="true"
                  name="cvemailcopy"
                  v-model="dataAnuncio.cvemailcopy"
                  class="h-4 w-4 text-red-600"
                />
                <span class="ml-2 text-gray-700"> Si </span>
              </label>
              <label class="inline-flex items-center">
                <input
                  type="radio"
                  value="false"
                  name="cvemailcopy"
                  v-model="dataAnuncio.cvemailcopy"
                  class="h-4 w-4 text-red-600"
                />
                <span class="ml-2 text-gray-700"> No </span>
              </label>
            </div>
          </div>
        </div>
        <div class="text-left">
          <div
            class="flex text-justify mt-2 text-xs font-semibold max-w-max overflow-visible"
          >
            <span
              v-if="dataAnuncio.empleadorAnonimo == 'true'"
              class="bg-green-300 p-2 rounded-lg text-xss transition ease-in-out delay-150 duration-300 dialog"
            >
              ACTIVADO &#8702;
              <i class="text-red-500 text-xs">"Empresa Anonima"</i>: Al
              habilitar esta opción este anuncio no mostrará el nombre de tu
              empresa.</span
            >
          </div>
          <div
            class="flex text-justify mt-2 text-xs font-semibold max-w-max overflow-visible"
          >
            <span
              v-if="dataAnuncio.salarioVisible == 'true'"
              class="bg-green-300 p-2 rounded-lg text-xss"
            >
              ACTIVADO &#8702;
              <i class="text-red-500 text-xs">"Oferta Visible"</i>: Al habilitar
              esta opción este anuncio mostrará el paquete salarial ofrecido.
            </span>
            <span v-else class="bg-green-300 p-2 rounded-lg text-xss">
              ACTIVADO &#8702;
              <i class="text-red-500 text-xs">Oferta Salarial: "A convenir"</i>:
              Por defecto este anuncio no mostrará el paquete salarial ofrecido.
            </span>
          </div>
        </div>
        <div class="flex flex-wrap justify-center md:justify-end mt-8">
          <div class="my-1 mr-1 hidden">
            <button
              class="flex items-center p-3 ml-2 transition ease-in duration-200 uppercase rounded-full bg-yellow-400 hover:bg-gray-800 hover:text-white border-1 border-yellow-600 focus:outline-none"
            >
              <svg
                width="20"
                height="20"
                fill=""
                viewBox="0 0 2304 1792"
                class=""
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1728 448l-384 704h768zm-1280 0l-384 704h768zm821-192q-14 40-45.5 71.5t-71.5 45.5v1291h608q14 0 23 9t9 23v64q0 14-9 23t-23 9h-1344q-14 0-23-9t-9-23v-64q0-14 9-23t23-9h608v-1291q-40-14-71.5-45.5t-45.5-71.5h-491q-14 0-23-9t-9-23v-64q0-14 9-23t23-9h491q21-57 70-92.5t111-35.5 111 35.5 70 92.5h491q14 0 23 9t9 23v64q0 14-9 23t-23 9h-491zm-181 16q33 0 56.5-23.5t23.5-56.5-23.5-56.5-56.5-23.5-56.5 23.5-23.5 56.5 23.5 56.5 56.5 23.5zm1088 880q0 73-46.5 131t-117.5 91-144.5 49.5-139.5 16.5-139.5-16.5-144.5-49.5-117.5-91-46.5-131q0-11 35-81t92-174.5 107-195.5 102-184 56-100q18-33 56-33t56 33q4 7 56 100t102 184 107 195.5 92 174.5 35 81zm-1280 0q0 73-46.5 131t-117.5 91-144.5 49.5-139.5 16.5-139.5-16.5-144.5-49.5-117.5-91-46.5-131q0-11 35-81t92-174.5 107-195.5 102-184 56-100q18-33 56-33t56 33q4 7 56 100t102 184 107 195.5 92 174.5 35 81z"
                ></path>
              </svg>
            </button>
            <label for="">Guardar</label>
          </div>
          <loader v-if="isLoading">
            <template v-slot:msg>
              {{ msg }}
            </template>

            <template v-slot:msg1>
              {{ msg1 }}
            </template>
          </loader>
          <div class="flex mx-4">
            <button
              @click="onSubmit"
              type="button"
              class="uppercase py-1.5 pl-4 pr-3 rounded-md flex justify-center items-center focus:ring-postul2 focus:ring-offset-color-postul2 transition ease-in duration-200 text-center text-sm text-black font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 border-postul2 hover:border-postul2 hover:text-postul2 hover:border-transparent hover:text-teal border-2"
            >
              Publicar
              <span class="ml-2">
                <svg
                  xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
                  xmlns="http://www.w3.org/2000/svg"
                  height="24"
                  width="24"
                  version="1.1"
                  xmlns:cc="http://creativecommons.org/ns#"
                  xmlns:dc="http://purl.org/dc/elements/1.1/"
                >
                  <g transform="translate(0 -1028.4)">
                    <path
                      d="m22 12c0 5.523-4.477 10-10 10-5.5228 0-10-4.477-10-10 0-5.5228 4.4772-10 10-10 5.523 0 10 4.4772 10 10z"
                      transform="translate(0 1029.4)"
                      fill="#27ae60"
                    />
                    <path
                      d="m22 12c0 5.523-4.477 10-10 10-5.5228 0-10-4.477-10-10 0-5.5228 4.4772-10 10-10 5.523 0 10 4.4772 10 10z"
                      transform="translate(0 1028.4)"
                      fill="#2ecc71"
                    />
                    <path
                      d="m16 1037.4-6 6-2.5-2.5-2.125 2.1 2.5 2.5 2 2 0.125 0.1 8.125-8.1-2.125-2.1z"
                      fill="#27ae60"
                    />
                    <path
                      d="m16 1036.4-6 6-2.5-2.5-2.125 2.1 2.5 2.5 2 2 0.125 0.1 8.125-8.1-2.125-2.1z"
                      fill="#ecf0f1"
                    />
                  </g>
                </svg>
              </span>
            </button>
          </div>

          <div class="my-1 hidden">
            <button
              class="flex items-center p-3 ml-3 transition ease-in duration-200 uppercase rounded-full hover:bg-gray-800 hover:text-white border-1 border-red-600 focus:outline-none bg-red-400"
            >
              <svg
                width="20"
                height="20"
                fill="currentColor"
                viewBox="0 0 2304 1792"
                class=""
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1728 448l-384 704h768zm-1280 0l-384 704h768zm821-192q-14 40-45.5 71.5t-71.5 45.5v1291h608q14 0 23 9t9 23v64q0 14-9 23t-23 9h-1344q-14 0-23-9t-9-23v-64q0-14 9-23t23-9h608v-1291q-40-14-71.5-45.5t-45.5-71.5h-491q-14 0-23-9t-9-23v-64q0-14 9-23t23-9h491q21-57 70-92.5t111-35.5 111 35.5 70 92.5h491q14 0 23 9t9 23v64q0 14-9 23t-23 9h-491zm-181 16q33 0 56.5-23.5t23.5-56.5-23.5-56.5-56.5-23.5-56.5 23.5-23.5 56.5 23.5 56.5 56.5 23.5zm1088 880q0 73-46.5 131t-117.5 91-144.5 49.5-139.5 16.5-139.5-16.5-144.5-49.5-117.5-91-46.5-131q0-11 35-81t92-174.5 107-195.5 102-184 56-100q18-33 56-33t56 33q4 7 56 100t102 184 107 195.5 92 174.5 35 81zm-1280 0q0 73-46.5 131t-117.5 91-144.5 49.5-139.5 16.5-139.5-16.5-144.5-49.5-117.5-91-46.5-131q0-11 35-81t92-174.5 107-195.5 102-184 56-100q18-33 56-33t56 33q4 7 56 100t102 184 107 195.5 92 174.5 35 81z"
                ></path>
              </svg>
            </button>
            <label for="">Despublicar</label>
          </div>
        </div>
      </FormulateForm>
    </div>
  </div>
</template>
<style scoped>
.text-xss {
  font-size: 0.685rem /* 10px */;
  line-height: 1rem /* 16px */;
}
</style>
<script>
import axios from "axios";
import loader from "@/components/shared/loader.vue";

export default {
  name: "anuncio",
  components: {
    loader,
  },
  created() {
    if (this.$store.state.regEmprs.id) { 
      this.dataAnuncio.owner = this.$store.state.regEmprs.id;
      //CLG("entro por el registro ", this.dataAnuncio.owner);
    } //regUserEmprs.empresa[0].id
    else {
      if (this.$store.state.regUserEmprs.empresa) {
        this.dataAnuncio.owner = this.$store.state.regUserEmprs.empresa[0].id;
        //CLG("entro por el login ", this.dataAnuncio.owner);
      } else {
        alert("Debe iniciar sesión para crear un nuevo anuncio");
        this.$router.push({
          name: "loginEmprs",
        });
        return;
      }
    }
  },
  methods: {
    numbersOnly(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    onSubmit() {
      this.isLoading = true;
      //CLG("ta llegando oferta ", this.dataAnuncio.ofertsalarl);
      //CLG("ta llegando monto ", this.monto);
      //CLG("ta llegando moneda ", this.moneda);

      this.dataAnuncio.ofertsalarl = this.monto + "" + this.moneda;
      //CLG("ta llegando oferta222 ", this.dataAnuncio.ofertsalarl);
      //CLG("anuncio ",this.dataAnuncio)
      axios({
        method: "POST",
        //url: `https://api.postula2.com/empresa/${this.$store.state.regUserEmprs.empresa[0].id}/anuncios`,
        url: "https://api.postula2.com/anuncioNew",
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.myTokToApiComs,
        },
        data: this.dataAnuncio,
      })
        // axios
        //   .post("https://api.postula2.com/anuncio", this.dataAnuncio)
        .then((res) => {
          //Perform Success Action
          //CLG("mission acomplished");
          //CLG(res.data.id);
          this.dataPostuldsAnuncio.owner = res.data.id;
          this.msg = "Anuncio creado con exito... Volviendo al menu principal";
          //this.$store.commit("getflagLogin", redFlag);
          this.$store.commit({
            type: "getflagLogin",
            greenFlag: true,
          });
          setTimeout(() => {
            this.isLoading = false;
            (this.dataAnuncio.tituloAnunc = ""),
              (this.dataAnuncio.descrpAnunc = ""),
              (this.dataAnuncio.fechFinalzcn = ""),
              (this.dataAnuncio.area = ""),
              (this.dataAnuncio.jerarquia = ""),
              (this.dataAnuncio.tipoempleo = ""),
              (this.dataAnuncio.tipoAnunc = ""),
              (this.dataAnuncio.modalidtrabj = ""),
              (this.dataAnuncio.ofertsalarl = ""),
              (this.dataAnuncio.pDiscapacitds = ""),
              (this.dataAnuncio.empleadorAnonimo = ""),
              (this.dataAnuncio.salarioVisible = ""),
              (this.monto = ""),
              (this.moneda = ""),
              this.$store.commit({
                type: "getflagLogin",
                greenFlag: null,
              });
            // this.$router.go(-1);
          }, 2500);
        })
        .catch(() => {
          // error.response.status Check status code
          if (
            !this.dataAnuncio.tituloAnunc ||
            !this.dataAnuncio.descrpAnunc ||
            !this.dataAnuncio.fechFinalzcn ||
            !this.dataAnuncio.area ||
            !this.dataAnuncio.jerarquia ||
            !this.dataAnuncio.tipoempleo ||
            !this.dataAnuncio.tipoAnunc ||
            !this.dataAnuncio.modalidtrabj ||
            !this.dataAnuncio.ofertsalarl ||
            !this.dataAnuncio.pDiscapacitds
          ) {
            this.msg1 =
              "Uno o más campos están vacíos, verifique e intente nuevamente";
          } else
            this.msg1 =
              "¡Verifique la información ingresada e intente nuevamente!";
          //this.$store.commit("getflagLogin", redFlag);
          this.$store.commit({
            type: "getflagLogin",
            redFlag: true,
          });
          setTimeout(() => {
            this.isLoading = false;
            this.$store.commit({
              type: "getflagLogin",
              redFlag: null,
            });
          }, 3000);
        })
        .finally(() => {});
    },
  },
  data() {
    return {
      isLoading: false,
      msg: null,
      msg1: null,

      moneda: "",
      monto: "",
      dataPostuldsAnuncio: {
        owner: null,
      },
      dataAnuncio: {
        tituloAnunc: "",
        //cantVacnts: "",
        descrpAnunc: "",
        fechFinalzcn: "",
        area: "",
        jerarquia: "",
        tipoempleo: "",
        tipoAnunc: "",
        modalidtrabj: "",
        ofertsalarl: this.monto + this.moneda,
        pDiscapacitds: "",
        cvemailcopy: "",
        anuncActivo: true,
        owner: null,
        empleadorAnonimo: "false",
        salarioVisible: "false",
      },
      dataLocalidadAnunc: {
        pais: "",
        estado: "",
        ciudad: "",
        direccion: "",

        municipio: "",
      },
    };
  },
};
</script>
