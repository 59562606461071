<template>
  <div class="p-4 bg-white shadow-lg rounded-2xl w-auto dark:bg-gray-800">
    <div class="">
      <div class="rounded overflow-hidden flex flex-col items-center">
        <img
          class="w-16 rounded-full"
          src="../../components/img/prfImgMale.jpg"
          alt="Imagen de perfil"
        />
        <div class="text-center">
          <div class="font-bold text-xl mb-0">
            <p
              class="text-sm font-thin text-gray-800 dark:text-white capitalize mt-1"
            >
              {{ assets.nombreUser + " " + assets.apellidoUser }}
            </p>
            <p class="text-xs text-gray-600 lowercase mt-1">
              {{ assets.emailUser }}
            </p>
            <p class="text-xs font-semibold text-gray-400 capitalize mt-1">
              {{ obtenerNvlEduc() }}
            </p>
          </div>
        </div>
        <div class="flex items-center mt-1">
          <img
            src="../../components/img/phone.png"
            alt="location"
            class="w-4"
          />
          <p class="text-gray-700 text-sm">
            <span class="ml-1 font-thin text-black dark:text-indigo-500">
              {{ assets.telf1 }}
            </span>
          </p>
          <div class="flex items-center ml-2">
            <img
              src="../../components/img/location.png"
              alt="location"
              class="w-5"
            />
            <p
              class="text-xs font-semibold dark:text-white capitalize"
              :class="{
                'text-green-600': tieneCiudad(),
                'text-yellow-600': !tieneCiudad(),
              }"
            >
              {{ obtenerIdCiudad() }}
            </p>
          </div>
        </div>
      </div>

      <div class="">
        <div class="flex flex-col justify-center"></div>
      </div>
    </div>
    <div class="flex items-center justify-between gap-4 mt-2">
      <button
        type="button"
        class="w-1/2 px-4 py-2 text-base bg-white border rounded-lg text-grey-500 hover:bg-gray-200 hidden"
      >
        Chat
      </button>
      <button
        v-on:click="setIdAndPrueba(assets.owner)"
        type="button"
        class="w-full px-4 py-2 text-base text-white colorPost2_btn border rounded-lg hover:bg-gray-700 ml-auto"
      >
        Ver información completa
      </button>
    </div>
    <!--//////////////////////// showModalVerMs ////////////////////////-->
    <div>
      <div
        v-if="showModalVerMs"
        class="overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex"
      >
        <div class="relative w-full max-h-full my-6 mx-auto max-w-6xl">
          <!--content-->
          <div
            class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
          >
            <!--header-->
            <div
              class="flex items-start justify-between lg:mt-2 px-7 py-2 border-b border-solid border-blueGray-200 rounded-t"
            >
              <h3 class="text-2xl">
                Anuncio: {{ $store.state.anuncs.tituloAnunc }}
              </h3>
              <button
                class="ml-auto border-0 text-black opacity-90 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                v-on:click.prevent="toggleModalVerMs()"
              >
                <span
                  class="text-black opacity-80 h-6 w-6 text-3xl block outline-none focus:outline-none"
                >
                  ×
                </span>
              </button>
            </div>
            <!--body-->
            <loader v-if="isLoading && !$store.state.userData" class="">
              <template v-slot:msg>
                {{ msg }}
              </template>

              <template v-slot:msg1>
                {{ msg1 }}
              </template>
            </loader>
            <div v-if="$store.state.userData" class="relative p-2 flex-auto">
              <personDataCv
                :persona="persona"
                :userData="userData"
              ></personDataCv>
            </div>
            <!--footer-->
            <div
              class="flex items-center justify-end px-6 py-2 border-t border-solid border-blueGray-200 rounded-b"
            >
              <button
                class="text-red-500 bg-transparent border border-solid border-red-500 hover:bg-red-500 hover:text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                v-on:click="toggleModalVerMs()"
              >
                Cerrar
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="showModalVerMs"
        class="opacity-25 fixed inset-0 z-40 bg-black"
      ></div>
    </div>
    <!--//////////////////////// /showModalVerMs ////////////////////////-->
  </div>
</template>

<script>
import loader from "@/components/shared/loader.vue";
import personDataCv from "@/components/personDataCvParaEmprs.vue";

import axios from "axios";

export default {
  name: "trPostuladoEnAnuncio",
  components: {
    loader,
    personDataCv,
  },
  props: {
    persona: {},
    userData: {},
    assets: {},
  },
  data() {
    return {
      hidePane2: "",
      test: "pruebas2",
      //assets: [],
      postulads: [],
      //personas: [],
      idAssets: null,
      idAn: "",
      anuncio: {},
      nmbre: "",
      showModalVerMs: false,
      showModalVerNots: false,
      showModalCrearNots: false,
      msg: "",
      msg1: "",
      isLoading: true,
      uri: "data:application/vnd.ms-excel;base64,",
      template:
        '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
      base64: function (s) {
        return window.btoa(unescape(encodeURIComponent(s)));
      },
      format: function (s, c) {
        return s.replace(/{(\w+)}/g, function (m, p) {
          return c[p];
        });
      },
      fechaPostAnunc: null,
      idAnuncio: "",
      idPersona: "",
    };
  },
  methods: {
    tieneCiudad() {
      return (
        this.assets.localidad &&
        this.assets.localidad.length > 0 &&
        this.assets.localidad[0].idCiudad !== undefined
      );
    },
    obtenerIdCiudad() {
      if (
        this.assets.localidad[0] &&
        this.assets.localidad[0].idCiudad !== undefined
      ) {
        return this.assets.localidad[0].idCiudad;
      } else {
        return "No hay ciudad";
      }
    },
    obtenerNvlEduc() {
      if (
        this.assets.educacnUsers[0] &&
        this.assets.educacnUsers[0].nvlEstudio !== undefined
      ) {
        return this.assets.educacnUsers[0].nvlEstudio;
      } else {
        return "No hay información";
      }
    },
    ////////////
    toggleModalVerMs: function () {
      this.showModalVerMs = !this.showModalVerMs;
    },
    toggleModalVerNots: function () {
      this.showModalVerNots = !this.showModalVerNots;
    },
    toggleModalCrearNots: function () {
      this.showModalCrearNots = !this.showModalCrearNots;
    },

    setIdAndPrueba(id) {
      this.idAssets = id;
      this.prueba(id);
      this.toggleModalVerMs();
    },
    prueba: function (value) {
      console.log(value);
      this.consultPerson(value);
      console.log("ID de assets:", this.idAssets);
    },
    volver() {
      this.$router.go(-1);
    },
    tableToExcel(table, name) {
      if (!table.nodeType) table = this.$refs.table;
      var ctx = { worksheet: name || "Prueba1", table: table.innerHTML };
      window.location.href =
        this.uri + this.base64(this.format(this.template, ctx));
    },
    tomo(value, value2, value3) {
      this.postulads = value;
      this.idAnuncio = value2;
      this.anuncio = value3;

      //CLG("postulados: ", this.postulads);
      //CLG("anuncio Titulo: ", value2);
    },
    consultPerson: function async(value) {
      console.log("funcion", value);
      axios({
        method: "post",
        //url: `https://api.postula2.com/persona/?owner=${value}`,personByOwnerId
        url: `https://api.postula2.com/personByOwnerId`,
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.myTokToApiComs,
        },
        data: {
          ownerId: value,
          anuncioId: this.$store.state.anuncs.id,
        },
      })
        .then((res) => {
          console.log("resp", res.data);
          this.$store.commit("getUserData", res.data.persona[0]);
          this.$store.commit("getPersonEvaluacn", res.data.evaluacions);

          //Perform Success Action getPersonEvaluacn
          //CLG("mandado al commit store", res.data[0]);
          // //CLG("id de owner es: ", this.$store.state.userData.owner.id);
          // //CLG("id de anuncio es: ", this.$store.state.anuncs.id);
          // //CLG("id de analista es: ", this.$store.state.analista.id);
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG("mission pelaste bolas");
          //CLG(error);
        });
    },
    logPersonaEval() {
      console.log("Valor de personaEval:", this.personaEval);
    },
  },
  watch: {
    showModalVerMs() {
      this.isLoading = true;
      if (this.showModalVerMs === false) {
        this.$store.commit("getPersonEvaluacn", "");
        this.$store.commit("getUserData", "");

        //this.consultPerson();
      }
      //console.log("cambiando");
    },
  },
  filters: {
    formatedTime: function (value) {
      var date = new Date(value);
      //console.log("Fecha en filtro:", value); // <------Este valor
      return (
        date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate() // +
        // " " +
        //date.getHours() +
        //":" +
        // date.getMinutes() +
        //":" +
        //date.getSeconds()
      );
      //CLG(formatedTime);
    },
    formatedAnuncActivo: function (value) {
      //var date = new Date(value);
      if (value == true) {
        return "Activo";
      } else {
        return "Inactivo";
      }
      //res.data[0].nombreUser+res.data[0].apellidoUser //CLG(formatedTime) https://api.postula2.com/registroPrimarioUser?id=;
    },
    reviewed: function (value) {
      if (value == "false") return;
      else return "Revisado";
    },

    evaluated: function (value) {
      if (value == "false") return;
      else return "En evaluación";
    },

    contacted: function (value) {
      if (value == "false") return;
      else return "Contactado";
    },

    finished: function (value) {
      if (value == "false") return;
      else return "Finalizado";
    },
  },
  created() {
    this.postulads = this.$store.state.anuncs.registroPrimarioUsers;
    this.idAnuncio = this.$store.state.anuncs.tituloAnunc;
    this.anuncio = this.$store.state.anuncs;
    this.idAn = this.$store.state.anuncs.id;
  },
  mounted() {
    //--------------------------/////////////////////
    //console.log(this.idAn);
    //console.log(this.$store.state);
    if (this.$store.state.regEmprs.id) {
      this.isLoading = false;
    } else {
      if (this.$store.state.regUserEmprs.empresa) {
        this.isLoading = false;
      } else {
        alert("Debe iniciar sesión o registrarse para ver esta información");
        this.$router.push({
          name: "loginEmprs",
        });
      }
    }
  },
};
</script>

<style scoped>
.colorPost2_btn {
  background-color: #134992;
}
.colorPost2_hover {
  background-color: #45618f;
}
</style>
