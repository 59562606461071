<template>
  <div>
    <loader v-if="isLoading" />

    <main class="bg-gray-50 dark:bg-gray-800 h-full max-h-full overflow-y-auto">
      <div class="flex items-start justify-between">
        <div class="flex flex-col w-full md:space-y-4">
          <div
            class="overflow-visible h-screen pb-24 px-4 md:px-6 md:mt-4 md:mb-8 lg:mt-6 border-dashed border-red-500"
          >
            <div
              class="flex items-center space-x-4 font-light w-full text-center text-xl sm:text-md md:text-2xl dark:text-white text-gray-800 border-b border-gray-200"
            >
              Administración de analistas
            </div>
            <div
              class="flex items-center mt-0.5 font-light w-1/6 text-center text-xs sm:text-xs md:text-xs dark:text-white text-blue-500"
            >
              <span
                v-if="this.$store.state.analista.id"
                @click="$router.push('homeAnalista')"
                class="cursor-pointer"
                >⇤ Volver</span
              >
              <span
                v-else
                @click="$router.push('landingempresarial')"
                class="cursor-pointer"
                >⇤ Volver</span
              >
            </div>
            <div
              class="place-items-center grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4 mt-2 mb-2"
            >
              <div
                class="bg-gray-50 border-gray-100 border-2 border-opacity-50 rounded-lg shadow-md"
                v-show="!isLoading"
              >
                <p
                  class="hidden text-sm w-max text-gray-700 dark:text-white font-semibold border-b border-gray-200"
                >
                  Project Reffered
                </p>
                <AnalistaFormLista></AnalistaFormLista>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
<script>
import loader from "@/components/shared/loader.vue";
import AnalistaFormLista from "./AnalistaFormLista.vue";

export default {
  name: "AnalistaConsultaTods",

  components: {
    AnalistaFormLista,
    loader,
  },
  methods: {
    volver() {
      this.$router.go(-1);
    },
  },
  data() {
    return {
      assets: [],

      isLoading: false,
    };
  },
};
</script>
