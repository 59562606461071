<template>
  <div class="grid grid-cols-2 border-t  bg-gray-50 rounded-md">
    <div class="border-t border-gray-200 ">
      <dl>
        <div class="hidden">
          {{ educacnUser.id }}
        </div>
        <div
          v-if="$store.state.userData"
          class="
            bg-gray-50
            grid grid-cols-3
            px-4
            py-5
            sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6
          "
        >
          <dt
            v-if="!editTituloFlag"
            class="text-sm text-left font-medium text-gray-500"
          >
            Título / Carrera
          </dt>
          <dd
            class="
              mt-1
              text-sm text-gray-900
              sm:mt-0 sm:col-span-2
              capitalize
              flex
            "
          >
            <div
              v-if="editTituloFlag"
              class="
                flex flex-col
                md:flex-row
                w-2/3
                md:w-full
                max-w-sm
                md:space-x-2
                space-y-2
                md:space-y-0
                justify-start
              "
            >
              <div class="">
                <label
                  for="price"
                  class="block text-xs font-medium text-gray-700 text-left ml-1"
                >
                  <strong>Editar Título / Carrera</strong>
                </label>

                <FormulateForm #default="{ hasErrors }" class="flex">
                  <FormulateInput
                    type="text"
                    v-model="data.tituloGrado"
                    class=""
                    :value="educacnUser.tituloGrado"
                    validation="required|min:4|matches:/^[A-Za-z\s*\á\é\í\ó\ú\ñ]+$/"
                    validation-behavior="value"
                    :validation-messages="{
                      min: 'Debe tener al menos 4 caracteres',
                      matches: 'Este campo solo acepta letras',
                      required: 'Este campo es obligatorio',
                    }"
                  />
                  <FormulateInput
                    type="submit"
                    :disabled="hasErrors"
                    @click.prevent="editTitulo(educacnUser.id)"
                    outer-class="
                    mx-1
                      flex
                    items-center
                    p-1
                    pt-2
                    transition
                    ease-in
                    duration-200
                    uppercase
                    rounded-full
                    border border-green-300
                    text-green-500
                    hover:bg-green-800 hover:text-yellow-200
                    focus:outline-none
                    h-8"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
                      />
                    </svg>
                  </FormulateInput>
                  <FormulateInput
                    type="submit"
                    @click="editTituloFlag = !editTituloFlag"
                    outer-class="
                      flex
                    items-center
                    p-1
                    pt-2
                    transition
                    ease-in
                    duration-200
                    uppercase
                    rounded-full
                    border border-red-300
                    text-red-500
                    hover:bg-red-800 hover:text-yellow-200
                    focus:outline-none
                    h-8"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </FormulateInput>
                </FormulateForm>
              </div>
            </div>

            <div class="flex" v-if="!editTituloFlag">
              <span class="text-gray-700 mr-6 dark:text-white font-normal">
                {{ educacnUser.tituloGrado }}
              </span>
              <label class="flex items-center">
                <button
                  @click="editTituloFlag = !editTituloFlag"
                  class="
                    flex
                    items-center
                    p-1
                    transition
                    ease-in
                    duration-200
                    uppercase
                    rounded-full
                    hover:bg-blue-800 hover:text-yellow-200
                    focus:outline-none
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-3.5 w-3.5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                    />
                  </svg>
                </button>
              </label>
            </div>
          </dd>
        </div>

        <div
          v-if="$store.state.userData"
          class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
        >
          <dt
            v-if="!editInstitucnFlag"
            class="text-sm text-left font-medium text-gray-500"
          >
            Institución educativa
          </dt>
          <dd
            class="
              mt-1
              text-sm text-gray-900
              sm:mt-0 sm:col-span-2
              capitalize
              flex
            "
          >
            <div
              v-if="editInstitucnFlag"
              class="
                flex flex-col
                md:flex-row
                w-2/3
                md:w-full
                max-w-sm
                md:space-x-2
                space-y-2
                md:space-y-0
                justify-start
              "
            >
              <div class="">
                <label
                  for="price"
                  class="block text-xs font-medium text-gray-700 text-left ml-1"
                >
                  <strong>Editar Institución educativa</strong>
                </label>

                <FormulateForm #default="{ hasErrors }" class="flex">
                  <FormulateInput
                    type="text"
                    v-model="data.institucn"
                    class=""
                    :value="educacnUser.institucn"
                    validation="required|min:4|matches:/^[A-Za-z\s*\á\é\í\ó\ú\ñ]+$/"
                    validation-behavior="value"
                    :validation-messages="{
                      min: 'Debe tener al menos 4 caracteres',
                      matches: 'Este campo solo acepta letras',
                      required: 'Este campo es obligatorio',
                    }"
                  />
                  <FormulateInput
                    type="submit"
                    :disabled="hasErrors"
                    @click.prevent="editInstitcn(educacnUser.id)"
                    outer-class="
                    mx-1
                      flex
                    items-center
                    p-1
                    pt-2
                    transition
                    ease-in
                    duration-200
                    uppercase
                    rounded-full
                    border border-green-300
                    text-green-500
                    hover:bg-green-800 hover:text-yellow-200
                    focus:outline-none
                    h-8"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
                      />
                    </svg>
                  </FormulateInput>
                  <FormulateInput
                    type="submit"
                    @click="editInstitucnFlag = !editInstitucnFlag"
                    outer-class="
                      flex
                    items-center
                    p-1
                    pt-2
                    transition
                    ease-in
                    duration-200
                    uppercase
                    rounded-full
                    border border-red-300
                    text-red-500
                    hover:bg-red-800 hover:text-yellow-200
                    focus:outline-none
                    h-8"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </FormulateInput>
                </FormulateForm>
              </div>
            </div>

            <div class="flex" v-if="!editInstitucnFlag">
              <span class="text-gray-700 mr-6 dark:text-white font-normal">
                {{ educacnUser.institucn }}
              </span>
              <label class="flex items-center">
                <button
                  @click="editInstitucnFlag = !editInstitucnFlag"
                  class="
                    flex
                    justify-end
                    items-center
                    p-1
                    transition
                    ease-in
                    duration-200
                    uppercase
                    rounded-full
                    hover:bg-blue-800 hover:text-yellow-200
                    focus:outline-none
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-3.5 w-3.5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                    />
                  </svg>
                </button>
              </label>
            </div>
          </dd>
        </div>

        <div
          v-if="$store.state.userData"
          class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
        >
          <dt
            v-if="!editEstadoFlag"
            class="text-sm text-left font-medium text-gray-500"
          >
            Estado
          </dt>
          <dd
            class="
              mt-1
              text-sm text-gray-900
              sm:mt-0 sm:col-span-2
              capitalize
              flex
            "
          >
            <form
              v-if="editEstadoFlag"
              class="
                flex flex-col
                md:flex-row
                w-3/4
                md:w-full
                max-w-sm
                md:space-x-3
                space-y-3
                md:space-y-0
                justify-start
              "
            >
              <div class="">
                <label
                  for="price"
                  class="block text-xs font-medium text-gray-700 text-left ml-1"
                >
                  <strong>Editar Estado</strong>
                </label>
                <select
                  type="text"
                  id='"form-subscribe-Subscribe'
                  class="
                    rounded-lg
                    border-transparent
                    flex-1
                    appearance-none
                    border border-gray-300
                    w-60
                    px-2
                    py-2
                    bg-white
                    text-gray-700
                    placeholder-gray-400
                    shadow-sm
                    text-xs
                    focus:outline-none
                    focus:ring-2
                    focus:ring-purple-600
                    focus:border-transparent
                  "
                  placeholder="Título / Carrera"
                  v-model="data.estado"
                >
                  <option disabled selected value="">Estado</option>
                  <option value="culminado">Culminado</option>
                  <option value="cursando">Cursando</option>
                  <option value="no culminado">Abandonado / Aplazado</option>
                </select>
              </div>
              <div
                class="
                  flex
                  w-auto
                  text-gray-500
                  items-end
                  justify-between
                  px-2
                  pb-0.5
                  space-x-1
                "
              >
                <button
                  @click.prevent="editEstado(educacnUser.id)"
                  class="
                    p-1
                    flex
                    items-center
                    transition
                    ease-in
                    duration-200
                    uppercase
                    rounded-full
                    border border-green-200
                    text-green-600
                    hover:border-green-600
                    hover:bg-green-600
                    hover:text-yellow-200
                    focus:outline-none
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
                    />
                  </svg>
                </button>
                <button
                  @click="editEstadoFlag = !editEstadoFlag"
                  class="
                    flex
                    items-center
                    p-1
                    transition
                    ease-in
                    duration-200
                    uppercase
                    rounded-full
                    border border-red-300
                    text-red-500
                    hover:bg-red-800 hover:text-yellow-200
                    focus:outline-none
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </button>
              </div>
            </form>

            <div class="flex" v-if="!editEstadoFlag">
              <span class="text-gray-700 mr-6 dark:text-white font-normal">
                {{ educacnUser.estado }}
              </span>
              <label class="flex items-center justify-end">
                <button
                  @click="editEstadoFlag = !editEstadoFlag"
                  class="
                    flex
                    justify-end
                    items-center
                    p-1
                    transition
                    ease-in
                    duration-200
                    uppercase
                    rounded-full
                    hover:bg-blue-800 hover:text-yellow-200
                    focus:outline-none
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-3.5 w-3.5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                    />
                  </svg>
                </button>
              </label>
            </div>
          </dd>
        </div>
      </dl>
    </div>

    <div class="border-t border-gray-200">
      <dl>
        <div
          v-if="$store.state.userData"
          class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
        >
          <dt
            v-if="!editNvlEstudioFlag"
            class="text-sm text-left font-medium text-gray-500"
          >
            Nivel de estudios
          </dt>
          <dd
            class="
              mt-1
              text-sm text-gray-900
              sm:mt-0 sm:col-span-2
              capitalize
              flex
            "
          >
            <form
              v-if="editNvlEstudioFlag"
              class="
                flex flex-col
                md:flex-row
                w-3/4
                md:w-full
                max-w-sm
                md:space-x-3
                space-y-3
                md:space-y-0
                justify-start
              "
            >
              <div class="">
                <label
                  for="price"
                  class="block text-xs font-medium text-gray-700 text-left ml-1"
                >
                  <strong>Editar Nivel de Estudios</strong>
                </label>
                <select
                  type="text"
                  id='"form-subscribe-Subscribe'
                  class="
                    rounded-lg
                    border-transparent
                    flex-1
                    appearance-none
                    border border-gray-300
                    w-60
                    px-2
                    py-2
                    bg-white
                    text-gray-700
                    placeholder-gray-400
                    shadow-sm
                    text-xs
                    focus:outline-none
                    focus:ring-2
                    focus:ring-purple-600
                    focus:border-transparent
                  "
                  placeholder="Título / Carrera"
                  v-model="data.nvlEstudio"
                >
                  <option class="text-gray-200" disabled value="">
                    Nivel de Estudios
                  </option>
                  <option value="bachiller">Bachiller</option>
                  <option value="universitario">Universitario</option>
                  <option value="maestría">Maestría</option>
                  <option value="doctorado">Doctorado</option>
                </select>
              </div>
              <div
                class="
                  flex
                  w-auto
                  text-gray-500
                  items-end
                  justify-between
                  px-2
                  pb-0.5
                  space-x-1
                "
              >
                <button
                  @click.prevent="editNvlEstuds(educacnUser.id)"
                  class="
                    flex
                    items-center
                    p-1
                    transition
                    ease-in
                    duration-200
                    uppercase
                    rounded-full
                    border border-green-200
                    text-green-600
                    hover:bg-green-600 hover:text-yellow-200
                    focus:outline-none
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
                    />
                  </svg>
                </button>
                <button
                  @click="editNvlEstudioFlag = !editNvlEstudioFlag"
                  class="
                    flex
                    items-center
                    p-1
                    transition
                    ease-in
                    duration-200
                    uppercase
                    rounded-full
                    border border-red-300
                    text-red-500
                    hover:bg-red-800 hover:text-yellow-200
                    focus:outline-none
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </button>
              </div>
            </form>

            <div class="flex" v-if="!editNvlEstudioFlag">
              <span class="text-gray-700 mr-6 dark:text-white font-normal">
                {{ educacnUser.nvlEstudio }}
              </span>
              <label class="flex items-center">
                <button
                  @click="editNvlEstudioFlag = !editNvlEstudioFlag"
                  class="
                    flex
                    items-center
                    p-1
                    transition
                    ease-in
                    duration-200
                    uppercase
                    rounded-full
                    hover:bg-blue-800 hover:text-yellow-200
                    focus:outline-none
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-3.5 w-3.5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                    />
                  </svg>
                </button>
              </label>
            </div>
          </dd>
        </div>

        <div
          v-if="$store.state.userData"
          class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
        >
          <dt
            v-if="!editPaisFlag"
            class="text-sm text-left font-medium text-gray-500"
          >
            País emisor
          </dt>
          <dd
            class="
              mt-1
              text-sm text-gray-900
              sm:mt-0 sm:col-span-2
              capitalize
              flex
            "
          >
            <form
              v-if="editPaisFlag"
              class="
                flex flex-col
                md:flex-row
                w-3/4
                md:w-full
                max-w-sm
                md:space-x-3
                space-y-3
                md:space-y-0
                justify-start
              "
            >
              <div class="">
                <label
                  for="price"
                  class="block text-xs font-medium text-gray-700 text-left ml-1"
                >
                  <strong>Editar País de estudios</strong>
                </label>
                <select
                  type="text"
                  id='"form-subscribe-Subscribe'
                  class="
                    rounded-lg
                    border-transparent
                    flex-1
                    appearance-none
                    border border-gray-300
                    w-60
                    px-2
                    py-2
                    bg-white
                    text-gray-700
                    placeholder-gray-400
                    shadow-sm
                    text-xs
                    focus:outline-none
                    focus:ring-2
                    focus:ring-purple-600
                    focus:border-transparent
                  "
                  placeholder="Título / Carrera"
                  v-model="data.pais"
                >
                  <option class="text-gray-300" disabled value="">País</option>
                  <option value="Venezuela">Venezuela</option>
                </select>
              </div>

              <div
                class="
                  flex
                  w-auto
                  text-gray-500
                  items-end
                  justify-between
                  px-2
                  pb-0.5
                  space-x-1
                "
              >
                <button
                  @click.prevent="editPais(educacnUser.id)"
                  class="
                    p-1
                    flex
                    items-center
                    transition
                    ease-in
                    duration-200
                    uppercase
                    rounded-full
                    border border-green-200
                    text-green-600
                    hover:border-green-600
                    hover:bg-green-600
                    hover:text-yellow-200
                    focus:outline-none
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
                    />
                  </svg>
                </button>
                <button
                  @click="editPaisFlag = !editPaisFlag"
                  class="
                    flex
                    items-center
                    p-1
                    transition
                    ease-in
                    duration-200
                    uppercase
                    rounded-full
                    border border-red-300
                    text-red-500
                    hover:bg-red-800 hover:text-yellow-200
                    focus:outline-none
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </button>
              </div>
            </form>

            <div class="flex" v-if="!editPaisFlag">
              <span class="text-gray-700 mr-6 dark:text-white font-normal">
                {{ educacnUser.pais }}
              </span>
              <label class="flex items-center">
                <button
                  @click="editPaisFlag = !editPaisFlag"
                  class="
                    flex
                    items-center
                    p-1
                    transition
                    ease-in
                    duration-200
                    uppercase
                    rounded-full
                    hover:bg-blue-800 hover:text-yellow-200
                    focus:outline-none
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-3.5 w-3.5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                    />
                  </svg>
                </button>
              </label>
            </div>
          </dd>
        </div>
      </dl>
    </div>
    <loader v-if="isLoading">
      <template v-slot:msg>
        {{ msg }}
      </template>

      <template v-slot:msg1>
        {{ msg1 }}
      </template>
    </loader>
  </div>
</template>
<style scoped></style>
<script>
import axios from "axios";
import loader from "@/components/shared/loader.vue";

export default {
  name: "formEducacion",
  components: {
    loader,
  },
  props: {
    educacnUser: {},
  },
  methods: {
    editarCampo() {
      this.editarFlag = !this.editarFlag;
    },
    editTitulo(idEduc) {
      let data = JSON.stringify({
        id: idEduc,
        tituloGrado: this.data.tituloGrado,
      });
      axios({
        method: "put",
        url: `https://api.postula2.com/editEducacn/`,
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.myTokToApiComs,
        },
        data: data,
      })
        .then(() => {
          //CLG("campo editado con exito");
          this.editFinished();
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG("mission pelaste bolas in educacion");
          //CLG(error);
        })
        .finally(() => {
          //Perform action in always
          this.editTituloFlag = !this.editTituloFlag;
          this.data.tituloGrado = "";
        });
    },
    editInstitcn(idEduc) {
      let data = JSON.stringify({
        id: idEduc,
        institucn: this.data.institucn,

        //pais: this.data.pais,
        //estado: this.data.estado,
        //nvlEstudio: this.data.nvlEstudio,
        //areaEstud: this.data.areaEstud,
      });
      axios({
        method: "put",
        url: `https://api.postula2.com/editEducacn/`,
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.myTokToApiComs,
        },
        data: data,
      })
        .then(() => {
          //CLG("campo editado con exito");
          this.editFinished();
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG("mission pelaste bolas in educacion");
          //CLG(error);
        })
        .finally(() => {
          //Perform action in always
          this.editInstitucnFlag = !this.editInstitucnFlag;
          this.data.institucn = "";
        });
    },
    editEstado(idEduc) {
      let data = JSON.stringify({
        id: idEduc,
        estado: this.data.estado,

        //pais: this.data.pais,
        //nvlEstudio: this.data.nvlEstudio,
        //areaEstud: this.data.areaEstud,
      });
      axios({
        method: "put",
        url: `https://api.postula2.com/editEducacn/`,
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.myTokToApiComs,
        },
        data: data,
      })
        .then(() => {
          //CLG("campo editado con exito");
          this.editFinished();
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG("mission pelaste bolas in educacion");
          //CLG(error);
        })
        .finally(() => {
          //Perform action in always
          this.editEstadoFlag = !this.editEstadoFlag;
          this.data.estado = "";
        });
    },
    editNvlEstuds(idEduc) {
      let data = JSON.stringify({
        id: idEduc,
        nvlEstudio: this.data.nvlEstudio,

        //pais: this.data.pais,
        //nvlEstudio: this.data.nvlEstudio,
        //areaEstud: this.data.areaEstud,
      });
      axios({
        method: "put",
        url: `https://api.postula2.com/editEducacn/`,
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.myTokToApiComs,
        },
        data: data,
      })
        .then(() => {
          //CLG("campo editado con exito");
          this.editFinished();
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG("mission pelaste bolas in educacion");
          //CLG(error);
        })
        .finally(() => {
          //Perform action in always
          this.editNvlEstudioFlag = !this.editNvlEstudioFlag;
          this.data.nvlEstudio = "";
        });
    },
    editPais(idEduc) {
      let data = JSON.stringify({
        id: idEduc,
        pais: this.data.pais,
      });
      axios({
        method: "put",
        url: `https://api.postula2.com/editEducacn/`,
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.myTokToApiComs,
        },
        data: data,
      })
        .then(() => {
          //CLG("campo editado con exito");
          this.editFinished();
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG("mission pelaste bolas in educacion");
          //CLG(error);
        })
        .finally(() => {
          //Perform action in always
          this.editPaisFlag = !this.editPaisFlag;
          this.data.pais = "";
        });
    },
    editar() {
      this.editing = true;
    },
    editFinished() {
      this.editing = false;
      //https://api.postula2.com/persona/624745e3e602450004288516/experncLabors
      axios({
        method: "get",
        url: `https://api.postula2.com/persona/${this.$store.state.userData.id}/educacnUsers`,
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.myTokToApiComs,
        },
      })
        .then((res) => {
          this.$store.state.userData.educacnUsers = res.data;
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG("mission pelaste bolas in educacion");
          //CLG(error);
        })
        .finally(() => {
          //Perform action in always
        });
    },
    // onSubmit() {
    //   //let dataUser = this.data;
    //   console.log("BECEROOOOOOO")
    //   this.isLoading = true;
    //   this.data.owner = this.$store.state.userData.id;
    //   axios
    //     .post("https://api.postula2.com/educacnUser", this.data)
    //     .then(() => {
    //       //Perform Success Action
    //       //CLG("response:", res);
    //       this.msg = "¡Experiencia educativa registrada satisfactoriamente...!";
    //       //this.$store.commit("getflagLogin", redFlag);
    //       this.$store.commit({
    //         type: "getflagLogin",
    //         greenFlag: true,
    //       });
    //       setTimeout(() => {
    //         this.editFinished();

    //         this.data.tituloGrado = "";
    //         this.data.pais = "";
    //         this.data.estado = "";
    //         this.data.nvlEstudio = "";
    //         this.data.areaEstud = "";
    //         this.data.institucn = "";
    //         this.data.owner = null;
    //         //this.editing = false;
    //         this.isLoading = false;
    //         this.$store.commit({
    //           type: "getflagLogin",
    //           greenFlag: null,
    //         });
    //       }, 2000);
    //     })
    //     .catch(() => {
    //       // error.response.status Check status code
    //       //CLG(error);
    //       this.msg1 =
    //         "¡Verifique la información ingresada e intente nuevamente!";
    //       //this.$store.commit("getflagLogin", redFlag);
    //       this.$store.commit({
    //         type: "getflagLogin",
    //         redFlag: true,
    //       });
    //       setTimeout(() => {
    //         this.isLoading = false;
    //         this.$store.commit({
    //           type: "getflagLogin",
    //           redFlag: null,
    //         });
    //       }, 3000);
    //     })
    //     .finally(() => {
    //       //Perform action in always
    //     });
    // },
    // envioData() {
    //   this.$bus.$emit("userdata", this.data);
    // },
  },
  data() {
    return {
      editarFlag: false,

      editTituloFlag: false,
      editPaisFlag: false,
      editEstadoFlag: false,
      editNvlEstudioFlag: false,
      editAreaEstudFlag: false,
      editInstitucnFlag: false,

      editing: false,
      isLoading: false,
      msg: null,
      msg1: null,
      data: {
        tituloGrado: "",
        pais: "",
        estado: "",
        nvlEstudio: "",
        areaEstud: "",
        institucn: "",
        owner: null,
      },
    };
  },
};
</script>
