<template>
  <section class="text-gray-600 body-font">
    <main class="bg-gray-100 dark:bg-gray-800">
      <!-- bloque oculto para barra de filtros y seleccion query -->
      <h1 class="text-3xl pt-12 font-semibold text-gray-800 dark:text-white">
        Encuentra tu empleo ideal
      </h1>
      <div class="flex flex-row items-start justify-end mx-4">
        <div class="md:space-y-4">
          <div class="pt-6 pb-1 px-4 md:px-6 mb-8">
            <div class="flex sm:md:flex-wrap justify-start">
              <h2 class="hidden">Anuncios Premium</h2>
              <div
                class="grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4 my-2 mr-4"
              >
                <div
                  v-show="!isLoading"
                  v-for="anuncioPremium in assetsPremium.slice(
                    9 * (currentPagePremium - 1),
                    9 * currentPagePremium
                  )"
                  :key="anuncioPremium.id"
                  class=""
                >
                  <AnuncioCreadoPremium
                    class="shadow-lg h-96 -p-12"
                    :assets="anuncioPremium"
                    :key="anuncioPremium.id"
                  />
                </div>
              </div>
              <!-- <div class="hidden grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4 my-2">
                <div v-for="adToshow in arrayAd" :key="adToshow.index" class="">
                  <div class="w-96 h-80 bg-gray-500 rounded-lg">
                    <adsense class="h-6" slotter="1" classNames="ad-noborder"></adsense>
                  </div>
                </div>
              </div> -->
            </div>
            <t-pagination
              :total-items="assetsPremium.length"
              :per-page="6"
              :limit="6"
              v-model="currentPagePremium"
              aria-controls="activosPremium"
              class="mt-14"
            />

            <div class="divide-x w-full border my-6"></div>

            <div class="flex flex-nowrap justify-start">
              <div
                class="grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4 my-2 mr-4"
              >
                <div
                  v-show="!isLoading"
                  v-for="anuncio in anunciosCombinadosPaginados"
                  :key="anuncio.id"
                >
                  <AnuncioDestacado
                    class="h-96"
                    v-if="anuncio.tipo === 'destacado'"
                    :assets="anuncio"
                  />
                  <AnuncioCreado
                    v-else
                    :assets="anuncio"
                    class="shadow-lg h-96"
                  />
                </div>
              </div>
            </div>
            <t-pagination
              :total-items="anunciosCombinados.length"
              :per-page="9"
              :limit="9"
              v-model="currentPage"
              aria-controls="anunciosCombinados"
              class="mt-14"
            />
          </div>

          <!-- <div class="pt-2 pb-16 px-4 md:px-6">
            <h1 class="text-3xl font-semibold text-gray-800 dark:text-white">
              Prueba nuestra busqueda mejorada y consigue tu empleo ideal
            </h1>
            <div>
              <the-searchbar-algolia />
            </div>
          </div> -->
        </div>
      </div>
    </main>
  </section>
</template>
<style scoped>
.w-90 {
  width: 22rem /* 352px h80 es 20rem h96 es 24rem*/;
}
.h-90 {
  height: 22rem /* 352px */;
}
.h-92 {
  height: 30rem /* 336px */;
}
</style>
<script>
import AnuncioDestacado from "./AnuncioDestacado.vue";
import AnuncioCreado from "@/components/AnuncioCreado.vue";
import AnuncioCreadoPremium from "@/components/layouts/anuncio/anuncioCreadoPremium.vue";
import Vue from "vue";
import VueTailwind from "vue-tailwind";
import { TPagination } from "vue-tailwind/dist/components";
import adsense from "./adsense";
import axios from "axios";

const settings = {
  "t-pagination": {
    component: TPagination,
    props: {
      classes: {
        wrapper: "table border-collapse text-center bg-white mx-auto shadow-sm",
        element:
          "w-8 h-8 border border-gray-200 table-cell hover:border-blue-100",
        activeElement:
          "w-8 h-8 border border-gray-200 border-blue-500 table-cell hover:border-blue-600",
        disabledElement: "w-8 h-8 border border-gray-200 table-cell",
        ellipsisElement: "w-8 h-8 border border-gray-200 hidden md:table-cell",
        activeButton:
          "bg-blue-500 w-full h-full text-white hover:bg-blue-600 transition duration-100 ease-in-out focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50",
        disabledButton:
          "opacity-25 w-full h-full cursor-not-allowed transition duration-100 ease-in-out",
        button:
          "hover:bg-blue-100 w-full h-full transition duration-100 ease-in-out focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50",
        ellipsis: "",
      },
      variants: {
        rounded: {
          wrapper: "bg-white mx-auto text-center flex space-x-2",
          element: "w-8 h-8 rounded-full",
          activeElement: "w-8 h-8 rounded-full",
          disabledElement: "w-8 h-8 rounded-full",
          ellipsisElement: "w-8 h-8 rounded-full hidden md:inline",
          activeButton:
            "border border-blue-500 bg-blue-500 w-full h-full rounded-full text-white hover:bg-blue-600 transition duration-100 ease-in-out focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50",
          disabledButton:
            "border border-gray-200  opacity-25 w-full h-full rounded-full cursor-not-allowed transition duration-100 ease-in-out",
          button:
            "border border-gray-200 hover:bg-blue-100 hover:border-blue-100 rounded-full w-full h-full transition duration-100 ease-in-out focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50",
          ellipsis: "",
        },
      },
    },
  },
};
Vue.use(VueTailwind, settings);

export default {
  name: "empleosHome",
  components: {
    adsense,
    AnuncioDestacado,
    AnuncioCreado,
    AnuncioCreadoPremium,
  },
  data() {
    return {
      currentPage: 1,
      currentPageNormal: 1,
      currentPageDescatacado: 1,
      currentPagePremium: 1,
      firstAd: 1,
      arrayAd: [1, 2, 3, 4, 5, 6],

      assets: [],
      assetsNt: [],
      assetsNormal: [],
      assetsDestaca: [],
      assetsPremium: [],
      toggled: false,
      limit: 300,

      isLoading: true,
      showModalVerMs: false,
    };
  },
  computed: {
    anunciosCombinados() {
      // Combina las listas de anuncios destacados y normales
      const destacados = this.assetsDestaca.map((anuncio) => ({
        ...anuncio,
        tipo: "destacado",
      }));
      const normales = this.assetsNormal.map((anuncio) => ({
        ...anuncio,
        tipo: "normal",
      }));
      return [...destacados, ...normales];
    },
    anunciosCombinadosPaginados() {
      // Devuelve solo los elementos correspondientes a la página actual
      const startIndex = (this.currentPage - 1) * 9;
      const endIndex = startIndex + 9;
      return this.anunciosCombinados.slice(startIndex, endIndex);
    },
  },
  filters: {
    formatedTime: function (value) {
      var date = new Date(value);

      return (
        date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear() // +
        // " " +
        //date.getHours() +
        //":" +
        // date.getMinutes() +
        //":" +
        //date.getSeconds()
      );
      ////CLG(formatedTime);
    },
  },
  created() {
    this.isLoading = true;
    axios
      .get(`https://api.postula2.com/anunciosActivos`)
      .then((res) => {
        //console.log(res.data.anuncios);
        // JSON responses are automatically parsed.
        this.isLoading = false;
        this.assets = res.data.anuncios.reverse();
        this.assetsNt = this.assets.filter(
          (asset) => asset.tipoAnunc.length == 0
        );
        this.assetsNormal = this.assets.filter(
          (asset) => asset.tipoAnunc === "normal"
        );
        this.assetsNt.forEach((asset) => {
          this.assetsNormal.push(asset);
        });
        this.assetsDestaca = this.assets.filter(
          (asset) => asset.tipoAnunc === "destacado"
        );
        this.assetsPremium = this.assets.filter(
          (asset) => asset.tipoAnunc === "premium"
        );
        //console.log("assetsNt+ assetsNormal", this.assetsNormal);
        //CLG(this.assets);
        // id
        // anuncActivo
        // updatedAt
        // tituloAnunc
        // owner
        //     nombreEmpr
        //     rifEmpr
        // updatedAt
        // descrpAnunc
        // tipoempleo
        // modalidtrabj
        // ofertsalarl
        // cvemailcopy
        this.$store.commit("getAnuncs", res.data.anuncios);
      })
      .catch(() => {
        //this.errors.push(e);
        //CLG("mission pelaste bolas");
        //CLG(error);
      });
  },
  methods: {
    displayText(texto) {
      //CLG("descripcion ES:", texto);
      return this.toggled ? texto : this.truncate(texto);
    },
    toggle() {
      //CLG("tog");
      this.toggled = !this.toggled;
    },
    truncate(text) {
      if (text.length > this.limit) {
        for (let i = this.limit; i > 0; i--) {
          const currChar = text.charAt(i);
          const prevChar = text.charAt(i - 1);
          const prevCharNotPuncuation =
            prevChar != "," || prevChar != "." || prevChar != ".";
          if (currChar === " " && prevCharNotPuncuation) {
            return text.substring(0, i) + "...";
          }
        }
      } else {
        return text;
      }
    },

    toggleModalVerMs: function () {
      this.showModalVerMs = !this.showModalVerMs;
    },
    formatedTime: function (value) {
      var date = new Date(value);

      return (
        date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear() // +
        // " " +
        //date.getHours() +
        //":" +
        // date.getMinutes() +
        //":" +
        //date.getSeconds()
      );
      ////CLG(formatedTime);
    },
  },
};
</script>
