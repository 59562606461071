<template>
  <section class="text-gray-600 body-font relative w-full bg-gray-200">
    <div class="container px-0 py-2 mx-auto flex sm:flex-nowrap flex-wrap">
      <div
        class="lg:w-1/3 md:w-1/2 bg-gray-100 flex flex-col md:ml-auto w-full md:py-8 mt-2 md:mt-0 justify-center mx-auto p-5 rounded-lg shadow"
      >
        <DatPerson></DatPerson>
      </div>

      <div
        class="lg:w-1/2 md:w-1/4 bg-gray-200 rounded-lg overflow-hidden sm:ml-10 sm:mb-5 p-0 flex items-center justify-items-center justify-between relative"
      >
        <iframe
          width="100%"
          height="100%"
          class="absolute inset-0 my-5"
          frameborder="0"
          title="img"
          marginheight="0"
          marginwidth="0"
          scrolling="no"
          :src="require('./img/interview.jpg').default"
          style="filter: grayscale(0.6) contrast(0.7) opacity(0.4)"
        ></iframe>

        <div
          class="bg-white bg-opacity-75 relative flex flex-wrap p-6 rounded shadow-md"
        >
          <h2
            class="text-4xl font-semibold text-gray-800 dark:text-white lg:text-4xl"
          >
            Completa los
            <span class="text-indigo-600 dark:text-indigo-400">datos...</span>
          </h2>

          <p
            class="mt-2 text-sm font-semibold text-gray-800 dark:text-gray-400 lg:text-base"
          >
            <span class="text-indigo-600 dark:text-indigo-400"
              >y en 2 simples pasos</span
            >
            podras empezar a buscar tu puesto de trabajo ideal.
          </p>

          <p class="text-xs lg:text-sm mt-8 hidden">
            Ó quizas desees crear un
            <a class="text-indigo-600 dark:text-indigo-400" href="">anuncio</a>
            primero antes de dar el gran paso
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import backgroundUrl1 from "./img/interview.jpg";
import DatPerson from "./DatPerson.vue";

export default {
  name: "App",

  components: {
    DatPerson,
  },
  data() {
    return {
      backgroundUrl1,
    };
  },
};
</script>
