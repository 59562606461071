<template>
  <div>
    <div class="flex-col bg-white rounded-lg dark:bg-gray-800">
      <loader v-if="isLoading">
        <template v-slot:msg>
          {{ msg }}
        </template>

        <template v-slot:msg1>
          {{ msg1 }}
        </template>
      </loader>
      <div v-if="editing == false" class="text-justify">
        <div class="bg-white max-w-full overflow-hidden sm:rounded-lg">
          <div class="mx-auto">
            <div class="flex flex-row">
              <div
                class="md:w-3/4 py-2 pb-2 md:pb-0 md:px-3 flex flex-col md:flex-row md:items-center justify-center md:justify-start"
              >
                <div class="flex flex-row items-center justify-start">
                  <!-- div 1 -->
                  <div class="p-2">
                    <span class="text-green-500">
                      <svg
                        class="h-5"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                        />
                      </svg>
                    </span>
                  </div>
                  <!-- div 2 -->
                  <div class="px-2">
                    <span
                      class="pr-4 leading-3 font-semibold text-blue-postulados"
                      >Experiencia laboral
                    </span>
                  </div>
                </div>

                <!-- div 3 -->
                <div class="p-2">
                  <span
                    class="md:flex md:items-center border-green-500 border-l-2 h-8 pl-3 md:pl-5 text-xs inline-block align-middle text-blue-postulados font-semibold"
                  >
                    Detalles de tus experiencias laborales, previas o actuales.
                  </span>
                </div>
              </div>
              <div
                class="md:w-1/4 flex items-center justify-center md:justify-end"
              >
                <div>
                  <button
                    @click="editar"
                    type="button"
                    class="uppercase py-1.5 px-3 mr-4 rounded-md flex justify-center items-center focus:ring-postul2 focus:ring-offset-color-postul2 transition ease-in duration-200 text-center text-sm text-blue-postulados font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 border-postul2 hover:border-postul2 hover:text-postul2 hover:border-transparent hover:text-white border-2"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-plus-circle h-7 w-7 mr"
                    >
                      <circle cx="12" cy="12" r="10"></circle>
                      <line x1="12" y1="8" x2="12" y2="16"></line>
                      <line x1="8" y1="12" x2="16" y2="12"></line>
                    </svg>
                    <span class="hidden md:block xl:block pl-1.5">
                      Agregar</span
                    >
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div
            v-for="experiencia in $store.state.userData.experncLabors"
            :key="experiencia.id"
            class="grid grid-cols-1"
          >
            <formExpLaborl :experncLabors="experiencia" :key="experiencia.id">
            </formExpLaborl>
          </div>
        </div>
      </div>

      <div
        v-if="editing"
        class="flex flex-col bg-white p-3 rounded-lg shadow-lg"
      >
        <div
          class="self-center mb-5 text-xl font-bold text-gray-800 sm:text-2xl dark:text-white w-full text-center"
        >
          Experiencia laboral
        </div>

        <div class="flex flex-col">
          <FormulateForm @submit="onSubmit" outer-class="flex">
            <div class="flex flex-wrap -mx-3 mb-2">
              <div class="w-full md:w-1/3 px-3 mb-4 md:mb-0">
                <label
                  for="price"
                  class="block text-xs font-medium text-gray-700 text-left ml-1"
                >
                  <strong> Nombre de la empresa</strong>
                </label>
                <div class="relative">
                  <FormulateInput
                    type="text"
                    v-model.lazy="data.nombEmprs"
                    validation="required|min:4|matches:/^[A-Za-z\s*\á\é\í\ó\ú\ñ]+$/"
                    :validation-messages="{
                      min: 'Debe tener al menos 4 caracteres',
                      matches: 'Este campo solo acepta letras',
                      required: 'Este campo es obligatorio',
                    }"
                    id="empresa"
                    input-class="
                      rounded-md
                      border-transparent
                      flex-1
                      appearance-none
                      border border-gray-300
                      w-full
                      py-2
                      px-2
                      bg-white
                      text-gray-700
                      placeholder-gray-400
                      shadow-sm
                      text-base
                      focus:outline-none focus:ring-2 focus:border-transparent
                    "
                    name="empresa"
                    placeholder="Nombre de la empresa"
                    errors-class="text-left ml-1"
                  />
                </div>
              </div>
              <div class="w-full md:w-1/3 px-3 mb-4 md:mb-0">
                <label
                  for="price"
                  class="block text-xs font-medium text-gray-700 text-left ml-1"
                >
                  <strong> Actividad economica</strong>
                </label>
                <div class="relative">
                  <FormulateInput
                    type="text"
                    v-model="data.idActvdEmprs"
                    validation="required|min:4|matches:/^[A-Za-z\s*\á\é\í\ó\ú\ñ]+$/"
                    :validation-messages="{
                      min: 'Debe tener al menos 4 caracteres',
                      matches: 'Este campo solo acepta letras',
                      required: 'Este campo es obligatorio',
                    }"
                    id="actividad"
                    input-class="
                      rounded-md
                      border-transparent
                      flex-1
                      appearance-none
                      border border-gray-300
                      w-full
                      py-2
                      px-2
                      bg-white
                      text-gray-700
                      placeholder-gray-400
                      shadow-sm
                      text-base
                      focus:outline-none focus:ring-2 focus:border-transparent
                    "
                    name="actividad"
                    placeholder="Actividad de la empresa"
                    errors-class="text-left ml-1"
                  />
                </div>
              </div>
              <div class="w-full md:w-1/3 px-3 mb-4 md:mb-0">
                <div class="relative">
                  <label
                    for="price"
                    class="rounded-md block text-xs font-medium text-gray-700 text-left ml-1"
                  >
                    <strong> País del ejercicio</strong>
                  </label>
                  <FormulateInput
                    v-model.lazy="data.idPais"
                    :options="{
                      Venezuela: 'Venezuela',
                    }"
                    type="select"
                    placeholder="País"
                    validation="required"
                    :validation-messages="{
                      required: 'Debes rellenar este campo',
                    }"
                    errors-class="text-left ml-1"
                  />
                </div>
              </div>
            </div>
            <div class="flex flex-wrap -mx-3 mb-2">
              <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label
                  for="price"
                  class="block text-xs font-medium text-gray-700 text-left ml-1"
                >
                  <strong> Ocupación, cargo o rol</strong>
                </label>
                <FormulateInput
                  type="text"
                  validation="required|min:4|matches:/^[A-Za-z\s*\á\é\í\ó\ú\ñ]+$/"
                  :validation-messages="{
                    min: 'Debe tener al menos 4 caracteres',
                    matches: 'Este campo solo acepta letras',
                    required: 'Este campo es obligatorio',
                  }"
                  id="actividad"
                  input-class="
                      rounded-md
                      border-transparent
                      flex-1
                      appearance-none
                      border border-gray-300
                      w-full
                      py-2
                      px-2
                      bg-white
                      text-gray-700
                      placeholder-gray-400
                      shadow-sm
                      text-base
                      focus:outline-none focus:ring-2 focus:border-transparent
                    "
                  name="ocupacion"
                  placeholder="Ocupación o cargo"
                  v-model.lazy="data.cargo"
                  errors-class="text-left ml-1"
                />
              </div>
              <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label
                  for="price"
                  class="block text-xs font-medium text-gray-700 text-left ml-1"
                >
                  <strong> Cantidad de personas a cargo</strong>
                </label>
                <FormulateInput
                  type="number"
                  id="persnAcargo"
                  class="text-gray-700 placeholder-gray-400 shadow-sm text-base"
                  name="tlf1"
                  validation="required|max:5,length|min:1,length|matches:/^[0-9]/|"
                  :validation-messages="{
                    required: 'Debes rellenar este campo',
                    max: 'Maximo 5 digitos',
                    min: 'Al menos 1 digito',
                    matches: 'Deben ser numeros',
                    not: 'No permitido',
                  }"
                  errors-class="text-left ml-1"
                  placeholder="Personas a cargo"
                  v-model.lazy.trim="data.persnsaCargo"
                />
              </div>
            </div>

            <div class="flex flex-wrap -mx-3 mb-2">
              <div class="w-full md:w-1/2 px-3 mb-2 md:mb-0">
                <div class="relative">
                  <label
                    for="price"
                    class="block text-xs font-medium text-gray-700 text-left ml-1"
                  >
                    <strong>Tiempo en el cargo</strong>
                  </label>
                  <FormulateInput
                    v-model.lazy="data.nivlExpernc"
                    :options="{
                      '-1 año o menos': 'Menos de un año',
                      '1 año': '1 año',
                      '1 año o más': 'Más de 1 año',
                      '2 años': '2 años',
                      '2 años o más': 'Más de 2 años',
                      '3 años': '3 años',
                      '3 años o mas': 'Más de 3 años',
                      '4 años': '4 años',
                      '5 años': '5 años',
                      '6 años': '6 años',
                      '7 años': '7 años o más',
                    }"
                    type="select"
                    placeholder="Seleccione"
                    validation="required"
                    :validation-messages="{
                      required: 'Debes rellenar este campo',
                    }"
                    errors-class="text-left ml-1"
                  />
                </div>
              </div>

              <div class="w-full md:w-1/2 px-3 mb-2 md:mb-0">
                <div class="relative">
                  <label
                    for="price"
                    class="block text-xs font-medium text-gray-700 text-left ml-1"
                  >
                    <strong>Área de ejecuciones</strong>
                  </label>
                  <FormulateInput
                    v-model.lazy="data.areaCargo"
                    :options="{
                      Gerencia: 'Gerencia',
                      Administrativo: 'Administrativo',
                      Operaciones: 'Operaciones',
                    }"
                    type="select"
                    placeholder="Seleccione"
                    validation="required"
                    :validation-messages="{
                      required: 'Debes rellenar este campo',
                    }"
                    errors-class="text-left ml-1"
                  />
                </div>
              </div>
            </div>

            <div class="flex flex-wrap -mx-3 mb-2">
              <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label
                  for="price"
                  class="block text-xs font-medium text-gray-700 text-left ml-1"
                >
                  <strong>Fecha de ingreso </strong>
                </label>
                <FormulateInput
                  v-model.lazy="data.fechDesd"
                  type="date"
                  name="fechDesd"
                  validation="required"
                  error-behavior="value"
                  :validation-messages="{
                    required: 'Debes rellenar este campo',
                  }"
                  errors-class="text-left ml-1"
                />
              </div>

              <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label
                  for="price"
                  class="block text-xs font-medium text-gray-700 text-left ml-1"
                >
                  <strong>Fecha de egreso </strong>
                </label>
                <FormulateInput
                  v-model.lazy="data.fechHast"
                  type="date"
                  name="fechHast"
                  validation="required"
                  error-behavior="value"
                  :validation-messages="{
                    required: 'Debes rellenar este campo',
                  }"
                  errors-class="text-left ml-1"
                />
              </div>
            </div>

            <div class="flex flex-col mb-1">
              <div class="relative">
                <label class="text-gray-700" for="name">
                  <label
                    for="price"
                    class="block text-xs font-medium text-gray-700 text-left ml-1"
                  >
                    <strong
                      >Descripción de labores y responsabilidades ejecutadas /
                      Logros alcanzados
                    </strong>
                  </label>
                  <FormulateInput
                    type="textarea"
                    v-model.lazy="data.descrpRespnsabld"
                    validation="required|max:400,length"
                    validation-name="comment"
                    error-behavior="value"
                    :help="`Maximo 400 caracteres. ${
                      400 - data.descrpRespnsabld.length
                    } restantes.`"
                    :validation-messages="{
                      required: 'Debes rellenar este campo',
                      max: 'Texto debe ser menor a 400 caracteres',
                    }"
                    errors-class="text-left ml-1"
                    help-class="text-left ml-1 text-xs font-semibold"
                  />
                </label>
              </div>
            </div>

            <div class="flex flex-wrap justify-end mt-6">
              <div class="flex flex-wrap justify-end mt-1">
                <button
                  type="submit"
                  class="py-2 px-4 mr-4 color_Postula2 hover:color_Postula2 focus:color_Postula2 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg"
                >
                  Guardar
                </button>
              </div>
              <div class="flex flex-wrap justify-end mt-1">
                <button
                  @click="editFinished"
                  class="py-2 px-4 text-red-500 bg-transparent border border-solid border-red-500 hover:bg-red-500 hover:text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg"
                >
                  Cerrar
                </button>
              </div>
            </div>
          </FormulateForm>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.bg-custom-postul2-blue {
  /*azul*/
  --tw-bg-opacity: 1;
  background-color: rgba(19, 73, 146, var(--tw-bg-opacity));
}
.bg-button-postul2-yellow {
  /*amarillo*/
  --tw-bg-opacity: 1;
  background-color: rgba(239, 224, 37, var(--tw-bg-opacity));
}
.hover\:bg-postuladosyellow-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(19, 73, 146, var(--tw-bg-opacity));
}
.bg-separador-postul2-yellow {
  /*amarillo*/
  --tw-bg-opacity: 1;
  border-color: rgba(239, 224, 37, var(--tw-bg-opacity));
}
.text-blue-postulados {
  --tw-text-opacity: 1;
  color: rgba(19, 73, 146, var(--tw-bg-opacity));
}
.color_Postula2 {
  background-color: #134992;
}
</style>

<script>
import axios from "axios";
import loader from "@/components/shared/loader.vue";
import formExpLaborl from "@/components/formExpLaborDataUser.vue";

export default {
  name: "expLaboral",
  components: {
    loader,
    formExpLaborl,
  },
  methods: {
    editarCampo() {
      this.editarFlag = !this.editarFlag;
    },
    editField(idExplaborl) {
      //this.editarFlag = false;
      ////CLG("id es: ", idEduc, "data es: ", dataToSend);
      ///editEducacn
      let data = JSON.stringify({
        id: idExplaborl,
        institucn: this.data.institucn,
        pais: this.data.pais,
        estado: this.data.estado,
        nvlEstudio: this.data.nvlEstudio,
        tituloGrado: this.data.tituloGrado,
        areaEstud: this.data.areaEstud,

        nombEmprs: "",
        idActvdEmprs: "",
        cargo: "",
        idPais: "",
        fechDesd: "",
        fechHast: "",
        alPresent: "",
        areaCargo: "",
        nivlExpernc: "",
        descrpRespnsabld: "",
        persnsaCargo: "",
      });
      axios({
        method: "get",
        url: `https://api.postula2.com/editExperncLaborl/`,
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.myTokToApiComs,
        },
        data: data,
      })
        .then(() => {
          //CLG("campo editado con exito");
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG("mission pelaste bolas in Experncia Laboral");
          //CLG(error);
        })
        .finally(() => {
          //Perform action in always
        });
    },
    editar() {
      this.editing = true;
    },
    editFinished() {
      this.editing = false;
      //https://api.postula2.com/persona/624745e3e602450004288516/experncLabors
      axios({
        method: "get",
        url: `https://api.postula2.com/persona/${this.$store.state.userData.id}/experncLabors`,
        // url: `https://api.postula2.com/persona/${this.$store.state.userData.id}/experncLabors`,
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.myTokToApiComs,
        },
      })
        .then((res) => {
          this.$store.state.userData.experncLabors = res.data;
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG("mission pelaste bolas in exp-laboral");
          //CLG(error);
        })
        .finally(() => {
          //Perform action in always
        });
    },
    onSubmit() {
      //let dataUser = this.data;
      this.isLoading = true;
      this.data.owner = this.$store.state.userData.id;
      // axios
      //   .post(
      //     "https://api.postula2.com/newExperncLaborl",
      //     //"https://api.postula2.com/experncLabor",
      //     this.data
      //   )
      axios({
        method: "POST",
        url: `https://api.postula2.com/newExperncLaborl`,
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.myTokToApiComs,
        },
        data: this.data,
      })
        .then(() => {
          // CLG("respuesta exp laboral es ",res.data)
          //Perform Success Action
          this.msg = "¡Experiencia laboral registrada satisfactoriamente...!";
          //this.$store.commit("getflagLogin", redFlag);
          this.$store.commit({
            type: "getflagLogin",
            greenFlag: true,
          });
          setTimeout(() => {
            this.editFinished();

            this.data.nombEmprs = "";
            this.data.idActvdEmprs = "";
            this.data.cargo = "";
            this.data.idPais = "";
            this.data.fechDesd = "";
            this.data.fechHast = "";
            this.data.alPresent = "";
            this.data.areaCargo = "";
            this.data.nivlExpernc = "";
            this.data.descrpRespnsabld = "";
            this.data.persnsaCargo = "";
            this.data.owner = null;
            //this.editing = false;
            this.isLoading = false;
            this.$store.commit({
              type: "getflagLogin",
              greenFlag: null,
            });
          }, 2000);
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG("error ", error);
          this.msg1 =
            "¡Verifique la información ingresada e intente nuevamente!";
          //this.$store.commit("getflagLogin", redFlag);
          this.$store.commit({
            type: "getflagLogin",
            redFlag: true,
          });
          setTimeout(() => {
            this.isLoading = false;
            this.$store.commit({
              type: "getflagLogin",
              redFlag: null,
            });
          }, 3000);
        })
        .finally(() => {
          //Perform action in always
        });
    },
    envioData() {
      this.$bus.$emit("userdata", this.data);
    },
  },
  data() {
    return {
      editarFlag: false,
      editing: false,
      isLoading: false,
      msg: null,
      msg1: null,
      data: {
        nombEmprs: "",
        idActvdEmprs: "",
        cargo: "",
        idPais: "",
        fechDesd: "",
        fechHast: "",
        alPresent: "",
        areaCargo: "",
        nivlExpernc: "",
        descrpRespnsabld: "",
        persnsaCargo: "",
        owner: null,
      },
    };
  },
};
</script>
