<template>
  <div id="app" class="w-full">
    <h1>Simple Accordion w/ Vue JS</h1>
    <div class="accordions p-2">
      <div
        class="rounded-md border-2 border-gray-500 border-opacity-50 mb-2 hover:border-green-500"
        v-for="(anuncio, index) in assets"
        :key="anuncio.id"
      >
        <loader v-if="isLoading" />
        <div
          class="rounded-md cursor-pointer border-2 border-light-red-500 border-opacity-25 bg-gray-300"
          v-on:click.self="cambio"
        >
          <div
            v-if="open2"
            class="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto"
          >
            <div
              class="inline-block min-w-full shadow rounded-lg overflow-hidden"
            >
              <table class="min-w-full leading-normal">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      class="px-1 py-2 bg-white border-b border-gray-200 text-gray-800 text-center text-xs uppercase font-normal"
                    >
                      #
                    </th>
                    <th
                      scope="col"
                      class="px-5 py-3 bg-white border-b border-gray-200 text-gray-800 text-center text-xs uppercase font-normal"
                    >
                      Nombre del anuncio
                    </th>

                    <th
                      scope="col"
                      class="px-5 py-3 bg-white border-b border-gray-200 text-gray-800 text-center text-xs uppercase font-normal"
                    >
                      Fecha inicio
                    </th>
                    <th
                      scope="col"
                      class="px-5 py-3 bg-white border-b border-gray-200 text-gray-800 text-center text-xs uppercase font-normal"
                    >
                      Fecha fin
                    </th>
                    <th
                      scope="col"
                      class="px-5 py-3 text-xs bg-white border-b border-gray-200 text-gray-800 text-center uppercase font-normal"
                    >
                      Cantidad postulados
                    </th>
                    <th
                      scope="col"
                      class="px-5 py-3 bg-white border-b border-gray-200 text-gray-800 text-center text-xs uppercase font-normal"
                    >
                      Nuevos Postulados
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td
                      class="px-5 py-5 border-b border-gray-200 bg-white text-sm"
                    >
                      <div class="flex items-center">
                        <div class="flex-shrink-0 hidden">
                          <a href="#" class="block relative">
                            <img
                              alt="profil"
                              src="/images/person/8.jpg"
                              class="mx-auto object-cover rounded-full h-10 w-10"
                            />
                          </a>
                        </div>
                        <div class="ml-3">
                          <p class="text-gray-900 whitespace-no-wrap">
                            {{ index + 1 }}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td
                      class="px-5 py-5 border-b border-gray-200 bg-white text-sm"
                    >
                      <p class="text-gray-900 whitespace-no-wrap">
                        {{ anuncio.tituloAnunc }}
                      </p>
                    </td>
                    <td
                      class="hidden px-5 py-5 border-b border-gray-200 bg-white text-sm"
                    >
                      <p
                        class="inset-0 bg-green-400 opacity-50 px-3 py-1 rounded-full text-green-900 font-bold whitespace-no-wrap"
                      >
                        {{ anuncio.anuncActivo | formatedAnuncActivo }}
                      </p>
                    </td>
                    <td
                      class="hidden px-5 py-5 border-b border-gray-200 bg-white text-sm"
                    >
                      <p class="text-gray-900 whitespace-no-wrap">
                        {{ anuncio.fechFinalzcn }}
                      </p>
                    </td>

                    <td
                      class="hidden px-5 py-5 border-b border-gray-200 bg-white text-sm"
                    >
                      <p class="text-gray-900 whitespace-no-wrap">
                        {{ anuncio.fechFinalzcn }}
                      </p>
                    </td>
                    <td class="px-5 bg-white border-b border-gray-200">
                      <button
                        class="flex-shrink-0 px-3 py-2 text-xs font-semibold text-white bg-green-600 rounded-lg shadow-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 focus:ring-offset-purple-200"
                        @click="
                          tomo(
                            anuncio.registroPrimarioUsers,
                            anuncio.tituloAnunc
                          )
                        "
                      >
                        Postulados
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div
                class="hidden px-5 bg-white py-5 flex flex-col xs:flex-row items-center xs:justify-between"
              >
                <div class="flex items-center">
                  <button
                    type="button"
                    class="w-full p-4 border text-base rounded-l-xl text-gray-600 bg-white hover:bg-gray-100"
                  >
                    <svg
                      width="9"
                      fill="currentColor"
                      height="8"
                      class=""
                      viewBox="0 0 1792 1792"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1427 301l-531 531 531 531q19 19 19 45t-19 45l-166 166q-19 19-45 19t-45-19l-742-742q-19-19-19-45t19-45l742-742q19-19 45-19t45 19l166 166q19 19 19 45t-19 45z"
                      ></path>
                    </svg>
                  </button>
                  <button
                    type="button"
                    class="w-full px-4 py-2 border-t border-b text-base text-indigo-500 bg-white hover:bg-gray-100"
                  >
                    1
                  </button>
                  <button
                    type="button"
                    class="w-full px-4 py-2 border text-base text-gray-600 bg-white hover:bg-gray-100"
                  >
                    2
                  </button>
                  <button
                    type="button"
                    class="w-full px-4 py-2 border-t border-b text-base text-gray-600 bg-white hover:bg-gray-100"
                  >
                    3
                  </button>
                  <button
                    type="button"
                    class="w-full px-4 py-2 border text-base text-gray-600 bg-white hover:bg-gray-100"
                  >
                    4
                  </button>
                  <button
                    type="button"
                    class="w-full p-4 border-t border-b border-r text-base rounded-r-xl text-gray-600 bg-white hover:bg-gray-100"
                  >
                    <svg
                      width="9"
                      fill="currentColor"
                      height="8"
                      class=""
                      viewBox="0 0 1792 1792"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1363 877l-742 742q-19 19-45 19t-45-19l-166-166q-19-19-19-45t19-45l531-531-531-531q-19-19-19-45t19-45l166-166q19-19 45-19t45 19l742 742q19 19 19 45t-19 45z"
                      ></path>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="py-4">
          <table
            ref="table"
            id="postulados"
            summary="Postulados"
            rules="groups"
            frame="hsides"
            border="2"
            class="min-w-full leading-normal"
          >
            <caption>
              <p class="text-left text-sm mx-2 mb-1" v-show="idAnuncio">
                <strong>Postulados al anuncio:</strong>
                <strong>"</strong>{{ idAnuncio }}<strong>"</strong>
              </p>
            </caption>

            <thead>
              <tr>
                <th
                  scope="col"
                  class="px-5 py-3 bg-white border-b border-gray-200 text-gray-800 text-center text-xs uppercase font-normal"
                >
                  #
                </th>
                <th
                  scope="col"
                  class="px-5 py-3 bg-white border-b border-gray-200 text-gray-800 text-center text-xs uppercase font-normal"
                >
                  Nombres y Apellidos
                </th>
                <th
                  scope="col"
                  class="px-5 py-3 bg-white border-b border-gray-200 text-gray-800 text-center text-xs uppercase font-normal"
                >
                  Email
                </th>
                <th
                  scope="col"
                  class="px-5 py-3 bg-white border-b border-gray-200 text-gray-800 text-center text-xs uppercase font-normal"
                >
                  Teléfono
                </th>
                <th
                  scope="col"
                  class="px-5 py-3 bg-white border-b border-gray-200 text-gray-800 text-center text-xs uppercase font-normal"
                >
                  Perfil creado 
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(persona, index) in postulads" :key="persona.id">
                <td class="px-3 py-3 border-b border-gray-200 bg-white text-xs">
                  <div class="flex items-center">
                    <div class="flex-shrink-0 hidden">
                      <a href="#" class="block relative">
                        <img
                          alt="profil"
                          src="/images/person/8.jpg"
                          class="mx-auto object-cover rounded-full h-10 w-10"
                        />
                      </a>
                    </div>
                    <div class="ml-3">
                      <p class="text-gray-900 whitespace-no-wrap">
                        {{ index + 1 }}
                      </p>
                    </div>
                  </div>
                </td>
                <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                  <p class="text-gray-900 whitespace-no-wrap">
                    {{ persona.nombreUser + " " + persona.apellidoUser }}
                  </p>
                </td>
                <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                  <p
                    class="inset-0 bg-green-400 opacity-50 px-3 py-1 rounded-full text-green-900 font-bold whitespace-no-wrap"
                  >
                    {{ persona.emailUser }}
                  </p>
                </td>
                <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                  <p class="text-gray-900 whitespace-no-wrap">
                    {{ persona.telf1 }}
                  </p>
                </td>
                <td class="py-5 border-b border-gray-200 bg-white text-sm">
                  {{ persona.createdAt | formatedTime }}
                </td>
              </tr>
            </tbody>
          </table>
          <button
            class="flex-shrink-0 px-4 py-2 mx-4 my-3 text-base font-semibold text-white bg-green-600 rounded-lg shadow-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 focus:ring-offset-green-200"
            @click="tableToExcel('table', 'postulados')"
          >
            Exportar XLS
          </button>
          <button
            class="flex-shrink-0 px-4 py-2 text-base font-semibold text-white bg-purple-600 rounded-lg shadow-md hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-purple-200"
            @click="exportPDF"
          >
            Exportar PDF
          </button>
        </div>
        <div
          class="border border-light-red-500 border-opacity-25 bg-gray-100 focus:border-blue-500"
          v-if="anuncio.open"
        >
          <div class="container mx-auto px-4 sm:px-8 max-w-5xl">
            <div class="py-8">
              <div class="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
                <div
                  class="inline-block min-w-full shadow rounded-lg overflow-hidden"
                >
                  <table class="min-w-full leading-normal">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          class="px-5 py-3 bg-white border-b border-gray-200 text-gray-800 text-left text-sm uppercase font-normal"
                        >
                          User
                        </th>
                        <th
                          scope="col"
                          class="px-5 py-3 bg-white border-b border-gray-200 text-gray-800 text-left text-sm uppercase font-normal"
                        >
                          Role
                        </th>
                        <th
                          scope="col"
                          class="px-5 py-3 bg-white border-b border-gray-200 text-gray-800 text-left text-sm uppercase font-normal"
                        >
                          Created at
                        </th>
                        <th
                          scope="col"
                          class="px-5 py-3 bg-white border-b border-gray-200 text-gray-800 text-left text-sm uppercase font-normal"
                        >
                          status
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td
                          class="px-5 py-5 border-b border-gray-200 bg-white text-sm"
                        >
                          <div class="flex items-center">
                            <div class="flex-shrink-0">
                              <a href="#" class="block relative">
                                <img
                                  alt="profil"
                                  src="/images/person/8.jpg"
                                  class="mx-auto object-cover rounded-full h-10 w-10"
                                />
                              </a>
                            </div>
                            <div class="ml-3">
                              <p class="text-gray-900 whitespace-no-wrap">
                                Jean marc
                              </p>
                            </div>
                          </div>
                        </td>
                        <td
                          class="px-5 py-5 border-b border-gray-200 bg-white text-sm"
                        >
                          <p class="text-gray-900 whitespace-no-wrap">Admin</p>
                        </td>
                        <td
                          class="px-5 py-5 border-b border-gray-200 bg-white text-sm"
                        >
                          <p class="text-gray-900 whitespace-no-wrap">
                            12/09/2020
                          </p>
                        </td>
                        <td
                          class="px-5 py-5 border-b border-gray-200 bg-white text-sm"
                        >
                          <span
                            class="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight"
                          >
                            <span
                              aria-hidden="true"
                              class="absolute inset-0 bg-green-200 opacity-50 rounded-full"
                            >
                            </span>
                            <span class="relative"> active </span>
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td
                          class="px-5 py-5 border-b border-gray-200 bg-white text-sm"
                        >
                          <div class="flex items-center">
                            <div class="flex-shrink-0">
                              <a href="#" class="block relative">
                                <img
                                  alt="profil"
                                  src="/images/person/9.jpg"
                                  class="mx-auto object-cover rounded-full h-10 w-10"
                                />
                              </a>
                            </div>
                            <div class="ml-3">
                              <p class="text-gray-900 whitespace-no-wrap">
                                Marcus coco
                              </p>
                            </div>
                          </div>
                        </td>
                        <td
                          class="px-5 py-5 border-b border-gray-200 bg-white text-sm"
                        >
                          <p class="text-gray-900 whitespace-no-wrap">
                            Designer
                          </p>
                        </td>
                        <td
                          class="px-5 py-5 border-b border-gray-200 bg-white text-sm"
                        >
                          <p class="text-gray-900 whitespace-no-wrap">
                            01/10/2012
                          </p>
                        </td>
                        <td
                          class="px-5 py-5 border-b border-gray-200 bg-white text-sm"
                        >
                          <span
                            class="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight"
                          >
                            <span
                              aria-hidden="true"
                              class="absolute inset-0 bg-green-200 opacity-50 rounded-full"
                            >
                            </span>
                            <span class="relative"> active </span>
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td
                          class="px-5 py-5 border-b border-gray-200 bg-white text-sm"
                        >
                          <div class="flex items-center">
                            <div class="flex-shrink-0">
                              <a href="#" class="block relative">
                                <img
                                  alt="profil"
                                  src="/images/person/10.jpg"
                                  class="mx-auto object-cover rounded-full h-10 w-10"
                                />
                              </a>
                            </div>
                            <div class="ml-3">
                              <p class="text-gray-900 whitespace-no-wrap">
                                Ecric marc
                              </p>
                            </div>
                          </div>
                        </td>
                        <td
                          class="px-5 py-5 border-b border-gray-200 bg-white text-sm"
                        >
                          <p class="text-gray-900 whitespace-no-wrap">
                            Developer
                          </p>
                        </td>
                        <td
                          class="px-5 py-5 border-b border-gray-200 bg-white text-sm"
                        >
                          <p class="text-gray-900 whitespace-no-wrap">
                            02/10/2018
                          </p>
                        </td>
                        <td
                          class="px-5 py-5 border-b border-gray-200 bg-white text-sm"
                        >
                          <span
                            class="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight"
                          >
                            <span
                              aria-hidden="true"
                              class="absolute inset-0 bg-green-200 opacity-50 rounded-full"
                            >
                            </span>
                            <span class="relative"> active </span>
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td
                          class="px-5 py-5 border-b border-gray-200 bg-white text-sm"
                        >
                          <div class="flex items-center">
                            <div class="flex-shrink-0">
                              <a href="#" class="block relative">
                                <img
                                  alt="profil"
                                  src="/images/person/6.jpg"
                                  class="mx-auto object-cover rounded-full h-10 w-10"
                                />
                              </a>
                            </div>
                            <div class="ml-3">
                              <p class="text-gray-900 whitespace-no-wrap">
                                Julien Huger
                              </p>
                            </div>
                          </div>
                        </td>
                        <td
                          class="px-5 py-5 border-b border-gray-200 bg-white text-sm"
                        >
                          <p class="text-gray-900 whitespace-no-wrap">User</p>
                        </td>
                        <td
                          class="px-5 py-5 border-b border-gray-200 bg-white text-sm"
                        >
                          <p class="text-gray-900 whitespace-no-wrap">
                            23/09/2010
                          </p>
                        </td>
                        <td
                          class="px-5 py-5 border-b border-gray-200 bg-white text-sm"
                        >
                          <span
                            class="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight"
                          >
                            <span
                              aria-hidden="true"
                              class="absolute inset-0 bg-green-200 opacity-50 rounded-full"
                            >
                            </span>
                            <span class="relative"> active </span>
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import accordion from "vue-tailwind-accordion";
import jsPDF from "jspdf";
import "jspdf-autotable";

import loader from "@/components/shared/loader.vue";

import axios from "axios";

export default {
  name: "postuladosV2",
  components: {
    loader,
    //  sideBarMenu,
  },
  data() {
    return {
      open2: false,
      assets: [],
      postulads: [],
      idAnuncio: "",
      isLoading: false,
      uri: "data:application/vnd.ms-excel;base64,",
      template:
        '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
      base64: function (s) {
        return window.btoa(unescape(encodeURIComponent(s)));
      },
      format: function (s, c) {
        return s.replace(/{(\w+)}/g, function (m, p) {
          return c[p];
        });
      },

      rows: {
        q1: {
          term: "Term 1",
          details: "Some text here...",
          open: false,
        },
        q2: {
          term: "Term 2",
          details: "Some text here...",
          open: false,
        },
        q3: {
          term: "Term 3",
          details: "Some text here...",
          open: false,
        },
      },
    };
  },
  filters: {
    formatedTime: function (value) {
      var date = new Date(value);

      return (
        date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear() // +
        // " " +
        //date.getHours() +
        //":" +
        // date.getMinutes() +
        //":" +
        //date.getSeconds()
      );
      ////CLG(formatedTime);
    },
    formatedAnuncActivo: function (value) {
      //var date = new Date(value);
      if (value == true) {
        return "Activo";
      } else {
        return "Inactivo";
      }
      //res.data[0].nombreUser+res.data[0].apellidoUser //CLG(formatedTime) https://api.postula2.com/registroPrimarioUser?id=;
    },
    consultPerson: function (value) {
      try {
        const res = axios({
          method: "get",
          url: `https://api.postula2.com/anuncio/${value}/registroPrimarioUsers`,
          headers: {
            "Content-Type": "application/json",
          },
          data: { value },
        }).then((res) => {
          //Perform Success Action
          //alert("Usuario no encontrado, debe registrarse");
          //CLG("respuesta obtenida", res.data);
          return res;
        });
        //Perform Success Actiona.al
        return res.data.nombreUser + " " + res.data.apellidoUser;
      } catch (error) {
        // error.response.status Check status code
        //CLG("mission pelaste bolas in get papu");
        //CLG(error);
      }
    },
  },
  mounted() {
    this.isLoading = true;
    if (this.$store.state.regEmprs.id) {
      axios
        .get(
          `https://api.postula2.com/anuncio?owner=${this.$store.state.regEmprs.id}`
          //`https://api.postula2.com/empresa/${this.$store.state.regEmprs.id}/anuncios`
        ) //https://api.postula2.com/anuncio?owner=61781688ef98528f16173d8c
        .then((res) => {
          //Perform Success Action
          this.isLoading = false;
          this.assets = res.data;
          //CLG("assets", this.assets);
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG("mission pelaste bolas");
          //CLG(error);
        })
        .finally(() => {
          //Perform action in always
        });
    } else {
      if (this.$store.state.regUserEmprs.empresa) {
        axios
          .get(
            `https://api.postula2.com/anuncio?owner=${this.$store.state.regUserEmprs.empresa[0].id}`
            //   `https://api.postula2.com/empresa/${this.$store.state.regUserEmprs.empresa[0].id}/anuncios`
          ) //https://api.postula2.com/anuncio?owner=${this.$store.state.regEmprs.id}
          .then((res) => {
            //Perform Success Action
            //alert("Usuario no encontrado, debe registrarse");
            //CLG("respuesta obtenida", res.data);
            this.isLoading = false;
            this.assets = res.data;
            //CLG("assets", this.assets);
          })
          .catch(() => {
            // error.response.status Check status code
            //CLG("mission pelaste bolas");
            //CLG(error);
          })
          .finally(() => {
            //Perform action in always
          });
      } else {
        alert("Debe iniciar sesión o registrarse para ver esta información");
        this.$router.push({
          name: "loginEmprs",
        });
      }
    }
  },
  methods: {
    cambio() {
      var flag = Boolean;
      if (!this.open2) {
        this.open2 = true;
        flag == true;
      } else {
        this.open2 = false;
      }
    },
    volver() {
      this.$router.push({
        name: "Home2",
      });
    },

    tableToExcel(table, name) {
      if (!table.nodeType) table = this.$refs.table;
      var ctx = { worksheet: name || "Prueba1", table: table.innerHTML };
      window.location.href =
        this.uri + this.base64(this.format(this.template, ctx));
    },
    exportPDF() {
      var vm = this;
      var columns = [
        { title: "nombre", dataKey: "nombreUser" },
        { title: "apellido", dataKey: "apellidoUser" },
      ];
      var doc = new jsPDF("p", "pt");
      doc.text("To Do List", 40, 40);
      doc.autoTable(columns, vm.postulads, {
        margin: { top: 60 },
      });
      doc.save("todos.pdf");
    },
    tomo(value, value2) {
      this.postulads = value;
      this.idAnuncio = value2;
      // //CLG("postulados: ", this.postulads);
      // //CLG("anuncio Titulo: ", value2);
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

#app {
  /* width: 100vw; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* padding: 16px; */
}

.accordions {
  width: 100%;
  /* max-width: 500px; */
}
</style>
