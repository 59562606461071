const Typesense = require("typesense");
const axios = require("axios");

// Función auxiliar para esperar un cierto tiempo
//const waitFor = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const personaServicio = async function (idOwner, nombreColeccion) {
  const typesense = new Typesense.Client({
    nodes: [
      {
        host: "192.168.10.224",
        port: "8108",
        protocol: "https",
      },
    ],
    apiKey: "xyz",
  });

  const datosposts = {
    name: `${nombreColeccion}`, //Postulados ->nombre colección original // nombre de la colección debe ser dinamico y en este caso debe ser el nombre de la empresa.
    enable_nested_fields: true,
    fields: [
      //Automaticos///
      //{ name: ".*", type: "auto" },
      // Datos Personales //
      { name: "createdAt", type: "int32" },
      { name: "nombreUser", type: "string" },
      { name: "apellidoUser", type: "string" },
      // Educación //
      { name: "educacnUsers.nvlEstudio", type: "string[]", facet: true },
      //{ name: "educacnUsers.nvlEstudio", type: "auto" },
      //{ name: "educacnUsers.tituloGrado", type: "auto" },
      // Experiencia //
      //{ name: "experncLabors.descrpRespnsabld", type: "auto" },
      // Localidad //
      { name: "localidad.idCiudad", type: "string[]", facet: true },

      //{ name: "localidad.idCiudad", type: "auto" },
      //{ name: "localidad.idEstado", type: "auto" },
    ],
    //"default_sorting_field": "createdAt"
  };

  console.log("Populating index in Typesense");
  console.log(`${nombreColeccion}`);

  try {
    await typesense.collections(`${nombreColeccion}`).delete();
    `Deleting existing collection: ${nombreColeccion}`;
  } catch (error) {
    // Do nothing
  }

  console.log("Creating schema: ");
  console.log(JSON.stringify(datosposts, null, 2));
  await typesense.collections().create(datosposts);

  console.log("Fetching data from the API using Axios...");
  try {
    let response = await axios.post(
      `https://api.postula2.com/mispostulads?idOwner=${idOwner}`
    );

    // Esperar hasta que 'candidatos' esté definido en la respuesta
    while (!response.data.resp.postulados.candidatos) {
      console.log("Esperando respuesta...");
      //await waitFor(1000); // Esperar 1 segundo antes de volver a verificar
      response = await axios.post(
        `https://api.postula2.com/mispostulads?idOwner=${idOwner}`
      ); // Volver a realizar la solicitud para obtener la respuesta actualizada
    }

    const candidatos = response.data.resp.postulados.candidatos;

    const candidatosUnidos = candidatos.map((candidato) => {
      // Combinar los dos objetos en uno solo usando el operador spread
      return {
        ...candidato[0], // primer objeto
        ...candidato[1], // segundo objeto
      };
    });

    // Imprimir o hacer algo con los candidatos unidos
    candidatosUnidos.forEach((candidatoUnido, index) => {
      console.log(`Candidato ${index + 1} unido:`, candidatoUnido);
    });

    const searchResults = candidatosUnidos.reverse();

    console.log("Adding records: ");

    const returnData = await typesense
      .collections(`${nombreColeccion}`)
      .documents()
      .import(searchResults);
    console.log(returnData);
    console.log("Done indexing.");

    const failedItems = returnData.filter((item) => item.success === false);
    if (failedItems.length > 0) {
      console.error(
        `Error importing items: ${JSON.parse(failedItems, null, 2)}`
      );

      // Imprime detalles sobre los documentos que fallaron
      failedItems.forEach((failedItem, index) => {
        console.error(
          `Failed Document ${index + 1} Details:`,
          failedItem.error
        );
      });

      throw new Error(
        `Error importing items ${JSON.parse(failedItems, null, 2)}`
      );
    }

    return returnData;
  } catch (error) {
    console.log("Error:", error.importResults);
    throw error; // Propagar el error para manejarlo en el componente Vue.js o donde sea necesario
  }
};

export default personaServicio;
