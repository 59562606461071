<template>
  <div class="container flex-wrap px-5 py-14 mx-auto items-center">
    <div
      class="
        w-full
        max-w-md
        mx-auto
        overflow-hidden
        bg-gray-300
        rounded-lg
        shadow-md
        dark:bg-gray-800
      "
    >
      <div class="px-8 pt-6 pb-2">
        <h2
          class="text-3xl font-bold text-center text-gray-700 dark:text-white"
        >
          Restablecer contraseña
        </h2>

        <Transition name="fade">
          <p
            v-if="entrando"
            class="mt-1 text-left text-gray-600 dark:text-gray-400 text-sm"
          >
            Escribe un correo electrónico para iniciar el reestablecimiento de
            contraseña
          </p>
        </Transition>
        <Transition name="fade">
          <p
            v-if="saliendo"
            class="
              mt-1
              text-left text-gray-600
              dark:text-gray-400
              text-sm
              bg-green-200
              p-2
              rounded-md
              font-semibold
            "
          >
            Responda la pregunta de seguridad generada durante el proceso de
            registro
          </p>
        </Transition>
        <Transition name="fade">
          <div
            v-if="changePass"
            class="
              mt-1
              text-left text-gray-600
              dark:text-gray-400
              text-sm
              bg-green-200
              p-2
              rounded-md
              font-semibold
            "
          >
            <p>Introduzca una nueva contraseña</p>
            <li v-if="changePass" class="text-xs">
              Al menos 7 caracteres incluyendo mayúsculas y minúsculas
            </li>
            <li v-if="changePass" class="text-xs">
              Al menos 1 caracter especial
            </li>
          </div>
        </Transition>
        <Transition name="fade">
          <form v-if="entrando" @submit.stop.prevent="onSubmit">
            <div class="w-full mt-4">
              <label
                for="price"
                class="
                  block
                  text-xs
                  font-medium
                  text-gray-700 text-left
                  ml-1
                  -mb-2
                "
              >
                <strong>Correo electrónico</strong>
              </label>
              <input
                class="
                  block
                  w-full
                  px-4
                  py-2
                  mt-2
                  text-gray-700
                  placeholder-gray-300
                  bg-white
                  border border-gray-300
                  rounded-md
                  dark:bg-gray-800
                  dark:border-gray-600
                  dark:placeholder-gray-300
                  focus:border-blue-500
                  dark:focus:border-blue-500
                  focus:outline-none focus:ring
                "
                type="email"
                placeholder="Correo electrónico"
                aria-label="Email Address"
                name="emailUser"
                v-model.lazy.trim="data.emailUser"
              />
            </div>
            <div class="mt-2">
              <label
                for="price"
                class="block text-xs font-medium text-gray-700 text-left ml-1"
              >
                <strong>Tipo de usuario</strong>
              </label>
              <div class="flex items-center gap-8 ml-1 mt-1">
                <label class="inline-flex items-center">
                  <input
                    type="radio"
                    name="vehicle"
                    class="h-4 w-4 text-red-600"
                    id="Empresa"
                    value="empresa"
                    v-model.lazy="data.usrType"
                  />
                  <span class="ml-2 text-gray-700 text-sm font-semibold">
                    Empresa
                  </span>
                </label>
                <label class="inline-flex items-center">
                  <input
                    type="radio"
                    name="vehicle"
                    class="h-4 w-4 text-red-600"
                    id="analista"
                    value="analista"
                    v-model.lazy="data.usrType"
                  />
                  <span class="ml-2 text-gray-700 text-sm font-semibold">
                    Analista
                  </span>
                </label>
                <label class="inline-flex items-center">
                  <input
                    type="radio"
                    name="vehicle"
                    class="h-4 w-4 text-red-600"
                    id="Persona"
                    value="persona"
                    v-model.lazy="data.usrType"
                  />
                  <span class="ml-2 text-gray-700 text-sm font-semibold">
                    Persona
                  </span>
                </label>
              </div>
            </div>
            <div class="flex flex-col items-center justify-center mt-6">
              <button
                class="
                  py-2
                  px-4
                  bg-postul2
                  hover:bg-blue-800 hover:ring-offset-blue-200
                  focus:ring-blue-600 focus:ring-offset-blue-200
                  text-postul2 text-white
                  w-2/6
                  transition
                  ease-in
                  duration-200
                  text-center text-base
                  font-semibold
                  shadow-md
                  focus:outline-none focus:ring-2 focus:ring-offset-2
                  rounded-lg
                "
                type="submit"
              >
                Enviar
              </button>
            </div>
          </form>
        </Transition>
        <Transition name="fade">
          <form v-if="saliendo" @submit.stop.prevent="verifyAnswr()">
            <div class="w-full mt-4">
              <label
                for="price"
                class="
                  block
                  text-xs
                  font-medium
                  text-gray-700 text-left
                  ml-1
                  -mb-2
                "
              >
                <strong>Pregunta de seguridad</strong>
              </label>
              <span
                class="
                  block
                  w-full
                  px-4
                  py-2
                  mt-2
                  text-gray-700
                  font-semibold
                  placeholder-gray-300
                  bg-white
                  border border-gray-300
                  rounded-md
                  dark:bg-gray-800
                  dark:border-gray-600
                  dark:placeholder-gray-300
                  dark:focus:border-blue-500
                "
              >
                {{ pregSec }}
              </span>
              <input
                class="
                  block
                  w-full
                  px-4
                  py-2
                  mt-2
                  text-gray-700
                  placeholder-gray-300
                  bg-white
                  border border-gray-300
                  rounded-md
                  dark:bg-gray-800
                  dark:border-gray-600
                  dark:placeholder-gray-400
                  focus:border-blue-500
                  dark:focus:border-blue-500
                  focus:outline-none focus:ring
                "
                type="password"
                placeholder="Respuesta de seguridad"
                aria-label="Password"
                name="passwUser"
                v-model.lazy="data.respSegrdUser"
              />
            </div>
            <div class="flex flex-col items-center justify-center mt-6">
              <button
                class="
                  py-2
                  px-4
                  bg-postul2
                  hover:bg-blue-800 hover:ring-offset-blue-200
                  focus:ring-blue-600 focus:ring-offset-blue-200
                  text-postul2 text-white
                  w-2/6
                  transition
                  ease-in
                  duration-200
                  text-center text-base
                  font-semibold
                  shadow-md
                  focus:outline-none focus:ring-2 focus:ring-offset-2
                  rounded-lg
                "
                type="submit"
              >
                Enviar
              </button>
            </div>
          </form>
        </Transition>
        <Transition name="fade">
          <form v-if="isAnalist" @submit.stop.prevent="analistCode()">
            <div class="w-full mt-4">
              <span
                class="
                  block
                  text-left
                  w-full
                  px-4
                  py-2
                  mt-2
                  text-gray-700
                  font-semibold
                  placeholder-gray-300
                  bg-white
                  border border-gray-300
                  rounded-md
                  dark:bg-gray-800
                  dark:border-gray-600
                  dark:placeholder-gray-300
                  dark:focus:border-blue-500
                "
              >
                Escribe el código de seguridad enviado a tu correo electrónico.
              </span>
              <label
                for="price"
                class="
                  block
                  text-xs
                  font-medium
                  text-gray-700 text-left
                  mt-2
                  ml-1
                  -mb-2
                "
              >
                <strong>Código de seguridad</strong>
              </label>
              <input
                class="
                  block
                  w-full
                  px-4
                  py-2
                  mt-2
                  text-gray-700
                  placeholder-gray-300
                  bg-white
                  border border-gray-300
                  rounded-md
                  dark:bg-gray-800
                  dark:border-gray-600
                  dark:placeholder-gray-400
                  focus:border-blue-500
                  dark:focus:border-blue-500
                  focus:outline-none focus:ring
                "
                type="password"
                placeholder="Código de seguridad"
                aria-label="Password"
                name="passwUser"
                v-model.lazy="data.emailCodeAnalist"
              />
            </div>
            <div class="flex flex-col items-center justify-center mt-6">
              <button
                class="
                  py-2
                  px-4
                  bg-postul2
                  hover:bg-blue-800 hover:ring-offset-blue-200
                  focus:ring-blue-600 focus:ring-offset-blue-200
                  text-postul2 text-white
                  w-2/6
                  transition
                  ease-in
                  duration-200
                  text-center text-base
                  font-semibold
                  shadow-md
                  focus:outline-none focus:ring-2 focus:ring-offset-2
                  rounded-lg
                "
                type="submit"
              >
                Enviar
              </button>
            </div>
          </form>
        </Transition>
        <Transition name="fade">
          <FormulateForm @submit="newPass()" v-if="changePass">
            <div class="w-full mt-4">
              <label
                for="price"
                class="block text-xs font-medium text-gray-700 text-left ml-1"
              >
                <strong>Nueva contraseña </strong>
              </label>
              <FormulateInput
                  type="password"
                  name="password"
                  placeholder="Contraseña"
                  validation="required|min:7 "
                  :validation-messages="{
                    required: 'Debes rellenar este campo',
                    min: 'Minimo 7 caracteres',
                  }"
                  class="text-left bg-white rounded-lg mb-2"
                />
                <label
                  for="price"
                  class="block text-xs font-medium text-gray-700 text-left ml-1"
                >
                  <strong> Confirmar contraseña </strong>
                </label>
                <FormulateInput
                  type="password"
                  placeholder="Confirmar contraseña"
                  name="password_confirm"
                  v-model.lazy.trim="data.newPassUser"
                  class="text-left bg-white rounded-lg mb-2"
                  validation="required|confirm"
                  :validation-messages="{
                    required: 'Debes rellenar este campo',
                    confirm: 'Contraseña no coincide',
                  }"
                  validation-name="Password confirmation es"
                />

              <FormulateInput
                input="

                  py-2
                  px-6 bg-postul2
                  hover:bg-blue-800 hover:ring-offset-blue-200
                  focus:ring-blue-600 focus:ring-offset-blue-200
                  text-postul2 text-white
                  w-2/6
                  transition
                  ease-in
                  duration-200
                  text-center text-base
                  font-semibold
                  shadow-md
                  focus:outline-none focus:ring-2 focus:ring-offset-2
                  rounded-lg
                "
                type="submit"
                label="Actualizar"
              />
            </div>

            <div class="flex flex-col items-center justify-center mt-6"></div>
          </FormulateForm>
        </Transition>
      </div>
      <Transition name="slide-fade">
        <loader v-if="isLoading">
          <template v-slot:msg>
            {{ msg }}
          </template>

          <template v-slot:msg1>
            {{ msg1 }}
          </template>
        </loader>
      </Transition>
      <div
        class="
          flex
          items-center
          justify-center
          text-center
          bg-gray-300
          dark:bg-gray-700
          text-xs
          my-2
        "
      >
        <a
          @click="$router.go(-1)"
          class="
            cursor-pointer
            text-xs text-gray-600
            dark:text-gray-200
            hover:text-gray-500 hover:underline
          "
          >Volver</a
        >
      </div>
      <div
        class="
          flex
          items-center
          justify-center
          py-1.5
          text-center
          bg-gray-200
          dark:bg-gray-700
          text-xs
        "
      >
        <span class="text-gray-600 dark:text-gray-200"
          >¿No tienes una cuenta?
        </span>

        <a
          href="#"
          class="
            mx-2
            font-bold
            text-blue-600
            dark:text-blue-400
            hover:text-blue-500
          "
          @click="$router.push('RegistroPerson')"
          >Regístrate</a
        >
      </div>
    </div>
  </div>
</template>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.text-xs2 {
  font-size: 0.5rem /* 12px */;
  line-height: 0.6rem /* 16px */;
}
.bg-postul2 {
  --tw-border-opacity: 1;
  background-color: rgba(19, 73, 146, var(--tw-bg-opacity));
}
.border-postul2 {
  --tw-border-opacity: 1;
  border-color: rgba(25, 73, 146, var(--tw-bg-opacity));
}
.hover\:border-postul2:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(19, 73, 146, var(--tw-bg-opacity));
}
.text-postul2 {
  --tw-text-opacity: 1;
  color: rgba(239, 224, 37, var(--tw-text-opacity));
}
.hover\:text-postul2:hover {
  --tw-text-opacity: 1;
  color: rgba(239, 224, 37, var(--tw-text-opacity));
}
.hover\:bgg-postul2:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(23, 73, 146, var(--tw-bg-opacity));
}
</style>

<script>
import axios from "axios";
import loader from "@/components/shared/loader.vue";

//import CryptoJS from 'crypto-js';

export default {
  name: "resetContraseña",
  components: { loader },
  methods: {
    onSubmit() {
      this.isLoading = true;

      ////console.log("respuesta get", this.data.emailUser);
      if (this.data.emailUser && this.data.usrType) {
        axios({
          method: "post",
          url: "https://api.postula2.com/resetaccountpassstart",
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            emailUsr: this.data.emailUser,
            usrType: this.data.usrType,
          },
        })
          // axios
          //   .post("https://api.postula2.com/resetaccountpassstart", this.data.emailUser)
          // .get(
          //   `https://api.postula2.com/registroPrimarioUser?emailUser=${this.data.emailUser}`)
          //loginEncr         .post("https://api.postula2.com/registroPrimarioUser", this.data)
          .then((res) => {
            //console.log("infor usuario 1", res.data);
            //})
            //   //Perform ERROR Action
            if (res.data.resp.type == "error") {
              if (res.data.resp.code == 404) {
                ///////////ENCIENDE NOTIFICACION VIA SLOT/////No existe user//////
                this.msg1 =
                  "¡Verifique la información ingresada e intente nuevamente!";
                //this.$store.commit("getflagLogin", redFlag);
                this.$store.commit({
                  type: "getflagLogin",
                  redFlag: true,
                });
                setTimeout(() => {
                  this.isLoading = false;
                  this.$store.commit({
                    type: "getflagLogin",
                    redFlag: null,
                  });
                }, 3000);
              } else if (res.data.resp.code == 403) {
                ///////////ENCIENDE NOTIFICACION VIA SLOT/////user no autenticado//////
                this.msg1 =
                  "¡Debes verificar tu correo electronico para continuar!";
                //this.$store.commit("getflagLogin", redFlag);
                this.$store.commit({
                  type: "getflagLogin",
                  redFlag: true,
                });
                setTimeout(() => {
                  this.isLoading = false;
                  this.$store.commit({
                    type: "getflagLogin",
                    redFlag: null,
                  });
                }, 2800);
              }

              ////////////////////////////////////////////////// SUCCESS ACTION
            } else if (res.data.resp.type == "success") {
              switch (res.data.resp.userType) {
                case "empresa":
                case "persona":
                  //code
                  //alert("¡¡¡ACCESS GRANTED!!!");
                  //console.log("toy llegando aqui11");
                  this.pregSec = res.data.resp.data.pregSecr;
                  ///////////ENCIENDE NOTIFICACION VIA SLOT///////////
                  this.msg = "¡Cuenta verificada!";
                  //this.$store.commit("getflagLogin", redFlag);
                  this.$store.commit({
                    type: "getflagLogin",
                    greenFlag: true,
                  });
                  setTimeout(() => {
                    this.isLoading = false;
                    this.$store.commit({
                      type: "getflagLogin",
                      greenFlag: null,
                    });
                    // setTimeout(() => {
                    //   this.success = !this.success;
                    // }, 500);
                    this.entrando = !this.entrando;
                    // this.$router.push({
                    //   name: "landing",
                    //   params: {},
                    // });
                  }, 1500);
                  setTimeout(() => {
                    this.saliendo = !this.saliendo;
                  }, 2010);
                  // //////////////////////////////////////////////////
                  break;
                case "analista":
                  //code
                  //console.log("estoy aqui22 y data viene con ", res.data);
                  this.msg = "¡Cuenta de analista verificada!";
                  this.$store.commit({
                    type: "getflagLogin",
                    greenFlag: true,
                  });
                  setTimeout(() => {
                    this.isLoading = false;
                    this.$store.commit({
                      type: "getflagLogin",
                      greenFlag: null,
                    });
                    // setTimeout(() => {
                    //   this.success = !this.success;
                    // }, 500);
                    this.entrando = !this.entrando;
                    // this.$router.push({
                    //   name: "landing",
                    //   params: {},
                    // });
                  }, 1500);
                  setTimeout(() => {
                    this.isAnalist = !this.isAnalist;
                    // if (res.data.resp.userType == "analista")
                    //   //Si es un analista entonces enciende el formulario de analista sino el normal
                    //   this.isAnalist = !this.isAnalist;
                    // else this.saliendo = !this.saliendo;
                  }, 2010);
                  // //////////////////////////////////////////////////
                  break;
              }
            }
          })
          .catch(() => {
            // error.response.status Check status code
            //CLG("mission pelaste bolas");
            //CLG(error);
          })
          .finally(() => {
            //Perform action in always
          });
      } else {
        if (!this.data.emailUser || !this.data.usrType)
          //alert("No pueden existir campos vacios");
          ///////////ENCIENDE NOTIFICACION VIA SLOT/////Datos incorrectos//////
          this.msg1 = "¡No pueden existir campos vacios!";
        //this.$store.commit("getflagLogin", redFlag);
        this.$store.commit({
          type: "getflagLogin",
          redFlag: true,
        });
        setTimeout(() => {
          this.isLoading = false;
          this.$store.commit({
            type: "getflagLogin",
            redFlag: null,
          });
        }, 2000);
        //////////////////////////////////////////////////
      }
    },
    verifyAnswr() {
      this.isLoading = true;
      //console.log("oholaa", this.data.respSegrdUser);
      if (this.data.respSegrdUser) {
        axios({
          method: "post",
          url: "https://api.postula2.com/resetaccountpasshalftime",
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            emailUsr: this.data.emailUser,
            respSecUsr: this.data.respSegrdUser,
            usrType: this.data.usrType,
          },
        })
          .then((res) => {
            //console.log("infor usuario verify", res.data);
            //})
            //   //Perform ERROR Action
            if (res.data.resp.type == "error") {
              if (res.data.resp.code == 203) {
                ///////////ENCIENDE NOTIFICACION VIA SLOT/////No existe user//////
                this.msg1 =
                  "¡Verifique la información ingresada e intente nuevamente!";
                //this.$store.commit("getflagLogin", redFlag);
                this.$store.commit({
                  type: "getflagLogin",
                  redFlag: true,
                });
                setTimeout(() => {
                  this.isLoading = false;
                  this.$store.commit({
                    type: "getflagLogin",
                    redFlag: null,
                  });
                }, 3000);
              }
              ////////////////////////////////////////////////// SUCCESS ACTION
            } else if (res.data.resp.type == "success") {
              //alert("¡¡¡ACCESS GRANTED!!!");
              //this.pregSec = res.data.resp.data.pregSecr;
              this.msg = "¡Respuesta verificada con exito!";
              if (this.data.usrType == "empresa")
                this.idUsr = res.data.resp.data.idEmpresa;
              else if (this.data.usrType == "persona")
                this.idUsr = res.data.resp.data.idUsr;
              ///////////ENCIENDE NOTIFICACION VIA SLOT///////////
              //this.$store.commit("getflagLogin", redFlag);
              this.$store.commit({
                type: "getflagLogin",
                greenFlag: true,
              });
              setTimeout(() => {
                this.isLoading = false;
                this.$store.commit({
                  type: "getflagLogin",
                  greenFlag: null,
                });
                // setTimeout(() => {
                //   this.success = !this.success;
                // }, 500);
                this.saliendo = !this.saliendo;
                // this.$router.push({
                //   name: "landing",
                //   params: {},
                // });
              }, 1500);
              setTimeout(() => {
                this.changePass = !this.changePass;
              }, 2010);
              //  setTimeout(() => {
              // this.saliendo = !this.saliendo;
              //   }, 2010);
              // //////////////////////////////////////////////////
            }
          })
          .catch(() => {
            // error.response.status Check status code
            //CLG("mission pelaste bolas");
            //CLG(error);
          })
          .finally(() => {
            //Perform action in always
          });
      } else {
        //alert("No pueden existir campos vacios");
        ///////////ENCIENDE NOTIFICACION VIA SLOT/////Datos incorrectos//////
        this.msg1 = "¡No pueden existir campos vacios!";
        //this.$store.commit("getflagLogin", redFlag);
        this.$store.commit({
          type: "getflagLogin",
          redFlag: true,
        });
        setTimeout(() => {
          this.isLoading = false;
          this.$store.commit({
            type: "getflagLogin",
            redFlag: null,
          });
        }, 2000);
        //////////////////////////////////////////////////
      }
    },
    analistCode() {
      this.isLoading = true;
      if (this.data.emailCodeAnalist) {
        axios({
          method: "post",
          url: "https://api.postula2.com/resetaccountpasshalftime",
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            emailUsr: this.data.emailUser,
            codeConfAnalist: this.data.emailCodeAnalist,
            usrType: this.data.usrType,
          },
        })
          .then((res) => {
            //console.log("infor usuario verify analista", res.data);
            //})
            //   //Perform ERROR Action
            if (res.data.resp.type == "error") {
              ///////////ENCIENDE NOTIFICACION VIA SLOT/////No existe user//////
              this.msg1 =
                "¡Verifique la información ingresada e intente nuevamente!";
              //this.$store.commit("getflagLogin", redFlag);
              this.$store.commit({
                type: "getflagLogin",
                redFlag: true,
              });
              setTimeout(() => {
                this.isLoading = false;
                this.$store.commit({
                  type: "getflagLogin",
                  redFlag: null,
                });
              }, 3000);

              ////////////////////////////////////////////////// SUCCESS ACTION
            } else if (res.data.resp.type == "success") {
              //alert("¡¡¡ACCESS GRANTED!!!");
              //this.pregSec = res.data.resp.data.pregSecr;
              ///////////ENCIENDE NOTIFICACION VIA SLOT///////////
              this.msg = "¡Respuesta verificada con exito!";
              this.idUsr = res.data.resp.data.idAnalist;
              //this.$store.commit("getflagLogin", redFlag);
              this.$store.commit({
                type: "getflagLogin",
                greenFlag: true,
              });
              setTimeout(() => {
                this.isLoading = false;
                this.$store.commit({
                  type: "getflagLogin",
                  greenFlag: null,
                });
                // setTimeout(() => {
                //   this.success = !this.success;
                // }, 500);
                this.isAnalist = !this.isAnalist;
                // this.$router.push({
                //   name: "landing",
                //   params: {},
                // });
              }, 1500);
              setTimeout(() => {
                this.changePass = !this.changePass;
              }, 2010);
              //  setTimeout(() => {
              // this.saliendo = !this.saliendo;
              //   }, 2010);
              // //////////////////////////////////////////////////
            }
          })
          .catch(() => {
            // error.response.status Check status code
            //CLG("mission pelaste bolas");
            //CLG(error);
          })
          .finally(() => {
            //Perform action in always
          });
      } else {
        //alert("No pueden existir campos vacios");
        ///////////ENCIENDE NOTIFICACION VIA SLOT/////Datos incorrectos//////
        this.msg1 = "¡No pueden existir campos vacios!";
        //this.$store.commit("getflagLogin", redFlag);
        this.$store.commit({
          type: "getflagLogin",
          redFlag: true,
        });
        setTimeout(() => {
          this.isLoading = false;
          this.$store.commit({
            type: "getflagLogin",
            redFlag: null,
          });
        }, 2000);
        //////////////////////////////////////////////////
      }
    },
    newPass() {
      this.isLoading = true;
      //console.log("id de usuario", this.idUsr);
      //console.log("type de usuario", this.data.usrType);
      //console.log("newPass", this.data.newPassUser);
      if (this.data.newPassUser) {
        axios({
          method: "post",
          url: "https://api.postula2.com/resetaccountpassendtime",
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            idUsr: this.idUsr,
            newPasswrd: this.data.newPassUser,
            usrType: this.data.usrType,
          },
        }).then((res) => {
          //console.log("respuesta es ", res.data);
          if (res.data.resp.type == "error") {
            ///////////ENCIENDE NOTIFICACION VIA SLOT/////No existe user//////
            this.msg1 = "¡Ocurrió un error inesperado, intenta nuevamente!";
            //this.$store.commit("getflagLogin", redFlag);
            this.$store.commit({
              type: "getflagLogin",
              redFlag: true,
            });
            setTimeout(() => {
              this.isLoading = false;
              this.$store.commit({
                type: "getflagLogin",
                redFlag: null,
              });
            }, 3000);

            ////////////////////////////////////////////////// SUCCESS ACTION
          } else if (res.data.resp.type == "success") {
            //alert("¡¡¡ACCESS GRANTED!!!");
            //this.pregSec = res.data.resp.data.pregSecr;
            ///////////ENCIENDE NOTIFICACION VIA SLOT///////////
            this.msg = "¡Contraseña actualizada con exito...Redireccionando!";
            //this.$store.commit("getflagLogin", redFlag);
            this.$store.commit({
              type: "getflagLogin",
              greenFlag: true,
            });
            setTimeout(() => {
              this.isLoading = false;
              this.$store.commit({
                type: "getflagLogin",
                greenFlag: null,
              });
              // setTimeout(() => {
              //   this.success = !this.success;
              // }, 500);
              switch (this.data.usrType) {
                case "empresa":
                case "analista":
                  this.$router.push({
                    name: "loginEmprs",
                    params: {},
                  });
                  break;
                case "persona":
                  this.$router.push({
                    name: "Login",
                    params: {},
                  });
                  break;
              }
            }, 2500);
            //  setTimeout(() => {
            // this.saliendo = !this.saliendo;
            //   }, 2010);
            // //////////////////////////////////////////////////
          }
        });
      } else {
        //alert("No pueden existir campos vacios");
        ///////////ENCIENDE NOTIFICACION VIA SLOT/////Datos incorrectos//////
        this.msg1 = "¡No pueden existir campos vacios!";
        //this.$store.commit("getflagLogin", redFlag);
        this.$store.commit({
          type: "getflagLogin",
          redFlag: true,
        });
        setTimeout(() => {
          this.isLoading = false;
          this.$store.commit({
            type: "getflagLogin",
            redFlag: null,
          });
        }, 2000);
        //////////////////////////////////////////////////
      }
    },
  },
  data() {
    return {
      isLoading: false,
      entrando: true,
      saliendo: false,
      changePass: false,
      isAnalist: false,
      msg: "",
      msg1: "",
      pregSec: "",
      idUsr: "",
      //respSegrdUser: "",
      data: {
        emailUser: "",
        usrType: "",
        respSegrdUser: "",
        newPassUser: "",
        emailCodeAnalist: "",
      },
    };
  },
};
</script>
