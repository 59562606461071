<template>
  <section
    class="text-gray-600 body-font items-center justify-center place-items-center"
  >
    <div class="relative">
      <!-- <carrusel class=""></carrusel> -->
      <!-- <EmpleosHome></EmpleosHome> -->
      <!-- <adsense class="h-6 hidden" slotter="111111111"  classNames="ad-noborder" ></adsense > -->
      <!--<algolia /> -->
      <!-- <sectionRegUser></sectionRegUser> -->
      <!-- Auncio argolia -->
      <Thesearchbarr />
    </div>
  </section>
</template>

<script>
// import EmpleosHome from "./EmpleosHome";
// import carrusel from "./carrusel";
// import sectionRegUser from "./sectionBannerRegInvit";
//import adsense from "./adsense";

//import algolia from "@/components/layouts/searchbar/newSearchBar.vue";

/////////
//import Thesearchbar from "@/components/layouts/searchBarNew.vue";

import Thesearchbarr from "@/components/layouts/searchComplete.vue";

export default {
  name: "HeadHome",
  components: {
    //algolia,
    Thesearchbarr,
    // EmpleosHome,
    // carrusel,
    // sectionRegUser,
    //adsense,
  },
};
</script>
