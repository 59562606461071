<template>
  <div>{{ fechaPostAnunc | formatedTime }}</div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      fechaPostAnunc: null,
      idAnuncio: "",
      idPersona: "",
    };
  },
  props: {
    info: {},
    info2: {},
  },
  filters: {
    formatedTime: function (value) {
      var date = new Date(value);
      //console.log("Fecha en filtro:", value); // <------Este valor
      return (
        date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate() // +
        // " " +
        //date.getHours() +
        //":" +
        // date.getMinutes() +
        //":" +
        //date.getSeconds()
      );
      //CLG(formatedTime);
    },
  },
  mounted() {
    this.idAnuncio = this.info;
    this.idPersona = this.info2;
    // console.log("en mounted tenggo", this.idAnuncio, this.idPersona);
    //console.log("Prueba 3");
    axios({
      method: "post",
      url: `https://api.postula2.com/findEvaluacnAnalist`,
      headers: {
        "Content-Type": "application/json",
        Authorization: this.$store.state.myTokToApiComs,
      },
      data: {
        idRegistroPrimarioUser: this.idPersona,
        idAnuncio: this.idAnuncio,
      },
    })
      .then((res) => {
        //console.log("respuesta back es ", res.data);
        //this.evaluacion = res.data.resp;
        this.fechaPostAnunc = res.data.fechaPostAnunc;
        this.$store.commit("getPersonEvaluacn", res.data.evaluaciones[0]);
        this.$store.commit("setFechaPostAnunc", res.data.fechaPostAnunc);

        //Perform Success Action
        //CLG("mandado al commit store", res.data[0]);
        // //CLG("id de owner es: ", this.$store.state.userData.owner.id);
        // //CLG("id de anuncio es: ", this.$store.state.anuncs.id);
        // //CLG("id de analista es: ", this.$store.state.analista.id);
      })
      .catch(() => {
        // error.response.status Check status code
        //CLG("mission pelaste bolas");
        //CLG(error);
      });
  },
};
</script>

<style></style>
