var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-row gap-2 lg:gap-6 flex justify-center items-center min-w-full",class:{
    'border-2 border-red-700': _vm.debg,
  }},[_c('div',{staticClass:"flex flex-wrap lg:w-52 gap-3 lg:gap-0"},[_c('div',{staticClass:"w-full lg:w-1/4 flex items-center justify-center"},[(
          this.$store.state.profileImage.flagImgUploaded === true &&
          !this.$store.state.userData.id &&
          this.$store.state.regUserEmprs.empresa
        )?_c('div',[_c('a',{staticClass:"block relative",on:{"click":_vm.goTo}},[(this.$store.state.profileImage.flagImgUploaded === true)?_c('img',{staticClass:"object-cover rounded-full h-10 w-10 cursor-pointer",attrs:{"alt":"profil","src":`https://api.postula2.com/imagesTiEmprs?id=${this.$store.state.profileImage.image.imageName}`}}):_vm._e()])]):(
          !this.$store.state.userData.id &&
          this.$store.state.regUserEmprs.empresa
        )?_c('div',[(this.$store.state.regUserEmprs.empresa.imageProfileInfo)?_c('a',{staticClass:"block relative",on:{"click":_vm.goTo}},[_c('img',{staticClass:"object-cover rounded-full h-10 w-10 cursor-pointer",attrs:{"alt":"profil","src":require("@/components/img/profileFemale.png")}})]):(this.$store.state.regUserEmprs.empresa[0].id)?_c('a',{staticClass:"block relative cursor-pointer h-10 w-10",on:{"click":_vm.goTo2}},[(
              this.$store.state.regUserEmprs.empresa[0].imgEmpr.length > 0
            )?_c('img',{staticClass:"object-cover rounded-full h-10 w-10",attrs:{"src":`https://api.postula2.com/imagesTiEmprs?id=${this.$store.state.regUserEmprs.empresa[0].imgEmpr}`,"alt":""},on:{"click":function($event){return _vm.$router.push('home')}}}):_c('img',{staticClass:"object-cover rounded-full h-10 w-10",attrs:{"src":require("@/components/img/edificio.png"),"alt":""},on:{"click":function($event){return _vm.$router.push('home')}}})]):_c('a',{staticClass:"block relative cursor-pointer",on:{"click":_vm.goTo}},[_c('img',{staticClass:"object-cover rounded-full h-10 w-10 border",attrs:{"src":require("@/components/img/profilepic.png"),"alt":""},on:{"click":function($event){return _vm.$router.push('home')}}})])]):_vm._e()]),_c('div',{staticClass:"w-full lg:w-2/4 flex items-center justify-center px-2 lg:pr-2"},[(
          !this.$store.state.userData.id &&
          this.$store.state.regUserEmprs.empresa
        )?_c('div',{staticClass:"flex flex-col cursor-pointer",on:{"click":_vm.goTo2}},[_c('span',{staticClass:"text-gray-600 dark:text-white text-xs font-medium"},[_vm._v(" "+_vm._s(this.$store.state.regUserEmprs.empresa[0].nombreEmpr)+" ")]),_c('span',{staticClass:"hidden text-gray-400 text-xs"},[_vm._v(" CTO ")])]):(
          !this.$store.state.analista &&
          !this.$store.state.regUserEmprs.empresa
        )?_c('div',{staticClass:"flex flex-col cursor-pointer",on:{"click":_vm.goTo2}},[_c('span',{staticClass:"text-gray-600 dark:text-white text-xs font-medium border-2 border-red-600"},[_vm._v(" "+_vm._s(this.$store.state.regEmprs.nombreEmpr)+" ")]),_c('span',{staticClass:"hidden text-gray-400 text-xs"},[_vm._v(" CTO ")])]):_vm._e()]),_c('div',{staticClass:"w-full lg:w-1/4 lg:flex lg:items-center"},[(
          !this.$store.state.userData.id &&
          this.$store.state.regUserEmprs.empresa
        )?_c('button',{staticClass:"py-1.5 px-2 normal-case bg-red-300 hover:bg-red-600 focus:ring-red-500 focus:ring-offset-red-200 text-white transition ease-in duration-200 text-center text-xs font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg lg:uppercase",on:{"click":function($event){return _vm.reloaded()}}},[_vm._v(" Salir ")]):_vm._e()])]),_c('div',{staticClass:"inline-block text-right"},[(_vm.isLoading)?_c('loader',{scopedSlots:_vm._u([{key:"msg",fn:function(){return [_vm._v(" "+_vm._s(_vm.msg)+" ")]},proxy:true},{key:"msg1",fn:function(){return [_vm._v(" "+_vm._s(_vm.msg1)+" ")]},proxy:true}],null,false,2476820201)}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }