<template>
  <div>
    <div class="container mx-auto px-4 sm:px-8 max-w-6xl">
      <div class="py-8">
        <div class="flex flex-row mb-1 sm:mb-0 justify-between w-full">
          <h2 class="text-2xl leading-tight">Users</h2>
          <div class="text-end">
            <form
              class="flex flex-col md:flex-row w-3/4 md:w-full max-w-sm md:space-x-3 space-y-3 md:space-y-0 justify-center"
            >
              <div class="relative">
                <input
                  type="text"
                  id='"form-subscribe-Filter'
                  class="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                  placeholder="name"
                />
              </div>
              <button
                class="flex-shrink-0 px-4 py-2 text-base font-semibold text-white bg-purple-600 rounded-lg shadow-md hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-purple-200"
                type="submit"
              >
                Filter
              </button>
            </form>
          </div>
        </div>
        <div class="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
          <div
            class="inline-block min-w-full shadow rounded-lg overflow-hidden"
          >
            <table class="min-w-full leading-normal">
              <thead>
                <tr>
                  <th
                    scope="col"
                    class="px-5 py-3 bg-white border-b border-gray-200 text-gray-800 text-left text-sm uppercase font-normal"
                  >
                    #Anuncio
                  </th>
                  <th
                    scope="col"
                    class="px-5 py-3 bg-white border-b border-gray-200 text-gray-800 text-left text-sm uppercase font-normal"
                  >
                    Titulo anuncio
                  </th>
                  <th
                    scope="col"
                    class="px-5 py-3 bg-white border-b border-gray-200 text-gray-800 text-left text-sm uppercase font-normal"
                  >
                    Estatus
                  </th>
                  <th
                    scope="col"
                    class="px-5 py-3 bg-white border-b border-gray-200 text-gray-800 text-left text-sm uppercase font-normal"
                  >
                    Fecha finalización
                  </th>
                  <th
                    scope="col"
                    class="px-5 py-3 bg-white border-b border-gray-200 text-gray-800 text-left text-sm uppercase font-normal"
                  >
                    Nombres y Apellidos
                  </th>
                  <th
                    scope="col"
                    class="px-5 py-3 bg-white border-b border-gray-200 text-gray-800 text-left text-sm uppercase font-normal"
                  >
                    Telefono
                  </th>
                  <th
                    scope="col"
                    class="px-5 py-3 bg-white border-b border-gray-200 text-gray-800 text-left text-sm uppercase font-normal"
                  >
                    Correo
                  </th>
                  <th
                    scope="col"
                    class="px-5 py-3 bg-white border-b border-gray-200 text-gray-800 text-left text-sm uppercase font-normal"
                  >
                    Contactado
                  </th>
                  <th
                    scope="col"
                    class="px-5 py-3 bg-white border-b border-gray-200 text-gray-800 text-left text-sm uppercase font-normal"
                  >
                    Ver más
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(anuncio, index) in assets" :key="anuncio.id">
                  <td
                    class="px-5 py-5 border-b border-gray-200 bg-white text-sm"
                  >
                    <div class="flex items-center">
                      <div class="flex-shrink-0 hidden">
                        <a href="#" class="block relative">
                          <img
                            alt="profil"
                            src="/images/person/8.jpg"
                            class="mx-auto object-cover rounded-full h-10 w-10"
                          />
                        </a>
                      </div>
                      <div class="ml-3">
                        <p class="text-gray-900 whitespace-no-wrap">
                          {{ index + 1 }}
                        </p>
                      </div>
                    </div>
                  </td>
                  <td
                    class="px-5 py-5 border-b border-gray-200 bg-white text-sm"
                  >
                    <p class="text-gray-900 whitespace-no-wrap">
                      {{ anuncio.tituloAnunc }}
                    </p>
                  </td>
                  <td
                    class="px-5 py-5 border-b border-gray-200 bg-white text-sm"
                  >
                    <p
                      class="inset-0 bg-green-400 opacity-50 px-3 py-1 rounded-full text-green-900 font-bold whitespace-no-wrap"
                    >
                      {{ anuncio.anuncActivo | formatedAnuncActivo }}
                    </p>
                  </td>
                  <td
                    class="px-5 py-5 border-b border-gray-200 bg-white text-sm"
                  >
                    <p class="text-gray-900 whitespace-no-wrap">
                      {{ anuncio.fechFinalzcn }}
                    </p>
                  </td>
                  <td
                    class="px-5 py-5 border-b border-gray-200 bg-white text-sm"
                  >
                    <span
                      class="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight"
                    >
                      <span
                        aria-hidden="true"
                        class="absolute inset-0 bg-green-400 opacity-50 rounded-full"
                      >
                      </span>
                      <span
                        v-for="persona in anuncio.personas"
                        :key="persona.id"
                      >
                        {{ persona.id }}
                      </span>
                      <span>{{ anuncio.id | consultPerson }}</span>
                    </span>
                  </td>
                  <td
                    class="px-5 py-5 border-b border-gray-200 bg-white text-sm"
                  >
                    <a href="#" class="text-indigo-600 hover:text-indigo-900">
                      Edit
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>

            <div
              class="px-5 bg-white py-5 flex flex-col xs:flex-row items-center xs:justify-between"
            >
              <div class="flex items-center">
                <button
                  type="button"
                  class="w-full p-4 border text-base rounded-l-xl text-gray-600 bg-white hover:bg-gray-100"
                >
                  <svg
                    width="9"
                    fill="currentColor"
                    height="8"
                    class=""
                    viewBox="0 0 1792 1792"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1427 301l-531 531 531 531q19 19 19 45t-19 45l-166 166q-19 19-45 19t-45-19l-742-742q-19-19-19-45t19-45l742-742q19-19 45-19t45 19l166 166q19 19 19 45t-19 45z"
                    ></path>
                  </svg>
                </button>
                <button
                  type="button"
                  class="w-full px-4 py-2 border-t border-b text-base text-indigo-500 bg-white hover:bg-gray-100"
                >
                  1
                </button>
                <button
                  type="button"
                  class="w-full px-4 py-2 border text-base text-gray-600 bg-white hover:bg-gray-100"
                >
                  2
                </button>
                <button
                  type="button"
                  class="w-full px-4 py-2 border-t border-b text-base text-gray-600 bg-white hover:bg-gray-100"
                >
                  3
                </button>
                <button
                  type="button"
                  class="w-full px-4 py-2 border text-base text-gray-600 bg-white hover:bg-gray-100"
                >
                  4
                </button>
                <button
                  type="button"
                  class="w-full p-4 border-t border-b border-r text-base rounded-r-xl text-gray-600 bg-white hover:bg-gray-100"
                >
                  <svg
                    width="9"
                    fill="currentColor"
                    height="8"
                    class=""
                    viewBox="0 0 1792 1792"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1363 877l-742 742q-19 19-45 19t-45-19l-166-166q-19-19-19-45t19-45l531-531-531-531q-19-19-19-45t19-45l166-166q19-19 45-19t45 19l742 742q19 19 19 45t-19 45z"
                    ></path>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import api from '@/services/api.js'
//import AnuncioCreado from "@/components/AnuncioCreado.vue";
//import loader from "@/components/shared/loader.vue";
import axios from "axios";

export default {
  name: "Home",
  components: {
    // AnuncioCreado,
    // loader,
  },
  data() {
    return {
      assets: [],
      postulads: [],
      nmbre: "",
      isLoading: false,
    };
  },
  methods: {
    consultPerson2: function (value) {
      //let naame=""
      axios
        .get(
          `https://api.postula2.com/registroPrimarioUser?id=${value}`
        )
        .then((res) => {
          //Perform Success Action
          //alert("Usuario no encontrado, debe registrarse");
          // naame = res.data[0].nombreUser+" "+res.data[0].apellidoUser
          // //CLG ("nombre es" ,naame)
          this.nombr = res.data[0].nombreUser + " " + res.data[0].apellidoUser;
          return this.nombr;
          ////CLG ("respuesta es" ,res.data[0].nombreUser+" "+res.data[0].apellidoUser)
          //return //CLG ("lo que llega adentro",naame);
          //return naame
          ////CLG("assets", this.assets);name
        });
      return this.nombr;
      ////CLG("nombre es: ",this.nombr)
      //return //CLG ("lo que llega afuera",naame);
    },
  },
  filters: {
    formatedAnuncActivo: function (value) {
      //var date = new Date(value);
      if (value == true) {
        return "Activo";
      } else {
        return "Inactivo";
      }
      //res.data[0].nombreUser+res.data[0].apellidoUser //CLG(formatedTime) https://api.postula2.com/registroPrimarioUser?id=;
    },
    consultPerson: function (value) {
      //let naame=""
      ////CLG("value tiene", value);
      axios({
        method: "get",
        url: `https://api.postula2.com/anuncio/${value}/personas`,
        headers: {
          "Content-Type": "application/json",
        },
        data: { value },
      })
        .then((res) => {
          var nombre;
          //this.$store.commit("getreguser", res.data);
          res.data.forEach((person) => {
            ////CLG("el id es: ", person.id);
            //--------------------------------------------------------------------//
            axios({
              method: "get",
              url: `https://api.postula2.com/persona/${person.id}/owner`, //https://api.postula2.com/anuncio/${value}/personas // https://api.postula2.com/persona/6176c822cc66b971d17ef41d/owner
              headers: {
                "Content-Type": "application/json",
              },
              data: `${person.id}`,
            })
              .then((res) => {
                //Perform Success Action
                nombre = res.data.nombreUser + " " + res.data.apellidoUser;
                ////CLG("mission acomplished in data usuario", res.data);
                //CLG(
                //   "El nombre de cada usuario es ",
                //   res.data.nombreUser + " " + res.data.apellidoUser
                // );
                ////CLG("respuesta post id objeto registrad", res.data.id);
                return nombre;
              })
              .catch(() => {
                // error.response.status Check status code
                //CLG("mission pelaste bolas in get papu");
                //CLG(error);
              });
            //--------------------------------------------------------------------//
          });
          //Perform Success Action
          ////CLG("mission acomplished in get papi", res.data);
          ////CLG("respuesta post id objeto registrad", res.data.id);
          //CLG("el nombre es ", nombre);
          return nombre;
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG("mission pelaste bolas in get papu");
          //CLG(error);
        });
      //name = nombre;
    },
  },
  mounted() {
    this.isLoading = true;
    if (this.$store.state.regEmprs.id) {
      axios
        .get(
          `https://api.postula2.com/empresa/${this.$store.state.regEmprs.id}/anuncios`
        )
        .then((res) => {
          //Perform Success Action
          //https://api.postula2.com/persona/6176c822cc66b971d17ef41d/anuncios
          //alert("Usuario no encontrado, debe registrarse");
          ////CLG("respuesta obtenida", res.data);
          this.isLoading = false;
          this.assets = res.data;
          //CLG("assets", this.assets);
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG("mission pelaste bolas");
          //CLG(error);
        })
        .finally(() => {
          //Perform action in always
        });
    } else {
      if (this.$store.state.regUserEmprs.empresa) {
        axios
          .get(
            `https://api.postula2.com/empresa/${this.$store.state.regUserEmprs.empresa[0].id}/anuncios`
          ) //https://api.postula2.com/empresa/${this.$store.state.regUserEmprs.empresa[0].id}/anuncios}
          .then((res) => {
            //Perform Success Action
            //alert("Usuario no encontrado, debe registrarse");
            //CLG("respuesta obtenida", res.data);
            this.isLoading = false;
            this.assets = res.data;
            //CLG("assets", this.assets);
          })
          .catch(() => {
            // error.response.status Check status code
            //CLG("mission pelaste bolas");
            //CLG(error);
          })
          .finally(() => {
            //Perform action in always
          });
      } else {
        alert("Debe iniciar sesión o registrarse para ver esta información");
        this.$router.push({
          name: "loginEmprs",
        });
      }
    }
    // axios
    //   .get(
    //     `https://api.postula2.com/anuncio?owner=${this.$store.state.regUserEmprs.empresa[0].id}`
    //   )//https://api.postula2.com/empresa/${this.$store.state.regEmprs.id}/anuncios
    //   .then((res) => {
    //     //Perform Success Action
    //     //alert("Usuario no encontrado, debe registrarse");
    //     //CLG("respuesta obtenida", res.data);
    //     this.isLoading = false;
    //     this.assets = res.data;
    //     //CLG("assets", this.assets);
    //   })
    //   .catch(() => {
    //     // error.response.status Check status code
    //     //CLG("mission pelaste bolas");
    //     //CLG(error);
    //   })
    //   .finally(() => {
    //     //Perform action in always
    //   });
  },
};
</script>

<style></style>
