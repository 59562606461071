<template>
  <div>
    <loader v-if="isLoading" />

    <main
      class="bg-gray-100 dark:bg-gray-800 h-full max-h-full overflow-y-auto relative mb-0 pb-0"
    >
      <div class="flex items-start justify-between">
        <div class="flex flex-col w-full md:w-8/12 md:space-y-4 mx-auto h-full">
          <div
            class="h-screen pb-2 mt-1 px-4 md:px-0 md:mt-1 md:mb-0 lg:mt-1 border-dashed border-red-500"
          >
            <div
              class="hidden flex items-center space-x-4 font-light w-full text-justify text-xl sm:text-md md:text-2xl dark:text-white text-gray-800 border-b border-gray-200"
            >
              {{ assets.tituloAnunc }}
            </div>
            <div
              class="grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4 mt-2 mb-4"
            >
              <div class="w-full" v-show="!isLoading">
                <p
                  class="hidden text-sm w-max text-gray-700 dark:text-white font-semibold border-b border-gray-200"
                >
                  Project Reffered
                </p>
                <AnuncioCreado :assets="this.assets" :key="this.assets.id" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import AnuncioCreado from "@/components/AnuncioCreado.vue";
import loader from "@/components/shared/loader.vue";
import axios from "axios";

export default {
  name: "anuncioByUrlID",
  components: {
    AnuncioCreado,
    loader,
  },
  data() {
    return {
      assets: [],

      isLoading: false,
    };
  },
  created() {
    //console.log("ID ES ---> ", this.$route.params.id)
  },
  mounted() {
    if (!this.$route.params.id) {
      this.$router.push({
        name: "HeaderHome",
      });
    }
    this.isLoading = true;
    // this.assets = this.$store.state.anuncsByPalabra.responseQuery;
    // this.isLoading = false;
    // console.log("busqueda es ", this.$route.params.id);

    axios({
      method: "GET",
      url: `https://api.postula2.com/anuncioById?id=${this.$route.params.id}`,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        // JSON responses are automatically parsed.
        //console.log("response ", res.data.anuncio[0])
        this.isLoading = false;
        this.assets = res.data.anuncio[0];
        // id
        // anuncActivo
        // updatedAt
        // tituloAnunc
        // owner
        //     nombreEmpr
        //     rifEmpr
        // updatedAt
        // descrpAnunc
        // tipoempleo
        // modalidtrabj
        // ofertsalarl
        // cvemailcopy
        this.$store.commit("getAnuncs", res.data.anuncio[0]);
      })
      .catch((error) => {
        //this.errors.push(e);
        //CLG("mission pelaste bolas");
        console.log(error);
      });
  },
};
</script>

<style></style>
