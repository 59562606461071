<template>
  <div>
    <loader v-if="isLoading" />

    <main class="bg-gray-50 dark:bg-gray-800 h-full max-h-full overflow-y-auto">
      <div class="flex items-start justify-between">
        <div class="flex flex-col w-full md:space-y-4">
          <div
            class="overflow-visible h-screen pb-24 px-4 md:px-6 md:pt-4 md:pb-8 lg:pt-6"
          >
            <div
              class="flex items-center space-x-4 font-light w-full text-center text-xl sm:text-md md:text-2xl dark:text-white text-gray-800 border-b border-gray-200"
            >
              Anuncios publicados
            </div>
            <div
              class="flex items-center mt-0.5 font-light w-1/6 text-center text-xs sm:text-xs md:text-xs dark:text-white text-blue-500"
            >
              <span
                v-if="!this.$store.state.analista.id"
                @click="volver"
                class="cursor-pointer"
                >⇤ Volver</span
              >
              <span v-else @click="volverAnalista" class="cursor-pointer"
                >⇤ Volver</span
              >
            </div>
            <h1
              class="mt-2 ml-2 text-lg text-left font-semibold border-b border-gray-200"
            >
              Anuncios activos
            </h1>
            <div
              class="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-2 mb-4 ml-2"
            >
              <div
                id="activos"
                class="w-full"
                v-show="!isLoading"
                v-for="anuncio in anuncsActivs.slice(
                  6 * (currentPageActive - 1),
                  6 * currentPageActive
                )"
                :key="anuncio.id"
              >
                <p
                  class="hidden text-sm w-max text-gray-700 dark:text-white font-semibold border-b border-gray-200"
                >
                  Project Reffered
                </p>
                <AnuncioActivo :anuncsActivs="anuncio" :key="anuncio.id" />
              </div>
            </div>
            <!-- <p>{{anuncsActivs.length}}</p> -->
            <t-pagination
              :total-items="anuncsActivs.length"
              :per-page="perPageActive"
              :limit="6"
              v-model="currentPageActive"
              aria-controls="activos"
              class="mt-6 mb-4"
            />

            <h1
              class="mt-8 ml-2 text-lg text-left font-semibold border-b border-gray-200"
            >
              Anuncios inactivos
            </h1>
            <div
              class="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-2 mb-4 ml-2"
            >
              <div
                class="w-full"
                v-show="!isLoading"
                v-for="anuncio in anuncsInactivs.slice(
                  6 * (currentPageInactive - 1),
                  6 * currentPageInactive
                )"
                :key="anuncio.id"
              >
                <p
                  class="hidden text-sm w-max text-gray-700 dark:text-white font-semibold border-b border-gray-200"
                >
                  Project Reffered
                </p>
                <AnuncioInactivo :anuncsInactivs="anuncio" :key="anuncio.id" />
              </div>
            </div>
            <t-pagination
              :total-items="anuncsInactivs.length"
              :per-page="perPageInactive"
              :limit="6"
              v-model="currentPageInactive"
              aria-controls="activos"
              class="mt-6 mb-4"
            />
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
//import api from '@/services/api.js'
import AnuncioActivo from "@/components/AnuncioActivo.vue";
import AnuncioInactivo from "@/components/AnuncioInactivo.vue";

import loader from "@/components/shared/loader.vue";
import axios from "axios";

import Vue from "vue";
import VueTailwind from "vue-tailwind";
import { TPagination } from "vue-tailwind/dist/components";

const settings = {
  "t-pagination": {
    component: TPagination,
    props: {
      classes: {
        wrapper: "table border-collapse text-center bg-white mx-auto shadow-sm",
        element:
          "w-8 h-8 border border-gray-200 table-cell hover:border-blue-100",
        activeElement:
          "w-8 h-8 border border-gray-200 border-blue-500 table-cell hover:border-blue-600",
        disabledElement: "w-8 h-8 border border-gray-200 table-cell",
        ellipsisElement: "w-8 h-8 border border-gray-200 hidden md:table-cell",
        activeButton:
          "bg-blue-500 w-full h-full text-white hover:bg-blue-600 transition duration-100 ease-in-out focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50",
        disabledButton:
          "opacity-25 w-full h-full cursor-not-allowed transition duration-100 ease-in-out",
        button:
          "hover:bg-blue-100 w-full h-full transition duration-100 ease-in-out focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50",
        ellipsis: "",
      },
      variants: {
        rounded: {
          wrapper: "bg-white mx-auto text-center flex space-x-2",
          element: "w-8 h-8 rounded-full",
          activeElement: "w-8 h-8 rounded-full",
          disabledElement: "w-8 h-8 rounded-full",
          ellipsisElement: "w-8 h-8 rounded-full hidden md:inline",
          activeButton:
            "border border-blue-500 bg-blue-500 w-full h-full rounded-full text-white hover:bg-blue-600 transition duration-100 ease-in-out focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50",
          disabledButton:
            "border border-gray-200  opacity-25 w-full h-full rounded-full cursor-not-allowed transition duration-100 ease-in-out",
          button:
            "border border-gray-200 hover:bg-blue-100 hover:border-blue-100 rounded-full w-full h-full transition duration-100 ease-in-out focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50",
          ellipsis: "",
        },
      },
    },
  },
};
Vue.use(VueTailwind, settings);

export default {
  name: "anunciosPorEmpresa",
  components: {
    AnuncioActivo,
    AnuncioInactivo,
    loader,
  },
  methods: {
    volver() {
      this.$router.push({
        name: "landing",
      });
    },
    volverAnalista() {
      this.$router.push({
        name: "homeAnalista",
      });
    },
  },
  data() {
    return {
      currentPageActive: 1,
      currentPageInactive: 1,
      perPageActive: 6,
      perPageInactive: 6,

      assets: {},
      anuncsActivs: [],
      anuncsInactivs: [],
      isLoading: false,
    };
  },
  mounted() {
    this.isLoading = true;
    // Eso da undefine //
    //console.log("MIRA ESTO: ", this.$store.state.regEmprs.id);
    if (this.$store.state.regEmprs.id) {
      axios({
        method: "GET",
        url: `https://api.postula2.com/empresa/${this.$store.state.regEmprs.id}/anuncios`,
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.myTokToApiComs,
        },
      })
        .then((res) => {
          //Perform Success Action
          //alert("Usuario no encontrado, debe registrarse");
          //console.log("respuesta obtenida", res.data);
          this.isLoading = false;
          this.assets = res.data.reverse();

          this.assets.forEach((anuncio) => {
            ////CLG("wtf", anuncio.anuncEstado);
            if (anuncio.anuncEstado == "activo") {
              this.anuncsActivs.push(anuncio);
            } else this.anuncsInactivs.push(anuncio);
          });

          //console.log("assets 1", this.assets);
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG("mission pelaste bolas");
          //CLG(error);
        })
        .finally(() => {
          //Perform action in always
        });
    } else {
      if (this.$store.state.regUserEmprs.empresa) {
        // axios({
        //   method: "GET",
        //   url: `https://api.postula2.com/empresa/${this.$store.state.regUserEmprs.empresa[0].id}/anuncios`,
        //   headers: {
        //     "Content-Type": "application/json",
        //     Authorization: this.$store.state.myTokToApiComs,
        //   },
        //
        // })
        axios({
          method: "POST",
          url: `https://api.postula2.com/anuncioByEmpresa`,
          headers: {
            "Content-Type": "application/json",
            Authorization: this.$store.state.myTokToApiComs,
          },
          data: { ownerId: this.$store.state.regEmprs.id },
        })
          .then((res) => {
            //Perform Success Action
            //alert("Usuario no encontrado, debe registrarse");
            // console.log("respuesta obtenida", res); 

            //console.log("Nuevos: ", res.data.anuncios.reverse());

            this.isLoading = false;
            this.assets = res.data.reverse();

            //console.log("PILAS", this.assets)

            this.assets.forEach((anuncio) => {
              ////CLG("wtf", anuncio.anuncEstado);
              if (anuncio.anuncEstado == "activo") {
                this.anuncsActivs.push(anuncio);
              } else this.anuncsInactivs.push(anuncio);
            });

            //console.log("assets 2 ", this.assets);
          })
          .catch(() => {
            // error.response.status Check status code
            //CLG("mission pelaste bolas");
            //CLG(error);
          })
          .finally(() => {
            //Perform action in always
          });
      } else {
        alert("Debe iniciar sesión o registrarse para ver esta información");
        this.$router.push({
          name: "loginEmprs",
        });
      }
    }

    //console.log("Bandera nueva: ", Object.assign({}, this.anuncsActivs));
  },
};
</script>

<style></style>
