<template>
  <div class="grid grid-cols-2 border-t">
    <div class="border-t border-gray-200">
      <dl>
        <div class="hidden">
          {{ localidad.id }}
        </div>
        <div
          v-if="$store.state.userData"
          class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
        >
          <dt
            v-if="!editPaisFlag"
            class="text-sm text-left font-medium text-gray-500"
          >
            País de residencia
          </dt>
          <dd
            class="
              mt-1
              text-sm text-gray-900
              sm:mt-0 sm:col-span-2
              capitalize
              flex
            "
          >
            <form
              v-if="editPaisFlag"
              class="
                flex flex-col
                md:flex-row
                w-3/4
                md:w-full
                max-w-sm
                md:space-x-3
                space-y-3
                md:space-y-0
                justify-start
              "
            >
              <div class="">
                <label
                  for="price"
                  class="block text-xs font-medium text-gray-700 text-left ml-1"
                >
                  <strong>Editar País de residencia</strong>
                </label>
                <select
                  type="text"
                  id='"form-subscribe-Subscribe'
                  class="
                    rounded-lg
                    border-transparent
                    flex-1
                    appearance-none
                    border border-gray-300
                    w-60
                    px-2
                    py-2
                    bg-white
                    text-gray-700
                    placeholder-gray-400
                    shadow-sm
                    text-xs
                    focus:outline-none
                    focus:ring-2
                    focus:ring-purple-600
                    focus:border-transparent
                  "
                  placeholder="Título / Carrera"
                  v-model="data.idPais"
                >
                  <option class="text-gray-300" disabled value="">País</option>
                  <option value="Venezuela">Venezuela</option>
                </select>
              </div>

              <div
                class="
                  flex
                  w-auto
                  text-gray-500
                  items-end
                  justify-between
                  px-2
                  pb-0.5
                  space-x-1
                "
              >
                <button
                  @click.prevent="editPais(localidad.id)"
                  class="
                    p-1
                    flex
                    items-center
                    transition
                    ease-in
                    duration-200
                    uppercase
                    rounded-full
                    border border-green-200
                    text-green-600
                    hover:border-green-600
                    hover:bg-green-600
                    hover:text-yellow-200
                    focus:outline-none
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
                    />
                  </svg>
                </button>
                <button
                  @click="editPaisFlag = !editPaisFlag"
                  class="
                    flex
                    items-center
                    p-1
                    transition
                    ease-in
                    duration-200
                    uppercase
                    rounded-full
                    border border-red-300
                    text-red-500
                    hover:bg-red-800 hover:text-yellow-200
                    focus:outline-none
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </button>
              </div>
            </form>

            <div class="flex" v-if="!editPaisFlag">
              <span class="text-gray-700 mr-6 dark:text-white font-normal">
                {{ localidad.idPais }}
              </span>
              <label class="flex items-center">
                <button
                  @click="editPaisFlag = !editPaisFlag"
                  class="
                    flex
                    items-center
                    p-1
                    transition
                    ease-in
                    duration-200
                    uppercase
                    rounded-full
                    hover:bg-blue-800 hover:text-yellow-200
                    focus:outline-none
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-3.5 w-3.5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                    />
                  </svg>
                </button>
              </label>
            </div>
          </dd>
        </div>

        <div
          v-if="$store.state.userData"
          class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
        >
          <dt
            v-if="!editCiudadFlag"
            class="text-sm text-left font-medium text-gray-500"
          >
            Ciudad de residencia
          </dt>
          <dd
            class="
              mt-1
              text-sm text-gray-900
              sm:mt-0 sm:col-span-2
              capitalize
              flex
            "
          >
            <form
              v-if="editCiudadFlag"
              class="
                flex flex-col
                md:flex-row
                w-3/4
                md:w-full
                max-w-sm
                md:space-x-3
                space-y-3
                md:space-y-0
                justify-start
              "
            >
              <div class="">
                <label
                  for="price"
                  class="block text-xs font-medium text-gray-700 text-left ml-1"
                >
                  <strong>Editar Ciudad de residencia</strong>
                </label>
                <FormulateForm
                  :element-class="
                    (context, classes) => ['bg-red-500'].concat(classes)
                  "
                  #default="{ hasErrors }"
                  class="flex"
                >
                  <FormulateInput
                    type="email"
                    v-model="data.idCiudad"
                    class=""
                    :value="localidad.idCiudad"
                    validation="required|min:4|matches:/^[A-Za-z\s*\á\é\í\ó\ú\ñ]+$/"
                    validation-behavior="live"
                    :validation-messages="{
                      min: 'Debe tener al menos 4 caracteres',
                      matches: 'Este campo solo acepta letras',
                      required: 'Este campo es obligatorio',
                    }"
                  />
                  <FormulateInput
                    type="submit"
                    :disabled="hasErrors"
                    @click.prevent="editCiudad(localidad.id)"
                    outer-class="
                    mx-1
                      flex
                    items-center
                    p-1
                    pt-2
                    transition
                    ease-in
                    duration-200
                    uppercase
                    rounded-full
                    border border-green-300
                    text-green-500
                    hover:bg-green-800 hover:text-yellow-200
                    focus:outline-none
                    h-8"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
                      />
                    </svg>
                  </FormulateInput>
                  <FormulateInput
                    type="submit"
                    @click="editCiudadFlag = !editCiudadFlag"
                    outer-class="
                    
                      flex
                    items-center
                    p-1
                    pt-2
                    transition
                    ease-in
                    duration-200
                    uppercase
                    rounded-full
                    border border-red-300
                    text-red-500
                    hover:bg-red-800 hover:text-yellow-200
                    focus:outline-none
                    h-8"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </FormulateInput>
                </FormulateForm>
              </div>
            </form>

            <div class="flex" v-if="!editCiudadFlag">
              <span class="text-gray-700 mr-6 dark:text-white font-normal">
                {{ localidad.idCiudad }}
              </span>
              <label class="flex items-center">
                <button
                  @click="editCiudadFlag = !editCiudadFlag"
                  class="
                    flex
                    items-center
                    p-1
                    transition
                    ease-in
                    duration-200
                    uppercase
                    rounded-full
                    hover:bg-blue-800 hover:text-yellow-200
                    focus:outline-none
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-3.5 w-3.5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                    />
                  </svg>
                </button>
              </label>
            </div>
          </dd>
        </div>

        <div
          v-if="$store.state.userData"
          class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
        >
          <dt
            v-if="!editParroqFlag"
            class="text-sm text-left font-medium text-gray-500"
          >
            Parroquia de residencia
          </dt>
          <dd
            class="
              mt-1
              text-sm text-gray-900
              sm:mt-0 sm:col-span-2
              capitalize
              flex
            "
          >
            <form
              v-if="editParroqFlag"
              class="
                flex flex-col
                md:flex-row
                w-3/4
                md:w-full
                max-w-sm
                md:space-x-3
                space-y-3
                md:space-y-0
                justify-start
              "
            >
              <div class="">
                <label
                  for="price"
                  class="block text-xs font-medium text-gray-700 text-left ml-1"
                >
                  <strong>Editar Parroquia de residencia</strong>
                </label>
                <FormulateForm
                  :element-class="
                    (context, classes) => ['bg-red-500'].concat(classes)
                  "
                  #default="{ hasErrors }"
                  class="flex"
                >
                  <FormulateInput
                    type="email"
                    v-model="data.idParroq"
                    class=""
                    :value="localidad.idParroq"
                    validation="required|min:4|matches:/^[A-Za-z\s*\á\é\í\ó\ú\ñ]+$/"
                    validation-behavior="live"
                    :validation-messages="{
                      min: 'Debe tener al menos 4 caracteres',
                      matches: 'Este campo solo acepta letras',
                      required: 'Este campo es obligatorio',
                    }"
                  />
                  <FormulateInput
                    type="submit"
                    :disabled="hasErrors"
                    @click.prevent="editParroq(localidad.id)"
                    outer-class="
                    mx-1
                      flex
                    items-center
                    p-1
                    pt-2
                    transition
                    ease-in
                    duration-200
                    uppercase
                    rounded-full
                    border border-green-300
                    text-green-500
                    hover:bg-green-800 hover:text-yellow-200
                    focus:outline-none
                    h-8"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
                      />
                    </svg>
                  </FormulateInput>
                  <FormulateInput
                    type="submit"
                    @click="editParroqFlag = !editParroqFlag"
                    outer-class="
                    
                      flex
                    items-center
                    p-1
                    pt-2
                    transition
                    ease-in
                    duration-200
                    uppercase
                    rounded-full
                    border border-red-300
                    text-red-500
                    hover:bg-red-800 hover:text-yellow-200
                    focus:outline-none
                    h-8"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </FormulateInput>
                </FormulateForm>
              </div>
            </form>

            <div class="flex" v-if="!editParroqFlag">
              <span class="text-gray-700 mr-6 dark:text-white font-normal">
                {{ localidad.idParroq }}
              </span>
              <label class="flex items-center">
                <button
                  @click="editParroqFlag = !editParroqFlag"
                  class="
                    flex
                    items-center
                    p-1
                    transition
                    ease-in
                    duration-200
                    uppercase
                    rounded-full
                    hover:bg-blue-800 hover:text-yellow-200
                    focus:outline-none
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-3.5 w-3.5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                    />
                  </svg>
                </button>
              </label>
            </div>
          </dd>
        </div>
      </dl>
    </div>
    <div class="border-t border-gray-200">
      <dl>
        <div
          v-if="$store.state.userData"
          class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
        >
          <dt
            v-if="!editEstadoFlag"
            class="text-sm text-left font-medium text-gray-500"
          >
            Estado de residencia
          </dt>
          <dd
            class="
              mt-1
              text-sm text-gray-900
              sm:mt-0 sm:col-span-2
              capitalize
              flex
            "
          >
            <form
              v-if="editEstadoFlag"
              class="
                flex flex-col
                md:flex-row
                w-3/4
                md:w-full
                max-w-sm
                md:space-x-3
                space-y-3
                md:space-y-0
                justify-start
              "
            >
              <div class="">
                <label
                  for="price"
                  class="block text-xs font-medium text-gray-700 text-left ml-1"
                >
                  <strong>Editar Estado de residencia</strong>
                </label>
                <select
                  type="text"
                  id='"form-subscribe-Subscribe'
                  class="
                    rounded-lg
                    border-transparent
                    flex-1
                    appearance-none
                    border border-gray-300
                    w-60
                    px-2
                    py-2
                    bg-white
                    text-gray-700
                    placeholder-gray-400
                    shadow-sm
                    text-xs
                    focus:outline-none
                    focus:ring-2
                    focus:ring-purple-600
                    focus:border-transparent
                  "
                  v-model="data.idEstado"
                >
                  <option class="text-gray-300" disabled value="">
                    Seleccione
                  </option>
                  <option value="Distrito Capital">Distrito Capital</option>
                  <option value="Miranda">Miranda</option>
                  <option value="La Guaira">La Guaira</option>
                </select>
              </div>

              <div
                class="
                  flex
                  w-auto
                  text-gray-500
                  items-end
                  justify-between
                  px-2
                  pb-0.5
                  space-x-1
                "
              >
                <button
                  @click.prevent="editEstado(localidad.id)"
                  class="
                    p-1
                    flex
                    items-center
                    transition
                    ease-in
                    duration-200
                    uppercase
                    rounded-full
                    border border-green-200
                    text-green-600
                    hover:border-green-600
                    hover:bg-green-600
                    hover:text-yellow-200
                    focus:outline-none
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
                    />
                  </svg>
                </button>
                <button
                  @click="editEstadoFlag = !editEstadoFlag"
                  class="
                    flex
                    items-center
                    p-1
                    transition
                    ease-in
                    duration-200
                    uppercase
                    rounded-full
                    border border-red-300
                    text-red-500
                    hover:bg-red-800 hover:text-yellow-200
                    focus:outline-none
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </button>
              </div>
            </form>

            <div class="flex" v-if="!editEstadoFlag">
              <span class="text-gray-700 mr-6 dark:text-white font-normal">
                {{ localidad.idEstado }}
              </span>
              <label class="flex items-center">
                <button
                  @click="editEstadoFlag = !editEstadoFlag"
                  class="
                    flex
                    items-center
                    p-1
                    transition
                    ease-in
                    duration-200
                    uppercase
                    rounded-full
                    hover:bg-blue-800 hover:text-yellow-200
                    focus:outline-none
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-3.5 w-3.5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                    />
                  </svg>
                </button>
              </label>
            </div>
          </dd>
        </div>

        <div
          v-if="$store.state.userData"
          class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
        >
          <dt
            v-if="!editMunicpFlag"
            class="text-sm text-left font-medium text-gray-500"
          >
            Municipio de residencia
          </dt>
          <dd
            class="
              mt-1
              text-sm text-gray-900
              sm:mt-0 sm:col-span-2
              capitalize
              flex
            "
          >
            <div
              v-if="editMunicpFlag"
              class="
                flex flex-col
                md:flex-row
                w-3/4
                md:w-full
                max-w-sm
                md:space-x-3
                space-y-3
                md:space-y-0
                justify-start
              "
            >
              <div class="">
                <label
                  for="price"
                  class="block text-xs font-medium text-gray-700 text-left ml-1"
                >
                  <strong>Editar Municipio de residencia</strong>
                </label>
                <FormulateForm #default="{ hasErrors }" class="flex">
                  <FormulateInput
                    type="text"
                    v-model="data.idMunicp"
                    class=""
                    :value="localidad.idMunicp"
                    validation="required|min:4|matches:/^[A-Za-z\s*\á\é\í\ó\ú\ñ]+$/"
                    validation-behavior="value"
                    :validation-messages="{
                      min: 'Debe tener al menos 4 caracteres',
                      matches: 'Este campo solo acepta letras',
                      required: 'Este campo es obligatorio',
                    }"
                  />
                  <FormulateInput
                    type="submit"
                    :disabled="hasErrors"
                    @click.prevent="editMunicp(localidad.id)"
                    outer-class="
                    mx-1
                      flex
                    items-center
                    p-1
                    pt-2
                    transition
                    ease-in
                    duration-200
                    uppercase
                    rounded-full
                    border border-green-300
                    text-green-500
                    hover:bg-green-800 hover:text-yellow-200
                    focus:outline-none
                    h-8"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
                      />
                    </svg>
                  </FormulateInput>
                  <FormulateInput
                    type="submit"
                    @click="editMunicpFlag = !editMunicpFlag"
                    outer-class="
                    
                      flex
                    items-center
                    p-1
                    pt-2
                    transition
                    ease-in
                    duration-200
                    uppercase
                    rounded-full
                    border border-red-300
                    text-red-500
                    hover:bg-red-800 hover:text-yellow-200
                    focus:outline-none
                    h-8"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </FormulateInput>
                </FormulateForm>
              </div>
              <div
                class="
                  flex
                  w-auto
                  text-gray-500
                  items-end
                  justify-between
                  px-2
                  pb-0.5
                  space-x-1
                "
              ></div>
            </div>

            <div class="flex" v-if="!editMunicpFlag">
              <span class="text-gray-700 mr-6 dark:text-white font-normal">
                {{ localidad.idMunicp }}
              </span>
              <label class="flex items-center">
                <button
                  @click="editMunicpFlag = !editMunicpFlag"
                  class="
                    flex
                    items-center
                    p-1
                    transition
                    ease-in
                    duration-200
                    uppercase
                    rounded-full
                    hover:bg-blue-800 hover:text-yellow-200
                    focus:outline-none
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-3.5 w-3.5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                    />
                  </svg>
                </button>
              </label>
            </div>
          </dd>
        </div>
      </dl>
    </div>
    <loader v-if="isLoading">
      <template v-slot:msg>
        {{ msg }}
      </template>

      <template v-slot:msg1>
        {{ msg1 }}
      </template>
    </loader>
  </div>
</template>
<style scoped></style>
<script>
import axios from "axios";
import loader from "@/components/shared/loader.vue";

export default {
  name: "formLocalidad",
  components: {
    loader,
  },
  props: {
    localidad: {},
  },
  methods: {
    editarCampo() {
      this.editarFlag = !this.editarFlag;
    },
    editPais(idLocalidd) {
      let data = JSON.stringify({
        id: idLocalidd,
        idPais: this.data.idPais,
      });
      axios({
        method: "put",
        url: `https://api.postula2.com/editLocalidd/`,
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.myTokToApiComs,
        },
        data: data,
      })
        .then(() => {
          //CLG("campo editado con exito");
          this.editFinished();
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG("mission pelaste bolas in educacion");
          //CLG(error);
        })
        .finally(() => {
          //Perform action in always
          this.editPaisFlag = !this.editPaisFlag;
          this.data.idPais = "";
        });
    },
    editEstado(idLocalidd) {
      let data = JSON.stringify({
        id: idLocalidd,
        idEstado: this.data.idEstado,
      });
      axios({
        method: "put",
        url: `https://api.postula2.com/editLocalidd/`,
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.myTokToApiComs,
        },
        data: data,
      })
        .then(() => {
          //CLG("campo editado con exito");
          this.editFinished();
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG("mission pelaste bolas in educacion");
          //CLG(error);
        })
        .finally(() => {
          //Perform action in always
          this.editEstadoFlag = !this.editEstadoFlag;
          this.data.idEstado = "";
        });
    },
    editCiudad(idLocalidd) {
      let data = JSON.stringify({
        id: idLocalidd,
        idCiudad: this.data.idCiudad,
      });
      axios({
        method: "put",
        url: `https://api.postula2.com/editLocalidd/`,
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.myTokToApiComs,
        },
        data: data,
      })
        .then(() => {
          //CLG("campo editado con exito");
          this.editFinished();
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG("mission pelaste bolas in educacion");
          //CLG(error);
        })
        .finally(() => {
          //Perform action in always
          this.editCiudadFlag = !this.editCiudadFlag;
          this.data.idCiudad = "";
        });
    },
    editMunicp(idLocalidd) {
      let data = JSON.stringify({
        id: idLocalidd,
        idMunicp: this.data.idMunicp,
      });
      axios({
        method: "put",
        url: `https://api.postula2.com/editLocalidd/`,
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.myTokToApiComs,
        },
        data: data,
      })
        .then(() => {
          //CLG("campo editado con exito");
          this.editFinished();
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG("mission pelaste bolas in educacion");
          //CLG(error);
        })
        .finally(() => {
          //Perform action in always
          this.editMunicpFlag = !this.editMunicpFlag;
          this.data.idMunicp = "";
        });
    },
    editParroq(idLocalidd) {
      let data = JSON.stringify({
        id: idLocalidd,
        idParroq: this.data.idParroq,
      });
      axios({
        method: "put",
        url: `https://api.postula2.com/editLocalidd/`,
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.myTokToApiComs,
        },
        data: data,
      })
        .then(() => {
          //CLG("campo editado con exito");
          this.editFinished();
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG("mission pelaste bolas in educacion");
          //CLG(error);
        })
        .finally(() => {
          //Perform action in always
          this.editParroqFlag = !this.editParroqFlag;
          this.data.idParroq = "";
        });
    },
    editar() {
      this.editing = true;
    },
    editFinished() {
      this.editing = false;
      //https://api.postula2.com/persona/624745e3e602450004288516/experncLabors
      axios({
        method: "get",
        url: `https://api.postula2.com/persona/${this.$store.state.userData.id}/localidad`,
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.myTokToApiComs,
        },
      })
        .then((res) => {
          this.$store.state.userData.localidad = res.data;
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG("mission pelaste bolas in educacion");
          //CLG(error);
        })
        .finally(() => {
          //Perform action in always
        });
    },
    onSubmit() {
      //let dataUser = this.data;
      this.isLoading = true;
      this.data.owner = this.$store.state.userData.id;
      axios
        .post("https://api.postula2.com/localidad", this.data)
        .then(() => {
          //Perform Success Action
          //CLG("response:", res);
          this.msg = "¡Localidad registrada satisfactoriamente...!";
          //this.$store.commit("getflagLogin", redFlag);
          this.$store.commit({
            type: "getflagLogin",
            greenFlag: true,
          });
          setTimeout(() => {
            this.editFinished();
            this.data.idPais = "";
            this.data.idEstado = "";
            this.data.idCiudad = "";
            this.data.idMunicp = "";
            this.data.idParroq = "";

            this.data.owner = null;
            //this.editing = false;
            this.isLoading = false;
            this.$store.commit({
              type: "getflagLogin",
              greenFlag: null,
            });
          }, 2000);
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG(error);
          this.msg1 =
            "¡Verifique la información ingresada e intente nuevamente!";
          //this.$store.commit("getflagLogin", redFlag);
          this.$store.commit({
            type: "getflagLogin",
            redFlag: true,
          });
          setTimeout(() => {
            this.isLoading = false;
            this.$store.commit({
              type: "getflagLogin",
              redFlag: null,
            });
          }, 3000);
        })
        .finally(() => {
          //Perform action in always
        });
    },
    envioData() {
      this.$bus.$emit("userdata", this.data);
    },
  },
  // created() {
  //   if (!this.$store.state.regUser.id) {
  //     alert("Debe iniciar sesion");
  //     this.$router.push({
  //       name: "login",
  //     });
  //   }
  // },
  data() {
    return {
      editarFlag: false,

      editPaisFlag: false,
      editEstadoFlag: false,
      editCiudadFlag: false,
      editMunicpFlag: false,
      editParroqFlag: false,

      editing: false,
      isLoading: false,
      msg: null,
      msg1: null,
      data: {
        idPais: "",
        idEstado: "",
        idCiudad: "",
        idMunicp: "",
        idParroq: "",
        owner: null,
      },
    };
  },
};
</script>
