<template>
  <div class="h-auto">
    <div
      class="flex items-center text-left text-xl sm:text-md md:text-2xl dark:text-white text-gray-800 border-b border-gray-200 md:pt-4 mx-6"
    >
      <p class="">Personas perfiladas &nbsp;</p>
      <p class="text-base font-bold uppercase hidden">
        <strong>"</strong>{{ idAnuncio }}<strong>"</strong>
      </p>
    </div>
    <div
      class="mx-6 flex items-center font-light text-center text-xs sm:text-xs md:text-xs dark:text-white text-blue-500"
    >
      <span @click="volver" class="cursor-pointer">⇤ Volver</span>
    </div>
    <loader v-if="isLoading"></loader>

    <div class="bg-gray-50 m-2 p-2 rounded-lg grid overflow-auto">
      <table
        ref="table"
        id="postulados"
        summary="Postulados"
        rules="groups"
        frame="hsides"
        border="2"
        class="min-w-full leading-normal rounded-lg"
      >
        <caption>
          <p class="hidden text-left text-sm mx-2 mb-1" v-show="idAnuncio">
            <strong>Postulados al anuncio:</strong>
            <strong>"</strong>{{ idAnuncio }}<strong>"</strong>
          </p>
        </caption>

        <thead>
          <tr>
            <th
              scope="col"
              class="px-5 py-3 bg-white border-b border-gray-200 text-gray-800 text-center text-xs uppercase font-normal"
            >
              Estatus
            </th>
            <th
              scope="col"
              class="px-5 py-3 bg-white border-b border-gray-200 text-gray-800 text-center text-xs uppercase font-normal"
            >
              Nombres y Apellidos
            </th>
            <th
              scope="col"
              class="px-5 py-3 bg-white border-b border-gray-200 text-gray-800 text-center text-xs uppercase font-normal"
            >
              Email
            </th>
            <th
              scope="col"
              class="px-5 py-3 bg-white border-b border-gray-200 text-gray-800 text-center text-xs uppercase font-normal"
            >
              Teléfono
            </th>
            <th
              scope="col"
              class="py-3 bg-white border-b border-gray-200 text-gray-800 text-center text-xs uppercase font-normal"
            >
              Fecha Postulación
            </th>
            <th
              scope="col"
              class="px-5 py-3 bg-white border-b border-gray-200 text-gray-800 text-center text-xs uppercase font-normal hidden"
            >
              Perfil creado
            </th>
            <th
              scope="col"
              class="py-3 bg-white border-b border-gray-200 text-gray-800 text-center text-xs uppercase font-normal"
            >
              Acciones
            </th>
          </tr>
        </thead>
        <tbody
          v-for="persona in postulads.slice(
            10 * (currentPageActive - 1),
            10 * currentPageActive
          )"
          :key="persona.id"
        >
          <trComp :persona="persona" :userData="userData"></trComp>
        </tbody>
      </table>
      <t-pagination
        :total-items="postulads.length"
        :per-page="perPageActive"
        :limit="10"
        v-model="currentPageActive"
        aria-controls="activos"
        class="mt-6 mb-4"
      />
      <div class="">
        <button
          class="w-36 place-self-center px-4 py-2 mx-4 my-3 text-sm font-semibold text-white bg-green-600 rounded-lg shadow-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 focus:ring-offset-green-200"
          @click="tableToExcel('table', 'postulados')"
        >
          Exportar XLS
        </button>
        <button
          class="w-36 place-self-center flex-shrink-0 px-4 py-2 text-sm font-semibold text-white bg-purple-600 rounded-lg shadow-md hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-purple-200"
          @click="exportPDF"
        >
          Exportar PDF
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import jsPDF from "jspdf";
import "jspdf-autotable";
import trComp from "@/views/dashboard/trPostuladoEnAnuncio.vue";


import loader from "@/components/shared/loader.vue";
//import personDataCv from "@/components/personDataCvParaEmprs.vue";
// import personNotasEmprs from "@/components/personNotasEmprs.vue";
// import evaluacionMostrar from "@/components/EvaluacnMostrar.vue";

import axios from "axios";

import Vue from "vue";
import VueTailwind from "vue-tailwind";
import { TPagination } from "vue-tailwind/dist/components";

const settings = {
  "t-pagination": {
    component: TPagination,
    props: {
      classes: {
        wrapper: "table border-collapse text-center bg-white mx-auto shadow-sm",
        element:
          "w-8 h-8 border border-gray-200 table-cell hover:border-blue-100",
        activeElement:
          "w-8 h-8 border border-gray-200 border-blue-500 table-cell hover:border-blue-600",
        disabledElement: "w-8 h-8 border border-gray-200 table-cell",
        ellipsisElement: "w-8 h-8 border border-gray-200 hidden md:table-cell",
        activeButton:
          "bg-blue-500 w-full h-full text-white hover:bg-blue-600 transition duration-100 ease-in-out focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50",
        disabledButton:
          "opacity-25 w-full h-full cursor-not-allowed transition duration-100 ease-in-out",
        button:
          "hover:bg-blue-100 w-full h-full transition duration-100 ease-in-out focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50",
        ellipsis: "",
      },
      variants: {
        rounded: {
          wrapper: "bg-white mx-auto text-center flex space-x-2",
          element: "w-8 h-8 rounded-full",
          activeElement: "w-8 h-8 rounded-full",
          disabledElement: "w-8 h-8 rounded-full",
          ellipsisElement: "w-8 h-8 rounded-full hidden md:inline",
          activeButton:
            "border border-blue-500 bg-blue-500 w-full h-full rounded-full text-white hover:bg-blue-600 transition duration-100 ease-in-out focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50",
          disabledButton:
            "border border-gray-200  opacity-25 w-full h-full rounded-full cursor-not-allowed transition duration-100 ease-in-out",
          button:
            "border border-gray-200 hover:bg-blue-100 hover:border-blue-100 rounded-full w-full h-full transition duration-100 ease-in-out focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50",
          ellipsis: "",
        },
      },
    },
  },
};
Vue.use(VueTailwind, settings);

export default {
  name: "personasPerfiladas",
  components: {
    loader,
    trComp,
    //  personDataCv,
    // personNotasEmprs,
    // evaluacionMostrar
    //  sideBarMenu,
  },
  data() {
    return {
      hidePane2: "",
      assets: [],
      postulads: [],
      userData: [],
      idAnuncio: "",
      anuncio: {},
      nmbre: "",
      showModalVerMs: false,
      showModalVerNots: false,
      showModalCrearNots: false,

      // Paginador //
      currentPageActive: 1,
      currentPageInactive: 1,
      perPageActive: 10,

      isLoading: true,
      uri: "data:application/vnd.ms-excel;base64,",
      template:
        '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
      base64: function (s) {
        return window.btoa(unescape(encodeURIComponent(s)));
      },
      format: function (s, c) {
        return s.replace(/{(\w+)}/g, function (m, p) {
          return c[p];
        });
      },
    };
  },
  methods: {
    toggleModalVerMs: function () {
      this.showModalVerMs = !this.showModalVerMs;
    },
    toggleModalVerNots: function () {
      this.showModalVerNots = !this.showModalVerNots;
    },
    toggleModalCrearNots: function () {
      this.showModalCrearNots = !this.showModalCrearNots;
    },
    prueba: function (value) {
      console.log("persona Id:", value);
      this.consultPerson(value);
    },
    volver() {
      this.$router.go(-1);
    },
    tableToExcel() {
  const data = this.postulads; // Obtener datos de todos los postulados
  const name = 'personas_perfiladas'; // Nombre del archivo Excel

  // Verificar que hay datos para exportar
  if (!data || data.length === 0) {
    console.error('No hay datos para exportar.');
    return;
  }

  // Crear la tabla HTML a partir de los datos
  const tableContent = data.map(persona => `
    <tr>
      <td>${persona.nombreUser}</td>
      <td>${persona.apellidoUser}</td>
      <td>${persona.emailUser}</td>
      <td>${persona.telf1}</td>
      <td class="fechaPostulacion">${this.$options.filters.formatedTime(persona.createdAt)}</td>
      <td>${persona.tituloAnunc}</td>

    </tr>
  `).join('');

  // Crear el archivo Excel
  const uri = 'data:application/vnd.ms-excel;base64,';
  const template = `
    <html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40">
      <head>
        <!--[if gte mso 9]>
        <xml>
          <x:ExcelWorkbook>
            <x:ExcelWorksheets>
              <x:ExcelWorksheet>
                <x:Name>{worksheet}</x:Name>
                <x:WorksheetOptions>
                  <x:DisplayGridlines/>
                </x:WorksheetOptions>
              </x:ExcelWorksheet>
            </x:ExcelWorksheets>
          </x:ExcelWorkbook>
        </xml>
        <![endif]-->
      </head>
      <body>
        <table>
          <thead>
            <tr>
              <th colspan="5" style="text-align: center; font-size: 18px;">
                Todo los postulados hasta ahora
              </th>
            <tr>
              <th>Nombre</th>
              <th>Apellido</th>
              <th>Email</th>
              <th>Teléfono</th>
              <th class="fechaPostulacion">Fecha Postulación</th>
              <th>Nombre Anunc</th>
            </tr>
          </thead>
          <tbody>
            ${tableContent}
          </tbody>
        </table>
      </body>
    </html>
  `;
  
  const base64 = s => window.btoa(unescape(encodeURIComponent(s)));
  const format = (s, c) => s.replace(/{(\w+)}/g, (m, p) => c[p]);

  // Crear el enlace de descarga
  const link = document.createElement('a');
  link.href = uri + base64(format(template, { worksheet: name }));
  link.download = name + '.xls';
  document.body.appendChild(link);
  link.click();

  // Limpiar el enlace de descarga después de hacer clic
  document.body.removeChild(link);
},
    exportPDF() {
      var vm = this;
      var columns = [
        { title: "Nombre", dataKey: "nombreUser" },
        { title: "Apellido", dataKey: "apellidoUser" },
        { title: "Correo", dataKey: "emailUser" },
        { title: "Teléfono", dataKey: "telf1" },
      ];
      var doc = new jsPDF("p", "pt");
      doc.text("Listado de personas", 40, 40);
      doc.autoTable(columns, vm.postulads, {
        margin: { top: 60 },
      });
      doc.save("todos.pdf");
    },
    tomo(value, value2, value3) {
      this.postulads = value;
      this.idAnuncio = value2;
      this.anuncio = value3;

      //CLG("postulados: ", this.postulads);
      //CLG("anuncio Titulo: ", value2);
    },
    consultPerson: function (value) {
      axios({
        method: "get",
        url: `https://api.postula2.com/persona/?owner=${value}`,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          this.$store.commit("getUserData", res.data[0]);
          //Perform Success Action
          //CLG("mandado al commit store", res.data[0]);
          // //CLG("id de owner es: ", this.$store.state.userData.owner.id);
          // //CLG("id de anuncio es: ", this.$store.state.anuncs.id);
          // //CLG("id de analista es: ", this.$store.state.analista.id);
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG("mission pelaste bolas");
          //CLG(error);
        });
    },
  },
  filters: {
    formatedTime: function (value) {
      var date = new Date(value);

      return (
        date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate() // +
        // " " +
        //date.getHours() +
        //":" +
        // date.getMinutes() +
        //":" +
        //date.getSeconds()
      );
      ////CLG(formatedTime);
    },
    formatedAnuncActivo: function (value) {
      //var date = new Date(value);
      if (value == true) {
        return "Activo";
      } else {
        return "Inactivo";
      }
      //res.data[0].nombreUser+res.data[0].apellidoUser //CLG(formatedTime) https://api.postula2.com/registroPrimarioUser?id=;
    },
  },
  created() {
    this.userData = this.$store.state.userData;
    this.postulads = this.$store.state.perfilados;

    //console.log(this.userData + "data");
    //console.log(this.postulads + " Postulados");

    this.idAnuncio = this.$store.state.anuncs.tituloAnunc;
    this.anuncio = this.$store.state.anuncs;
  },
  mounted() {
    if (this.$store.state.regEmprs.id) {
      this.isLoading = false;
    } else {
      if (this.$store.state.regUserEmprs.empresa) {
        this.isLoading = false;
      } else {
        alert("Debe iniciar sesión o registrarse para ver esta información");
        this.$router.push({
          name: "loginEmprs",
        });
      }
    }
  },
};
</script>

<style></style>
