<template>
  <div>
    <div
      class="flex items-center text-left text-xl sm:text-md md:text-2xl dark:text-white text-gray-800 border-b border-gray-200 mt-4 mx-6"
    >
      <p class="">Postulados al anuncio: &nbsp;</p>
      <p class="text-base font-bold uppercase">
        <strong>"</strong>{{ idAnuncio }}<strong>"</strong>
      </p>
    </div>
    <div
      class="mx-6 flex items-center font-light w-1/6 text-center text-xs sm:text-xs md:text-xs dark:text-white text-blue-500"
    >
      <span @click="volver" class="cursor-pointer">⇤ Volver</span>
    </div>
    <loader v-if="isLoading"></loader>

    <div class="bg-gray-50 m-2 p-2 rounded-lg">
      <table
        ref="table"
        id="postulados"
        summary="Postulados"
        rules="groups"
        frame="hsides"
        border="2"
        class="min-w-full leading-normal rounded-lg"
      >
        <caption>
          <p class="hidden text-left text-sm mx-2 mb-1" v-show="idAnuncio">
            <strong>Postulados al anuncio:</strong>
            <strong>"</strong>{{ idAnuncio }}<strong>"</strong>
          </p>
        </caption>

        <thead>
          <tr>
            <th
              scope="col"
              class="px-5 py-3 bg-white border-b border-gray-200 text-gray-800 text-center text-xs uppercase font-normal"
            >
              #
            </th>
            <th
              scope="col"
              class="px-5 py-3 bg-white border-b border-gray-200 text-gray-800 text-center text-xs uppercase font-normal"
            >
              Nombres y Apellidos
            </th>
            <th
              scope="col"
              class="px-5 py-3 bg-white border-b border-gray-200 text-gray-800 text-center text-xs uppercase font-normal"
            >
              Email
            </th>
            <th
              scope="col"
              class="px-5 py-3 bg-white border-b border-gray-200 text-gray-800 text-center text-xs uppercase font-normal"
            >
              Teléfono
            </th>
            <th
              scope="col"
              class="px-5 py-3 bg-white border-b border-gray-200 text-gray-800 text-center text-xs uppercase font-normal"
            >
              Perfil creado 
            </th>
            <th
              scope="col"
              class="w-64 px-5 py-3 bg-white border-b border-gray-200 text-gray-800 text-center text-xs uppercase font-normal"
            >
              Acciones
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(persona, index) in postulads" :key="persona.id">
            <td class="px-3 py-3 border-b border-gray-200 bg-white text-xs">
              <div class="flex items-center">
                <div class="flex-shrink-0 hidden">
                  <a href="#" class="block relative">
                    <img
                      alt="profil"
                      src="/images/person/8.jpg"
                      class="mx-auto object-cover rounded-full h-10 w-10"
                    />
                  </a>
                </div>
                <div class="ml-3">
                  <p class="text-gray-900 whitespace-no-wrap">
                    {{ index + 1 }}
                  </p>
                </div>
              </div>
            </td>
            <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
              <p class="text-gray-900 whitespace-no-wrap">
                {{ persona.nombreUser + " " + persona.apellidoUser }}
              </p>
            </td>
            <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
              <p
                class="inset-0 bg-green-400 opacity-50 px-3 py-1 rounded-full text-green-900 font-bold whitespace-no-wrap"
              >
                {{ persona.emailUser }}
              </p>
            </td>
            <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
              <p class="text-gray-900 whitespace-no-wrap">
                {{ persona.telf1 }}
              </p>
            </td>
            <td class="py-5 border-b border-gray-200 bg-white text-sm">
              {{ persona.createdAt | formatedTime }}
            </td>

            <td class="py-5 border-b border-gray-200 bg-white text-sm">
              <div class="flex items-center mx-2">
                <button
                  v-on:click="
                    toggleModalVerMs();
                    prueba(persona.id);
                  "
                  type="button"
                  class="bg-blue-400 w-full border-l border-t border-b text-xs font-bold rounded-l-md text-white hover:bg-blue-500 px-2 py-1"
                >
                  Ver más
                </button>
                <button
                  v-on:click="toggleModalVerNots()"
                  type="button"
                  class="bg-yellow-500 w-full border text-xs font-bold text-white hover:bg-yellow-600 px-2 py-1"
                >
                  Ver notas
                </button>
                <button
                  v-on:click="toggleModalCrearNots()"
                  type="button"
                  class="bg-green-400 w-full border-t border-b border-r text-xs font-bold rounded-r-md text-white hover:bg-green-600 px-2 py-1"
                >
                  Crear nota
                </button>
              </div>
              <!--//////////////////////// showModalVerMs ////////////////////////-->
              <div>
                <div
                  v-if="showModalVerMs"
                  class="overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex"
                >
                  <div
                    class="relative w-full max-h-full my-6 mx-auto max-w-6xl"
                  >
                    <!--content-->
                    <div
                      class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
                    >
                      <!--header-->
                      <div
                        class="flex items-start justify-between lg:mt-2 px-7 py-2 border-b border-solid border-blueGray-200 rounded-t"
                      >
                        <h3 class="text-2xl">
                          Anuncio: {{ $store.state.anuncs.tituloAnunc }}
                        </h3>

                        <button
                          class="ml-auto border-0 text-black opacity-90 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                          v-on:click.prevent="toggleModalVerMs()"
                        >
                          <span
                            class="text-black opacity-80 h-6 w-6 text-3xl block outline-none focus:outline-none"
                          >
                            ×
                          </span>
                        </button>
                      </div>
                      <!--body-->
                      <div class="relative p-2 flex-auto">
                        <personDataCv></personDataCv>
                      </div>
                      <!--footer-->
                      <div
                        class="flex items-center justify-end px-6 py-2 border-t border-solid border-blueGray-200 rounded-b"
                      >
                        <button
                          class="text-red-500 bg-transparent border border-solid border-red-500 hover:bg-red-500 hover:text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                          type="button"
                          v-on:click="toggleModalVerMs()"
                        >
                          Cerrar
                        </button>
                        <button
                          class="hidden text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                          type="button"
                          v-on:click="toggleModalVerMs()"
                        >
                          Save Changes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="showModalVerMs"
                  class="opacity-25 fixed inset-0 z-40 bg-black"
                ></div>
              </div>
              <!--//////////////////////// /showModalVerMs ////////////////////////-->

              <!--//////////////////////// showModalVerNots ////////////////////////-->
              <div>
                <div
                  v-if="showModalVerNots"
                  class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex"
                >
                  <div class="relative w-auto my-6 mx-auto max-w-6xl">
                    <!--content-->
                    <div
                      class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
                    >
                      <!--header-->
                      <div
                        class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t"
                      >
                        <h3 class="text-3xl font-semibold">
                          Notas de otros reclutadores
                        </h3>

                        <button
                          class="p-1 ml-auto border-0 text-black opacity-90 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                          v-on:click="toggleModalVerNots()"
                        >
                          <span
                            class="text-black opacity-80 h-6 w-6 text-3xl block outline-none focus:outline-none"
                          >
                            ×
                          </span>
                        </button>
                      </div>
                      <!--body-->
                      <div class="relative p-6 flex-auto">
                        <p
                          class="my-4 text-blueGray-500 text-lg leading-relaxed"
                        >
                          Ver notas
                        </p>
                      </div>
                      <!--footer-->
                      <div
                        class="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b"
                      >
                        <button
                          class="text-red-500 bg-transparent border border-solid border-red-500 hover:bg-red-500 hover:text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                          type="button"
                          v-on:click="toggleModalVerNots()"
                        >
                          Cerrar
                        </button>
                        <button
                          class="hidden text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                          type="button"
                          v-on:click="toggleModalVerNots()"
                        >
                          Save Changes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="showModalVerNots"
                  class="opacity-25 fixed inset-0 z-40 bg-black"
                ></div>
              </div>
              <!--//////////////////////// /showModalVerNots ////////////////////////-->

              <!--//////////////////////// showModalCrearNots ////////////////////////-->
              <div>
                <div
                  v-if="showModalCrearNots"
                  class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex"
                >
                  <div class="relative w-auto my-6 mx-auto max-w-6xl">
                    <!--content-->
                    <div
                      class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
                    >
                      <!--header-->
                      <div
                        class="flex items-start justify-between p-5 border-b border-solid border-gray-200 rounded-t"
                      >
                        <h3 class="text-2xl font-semibold">
                          Agregar nota al perfil de:
                          <p class="text-lg text-left">
                            {{
                              persona.nombreUser + " " + persona.apellidoUser
                            }}
                          </p>
                        </h3>

                        <button
                          class="p-1 ml-auto border-0 text-black opacity-90 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                          v-on:click="toggleModalCrearNots()"
                        >
                          <span
                            class="text-black opacity-80 h-6 w-6 text-3xl block outline-none focus:outline-none"
                          >
                            ×
                          </span>
                        </button>
                      </div>
                      <!--body-->
                      <div class="relative p-6 flex-auto">
                        <personNotasEmprs></personNotasEmprs>
                      </div>
                      <!--footer-->
                      <div
                        class="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b"
                      >
                        <button
                          class="text-red-500 bg-transparent border border-solid border-red-500 hover:bg-red-500 hover:text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                          type="button"
                          v-on:click="toggleModalCrearNots()"
                        >
                          Cerrar
                        </button>
                        <button
                          class="hidden text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                          type="button"
                          v-on:click="toggleModalCrearNots()"
                        >
                          Save Changes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="showModalCrearNots"
                  class="opacity-25 fixed inset-0 z-40 bg-black"
                ></div>
              </div>
              <!--//////////////////////// /showModalCrearNots ////////////////////////-->
            </td>
          </tr>
        </tbody>
      </table>
      <button
        class="flex-shrink-0 px-4 py-2 mx-4 my-3 text-base font-semibold text-white bg-green-600 rounded-lg shadow-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 focus:ring-offset-green-200"
        @click="tableToExcel('table', 'postulados')"
      >
        Exportar XLS
      </button>
      <button
        class="flex-shrink-0 px-4 py-2 text-base font-semibold text-white bg-purple-600 rounded-lg shadow-md hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-purple-200"
        @click="exportPDF"
      >
        Exportar PDF
      </button>
    </div>
  </div>
</template>

<script>
import jsPDF from "jspdf";
import "jspdf-autotable";

import loader from "@/components/shared/loader.vue";
import personDataCv from "@/components/personDataCvParaEmprs.vue";
import personNotasEmprs from "@/components/personNotasEmprs.vue";

import axios from "axios";

export default {
  name: "listaPostulados",
  components: {
    loader,
    personDataCv,
    personNotasEmprs,
    //  sideBarMenu,
  },
  data() {
    return {
      hidePane2: "",
      assets: [],
      postulads: [],
      idAnuncio: "",
      anuncio: {},
      nmbre: "",
      showModalVerMs: false,
      showModalVerNots: false,
      showModalCrearNots: false,

      isLoading: true,
      uri: "data:application/vnd.ms-excel;base64,",
      template:
        '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
      base64: function (s) {
        return window.btoa(unescape(encodeURIComponent(s)));
      },
      format: function (s, c) {
        return s.replace(/{(\w+)}/g, function (m, p) {
          return c[p];
        });
      },
    };
  },
  methods: {
    toggleModalVerMs: function () {
      this.showModalVerMs = !this.showModalVerMs;
    },
    toggleModalVerNots: function () {
      this.showModalVerNots = !this.showModalVerNots;
    },
    toggleModalCrearNots: function () {
      this.showModalCrearNots = !this.showModalCrearNots;
    },
    prueba: function (value) {
      ////CLG("persona Id:", value);
      this.consultPerson(value);
    },
    volver() {
      this.$router.push({
        name: "AnuncioPostulds",
      });
    },
    tableToExcel(table, name) {
      if (!table.nodeType) table = this.$refs.table;
      var ctx = { worksheet: name || "Prueba1", table: table.innerHTML };
      window.location.href =
        this.uri + this.base64(this.format(this.template, ctx));
    },
    exportPDF() {
      var vm = this;
      var columns = [
        { title: "nombre", dataKey: "nombreUser" },
        { title: "apellido", dataKey: "apellidoUser" },
      ];
      var doc = new jsPDF("p", "pt");
      doc.text("To Do List", 40, 40);
      doc.autoTable(columns, vm.postulads, {
        margin: { top: 60 },
      });
      doc.save("todos.pdf");
    },
    tomo(value, value2, value3) {
      this.postulads = value;
      this.idAnuncio = value2;
      this.anuncio = value3;

      //CLG("postulados: ", this.postulads);
      //CLG("anuncio Titulo: ", value2);
    },
    consultPerson: function (value) {
      axios({
        method: "get",
        url: `https://api.postula2.com/persona/?owner=${value}`,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          this.$store.commit("getUserData", res.data[0]);
          //Perform Success Action
          //CLG("mandado al commit store", res.data[0]);
          // //CLG("id de owner es: ", this.$store.state.userData.owner.id);
          // //CLG("id de anuncio es: ", this.$store.state.anuncs.id);
          // //CLG("id de analista es: ", this.$store.state.analista.id);
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG("mission pelaste bolas");
          //CLG(error);
        });
    },
  },
  filters: {
    formatedTime: function (value) {
      var date = new Date(value);

      return (
        date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate() // +
        // " " +
        //date.getHours() +
        //":" +
        // date.getMinutes() +
        //":" +
        //date.getSeconds()
      );
      ////CLG(formatedTime);
    },
    formatedAnuncActivo: function (value) {
      //var date = new Date(value);
      if (value == true) {
        return "Activo";
      } else {
        return "Inactivo";
      }
      //res.data[0].nombreUser+res.data[0].apellidoUser //CLG(formatedTime) https://api.postula2.com/registroPrimarioUser?id=;
    },
  },
  created() {
    this.postulads = this.$store.state.anuncs.registroPrimarioUsers;
    this.idAnuncio = this.$store.state.anuncs.tituloAnunc;
    this.anuncio = this.$store.state.anuncs;
  },
  mounted() {
    if (this.$store.state.regEmprs.id) {
      this.isLoading = false;
    } else {
      if (this.$store.state.regUserEmprs.empresa) {
        this.isLoading = false;
      } else {
        alert("Debe iniciar sesión o registrarse para ver esta información");
        this.$router.push({
          name: "loginEmprs",
        });
      }
    }
  },
};
</script>

<style></style>
