<template>
  <div class="flex border border-red-100 rounded-lg p-4 justify-center">
    <form @submit.stop.prevent="onSubmit">
      <p class="mb-2 font-bold text-base">Nueva observación</p>
      <div class="bg-white max-w-xl shadow overflow-auto sm:rounded-lg">
        <div class="flex relative">
          <span
            class="rounded-l-md inline-flex items-center px-3 border-t bg-white border-l border-b border-gray-300 text-gray-500 shadow-sm text-sm"
          >
            <svg
              width="15"
              height="15"
              fill="currentColor"
              viewBox="0 0 1792 1792"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1792 710v794q0 66-47 113t-113 47h-1472q-66 0-113-47t-47-113v-794q44 49 101 87 362 246 497 345 57 42 92.5 65.5t94.5 48 110 24.5h2q51 0 110-24.5t94.5-48 92.5-65.5q170-123 498-345 57-39 100-87zm0-294q0 79-49 151t-122 123q-376 261-468 325-10 7-42.5 30.5t-54 38-52 32.5-57.5 27-50 9h-2q-23 0-50-9t-57.5-27-52-32.5-54-38-42.5-30.5q-91-64-262-182.5t-205-142.5q-62-42-117-115.5t-55-136.5q0-78 41.5-130t118.5-52h1472q65 0 112.5 47t47.5 113z"
              ></path>
            </svg>
          </span>
          <input
            :maxlength="max"
            v-model.lazy.trim="data.comment"
            type="text"
            id="email-with-icon"
            class="rounded-r-lg flex-1 appearance-none border border-gray-300 w-96 py-2 px-4< bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
            name="email"
            placeholder="Escriba un comentario"
          />
        </div>

        <div class="form-group hidden">
          <div class="input-group">
            <div class="input-group-addon" v-text="max - text.length"></div>
          </div>
        </div>

        <div class="flex items-center gap-2 mt-4">
          <label
            class="inline-flex items-center border border-solid border-gray-200 p-1 my-1 rounded-md"
          >
            <input
              v-model.lazy.trim="data.dataRevisd"
              type="checkbox"
              name="dataRevisd"
              class="h-5 w-5 text-red-600"
              value="true"
              checked
            />
            <span class="ml-2 text-gray-700"> Info. revisada </span>
          </label>
          <label
            class="inline-flex items-center border border-solid border-gray-200 p-1 my-1 rounded-md"
          >
            <input
              v-model.lazy.trim="data.dataEvaluacn"
              type="checkbox"
              name="dataEvaluacn"
              class="h-5 w-5 text-red-600"
            />
            <span class="ml-2 text-gray-700"> Info. en evaluación </span>
          </label>
          <label
            class="inline-flex items-center border border-solid border-gray-200 p-1 my-1 rounded-md"
          >
            <input
              v-model.lazy.trim="data.dataFaseFinal"
              type="checkbox"
              name="dataFaseFinal"
              class="h-5 w-5 text-red-600"
            />
            <span class="ml-2 text-gray-700"> Info. en fase final </span>
          </label>
          <label
            class="inline-flex items-center border border-solid border-gray-200 p-1 my-1 rounded-md"
          >
            <input
              v-model.lazy.trim="data.dataContctdo"
              type="checkbox"
              name="dataContctdo"
              class="h-5 w-5 text-red-600"
            />
            <span class="ml-2 text-gray-700"> Contactado </span>
          </label>
        </div>
        <div class="p-4 w-full md:w-1/2 mx-auto">
          <button
            class="flex-shrink-0 px-4 py-2 text-base font-semibold text-white backasista rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2"
            type="submit"
          >
            Enviar
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import jsPDF from "jspdf";
import "jspdf-autotable";
import axios from "axios";

export default {
  data() {
    return {
      data: {
        comment: "",
        dataRevisd: false,
        dataEvaluacn: false,
        dataFaseFinal: false,
        dataContctdo: false,
        idRegistroPrimarioUser: "",
        idAnalist: "",
        idAnuncio: "",
      },
      max: 200,
      text: "",
      uri: "data:application/vnd.ms-excel;base64,",
      template:
        '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
      base64: function (s) {
        return window.btoa(unescape(encodeURIComponent(s)));
      },
      format: function (s, c) {
        return s.replace(/{(\w+)}/g, function (m, p) {
          return c[p];
        });
      },
    };
  },
  methods: {
    onSubmit() {
      if (this.$store.state.analista.id)
        this.data.idAnalist = this.$store.state.analista.id;
      else this.data.idAnalist = null;

      this.data.idRegistroPrimarioUser = this.$store.state.userData.owner.id;
      //  this.data.idAnalist = this.$store.state.analista ? this.$store.state.analista.id : null ;
      this.data.idAnuncio = this.$store.state.anuncs.id;
      //CLG("esta data es", this.data);
      axios({
        method: "post",
        url: `https://api.postula2.com/createEvaluacnAnalist`,
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.myTokToApiComs,
        },
        data: this.data,
      })
        .then((res) => {
          //Perform Success Action
          //CLG("mission acomplished");
          //CLG("respuesta post id evaluacion registrada", res.data);
          var newEval = this.$store.state.personaEval;
          newEval.push(res.data.createdEval);
          //console.log("newEval sera ", newEval);
          this.$store.commit("getPersonEvaluacn", newEval);
          this.data.comment = "";
          this.data.dataRevisd = false;
          this.data.dataEvaluacn = false;
          this.data.dataFaseFinal = false;
          this.data.dataContctdo = false;
          //this.$store.commit("getRegUserEmprs", res.data);
          //alert("Evaluación registrada con exito");
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG("mission pelaste bolas");
          //CLG(error);
        })
        .finally(() => {
          //Perform action in always
        });
    },
    tableToExcel(table, name) {
      if (!table.nodeType) table = this.$refs.table;
      var ctx = { worksheet: name || "Prueba1", table: table.innerHTML };
      window.location.href =
        this.uri + this.base64(this.format(this.template, ctx));
    },
    exportPDF() {
      var vm = this;
      var columns = [
        {
          title: "nombre",
          dataKey: this.$store.state.userData.owner.nombreUser,
        },
        { title: "apellido", dataKey: "apellidoUser" },
      ];
      var doc = new jsPDF("p", "pt");
      doc.text("To Do List", 40, 40);
      doc.autoTable(columns, vm.postulads, {
        margin: { top: 60 },
      });
      doc.save("todos.pdf");
    },
  },
  filters: {
    formatedTime: function (value) {
      var date = new Date(value);

      return (
        date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
        // " " +
        //date.getHours() +
        //":" +
        // date.getMinutes() +
        //":" +
        //date.getSeconds()
      );
      ////CLG(formatedTime);
    },
  },
};
</script>

<style scoped>
.text-xs2 {
  font-size: 0.62rem;
}
input[type="checkbox"] {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  background: white;
  border-radius: 5px;
  border: 2px solid #555;
}
input[type="checkbox"]:checked {
  background: rgb(33, 34, 36);
}
</style>
