var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.isLoading)?_c('loader'):_vm._e(),_c('main',{staticClass:"bg-gray-50 dark:bg-gray-800 h-full max-h-full overflow-y-auto"},[_c('div',{staticClass:"flex items-start justify-between"},[_c('div',{staticClass:"flex flex-col w-full md:space-y-4"},[_c('div',{staticClass:"overflow-visible h-screen pb-24 px-4 md:px-6 md:pt-4 md:pb-8 lg:pt-6"},[_c('div',{staticClass:"flex items-center space-x-4 font-light w-full text-center text-xl sm:text-md md:text-2xl dark:text-white text-gray-800 border-b border-gray-200"},[_vm._v(" Anuncios publicados ")]),_c('div',{staticClass:"flex items-center mt-0.5 font-light w-1/6 text-center text-xs sm:text-xs md:text-xs dark:text-white text-blue-500"},[(!this.$store.state.analista.id)?_c('span',{staticClass:"cursor-pointer",on:{"click":_vm.volver}},[_vm._v("⇤ Volver")]):_c('span',{staticClass:"cursor-pointer",on:{"click":_vm.volverAnalista}},[_vm._v("⇤ Volver")])]),_c('h1',{staticClass:"mt-2 ml-2 text-lg text-left font-semibold border-b border-gray-200"},[_vm._v(" Anuncios activos ")]),_c('div',{staticClass:"grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-2 mb-4 ml-2"},_vm._l((_vm.anuncsActivs.slice(
                6 * (_vm.currentPageActive - 1),
                6 * _vm.currentPageActive
              )),function(anuncio){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLoading),expression:"!isLoading"}],key:anuncio.id,staticClass:"w-full",attrs:{"id":"activos"}},[_c('p',{staticClass:"hidden text-sm w-max text-gray-700 dark:text-white font-semibold border-b border-gray-200"},[_vm._v(" Project Reffered ")]),_c('AnuncioActivo',{key:anuncio.id,attrs:{"anuncsActivs":anuncio}})],1)}),0),_c('t-pagination',{staticClass:"mt-6 mb-4",attrs:{"total-items":_vm.anuncsActivs.length,"per-page":_vm.perPageActive,"limit":6,"aria-controls":"activos"},model:{value:(_vm.currentPageActive),callback:function ($$v) {_vm.currentPageActive=$$v},expression:"currentPageActive"}}),_c('h1',{staticClass:"mt-8 ml-2 text-lg text-left font-semibold border-b border-gray-200"},[_vm._v(" Anuncios inactivos ")]),_c('div',{staticClass:"grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-2 mb-4 ml-2"},_vm._l((_vm.anuncsInactivs.slice(
                6 * (_vm.currentPageInactive - 1),
                6 * _vm.currentPageInactive
              )),function(anuncio){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLoading),expression:"!isLoading"}],key:anuncio.id,staticClass:"w-full"},[_c('p',{staticClass:"hidden text-sm w-max text-gray-700 dark:text-white font-semibold border-b border-gray-200"},[_vm._v(" Project Reffered ")]),_c('AnuncioInactivo',{key:anuncio.id,attrs:{"anuncsInactivs":anuncio}})],1)}),0),_c('t-pagination',{staticClass:"mt-6 mb-4",attrs:{"total-items":_vm.anuncsInactivs.length,"per-page":_vm.perPageInactive,"limit":6,"aria-controls":"activos"},model:{value:(_vm.currentPageInactive),callback:function ($$v) {_vm.currentPageInactive=$$v},expression:"currentPageInactive"}})],1)])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }