<template>
  <div class="py-10 ">
    <div class="flex-col bg-gray-100 rounded-lg dark:bg-gray-800">
      <div
        class="
          self-center
          mb-5
          text-xl
          font-bold
          text-gray-800
          sm:text-md
          dark:text-white
          w-full
          text-center
        "
      >
        Registro de Empresa
      </div>

      <div class="flex flex-col">
        <form @submit.stop.prevent="onSubmit">
          <div
            class="
              self-center
              mb-2
              font-semibold
              text-gray-800
              sm:text-md
              dark:text-white
              w-full
            "
          >
            Información de usuario
          </div>

          <hr class="mx-2" />

          <div class="grid grid-rows-1 grid-flow-col max-w-full">
            <div class="row-span-full hidden">
              <div class="p-3">
                <div class="flex-col flex justify-center items-center">
                  <div class="flex-shrink-0">
                    <a href="#" class="block relative">
                      <img
                        alt="profil"
                        :src="require('./img/img-profile.jpg').default"
                        class="mx-auto object-cover rounded-full h-20 w-20"
                      />
                    </a>
                  </div>
                  <div class="text-center flex flex-col">
                    <span
                      class="text-gray-900 dark:text-white text-sm font-medium"
                    >
                      Skin solutions
                    </span>
                    <a class="text-gray-500 text-xs" href="#">
                      Cambiar imagen
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div class="row-span-auto p-1 mb-2">
              <div class="flex gap-2 pt-5">
                <div class="relative w-full md:w-1/2">
                  <input
                    type="text"
                    id="empresa_responsable_name"
                    class="
                      rounded-lg
                      border-transparent
                      flex-1
                      appearance-none
                      border border-gray-300
                      w-full
                      py-1.5
                      px-4
                      text-gray-700
                      placeholder-gray-400
                      shadow-sm
                      text-sm
                      focus:outline-none
                      focus:ring-2
                      focus:ring-purple-600
                      focus:border-transparent
                      bg-gray-200
                      font-semibold
                    "
                    disabled
                    name="nombreUser"
                    :placeholder="
                      $store.state.regUserEmprs.nombreUserEmprs +
                      ' ' +
                      $store.state.regUserEmprs.apellidoUserEmprs
                    "
                  />
                </div>
                <div class="relative w-full md:w-1/2">
                  <input
                    type="text"
                    id="empresa_responsable_name"
                    class="
                      rounded-lg
                      border-transparent
                      flex-1
                      appearance-none
                      border border-gray-300
                      w-full
                      py-1.5
                      px-4
                      text-gray-700
                      placeholder-gray-400
                      shadow-sm
                      text-sm
                      focus:outline-none
                      focus:ring-2
                      focus:ring-purple-600
                      focus:border-transparent
                      bg-gray-200
                      font-semibold
                    "
                    name="email"
                    disabled
                    :placeholder="$store.state.regUserEmprs.emailUserEmprs"
                  />
                </div>
              </div>
            </div>

            <div class="row-span-auto p-1 hidden">
              <div class="flex gap-2">
                <div class="relative w-full md:w-1/2">
                  <input
                    type="text"
                    id="empresa_responsable_name"
                    class="
                      rounded-lg
                      border-transparent
                      flex-1
                      appearance-none
                      border border-gray-300
                      w-full
                      py-1.5
                      px-4
                      text-gray-700
                      placeholder-gray-400
                      shadow-sm
                      text-sm
                      focus:outline-none
                      focus:ring-2
                      focus:ring-purple-600
                      focus:border-transparent
                      bg-gray-200
                      font-semibold
                    "
                    name="email"
                    disabled
                    :placeholder="$store.state.regUserEmprs.emailUserEmprs"
                  />
                </div>
                <div class="relative w-full md:w-1/2">
                  <input
                    type="text"
                    id="empresa_responsable_telef"
                    class="
                      hidden
                      rounded-lg
                      border-transparent
                      flex-1
                      appearance-none
                      border border-gray-300
                      w-full
                      py-1.5
                      px-4
                      text-gray-700
                      placeholder-gray-400
                      shadow-sm
                      text-sm
                      focus:outline-none
                      focus:ring-2
                      focus:ring-purple-600
                      focus:border-transparent
                      bg-gray-200
                      font-semibold
                    "
                    disabled
                    name="passw"
                    placeholder="Contraseña"
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            class="
              self-center
              mb-1
              font-semibold
              text-gray-800
              sm:text-md
              dark:text-white
              w-full
            "
          >
            Datos de empresa
          </div>
          <hr class="mt-1 mb-2" />

          <div class="flex gap-2 mb-2">
            <div class="relative w-full md:w-1/2">
              <input
                type="text"
                id="create-account-first-name"
                class="
                  rounded-lg
                  border-transparent
                  flex-1
                  appearance-none
                  border border-gray-300
                  w-full
                  py-1.5
                  px-6
                  bg-white
                  text-gray-700
                  placeholder-gray-400
                  shadow-sm
                  text-sm
                  focus:outline-none
                  focus:ring-2
                  focus:ring-purple-600
                  focus:border-transparent
                "
                name="nomcomercial"
                placeholder="Nombre comercial"
                v-model.lazy.trim="data.nombreEmpr"
              />
            </div>
            <div class="relative w-full md:w-1/2">
              <input
                type="text"
                id="create-account-last-name"
                class="
                  rounded-lg
                  border-transparent
                  flex-1
                  appearance-none
                  border border-gray-300
                  w-full
                  py-1.5
                  px-6
                  bg-white
                  text-gray-700
                  placeholder-gray-400
                  shadow-sm
                  text-sm
                  focus:outline-none
                  focus:ring-2
                  focus:ring-purple-600
                  focus:border-transparent
                "
                name="razonsocial"
                placeholder="Razón social registrada"
                v-model.lazy.trim="data.razonSocialEmpr"
              />
            </div>
          </div>

          <div class="flex gap-2 mb-2">
            <div class="relative w-full md:w-1/2">
              <input
                type="text"
                id="create-account-first-name"
                class="
                  rounded-lg
                  border-transparent
                  flex-1
                  appearance-none
                  border border-gray-300
                  w-full
                  py-1.5
                  px-6
                  bg-white
                  text-gray-700
                  placeholder-gray-400
                  shadow-sm
                  text-sm
                  focus:outline-none
                  focus:ring-2
                  focus:ring-purple-600
                  focus:border-transparent
                "
                name="idfiscal"
                placeholder="Rif/Id. Fiscal"
                v-model.lazy.trim="data.rifEmpr"
              />
            </div>
            <div class="relative w-full md:w-1/2">
              <input
                type="text"
                id="create-account-last-name"
                class="
                  rounded-lg
                  border-transparent
                  flex-1
                  appearance-none
                  border border-gray-300
                  w-full
                  py-1.5
                  px-6
                  bg-white
                  text-gray-700
                  placeholder-gray-400
                  shadow-sm
                  text-sm
                  focus:outline-none
                  focus:ring-2
                  focus:ring-purple-600
                  focus:border-transparent
                "
                name="numempleados"
                placeholder="Numero de trabajadores"
                v-model.lazy.trim="data.cantEmpleads"
              />
            </div>
          </div>

          <div class="flex flex-wrap -mx-3 mb-2 hidden">
            <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <div class="relative">
                <select
                  class="
                    block
                    appearance-none
                    w-full
                    bg-gray-200
                    border border-gray-200
                    text-gray-700
                    py-1.5
                    px-5
                    pr-8
                    text-sm
                    rounded-md
                    leading-tight
                    focus:outline-none focus:bg-white focus:border-gray-500
                  "
                  id="grid-state"
                  placeholder="País"
                >
                  <option>País</option>
                  <option>Venezuela</option>
                  <option>colombia</option>
                </select>
                <div
                  class="
                    pointer-events-none
                    absolute
                    inset-y-0
                    right-0
                    flex
                    items-center
                    px-2
                    text-gray-700
                  "
                >
                  <svg
                    class="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                    />
                  </svg>
                </div>
              </div>
            </div>

            <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <div class="relative">
                <select
                  class="
                    block
                    appearance-none
                    w-full
                    bg-gray-200
                    border border-gray-200
                    text-gray-700
                    py-1.5
                    px-5
                    pr-8
                    text-sm
                    rounded-md
                    leading-tight
                    focus:outline-none focus:bg-white focus:border-gray-500
                  "
                  id="grid-state"
                >
                  <option>Estado</option>
                  <option>Dto. Capital</option>
                  <option>Miranda</option>
                </select>
                <div
                  class="
                    pointer-events-none
                    absolute
                    inset-y-0
                    right-0
                    flex
                    items-center
                    px-2
                    text-gray-700
                  "
                >
                  <svg
                    class="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <div class="relative">
                <select
                  class="
                    block
                    appearance-none
                    w-full
                    bg-gray-200
                    border border-gray-200
                    text-gray-700
                    py-1.5
                    px-5
                    pr-8
                    rounded-md
                    text-sm
                    leading-tight
                    focus:outline-none focus:bg-white focus:border-gray-500
                  "
                  id="grid-state"
                >
                  <option>Ciudad</option>
                  <option>Pto. La Cruz</option>
                  <option>Barquisimeto</option>
                </select>
                <div
                  class="
                    pointer-events-none
                    absolute
                    inset-y-0
                    right-0
                    flex
                    items-center
                    px-2
                    text-gray-700
                  "
                >
                  <svg
                    class="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>

          <div class="flex flex-wrap mb-2 hidden">
            <select
              class="
                block
                w-64
                text-gray-700
                py-1.5
                px-3
                border border-gray-300
                bg-white
                rounded-md
                shadow-sm
                text-sm
                focus:outline-none
                focus:ring-primary-500
                focus:border-primary-500
              "
              name="animals"
            >
              <option value="">Sector empresarial</option>
              <option value="dog">Industria</option>
              <option value="cat">Salud</option>
              <option value="hamster">Alimentación</option>
            </select>
          </div>

          <div class="flex flex-col mb-1">
            <div class="relative">
              <label class="text-gray-700" for="name">
                <textarea
                  class="
                    flex-1
                    appearance-none
                    border border-gray-300
                    w-full
                    py-1.5
                    px-4
                    bg-white
                    text-gray-700
                    placeholder-gray-400
                    rounded-md
                    text-sm
                    focus:outline-none
                    focus:ring-2
                    focus:ring-purple-600
                    focus:border-transparent
                  "
                  id="comment"
                  placeholder="Dirección"
                  v-model.lazy.trim="data.direccEmpr"
                  name="comment"
                  rows="2"
                  cols="40"
                >
                </textarea>
              </label>
            </div>
          </div>

          <div class="flex flex-col mb-2">
            <div class="relative">
              <label class="text-gray-700" for="name">
                <textarea
                  class="
                    flex-1
                    appearance-none
                    border border-gray-300
                    w-full
                    py-1.5
                    px-4
                    bg-white
                    text-gray-700
                    placeholder-gray-400
                    rounded-lg
                    text-sm
                    focus:outline-none
                    focus:ring-2
                    focus:ring-purple-600
                    focus:border-transparent
                  "
                  id="comment"
                  placeholder="Breve descripción de la empresa (visible al público)"
                  v-model.lazy.trim="data.descEmpr"
                  name="comment"
                  rows="3"
                  cols="40"
                >
                </textarea>
              </label>
            </div>
          </div>

          <div class="flex flex-wrap justify-center mt-6">
            <div class="my-1 mr-1 hidden">
              <button
                class="
                  flex
                  items-center
                  p-3
                  ml-2
                  transition
                  ease-in
                  duration-200
                  uppercase
                  rounded-full
                  bg-yellow-400
                  hover:bg-gray-800 hover:text-white
                  border-1 border-yellow-500
                  focus:outline-none
                "
              >
                <svg
                  width="20"
                  height="20"
                  fill=""
                  viewBox="0 0 2304 1792"
                  class=""
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1728 448l-384 704h768zm-1280 0l-384 704h768zm821-192q-14 40-45.5 71.5t-71.5 45.5v1291h608q14 0 23 9t9 23v64q0 14-9 23t-23 9h-1344q-14 0-23-9t-9-23v-64q0-14 9-23t23-9h608v-1291q-40-14-71.5-45.5t-45.5-71.5h-491q-14 0-23-9t-9-23v-64q0-14 9-23t23-9h491q21-57 70-92.5t111-35.5 111 35.5 70 92.5h491q14 0 23 9t9 23v64q0 14-9 23t-23 9h-491zm-181 16q33 0 56.5-23.5t23.5-56.5-23.5-56.5-56.5-23.5-56.5 23.5-23.5 56.5 23.5 56.5 56.5 23.5zm1088 880q0 73-46.5 131t-117.5 91-144.5 49.5-139.5 16.5-139.5-16.5-144.5-49.5-117.5-91-46.5-131q0-11 35-81t92-174.5 107-195.5 102-184 56-100q18-33 56-33t56 33q4 7 56 100t102 184 107 195.5 92 174.5 35 81zm-1280 0q0 73-46.5 131t-117.5 91-144.5 49.5-139.5 16.5-139.5-16.5-144.5-49.5-117.5-91-46.5-131q0-11 35-81t92-174.5 107-195.5 102-184 56-100q18-33 56-33t56 33q4 7 56 100t102 184 107 195.5 92 174.5 35 81z"
                  ></path>
                </svg>
              </button>
              <label for="">Guardar</label>
            </div>

            <div class="my-1 mx-2">
              <button
                @click="onSubmit"
                type="button"
                class="
                  uppercase
                  py-1.5
                  pl-4
                  pr-3
                  rounded-md
                  flex
                  justify-center
                  items-center
                  focus:ring-postul2 focus:ring-offset-color-postul2
                  transition
                  ease-in
                  duration-200
                  text-center text-sm text-black
                  font-semibold
                  shadow-md
                  focus:outline-none focus:ring-2 focus:ring-offset-2
                  border-postul2
                  hover:border-postul2
                  hover:text-postul2
                  hover:border-transparent
                  hover:text-teal
                  border-2
                "
              >
                Completar Registro inicial
                <span class="ml-2">
                  <svg
                    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    width="24"
                    version="1.1"
                    xmlns:cc="http://creativecommons.org/ns#"
                    xmlns:dc="http://purl.org/dc/elements/1.1/"
                  >
                    <g transform="translate(0 -1028.4)">
                      <path
                        d="m22 12c0 5.523-4.477 10-10 10-5.5228 0-10-4.477-10-10 0-5.5228 4.4772-10 10-10 5.523 0 10 4.4772 10 10z"
                        transform="translate(0 1029.4)"
                        fill="#27ae60"
                      />
                      <path
                        d="m22 12c0 5.523-4.477 10-10 10-5.5228 0-10-4.477-10-10 0-5.5228 4.4772-10 10-10 5.523 0 10 4.4772 10 10z"
                        transform="translate(0 1028.4)"
                        fill="#2ecc71"
                      />
                      <path
                        d="m16 1037.4-6 6-2.5-2.5-2.125 2.1 2.5 2.5 2 2 0.125 0.1 8.125-8.1-2.125-2.1z"
                        fill="#27ae60"
                      />
                      <path
                        d="m16 1036.4-6 6-2.5-2.5-2.125 2.1 2.5 2.5 2 2 0.125 0.1 8.125-8.1-2.125-2.1z"
                        fill="#ecf0f1"
                      />
                    </g>
                  </svg>
                </span>
              </button>
            </div>

            <div class="my-1 mr-1 hidden">
              <button
                class="
                  flex
                  items-center
                  p-4
                  transition
                  ease-in
                  duration-200
                  uppercase
                  rounded-full
                  hover:bg-gray-800 hover:text-white
                  border-2 border-gray-900
                  focus:outline-none
                "
              >
                <svg
                  width="20"
                  height="20"
                  fill="currentColor"
                  viewBox="0 0 2304 1792"
                  class=""
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1728 448l-384 704h768zm-1280 0l-384 704h768zm821-192q-14 40-45.5 71.5t-71.5 45.5v1291h608q14 0 23 9t9 23v64q0 14-9 23t-23 9h-1344q-14 0-23-9t-9-23v-64q0-14 9-23t23-9h608v-1291q-40-14-71.5-45.5t-45.5-71.5h-491q-14 0-23-9t-9-23v-64q0-14 9-23t23-9h491q21-57 70-92.5t111-35.5 111 35.5 70 92.5h491q14 0 23 9t9 23v64q0 14-9 23t-23 9h-491zm-181 16q33 0 56.5-23.5t23.5-56.5-23.5-56.5-56.5-23.5-56.5 23.5-23.5 56.5 23.5 56.5 56.5 23.5zm1088 880q0 73-46.5 131t-117.5 91-144.5 49.5-139.5 16.5-139.5-16.5-144.5-49.5-117.5-91-46.5-131q0-11 35-81t92-174.5 107-195.5 102-184 56-100q18-33 56-33t56 33q4 7 56 100t102 184 107 195.5 92 174.5 35 81zm-1280 0q0 73-46.5 131t-117.5 91-144.5 49.5-139.5 16.5-139.5-16.5-144.5-49.5-117.5-91-46.5-131q0-11 35-81t92-174.5 107-195.5 102-184 56-100q18-33 56-33t56 33q4 7 56 100t102 184 107 195.5 92 174.5 35 81z"
                  ></path>
                </svg>
              </button>
            </div>

            <loader v-if="isLoading">
              <template v-slot:msg>
                {{ msg }}
              </template>

              <template v-slot:msg1>
                {{ msg1 }}
              </template>
            </loader>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import loader from "@/components/shared/loader.vue";

export default {
  name: "Empresa",
  components: { loader },

  data() {
    return {
      isLoading: false,
      msg: null,
      msg1: null,
      objectUserData: {},
      data: {
        nombreEmpr: "",
        rifEmpr: "",
        descEmpr: "",
        cantEmpleads: "",
        direccEmpr: "",
        razonSocialEmpr: "",
        owner: null,
      },
    };
  },
  methods: {
    onSubmit() {
      this.isLoading = true;
      //let dataUser=this.data;
      this.data.owner = this.$store.state.regUserEmprs.id;

      axios({
        method: "post",
        //url: `https://api.postula2.com/persona/?owner=${value}`,personByOwnerId
        url: `https://api.postula2.com/newEmpresa`,
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.myTokToApiComs,
        },
        data: this.data,
      })
        .then((res) => {
          //Perform Success Action
          this.$store.commit("getEmprs", res.data);
          this.msg =
            "Empresa registrada satisfactoriamente.. Inicia sesión ahora para comenzar..";
          //this.$store.commit("getflagLogin", redFlag);
          this.$store.commit({
            type: "getflagLogin",
            greenFlag: true,
          });
          setTimeout(() => {
            this.isLoading = false;
            this.$store.commit({
              type: "getflagLogin",
              greenFlag: null,
            });

            this.$router.push({
              name: "loginEmprs",
            });
          }, 2500);
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG("mission pelaste bolas in empresa");
          //CLG(error);
          this.msg1 =
            "¡Verifique la información ingresada e intente nuevamente!";
          //this.$store.commit("getflagLogin", redFlag);
          this.$store.commit({
            type: "getflagLogin",
            redFlag: true,
          });
          setTimeout(() => {
            this.isLoading = false;
            this.$store.commit({
              type: "getflagLogin",
              redFlag: null,
            });
          }, 3000);
        })
        .finally(() => {
          //  //Perform action in always
          //CLG("mission terminated in empresa");
        });
    },
  },
  created() {
    if (!this.$store.state.regUserEmprs.id) {
      this.$router.push({
        name: "homec",
      });
    }
  },
};
</script>
