<!--Este es un componente de prueba, se esta llamando en el router, hay que eliminiarlo si deja de ser necesario-->

<template>
  <div>
    <div
      v-if="loading"
      class="bg-gray-600 w-full absolute z-50 skeleton-loader flex flex-col items-center justify-center h-screen"
    >
      <loadermini>
        <template v-slot:msg>
          {{ msg }}
        </template>

        <template v-slot:msg1>
          {{ msg1 }}
        </template>
      </loadermini>
      <div class="text-center">
        <div>
          <span class="text-black">Cargando perfiles...</span>
        </div>
      </div>
      <div
        class="border border-blue-300 shadow rounded-md p-4 max-w-sm w-full mx-auto mb-44"
      >
        <div class="animate-pulse flex space-x-4">
          <div class="rounded-full bg-blue-400 h-12 w-12"></div>
          <div class="flex-1 space-y-4 py-1">
            <div class="h-4 bg-blue-400 rounded w-3/4"></div>
            <div class="space-y-2">
              <div class="h-4 bg-blue-400 rounded"></div>
              <div class="h-4 bg-blue-400 rounded w-5/6"></div>
            </div>
          </div>
        </div>
      </div>
      <!--<h3 class="text-white">Cargando contenido</h3>-->
    </div>
    <div v-else id="root" class="w-12/12 h-3/5 bg-gray-50 relative">
      <ais-instant-search :search-client="searchClient" :index-name="Idemprs">
        <ais-configure
          :attributes-to-snippet.camel="['description:10']"
          :snippet-ellipsis-text.camel="'…'"
          :remove-words-if-no-result.camel="'allOptional'"
        />

        <header
          class="header_compont w-full max-w-full mx-auto flex justify-center items-center rounded-t-lg"
          id="header"
        >
          <ais-search-box
            placeholder="Cargo o área …"
            class="max-w-md mx-auto mt-36"
          >
            <template #submit-icon>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 18 18"
                width="16"
                height="16"
              >
                <g
                  fill="none"
                  fill-rule="evenodd"
                  stroke="#134892"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.67"
                  transform="translate(1 1)"
                >
                  <circle cx="7.11" cy="7.11" r="7.11" />
                  <path d="M16 16l-3.87-3.87" />
                </g>
              </svg>
            </template>
          </ais-search-box>
        </header>

        <div class="m-4">
          <button
            @click="llamarServicioPersona"
            :disabled="!estadoBoton"
            type="button"
            class="text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800"
          >
            <svg
              class="inline w-4 h-4 text-gray-800 dark:text-white animate-spin"
              :class="{ hidden: !mostrarIcono }"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 18 20"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M16 1v5h-5M2 19v-5h5m10-4a8 8 0 0 1-14.947 3.97M1 10a8 8 0 0 1 14.947-3.97"
              />
            </svg>
            Actualizar Postulados
          </button>
        </div>

        <main class="container bg-gray-50 w-full max-w-full mx-auto p-0">
          <div class="container-wrapper">
            <section class="container-filters m-0 lg:mx-3">
              <div class="container-header">
                <h2>Filtros</h2>

                <ais-clear-refinements data-layout="desktop">
                  <template #resetLabel>
                    <div class="clear-filters">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        viewBox="0 0 11 11"
                      >
                        <g fill="none" fill-rule="evenodd" opacity=".4">
                          <path d="M0 0h11v11H0z" />
                          <path
                            fill="#000"
                            fill-rule="nonzero"
                            d="M8.26 2.75a3.896 3.896 0 1 0 1.102 3.262l.007-.056a.49.49 0 0 1 .485-.456c.253 0 .451.206.437.457 0 0 .012-.109-.006.061a4.813 4.813 0 1 1-1.348-3.887v-.987a.458.458 0 1 1 .917.002v2.062a.459.459 0 0 1-.459.459H7.334a.458.458 0 1 1-.002-.917h.928z"
                          />
                        </g>
                      </svg>
                      Limpiar filtros
                    </div>
                  </template>
                </ais-clear-refinements>

                <ais-stats data-layout="mobile">
                  <template #default="{ nbHits }">
                    <span class="ais-Stats-text">
                      <strong>{{ formatNumber(nbHits) }}</strong> resultados
                    </span>
                  </template>
                </ais-stats>
              </div>
              <div class="container-body">
                <ais-panel>
                  <template #header> Educación </template>
                  <template #default>
                    <ais-refinement-list attribute="educacnUsers.nvlEstudio" />
                  </template>
                </ais-panel>
              </div>
              <div class="container-body">
                <ais-panel>
                  <template #header> Locación </template>
                  <template #default>
                    <ais-refinement-list attribute="localidad.idCiudad" />
                  </template>
                </ais-panel>
              </div>
            </section>

            <footer class="container-filters-footer" data-layout="mobile">
              <clear-refinements
                class="container-filters-footer-button-wrapper"
                @click="closeFilters"
              />

              <ais-stats class="container-filters-footer-button-wrapper">
                <template #default="{ nbHits }">
                  <button class="button button-primary" @click="closeFilters">
                    Ver {{ formatNumber(nbHits) }} resultados
                  </button>
                </template>
              </ais-stats>
            </footer>
          </div>
          <section class="container-results">
            <header class="container-header container-options">
              <ais-hits-per-page
                class="container-option"
                :items="[
                  {
                    label: '12 resultados por página',
                    value: 12,
                    default: true,
                  },
                  {
                    label: '24 resultados por página',
                    value: 24,
                  },
                  {
                    label: '48 resultados por página',
                    value: 48,
                  },
                ]"
              />
            </header>

            <template v-if="mostrarMensajeNoPostulados == 0">
              <div>
                <h2><strong>No hay postulados en sus anuncios.</strong></h2>
              </div>
            </template>

            <ais-hits class="my-custom-hits">
              <template #item="{ item }">
                <article class="hit">
                  <div class="w-full max-w-lg">
                    <trComp
                      :persona="postulads"
                      :userData="userData"
                      :assets="item"
                      :key="item.createdAt"
                    />
                  </div>
                </article>
              </template>
            </ais-hits>

            <no-results />

            <footer class="container-footer">
              <div class="flex justify-center">
                <ais-pagination :padding="2">
                  <template
                    #default="{
                      currentRefinement,
                      pages,
                      isFirstPage,
                      isLastPage,
                      refine,
                    }"
                  >
                    <nav aria-label="Pagination Navigation">
                      <ul class="flex space-x-2 p-18">
                        <li v-if="!isFirstPage" class="page-item">
                          <a
                            class="px-4 py-2 border postula2Primary rounded-md"
                            href="#"
                            @click.prevent="refine(currentRefinement - 1)"
                            aria-label="Previous"
                          >
                            <span aria-hidden="true">&laquo;</span>
                          </a>
                        </li>
                        <li v-for="page in pages" :key="page" class="page-item">
                          <a
                            class="px-4 py-2 border postula2Primary rounded-md"
                            :class="{
                              'bg-colorPost': currentRefinement === page,
                              'text-white': currentRefinement === page,
                            }"
                            href="#"
                            @click.prevent="refine(page)"
                            :aria-label="`Page ${page + 1}`"
                          >
                            {{ page + 1 }}
                          </a>
                        </li>
                        <li v-if="!isLastPage" class="page-item">
                          <a
                            class="px-4 py-2 border postula2Primary rounded-md"
                            href="#"
                            @click.prevent="refine(currentRefinement + 1)"
                            aria-label="Next"
                          >
                            <span aria-hidden="true">&raquo;</span>
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </template>
                </ais-pagination>
              </div>
            </footer>
          </section>
        </main>

        <aside data-layout="mobile">
          <button class="filters-button" @click="openFilters">
            <svg xmlns="http://www.w3.org/2000/svg" viewbox="0 0 16 14">
              <path
                d="M15 1H1l5.6 6.3v4.37L9.4 13V7.3z"
                stroke="#fff"
                stroke-width="1.29"
                fill="none"
                fill-rule="evenodd"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            Filtros
          </button>
        </aside>
      </ais-instant-search>
    </div>
  </div>
</template>

<script>
import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";
import { AisRefinementList } from "vue-instantsearch";
import ClearRefinements from "../layouts/assetsP/js/widgets/ClearRefinements.vue";

import { formatNumber } from "../layouts/assetsP/js/utils";
import NoResults from "../layouts/assetsP/js/widgets/NoResults.vue";
import axios from "axios";

//import "../layouts/assetsP/css/theme-pp.css";
import "../layouts/assetsP/css/app.css";
import "../layouts/assetsP/css/app.mobile.css";
import "../layouts/assetsP/js/widgets/PriceSlider.css";

import loadermini from "@/components/shared/loadermini.vue";

//import anuncioResultsAlgolia from "@/components/layouts/searchbar/anuncioResultsAlgolia.vue";
import trComp from "@/views/dashboard/allPostulados.vue";

///Servicio///
import personaServicio from "@/services/personaServicio.js";

const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
  server: {
    apiKey: "xyz", // Be sure to use an API key that only allows searches, in production
    nodes: [
      {
        host: "192.168.10.224",
        port: "8108",
        protocol: "https",
      },
    ],
  },
  // The following parameters are directly passed to Typesense's search API endpoint.
  //  So you can pass any parameters supported by the search endpoint below.
  //  queryBy is required.
  //  filterBy is managed and overridden by InstantSearch.js. To set it, you want to use one of the filter widgets like refinementList or use the `configure` widget.
  additionalSearchParameters: {
    queryBy: "nombreUser, apellidoUser, ", // query de busqieda según los datos de la colección
  },
});
const searchClient = typesenseInstantsearchAdapter.searchClient;

export default {
  name: "SearchPersona",

  components: {
    trComp,
    AisRefinementList,
    ClearRefinements,
    NoResults,
    loadermini,
  },
  data() {
    return {
      searchClient,
      Idemprs: this.$store.state.regUserEmprs.empresa[0].owner,
      candidatos: [], // Inicializa el array de candidatos
      mostrarMensajeNoPostulados: false,
      loading: true,
      tiempoDeshabilitar: 600000, // 10 minutos en milisegundos
      estadoBoton: true,
      isAnimating: "animate-spin",
      cargando: false, // Inicializa la variable cargando a false
      mostrarIcono: false,
    };
  },
  created() {
    /// data ///
    this.postulads = this.$store.state.perfilados;
    this.userData = this.$store.state.userData;

    /// data ///
    this.onKeyUp = (event) => {
      if (event.key !== "Escape") {
        return;
      }
      this.closeFilters();
    };

    this.onClick = (event) => {
      if (event.target !== this.header) {
        return;
      }

      this.closeFilters();
    };
  },
  watch: {
    async miData(newValue, oldValue) {
      if (newValue !== oldValue) {
        // Ejecutar el script con la nueva data de manera asíncrona
        try {
          await personaServicio(newValue);
        } catch (error) {
          console.error("Error al actualizar el índice en Typesense:", error);
          // Puedes manejar el error aquí según tus necesidades
        }
      }
    },
  },
  mounted() {
    this.resultsContainer = document.querySelector(".container-results");
    this.header = document.querySelector("#header");

    //axios
    //.get("https://api.postula2.com/anunciosActivos")
    //.then((response) => {
    //console.log("Respuesta de la solicitud:", response.data);
    //})
    //.catch((error) => {
    //console.error("Error al realizar la solicitud:", error);
    //});

    // Realiza la solicitud HTTP al endpoint con axios
    const url = `https://api.postula2.com/mispostulads?idOwner=${this.$store.state.regUserEmprs.empresa[0].id}`;
    console.log("URL de la solicitud POST:", url);

    axios({
      method: "post",
      url: url,
    })
      .then((response) => {
        // Muestra los resultados en la consola
        console.log(
          "Resultados de la solicitud POST en el componente:",
          response
        );
        this.mostrarMensajeNoPostulados =
          response.data.resp.postulados.cantidad;
        console.log("Prueba", this.mostrarMensajeNoPostulados);
        this.loading = false;

        // Verifica si la respuesta tiene contenido
        if (response.data) {
          // Verifica si 'postulados' está definido y es un objeto
          if (
            response.data.resp.postulados &&
            typeof response.data.resp.postulados === "object"
          ) {
            // Convierte el objeto 'postulados' a una cadena JSON válida
            const postuladosJson = JSON.stringify(
              response.data.resp.postulados
            );

            // Log para verificar la cadena JSON resultante
            console.log("Postulados en formato JSON:", postuladosJson);

            // Continúa con el resto de tu lógica aquí...
          } else {
            // Manejar el caso en que 'postulados' no está definida o no es un objeto
            console.error(
              "La propiedad 'postulados' no está definida o no es un objeto en la respuesta."
            );
            // this.mostrarMensajeNoPostulados = true; // Muestra el mensaje en este caso, ajusta según tus necesidades
          }
        } else {
          // Manejar el caso en que la respuesta está vacía
          console.error("La respuesta del servidor está vacía.");
          this.mostrarMensajeNoPostulados = true; // Muestra el mensaje en este caso, ajusta según tus necesidades
        }
      })
      .catch((error) => {
        // Maneja los errores en caso de que ocurran
        console.error("Error al realizar la solicitud POST:", error);
        this.loading = false;
      });
  },
  methods: {
    formatNumber,
    toValue(value, range) {
      return [
        typeof value.min === "number" ? value.min : range.min,
        typeof value.max === "number" ? value.max : range.max,
      ];
    },
    openFilters() {
      document.body.classList.add("filtering");
      window.scrollTo(0, 0);
      window.addEventListener("keyup", this.onKeyUp);
      window.addEventListener("click", this.onClick);
    },
    closeFilters() {
      document.body.classList.remove("filtering");
      this.resultsContainer.scrollIntoView();
      window.removeEventListener("keyup", this.onKeyUp);
      window.removeEventListener("click", this.onClick);
    },
    transformAreaItems(items) {
      return items.filter(
        (item) => item.label === "Opción 1" || item.label === "Opción 2"
      );
    },
    ////// llamada al servicio //////
    llamarServicioPersona() {
      this.mostrarIcono = true;

      const idOwner = this.$store.state.regUserEmprs.empresa[0].id;

      personaServicio(idOwner, this.Idemprs).then((result) => {
        // Manejar el resultado si es necesario
        console.log("Resultado del servicio:", result);
        // Refrescar la página
        //window.location.reload();
      });
      this.estadoBoton = false;
      setTimeout(() => {
        this.estadoBoton = true;
      }, this.tiempoDeshabilitar);
      this.mostrarIcono = false;
    },
  },
};
</script>

<style>
.header_compont {
  align-items: center;
  background: linear-gradient(to bottom, #134892, #134892);
  background-image: url("@/components/img/bannerPersona-Desk.jpg");
  background-image: url("@/components/img/bannerPersona-Desk.jpg"),
    linear-gradient(to bottom, #134892, #134892);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 220px;
  padding: 0.5rem 1rem;
  text-align: center;
}

.header_compont .ais-SearchBox {
  height: 64px;
  width: 60%;
  margin: 0 auto;
  z-index: 1;
}

.header_compont .ais-SearchBox .ais-SearchBox-input {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.4);
  font-family: Hind, sans-serif;
  height: 32px;
  /*
      The "Hind" font family is vertically off-balance.
      Adding 4px of padding top makes it more vertically aligned.
    */
  padding: 4px 48px 0 64px;
}

.header_compont .ais-SearchBox-submit {
  padding: 0 1rem 0 2rem;
  width: 64px;
}

.header_compont .ais-SearchBox .ais-SearchBox-input::placeholder {
  color: rgba(33, 36, 61, 0.24);
  opacity: 1; /* Firefox */
}
.postula2Primary {
  border-color: #134992;
}

.skeleton-loader {
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
  border-radius: 0.375rem;
  margin-bottom: 1rem; /* Ajusta el margen según sea necesario */
}

@keyframes loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

animate-spin {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* GLOBAL */

[class^="ais-"] {
  box-sizing: border-box;
  font-size: 0.6rem;
  font-weight: 400;
  text-align: left;
}

a[class^="ais-"] {
  color: #21243d;
  text-decoration: none;
}

/*
    We need to target the root element because Angular InstantSearch
    creates web components which are not targetable with the `*` selector.
  */
[class^='ais-'][class$='--disabled'],
  /*
    We need to target all elements for widgets containing
    multiple sub elements (e.g. RangeSlider)
  */
  [class^='ais-'][class$='--disabled'] * {
  cursor: not-allowed;
}

.ais-Breadcrumb,
.ais-ClearRefinements,
.ais-CurrentRefinements,
.ais-ClearRefinements-button,
.ais-GeoSearch,
.ais-HierarchicalMenu,
.ais-Hits,
.ais-Results,
.ais-HitsPerPage,
.ais-ResultsPerPage,
.ais-InfiniteHits,
.ais-InfiniteResults,
.ais-Menu,
.ais-MenuSelect,
.ais-NumericMenu,
.ais-NumericSelector,
.ais-Pagination,
.ais-Panel,
.ais-PoweredBy,
.ais-RangeInput,
.ais-RangeSlider,
.ais-RatingMenu,
.ais-RefinementList,
.ais-SearchBox,
.ais-SortBy,
.ais-SortBy-select,
.ais-HitsPerPage-select,
.ais-Stats,
.ais-ToggleRefinement {
  color: #21243d;
  font-size: 0.75rem;
}

/* Highlighting */

.ais-Highlight-highlighted,
.ais-Snippet-highlighted {
  background: rgba(226, 164, 0, 0.4);
}

/* Hits */

.ais-Hits-list {
  display: grid;
  grid-gap: 8px;
}

@media (min-width: 680px) {
  .my-custom-hits .ais-Hits-list {
    grid-template-columns: 1fr;
  }
}

@media (min-width: 768px) {
  .my-custom-hits .ais-Hits-list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1260px) {
  .my-custom-hits .ais-Hits-list {
    grid-template-columns: repeat(4, 1fr);
  }
}

/* Menus */

.ais-RefinementList-item,
.ais-Menu-item,
.ais-HierarchicalMenu-item,
.ais-RatingMenu-item {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ais-RefinementList-item,
  /*
   The refinement list item in InstantSearch.js contains a wrapping `div` because of
   the template behavior. We therefore need to apply the styles to all the elements
   in a refinement list.
  */
  .ais-RefinementList-item *,
  .ais-RatingMenu-item {
  cursor: pointer;
}

.ais-HierarchicalMenu-link,
.ais-RatingMenu-item,
.ais-RefinementList-item {
  padding-bottom: 1rem;
}

.ais-Breadcrumb-item--selected,
.ais-HierarchicalMenu-item--selected,
.ais-Menu-item--selected {
  font-weight: bold;
}

.ais-RatingMenu-starIcon--full {
  fill: #e2a400;
}

.ais-RatingMenu-starIcon--empty {
  fill: rgba(0, 0, 0, 0.08);
}

/* Panel */

.ais-Panel--collapsible {
  position: relative;
}

.ais-Panel--collapsed .ais-Panel-body,
.ais-Panel--collapsed .ais-Panel-footer {
  display: none;
}

.ais-Panel-collapseButton {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  position: absolute;
  right: 0;
}

.ais-Panel-header {
  border: none;
  color: #21243d;
  font-size: 0.678rem;
  font-weight: 600;
  letter-spacing: 0.08rem;
  line-height: 1.6;
  padding-bottom: 1rem;
  text-transform: uppercase;
}

/* Search box */

@media (max-width: 680px) {
  .ais-SearchBox-form {
    position: relative;
  }
}

.ais-SearchBox-form {
  position: relative;
}

.ais-SearchBox-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: rgba(255, 255, 255);
  border: none;
  border-radius: 3px;
  color: rgba(33, 36, 61, 0.8);
  font-family: inherit;
  min-height: 54px;
  outline: none;
  padding-left: 56px;
  width: 100%;
}
@media (max-width: 680px) {
  .ais-SearchBox-input {
    padding-left: 0;
  }
}

.ais-SearchBox-input::placeholder {
  color: rgba(33, 36, 61, 0.5);
  opacity: 1; /* Firefox */
}

.ais-SearchBox-input:-ms-input-placeholder {
  color: rgba(33, 36, 61, 0.5);
}

.ais-SearchBox-input::-ms-input-placeholder {
  color: rgba(33, 36, 61, 0.5);
}

.ais-SearchBox-reset,
.ais-SearchBox-loadingIndicator,
.ais-SearchBox-submit {
  align-items: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 48px;
}

.ais-SearchBox-reset[hidden],
.ais-SearchBox-loadingIndicator[hidden] {
  display: none;
}
.ais-SearchBox-reset {
  display: none;
}

.ais-SearchBox-submit {
  left: 0;
}

.ais-SearchBox-reset,
.ais-SearchBox-loadingIndicator {
  right: 0;
}

.ais-SearchBox-resetIcon {
  width: 10px;
  height: 10px;
}

/* SFFV search box */

.ais-RefinementList .ais-SearchBox-input {
  border-radius: 3px;
  color: rgba(33, 36, 61, 0.8);
  font-size: 0.8rem;
  min-height: 40px;
  padding: 0 44px;
}

.ais-RefinementList .ais-SearchBox-form {
  margin-bottom: 1rem;
}

/* Menus */

.ais-HierarchicalMenu-link,
.ais-RatingMenu-link,
.ais-RefinementList-label {
  align-items: center;
  display: flex;
}

.ais-RefinementList-checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: rgba(65, 66, 71, 0.08);
  border: none;
  border-radius: 2px;
  height: 1rem;
  margin: 0;
  margin-right: 1rem;
  position: relative;
  width: 1rem;
}

.ais-RefinementList-item--selected {
  font-weight: bold;
}

.ais-RefinementList-item--selected .ais-RefinementList-checkbox {
  background-color: #134992;
}

.ais-RefinementList-item--selected .ais-RefinementList-checkbox::after {
  background-color: #fff;
  border-radius: 4px;
  content: "";
  height: 4px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateX(-2px) translateY(-2px);
  width: 4px;
}

.ais-HierarchicalMenu-count,
.ais-Menu-count,
.ais-RefinementList-count,
.ais-ToggleRefinement-count,
.ais-RatingMenu-count {
  align-items: center;
  background-color: rgba(65, 66, 71, 0.08);
  border-radius: 4px;
  color: rgba(33, 36, 61, 0.8);
  display: flex;
  font-size: 0.64rem;
  font-weight: 600;
  letter-spacing: 1.1px;
  margin-left: 8px;
  padding: 0 4px;
}

.ais-HierarchicalMenu-showMore,
.ais-Menu-showMore,
.ais-RefinementList-showMore {
  margin-top: 1rem;
}

.ais-HierarchicalMenu-list {
  font-weight: normal;
}

.ais-HierarchicalMenu-link::before {
  align-items: center;
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3E%3Cpath fill='%2390919E' fill-rule='nonzero' d='M0 4l4-4 4 4z'/%3E%3C/svg%3E%0A");
  display: flex;
  height: 8px;
  justify-content: center;
  margin-right: 1rem;
  width: 8px;
}

.ais-HierarchicalMenu-item--selected .ais-HierarchicalMenu-link::before {
  transform: rotate(180deg);
}

.ais-HierarchicalMenu-item--selected
  .ais-HierarchicalMenu-item:not(.ais-HierarchicalMenu-item--selected)
  .ais-HierarchicalMenu-link::before {
  transform: rotate(0);
}

/* ClearRefinements */

.ais-ClearRefinements,
.ais-ClearRefinements-button {
  color: rgba(33, 36, 61, 0.7);
}

.ais-ClearRefinements-button--disabled {
  color: rgba(33, 36, 61, 0.5);
}

/* ToggleRefinement */

.ais-ToggleRefinement-label {
  cursor: pointer;
  display: flex;
}

.ais-ToggleRefinement-checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: rgba(65, 66, 71, 0.08);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  height: 16px;
  margin-right: 16px;
  min-width: 30px;
  transition: background 150ms ease-out;
}

.ais-ToggleRefinement-checkbox:checked {
  background: #134992;
}

.ais-ToggleRefinement-checkbox::after {
  background-image: linear-gradient(to top, #f5f5fa, #fff);
  border-radius: 100%;
  box-shadow: 0 4px 11px 0 rgba(37, 44, 97, 0.15),
    0 2px 3px 0 rgba(93, 100, 148, 0.2);
  content: "";
  height: 16px;
  position: absolute;
  transition: transform 150ms ease-out;
  width: 16px;
}

.ais-ToggleRefinement-checkbox:checked::after {
  transform: translateX(100%);
}

/* Selectors */

.ais-SortBy,
.ais-HitsPerPage {
  position: relative;
}

.ais-SortBy::after,
.ais-HitsPerPage::after {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='7' viewBox='0 0 12 7'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M14-5v16H-2V-5z'/%3E%3Cpath fill='%23000' stroke='%23FFF' stroke-width='.5' d='M2.228 1.332a.664.664 0 0 0-.942.001.665.665 0 0 0-.002.941l4.247 4.247c.259.26.679.26.938 0l4.247-4.247a.664.664 0 0 0-.002-.94.666.666 0 0 0-.942-.002L6 5.105 2.228 1.332z'/%3E%3C/g%3E%3C/svg%3E%0A");
  display: inline-block;
}

.ais-SortBy-select,
.ais-HitsPerPage-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  border: none;
  color: #21243d;
  font-family: inherit;
}

/* Sliders */

.ais-RangeSlider .rheostat-horizontal {
  cursor: pointer;
  width: calc(100% - 10px);
}

.ais-RangeSlider .rheostat-background {
  background-color: rgba(65, 66, 71, 0.08);
  border: none;
  border-radius: 3px;
  height: 3px;
}

.ais-RangeSlider .rheostat-progress {
  background-color: #e2a400;
  border-radius: 3px;
  height: 3px;
  top: 0;
}

.ais-RangeSlider .rheostat-tooltip {
  font-weight: bold;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ais-RangeSlider .rheostat-handle {
  background-image: linear-gradient(to top, #f5f5fa, #fff);
  border: none;
  box-shadow: 0 4px 11px 0 rgba(37, 44, 97, 0.15),
    0 2px 3px 0 rgba(93, 100, 148, 0.2);
  margin-left: -5px;
  top: -9px;
}

.ais-RangeSlider .rheostat-marker {
  background-color: rgba(65, 66, 71, 0.08);
}

/* RangeInput */

.ais-RangeInput-input {
  background: none;
  border: none;
  border-bottom: 1px solid #ebecf3;
  color: #21243d;
  font-family: inherit;
  font-size: 0.875rem;
  font-weight: 600;
  min-width: none;
  padding: 0;
  padding-bottom: 3px;
}

.ais-RangeInput-label:first-of-type {
  margin-right: 6px;
}

.ais-RangeInput-label:last-of-type {
  margin-left: 6px;
}

/* Pagination */

.ais-Pagination-list {
  border: 2px solid red;
  justify-content: center;
}

.ais-Pagination-item,
.ais-Pagination-link {
  align-items: center;
  display: flex;
  justify-content: center;
}

.ais-Pagination-item {
  height: 38px;
  width: 38px;
}

.ais-Pagination-item {
  background-color: rgba(65, 66, 71, 0.08);
  border-radius: 4px;
  color: #414247;
}

.ais-Pagination-item--selected {
  background-color: #e2a400;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.34),
    rgba(255, 255, 255, 0)
  );
  font-weight: bold;
}

.ais-Pagination-item--firstPage,
.ais-Pagination-item--previousPage,
.ais-Pagination-item--nextPage,
.ais-Pagination-item--lastPage {
  background: none;
}

.ais-Pagination-item--disabled {
  opacity: 0.33;
}

.ais-Pagination-item--selected a {
  color: #fff;
}

.ais-Pagination-item.ais-Pagination-item--page {
  margin-right: 4px;
}

.ais-Pagination-item.ais-Pagination-item--previousPage {
  margin-right: 1rem;
}

.ais-Pagination-item.ais-Pagination-item--nextPage {
  margin-left: calc(1rem - 4px);
}

.ais-Pagination-link {
  height: 100%;
  width: 100%;
}
</style>
