<template>
  <div>
    <loader v-if="isLoading" />

    <main class="bg-gray-50 dark:bg-gray-800 overflow-y-auto h-auto">
      <div class="flex items-start justify-between">
        <div class="flex flex-col w-full md:space-y-4">
          <div
            class="overflow-visible pb-24 px-4 md:px-6 md:pt-4 md:mb-8 lg:pt-6 border-dashed border-red-500"
          >
            <div
              class="pt-2 flex items-center space-x-4 font-light w-full text-center text-xl sm:text-md md:text-2xl dark:text-white text-gray-800 border-b border-gray-200"
            >
              Crear nuevo anuncio
            </div>
            <div
              class="flex items-center mt-0.5 font-light w-1/6 text-center text-xs sm:text-xs md:text-xs dark:text-white text-blue-500"
            >
              <span @click="volver" class="cursor-pointer">⇤ Volver</span>
            </div>
            <div
              class="place-items-center grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4 mt-2 mb-4"
            >
              <div
                class="my-6 p-4 w-2/3 bg-gray-200 border-gray-300 border-2 border-opacity-50 rounded-lg shadow-md"
                v-show="!isLoading"
              >
                <p
                  class="hidden text-sm w-max text-gray-700 dark:text-white font-semibold border-b border-gray-200"
                >
                  Project Reffered
                </p>
                <Anuncio></Anuncio>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
<script>
import Anuncio from "./Anuncio.vue";
import loader from "@/components/shared/loader.vue";

export default {
  name: "RegstAnuncio",

  components: {
    Anuncio,
    loader,
  },
  methods: {
    volver() {
      this.$router.go(-1);
    },
  },
  data() {
    return {
      assets: [],

      isLoading: false,
    };
  },
};
</script>
