<template>
  <div
    v-if="referncUsers"
    class="grid grid-cols-2 border-t bg-gray-50 rounded-md"
  >
    <div class="border-t border-gray-200">
      <dl>
        <div class="hidden">
          {{ referncUsers.id }}
        </div>
        <div
          v-if="$store.state.userData"
          class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
        >
          <dt
            v-if="!editNombReferFlag"
            class="text-sm text-left font-medium text-gray-500"
          >
            Nombre(s) y Apellido(s) de quien te refiere
          </dt>
          <dd
            class="
              mt-1
              text-sm text-gray-900
              sm:mt-0 sm:col-span-2
              capitalize
              flex
            "
          >
            <FormulateForm
              v-if="editNombReferFlag"
              #default="{ hasErrors }"
              class="flex"
            >
              <div
                class="
                  grid
                  w-3/4
                  md:w-full
                  max-w-sm
                  space-y-3
                  md:space-y-2
                  justify-start
                "
              >
                <div class="flex md:space-x-3">
                  <div class="">
                    <label
                      for="price"
                      class="
                        block
                        text-xs
                        font-medium
                        text-gray-700 text-left
                        ml-1
                      "
                    >
                      <strong>Editar Nombre de quien refiere </strong>
                    </label>
                    <FormulateInput
                      type="text"
                      name="nombre"
                      :value="referncUsers.nombRefer"
                      v-model="data.nombRefer"
                      placeholder="Nombre"
                      validation-behavior="value"
                      validation="required|min:4|matches:/^[A-Za-z\s*\á\é\í\ó\ú\ñ]+$/"
                      class="
                        rounded-lg
                        border-transparent
                        flex-1
                        appearance-none
                        border border-gray-300
                        w-60
                        px-2
                        py-2
                        bg-white
                        text-gray-700
                        placeholder-gray-400
                        shadow-sm
                        text-xs
                        focus:outline-none
                        focus:ring-2
                        focus:ring-purple-600
                        focus:border-transparent
                      "
                      :validation-messages="{
                        min: 'Debe tener al menos 4 caracteres',
                        matches: 'Este campo solo acepta letras',
                        required: 'Este campo es obligatorio',
                      }"
                    />
                  </div>

                  <div
                    class="
                      flex
                      w-auto
                      text-gray-500
                      items-end
                      justify-between
                      px-2
                      pb-0.5
                      space-x-1
                    "
                  >
                    <FormulateInput
                      type="submit"
                      :disabled="hasErrors"
                      @click="editNombRefer(referncUsers.id)"
                      outer-class="
                        p-1
                        h-8
                        pt-1
                        items-center
                        transition
                        ease-in
                        duration-200
                        uppercase
                        rounded-full
                        border border-green-200
                        text-green-600
                        hover:border-green-600
                        hover:bg-green-600
                        hover:text-yellow-200
                        focus:outline-none
                      "
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
                        />
                      </svg>
                    </FormulateInput>
                    <FormulateInput
                      type="submit"
                      @click="editNombReferFlag = !editNombReferFlag"
                      outer-class="
                        items-center
                        p-1
                        h-8
                        pt-1
                        transition
                        ease-in
                        duration-200
                        uppercase
                        rounded-full
                        border border-red-300
                        text-red-500
                        hover:bg-red-800 hover:text-yellow-200
                        focus:outline-none
                      "
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                    </FormulateInput>
                  </div>
                </div>
                <!--  -->
                <div class="flex md:space-x-3">
                  <div class="">
                    <label
                      for="price"
                      class="
                        block
                        text-xs
                        font-medium
                        text-gray-700 text-left
                        ml-1
                      "
                    >
                      <strong>Editar Apellido de quien refiere</strong>
                    </label>
                    <FormulateInput
                      type="text"
                      name="apellido"
                      :value="referncUsers.apellidRefer"
                      v-model="data.apellidRefer"
                      validation-behavior="value"
                      placeholder="Apellido"
                      validation="required|min:4|matches:/^[A-Za-z\s*\á\é\í\ó\ú\ñ]+$/"
                      class="
                        rounded-lg
                        border-transparent
                        flex-1
                        appearance-none
                        border border-gray-300
                        w-60
                        px-2
                        py-2
                        bg-white
                        text-gray-700
                        placeholder-gray-400
                        shadow-sm
                        text-xs
                        focus:outline-none
                        focus:ring-2
                        focus:ring-purple-600
                        focus:border-transparent
                      "
                      :validation-messages="{
                        min: 'Debe tener al menos 4 caracteres',
                        matches: 'Este campo solo acepta letras',
                        required: 'Este campo es obligatorio',
                      }"
                    />
                  </div>

                  <div
                    class="
                      flex
                      w-auto
                      text-gray-500
                      items-end
                      justify-between
                      px-2
                      pb-0.5
                      space-x-1
                    "
                  >
                    <FormulateInput
                      type="submit"
                      :disabled="hasErrors"
                      @click="editApellidRefer(referncUsers.id)"
                      outer-class="
                        p-1
                        h-8
                        pt-1
                        items-center
                        transition
                        ease-in
                        duration-200
                        uppercase
                        rounded-full
                        border border-green-200
                        text-green-600
                        hover:border-green-600
                        hover:bg-green-600
                        hover:text-yellow-200
                        focus:outline-none
                      "
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
                        />
                      </svg>
                    </FormulateInput>
                    <FormulateInput
                      type="submit"
                      @click="editNombReferFlag = !editNombReferFlag"
                      outer-class="
                        items-center
                        p-1
                        h-8
                        pt-1
                        inline-block
                        align-middle
                        transition
                        ease-in
                        duration-200
                        uppercase
                        rounded-full
                        border border-red-300
                        text-red-500
                        hover:bg-red-800 hover:text-yellow-200
                        focus:outline-none
                      "
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                    </FormulateInput>
                  </div>
                </div>
              </div>
            </FormulateForm>

            <div class="flex" v-if="!editNombReferFlag">
              <span
                class="
                  items-center
                  text-gray-700
                  mr-6
                  dark:text-white
                  font-normal
                "
              >
                {{ referncUsers.nombRefer + " " + referncUsers.apellidRefer }}
              </span>
              <label class="flex items-center">
                <button
                  @click="editNombReferFlag = !editNombReferFlag"
                  class="
                    flex
                    items-center
                    p-1
                    transition
                    ease-in
                    duration-200
                    uppercase
                    rounded-full
                    hover:bg-blue-800 hover:text-yellow-200
                    focus:outline-none
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-3.5 w-3.5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                    />
                  </svg>
                </button>
              </label>
            </div>
          </dd>
        </div>

        <div
          v-if="$store.state.userData"
          class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
        >
          <dt
            v-if="!editEmailReferFlag"
            class="text-sm text-left font-medium text-gray-500"
          >
            Correo electrónico de quien te refiere
          </dt>
          <dd
            class="
              mt-1
              text-sm text-gray-900
              sm:mt-0 sm:col-span-2
              capitalize
              flex
            "
          >
            <form
              v-if="editEmailReferFlag"
              class="
                flex flex-col
                md:flex-row
                w-3/4
                md:w-full
                max-w-sm
                md:space-x-3
                space-y-3
                md:space-y-0
                justify-start
              "
            >
              <div class="">
                <label
                  for="price"
                  class="block text-xs font-medium text-gray-700 text-left ml-1"
                >
                  <strong> Editar Correo electrónico de quien refiere </strong>
                </label>
                <input
                  type="text"
                  id='"form-subscribe-Subscribe'
                  class="
                    rounded-lg
                    border-transparent
                    flex-1
                    appearance-none
                    border border-gray-300
                    w-60
                    px-2
                    py-2
                    bg-white
                    text-gray-700
                    placeholder-gray-400
                    shadow-sm
                    text-xs
                    focus:outline-none
                    focus:ring-2
                    focus:ring-purple-600
                    focus:border-transparent
                  "
                  placeholder="Correo electrónico de quien refiere"
                  v-model="data.emailRefer"
                />
              </div>
              <div
                class="
                  flex
                  w-auto
                  text-gray-500
                  items-end
                  justify-between
                  px-2
                  pb-0.5
                  space-x-1
                "
              >
                <button
                  @click.prevent="editEmailRefer(referncUsers.id)"
                  class="
                    p-1
                    flex
                    items-center
                    transition
                    ease-in
                    duration-200
                    uppercase
                    rounded-full
                    border border-green-200
                    text-green-600
                    hover:border-green-600
                    hover:bg-green-600
                    hover:text-yellow-200
                    focus:outline-none
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
                    />
                  </svg>
                </button>
                <button
                  @click="editEmailReferFlag = !editEmailReferFlag"
                  class="
                    flex
                    items-center
                    p-1
                    transition
                    ease-in
                    duration-200
                    uppercase
                    rounded-full
                    border border-red-300
                    text-red-500
                    hover:bg-red-800 hover:text-yellow-200
                    focus:outline-none
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </button>
              </div>
            </form>

            <div class="flex" v-if="!editEmailReferFlag">
              <span class="text-gray-700 mr-6 dark:text-white font-normal">
                {{ referncUsers.emailRefer }}
              </span>
              <label class="flex items-center">
                <button
                  @click="editEmailReferFlag = !editEmailReferFlag"
                  class="
                    flex
                    items-center
                    p-1
                    transition
                    ease-in
                    duration-200
                    uppercase
                    rounded-full
                    hover:bg-blue-800 hover:text-yellow-200
                    focus:outline-none
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-3.5 w-3.5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                    />
                  </svg>
                </button>
              </label>
            </div>
          </dd>
        </div>

        <div
          v-if="$store.state.userData"
          class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
        >
          <dt
            v-if="!editTelefReferFlag"
            class="text-sm text-left font-medium text-gray-500"
          >
            Numero contacto de quien refiere
          </dt>
          <dd
            class="
              mt-1
              text-sm text-gray-900
              sm:mt-0 sm:col-span-2
              capitalize
              flex
            "
          >
            <FormulateForm #default="{ hasErrors }">
              <div
                v-if="editTelefReferFlag"
                class="
                  flex flex-nowrap
                  items-start
                  md:flex-nowrap
                  w-3/4
                  md:w-full
                  max-w-sm
                  md:space-x-3
                  space-y-3
                  md:space-y-0
                  justify-start
                "
              >
                <div class="w-1/5">
                  <label
                    for="price"
                    class="
                      text-xs
                      font-medium
                      text-gray-700 text-left
                      ml-1
                      truncate
                      w-6
                    "
                  >
                    <strong>Editar Numero</strong>
                  </label>
                  <FormulateInput
                    v-model.lazy="cod"
                    :options="{
                      '0412': '0412',
                      '0414': '0414',
                      '0424': '0424',
                      '0416': '0416',
                      '0426': '0426',
                    }"
                    type="select"
                    placeholder="04XX"
                    validation="required"
                    :validation-messages="{
                      required: 'Debes rellenar este campo',
                    }"
                    errors-class="text-left ml-1"
                  />
                </div>
                <div class="mt-3 pt-5 w-full ml-1.5">
                  <FormulateInput
                    type="number"
                    placeholder="1234567"
                    name="numeroCelular"
                    v-model.lazy="numero"
                    validation="required|max:7,length|min:7,length|matches:/^[0-9]/|"
                    error-behavior="value"
                    :validation-messages="{
                      required: 'Debes rellenar este campo',
                      max: 'Maximo 7 digitos',
                      min: 'Al menos 7 digitos',
                      matches: 'Deben ser numeros',
                      not: 'No permitido',
                    }"
                    errors-class="text-left ml-1  z-10"
                  />
                </div>

                <div
                  class="
                    pt-6
                    flex
                    text-gray-500
                    items-start
                    justify-between
                    pb-0.5
                    space-x-1
                  "
                >
                  <button
                    type="submit"
                    :disabled="hasErrors"
                    @click.prevent="editTelefRefer(referncUsers.id)"
                    class="
                      p-1
                      flex
                      items-center
                      transition
                      ease-in
                      duration-200
                      uppercase
                      rounded-full
                      border border-green-200
                      text-green-600
                      hover:border-green-600
                      hover:bg-green-600
                      hover:text-yellow-200
                      focus:outline-none
                    "
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
                      />
                    </svg>
                  </button>
                  <button
                    @click="editTelefReferFlag = !editTelefReferFlag"
                    class="
                      items-center
                      p-1
                      transition
                      ease-in
                      duration-200
                      uppercase
                      rounded-full
                      border border-red-300
                      text-red-500
                      hover:bg-red-800 hover:text-yellow-200
                      focus:outline-none
                    "
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </FormulateForm>
            <div class="flex" v-if="!editTelefReferFlag">
              <span class="text-gray-700 mr-6 dark:text-white font-normal">
                {{ referncUsers.telefRefer }}
              </span>
              <label class="flex items-center">
                <button
                  @click="editTelefReferFlag = !editTelefReferFlag"
                  class="
                    flex
                    items-center
                    p-1
                    transition
                    ease-in
                    duration-200
                    uppercase
                    rounded-full
                    hover:bg-blue-800 hover:text-yellow-200
                    focus:outline-none
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-3.5 w-3.5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                    />
                  </svg>
                </button>
              </label>
            </div>
          </dd>
        </div>
      </dl>
    </div>

    <div class="border-t border-gray-200">
      <dl>
        <div
          v-if="$store.state.userData"
          class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
        >
          <dt
            v-if="!editTipoReferFlag"
            class="text-sm text-left font-medium text-gray-500"
          >
            Tipo de referencia
          </dt>
          <dd
            class="
              mt-1
              text-sm text-gray-900
              sm:mt-0 sm:col-span-2
              capitalize
              flex
            "
          >
            <form
              v-if="editTipoReferFlag"
              class="
                flex flex-col
                md:flex-row
                w-3/4
                md:w-full
                max-w-sm
                md:space-x-3
                space-y-3
                md:space-y-0
                justify-start
              "
            >
              <div class="">
                <label
                  for="price"
                  class="block text-xs font-medium text-gray-700 text-left ml-1"
                >
                  <strong>Editar Tipo de referencia</strong>
                </label>
                <select
                  type="text"
                  id='"form-subscribe-Subscribe'
                  class="
                    rounded-lg
                    border-transparent
                    flex-1
                    appearance-none
                    border border-gray-300
                    w-60
                    px-2
                    py-2
                    bg-white
                    text-gray-700
                    placeholder-gray-400
                    shadow-sm
                    text-xs
                    focus:outline-none
                    focus:ring-2
                    focus:ring-purple-600
                    focus:border-transparent
                  "
                  v-model="data.tipoRefer"
                >
                  <option class="text-gray-300" disabled value="">
                    Seleccione
                  </option>
                  <option>Laboral</option>
                  <option>Personal</option>
                </select>
              </div>

              <div
                class="
                  flex
                  w-auto
                  text-gray-500
                  items-end
                  justify-between
                  px-2
                  pb-0.5
                  space-x-1
                "
              >
                <button
                  @click.prevent="editTipoRefer(referncUsers.id)"
                  class="
                    p-1
                    flex
                    items-center
                    transition
                    ease-in
                    duration-200
                    uppercase
                    rounded-full
                    border border-green-200
                    text-green-600
                    hover:border-green-600
                    hover:bg-green-600
                    hover:text-yellow-200
                    focus:outline-none
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
                    />
                  </svg>
                </button>
                <button
                  @click="editTipoReferFlag = !editTipoReferFlag"
                  class="
                    flex
                    items-center
                    p-1
                    transition
                    ease-in
                    duration-200
                    uppercase
                    rounded-full
                    border border-red-300
                    text-red-500
                    hover:bg-red-800 hover:text-yellow-200
                    focus:outline-none
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </button>
              </div>
            </form>

            <div class="flex" v-if="!editTipoReferFlag">
              <span class="text-gray-700 mr-6 dark:text-white font-normal">
                {{ referncUsers.tipoRefer }}
              </span>
              <label class="flex items-center">
                <button
                  @click="editTipoReferFlag = !editTipoReferFlag"
                  class="
                    flex
                    items-center
                    p-1
                    transition
                    ease-in
                    duration-200
                    uppercase
                    rounded-full
                    hover:bg-blue-800 hover:text-yellow-200
                    focus:outline-none
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-3.5 w-3.5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                    />
                  </svg>
                </button>
              </label>
            </div>
          </dd>
        </div>

        <div
          v-if="$store.state.userData"
          class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
        >
          <dt
            v-if="!editRelacnReferFlag"
            class="text-sm text-left font-medium text-gray-500"
          >
            Relacion de la referencia
          </dt>
          <dd
            class="
              mt-1
              text-sm text-gray-900
              sm:mt-0 sm:col-span-2
              capitalize
              flex
            "
          >
            <form
              v-if="editRelacnReferFlag"
              class="
                flex flex-col
                md:flex-row
                w-3/4
                md:w-full
                max-w-sm
                md:space-x-3
                space-y-3
                md:space-y-0
                justify-start
              "
            >
              <div class="">
                <label
                  for="price"
                  class="block text-xs font-medium text-gray-700 text-left ml-1"
                >
                  <strong>Editar Relacion de la referencia</strong>
                </label>
                <select
                  type="text"
                  id='"form-subscribe-Subscribe'
                  class="
                    rounded-lg
                    border-transparent
                    flex-1
                    appearance-none
                    border border-gray-300
                    w-60
                    px-2
                    py-2
                    bg-white
                    text-gray-700
                    placeholder-gray-400
                    shadow-sm
                    text-xs
                    focus:outline-none
                    focus:ring-2
                    focus:ring-purple-600
                    focus:border-transparent
                  "
                  v-model="data.relacnRefer"
                >
                  <option class="text-gray-300" disabled value="">
                    Seleccione
                  </option>
                  <option>Superior inmediato</option>
                  <option>Superior no inmediato</option>
                  <option>Subordinado</option>
                </select>
              </div>

              <div
                class="
                  flex
                  w-auto
                  text-gray-500
                  items-end
                  justify-between
                  px-2
                  pb-0.5
                  space-x-1
                "
              >
                <button
                  @click.prevent="editRelacnRefer(referncUsers.id)"
                  class="
                    p-1
                    flex
                    items-center
                    transition
                    ease-in
                    duration-200
                    uppercase
                    rounded-full
                    border border-green-200
                    text-green-600
                    hover:border-green-600
                    hover:bg-green-600
                    hover:text-yellow-200
                    focus:outline-none
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
                    />
                  </svg>
                </button>
                <button
                  @click="editRelacnReferFlag = !editRelacnReferFlag"
                  class="
                    flex
                    items-center
                    p-1
                    transition
                    ease-in
                    duration-200
                    uppercase
                    rounded-full
                    border border-red-300
                    text-red-500
                    hover:bg-red-800 hover:text-yellow-200
                    focus:outline-none
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </button>
              </div>
            </form>

            <div class="flex" v-if="!editRelacnReferFlag">
              <span class="text-gray-700 mr-6 dark:text-white font-normal">
                {{ referncUsers.relacnRefer }}
              </span>
              <label class="flex items-center">
                <button
                  @click="editRelacnReferFlag = !editRelacnReferFlag"
                  class="
                    flex
                    items-center
                    p-1
                    transition
                    ease-in
                    duration-200
                    uppercase
                    rounded-full
                    hover:bg-blue-800 hover:text-yellow-200
                    focus:outline-none
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-3.5 w-3.5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                    />
                  </svg>
                </button>
              </label>
            </div>
          </dd>
        </div>
      </dl>
    </div>
    <loader v-if="isLoading">
      <template v-slot:msg>
        {{ msg }}
      </template>

      <template v-slot:msg1>
        {{ msg1 }}
      </template>
    </loader>
  </div>
</template>
<style scoped></style>
<script>
import axios from "axios";
import loader from "@/components/shared/loader.vue";

export default {
  name: "formReferncLabrl",
  components: {
    loader,
  },
  props: {
    referncUsers: {},
  },
  methods: {
    editarCampo() {
      this.editarFlag = !this.editarFlag;
    },
    editNombRefer(idRefLabrl) {
      let data = JSON.stringify({
        id: idRefLabrl,
        nombRefer: this.data.nombRefer,
      });
      axios({
        method: "put",
        url: `https://api.postula2.com/editReferLabor/`,
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.myTokToApiComs,
        },
        data: data,
      })
        .then(() => {
          //CLG("campo editado con exito");
          this.editFinished();
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG("mission pelaste bolas in educacion");
          //CLG(error);
        })
        .finally(() => {
          //Perform action in always
          this.editNombReferFlag = !this.editNombReferFlag;
          this.data.nombRefer = "";
        });
    },
    editApellidRefer(idRefLabrl) {
      let data = JSON.stringify({
        id: idRefLabrl,
        apellidRefer: this.data.apellidRefer,
      });
      axios({
        method: "put",
        url: `https://api.postula2.com/editReferLabor/`,
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.myTokToApiComs,
        },
        data: data,
      })
        .then(() => {
          //CLG("campo editado con exito");
          this.editFinished();
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG("mission pelaste bolas in educacion");
          //CLG(error);
        })
        .finally(() => {
          //Perform action in always
          this.editNombReferFlag = !this.editNombReferFlag;
          this.data.apellidRefer = "";
        });
    },
    editEmailRefer(idRefLabrl) {
      let data = JSON.stringify({
        id: idRefLabrl,
        emailRefer: this.data.emailRefer,
      });
      axios({
        method: "put",
        url: `https://api.postula2.com/editReferLabor/`,
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.myTokToApiComs,
        },
        data: data,
      })
        .then(() => {
          //CLG("campo editado con exito");
          this.editFinished();
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG("data: ", data);
          //CLG("mission pelaste bolas in refLaboral");
          //CLG(error);
        })
        .finally(() => {
          //Perform action in always
          this.editEmailReferFlag = !this.editEmailReferFlag;
          this.data.emailRefer = "";
        });
    },
    editTelefRefer(idRefLabrl) {
      this.data.telefRefer = this.cod + this.numero;
      let data = JSON.stringify({
        id: idRefLabrl,
        telefRefer: this.data.telefRefer,
      });
      axios({
        method: "put",
        url: `https://api.postula2.com/editReferLabor/`,
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.myTokToApiComs,
        },
        data: data,
      })
        .then(() => {
          //CLG("campo editado con exito");
          this.editFinished();
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG("mission pelaste bolas in educacion");
          //CLG(error);
        })
        .finally(() => {
          //Perform action in always
          this.editTelefReferFlag = !this.editTelefReferFlag;
          this.data.telefRefer = "";
        });
    },
    editTipoRefer(idRefLabrl) {
      let data = JSON.stringify({
        id: idRefLabrl,
        tipoRefer: this.data.tipoRefer,
      });
      axios({
        method: "put",
        url: `https://api.postula2.com/editReferLabor/`,
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.myTokToApiComs,
        },
        data: data,
      })
        .then(() => {
          //CLG("campo editado con exito");
          this.editFinished();
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG("mission pelaste bolas in educacion");
          //CLG(error);
        })
        .finally(() => {
          //Perform action in always
          this.editTipoReferFlag = !this.editTipoReferFlag;
          this.data.tipoRefer = "";
        });
    },
    editRelacnRefer(idRefLabrl) {
      let data = JSON.stringify({
        id: idRefLabrl,
        relacnRefer: this.data.relacnRefer,
      });
      axios({
        method: "put",
        url: `https://api.postula2.com/editReferLabor/`,
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.myTokToApiComs,
        },
        data: data,
      })
        .then(() => {
          //CLG("campo editado con exito");
          this.editFinished();
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG("mission pelaste bolas in educacion");
          //CLG(error);
        })
        .finally(() => {
          //Perform action in always
          this.editRelacnReferFlag = !this.editRelacnReferFlag;
          this.data.relacnRefer = "";
        });
    },
    editar() {
      this.editing = true;
    },
    editFinished() {
      this.editing = false;
      //https://api.postula2.com/persona/624745e3e602450004288516/experncLabors
      axios({
        method: "get",
        url: `https://api.postula2.com/persona/${this.$store.state.userData.id}/ReferncUsers`,
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.myTokToApiComs,
        },
      })
        .then((res) => {
          this.$store.state.userData.referncUsers = res.data;
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG("mission pelaste bolas in editReferLabor");
          //CLG(error);
        })
        .finally(() => {
          //Perform action in always
        });
    },
    onSubmit() {
      //let dataUser = this.data;
      this.isLoading = true;
      this.data.owner = this.$store.state.userData.id;
      axios
        .post(
          "https://api.postula2.com/ReferncUsers",
          this.data
        )
        .then(() => {
          //Perform Success Action
          //CLG("response:", res);
          this.msg = "Referencia laboral registrada satisfactoriamente...!";
          //this.$store.commit("getflagLogin", redFlag);
          this.$store.commit({
            type: "getflagLogin",
            greenFlag: true,
          });
          setTimeout(() => {
            this.editFinished();
            (this.data.nombRefer = ""),
              (this.data.apellidRefer = ""),
              (this.data.emailRefer = ""),
              (this.data.telefRefer = ""),
              (this.data.tipoRefer = ""),
              (this.data.relacnRefer = ""),
              (this.data.owner = null);
            //this.editing = false;
            this.isLoading = false;
            this.$store.commit({
              type: "getflagLogin",
              greenFlag: null,
            });
          }, 2000);
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG(error);
          this.msg1 =
            "¡Verifique la información ingresada e intente nuevamente!";
          //this.$store.commit("getflagLogin", redFlag);
          this.$store.commit({
            type: "getflagLogin",
            redFlag: true,
          });
          setTimeout(() => {
            this.isLoading = false;
            this.$store.commit({
              type: "getflagLogin",
              redFlag: null,
            });
          }, 3000);
        })
        .finally(() => {
          //Perform action in always
        });
    },
    envioData() {
      this.$bus.$emit("userdata", this.data);
    },
  },
  // created() {
  //   if (!this.$store.state.regUser.id) {
  //     alert("Debe iniciar sesion");
  //     this.$router.push({
  //       name: "login",
  //     });
  //   }
  // },
  data() {
    return {
      editarFlag: false,

      editNombReferFlag: false,
      editApellidReferFlag: false,
      editEmailReferFlag: false,
      editTelefReferFlag: false,
      editTipoReferFlag: false,
      editRelacnReferFlag: false,

      editing: false,
      isLoading: false,
      msg: null,
      msg1: null,
      cod: "",
      numero: "",
      data: {
        nombRefer: "",
        apellidRefer: "",
        emailRefer: "",
        telefRefer: "",
        tipoRefer: "",
        relacnRefer: "",
        confirmRefer: "1",
        owner: null,
      },
    };
  },
};
</script>
