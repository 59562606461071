var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container flex-wrap px-5 py-4 mx-auto items-center"},[_c('div',{staticClass:"overflow-hidden rounded-lg h-90 w-full md:w-12/12 m-auto"},[_vm._m(0),_c('div',{staticClass:"bg-gray-300 dark:bg-gray-800 w-full"},[_c('div',{staticClass:"max-w-md mx-auto overflow-hidden bg-gray-300 rounded-lg dark:bg-gray-800"},[_c('div',{staticClass:"px-3 md:px-8 py-6"},[_c('h2',{staticClass:"text-2xl font-bold text-center text-gray-700 dark:text-white"},[_vm._v(" Registro de usuarios ")]),_c('p',{staticClass:"mt-1 text-center text-lg text-gray-500 dark:text-gray-400"},[_vm._v(" Crea una cuenta ")]),_c('FormulateForm',{on:{"submit":_vm.onSubmit}},[_c('div',{staticClass:"w-full mt-3"},[_c('label',{staticClass:"block text-xs font-medium text-gray-700 text-left ml-1",attrs:{"for":"price"}},[_c('strong',[_vm._v("Nombre")])]),_c('FormulateInput',{staticClass:"text-left bg-white rounded-lg",attrs:{"type":"text","name":"nombre","placeholder":"Nombre","validation":"required|min:4|matches:/[A-Za-z\\s\\á\\é\\í\\ó\\ú]+$/","validation-messages":{
                  min: 'Debe tener al menos 4 caracteres',
                  matches: 'Este campo solo acepta letras',
                  required: 'Este campo es obligatorio',
                }},model:{value:(_vm.data.nombreUser),callback:function ($$v) {_vm.$set(_vm.data, "nombreUser", $$v)},expression:"data.nombreUser"}})],1),_c('div',{staticClass:"w-full mt-2"},[_c('label',{staticClass:"block text-xs font-medium text-gray-700 text-left ml-1",attrs:{"for":"price"}},[_c('strong',[_vm._v("Apellido")])]),_c('FormulateInput',{staticClass:"text-left bg-white rounded-lg",attrs:{"type":"text","placeholder":"Apellido","name":"apellido","validation":"required|min:4|matches:/^[A-Za-z\\s*\\á\\é\\í\\ó\\ú\\ñ]+$/","error-behavior":"value","validation-messages":{
                  min: 'Debe tener al menos 4 caracteres',
                  matches: 'Este campo solo acepta letras',
                  required: 'Este campo es obligatorio',
                }},model:{value:(_vm.data.apellidoUser),callback:function ($$v) {_vm.$set(_vm.data, "apellidoUser", $$v)},expression:"data.apellidoUser"}})],1),_c('div',{staticClass:"w-full mt-2"},[_c('label',{staticClass:"block text-xs font-medium text-gray-700 text-left ml-1",attrs:{"for":"price"}},[_c('strong',[_vm._v("Correo electrónico")])]),_c('FormulateInput',{staticClass:"text-left bg-white rounded-lg",attrs:{"type":"email","name":"email","placeholder":"Correo electrónico","validation":"^required|min:4,^required|email","error-behavior":"value","validation-messages":{
                  required: 'Debe ingresar un email valido',
                }},model:{value:(_vm.data.emailUser),callback:function ($$v) {_vm.$set(_vm.data, "emailUser", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"data.emailUser"}})],1),_c('div',{staticClass:"w-full flex mt-2"},[_c('div',{staticClass:"grid w-1/4"},[_c('label',{staticClass:"text-xs font-medium text-gray-700 text-left ml-1",attrs:{"for":"price"}},[_c('strong',[_vm._v("Teléfono")])]),_c('select',{directives:[{name:"model",rawName:"v-model.lazy.trim",value:(_vm.cod),expression:"cod",modifiers:{"lazy":true,"trim":true}}],staticClass:"text-sm focus:ring-indigo-500 h-10 py-2 px-4 border-t border-l border-gray-300 border-b focus:border-indigo-500 pl-2 pr-2 border-transparent bg-transparent text-gray-500 bg-white rounded-md",attrs:{"id":"operador","name":"codigoOperador","placeholder":"est","aria-placeholder":"asd"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.cod=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"disabled":"","value":""}},[_vm._v("04XX")]),_c('option',[_vm._v("0412")]),_c('option',[_vm._v("0414")]),_c('option',[_vm._v("0424")]),_c('option',[_vm._v("0416")]),_c('option',[_vm._v("0426")])])]),_c('div',{staticClass:"mt-0.5 w-3/4 ml-1.5"},[_c('FormulateInput',{staticClass:"text-left bg-white rounded-lg mt-4",attrs:{"type":"number","placeholder":"Teléfono","name":"numeroCelular","validation":"required|max:7,length|min:7,length|matches:/^[0-9]/|","validation-messages":{
                    required: 'Debes rellenar este campo',
                    max: 'Maximo 7 digitos',
                    min: 'Al menos 7 digitos',
                    matches: 'Deben ser numeros',
                    not: 'No permitido',
                  }},model:{value:(_vm.numero),callback:function ($$v) {_vm.numero=$$v},expression:"numero"}})],1)]),_c('div',{staticClass:"w-full mt-2"},[_c('label',{staticClass:"block text-xs font-medium text-gray-700 text-left ml-1",attrs:{"for":"price"}},[_c('strong',[_vm._v(" Contraseña ")])]),_c('FormulateInput',{staticClass:"text-left bg-white rounded-lg mb-2",attrs:{"type":"password","name":"password","placeholder":"Contraseña","validation":"required|min:6 ","validation-messages":{
                  required: 'Debes rellenar este campo',
                  min: 'Minimo 6 caracteres',
                }}}),_c('label',{staticClass:"block text-xs font-medium text-gray-700 text-left ml-1",attrs:{"for":"price"}},[_c('strong',[_vm._v(" Confirmar contraseña ")])]),_c('FormulateInput',{staticClass:"text-left bg-white rounded-lg mb-2",attrs:{"type":"password","placeholder":"Confirmar contraseña","name":"password_confirm","validation":"required|confirm","validation-messages":{
                  required: 'Debes rellenar este campo',
                  confirm: 'Contraseña no coincide',
                },"validation-name":"Password confirmation es"},model:{value:(_vm.data.passwUser),callback:function ($$v) {_vm.$set(_vm.data, "passwUser", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"data.passwUser"}})],1),_c('div',{staticClass:"w-full mt-2"},[_c('label',{staticClass:"block text-xs font-medium text-gray-700 text-left ml-1",attrs:{"for":"price"}},[_c('strong',[_vm._v(" Pregunta de seguridad ")])]),_c('FormulateInput',{staticClass:"text-left bg-white rounded-lg mb-2",attrs:{"options":{
                  'Nombre de tu primera mascota':
                    'Nombre de tu primera mascota',
                  'Nombre de tu primer jefe': 'Nombre de tu primer jefe',
                  'Lugar de nacimiento de tu padre':
                    'Lugar de nacimiento de tu padre',
                  'Nombra tu comida favorita': 'Nombra tu comida favorita',
                  'Marca de vehiculo favorita': 'Marca de vehiculo favorita',
                  'Nombre de tu hermano/a': 'Nombre de tu hermano/a',
                  'Nombre de tu primer maestro':
                    'Nombre de tu primer maestro',
                },"validation":"required","validation-messages":{
                  required: 'Debes rellenar este campo',
                },"type":"select","placeholder":"Seleccione"},model:{value:(_vm.data.secQuest),callback:function ($$v) {_vm.$set(_vm.data, "secQuest", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"data.secQuest"}}),_c('label',{staticClass:"block text-xs font-medium text-gray-700 text-left ml-1 mt-0",attrs:{"for":"price"}},[_c('strong',[_vm._v(" Respuesta ")])]),_c('FormulateInput',{staticClass:"text-left bg-white rounded-lg",attrs:{"type":"text","placeholder":"Respuesta de seguridad","name":"password_confirm","validation":"required","validation-messages":{
                  required: 'Debes rellenar este campo',
                },"validation-name":"Password confirmation es"},model:{value:(_vm.data.resSecQuest),callback:function ($$v) {_vm.$set(_vm.data, "resSecQuest", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"data.resSecQuest"}})],1),_c('div',{staticClass:"w-full p-2 mt-4 bg-gray-100 rounded-md"},[_c('FormulateInput',{staticClass:"formulate-input-decorator text-left text-sm px-1 rounded-lg mb-2",attrs:{"label":"Autorizo el uso de mi información para fines adicionales","validation":"required","validation-messages":{
                  required: 'Debes rellenar este campo',
                },"type":"checkbox","placeholder":"Seleccione"},model:{value:(_vm.data.autorizationUsageInfo),callback:function ($$v) {_vm.$set(_vm.data, "autorizationUsageInfo", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"data.autorizationUsageInfo"}}),_c('FormulateInput',{staticClass:"formulate-input-decorator text-left text-sm px-1 rounded-lg mb-2",attrs:{"label":"Quiero recibir Novedades y Promociones","type":"checkbox","placeholder":"Seleccione"},model:{value:(_vm.data.autorizationNewsProms),callback:function ($$v) {_vm.$set(_vm.data, "autorizationNewsProms", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"data.autorizationNewsProms"}})],1),_c('div',{staticClass:"flex items-center justify-center mt-4"},[_c('button',{staticClass:"py-2 px-4 bg-postul2 hover:bg-blue-800 hover:ring-offset-blue-200 focus:ring-blue-600 focus:ring-offset-blue-200 text-postul2 text-white w-2/6 transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg",attrs:{"type":"submit"}},[_vm._v(" Enviar ")])])])],1),(_vm.isLoading)?_c('loader',{scopedSlots:_vm._u([{key:"msg",fn:function(){return [_vm._v(" "+_vm._s(_vm.msg)+" ")]},proxy:true},{key:"msg1",fn:function(){return [_vm._v(" "+_vm._s(_vm.msg1)+" ")]},proxy:true}],null,false,2476820201)}):_vm._e()],1)]),_c('div',{staticClass:"flex items-center justify-center py-1.5 text-xs text-center bg-gray-200 dark:bg-gray-700"},[_c('span',{staticClass:"text-gray-600 dark:text-gray-200"},[_vm._v("¿Ya estás registrado? ")]),_c('a',{staticClass:"mx-2 font-bold text-blue-600 dark:text-blue-400 hover:text-blue-500",attrs:{"href":"#"},on:{"click":function($event){return _vm.$router.push('login')}}},[_vm._v("Iniciar sesión")])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"w-full block h-full"},[_c('img',{staticClass:"max-h-40 md:max-h-48 w-full object-cover",attrs:{"alt":"blog photo","src":require("./img/BANNER2.png")}})])
}]

export { render, staticRenderFns }