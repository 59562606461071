<template>
  <section class="text-gray-600 body-font relative w-full bg-gray-200">
    <div class="container px-0 py-1 mx-auto flex sm:flex-nowrap flex-wrap">
      <div
        class="lg:w-1/2 md:w-1/2 bg-gray-100 flex flex-col md:ml-auto w-full md:py-8 mt-8 md:mt-0 justify-center mx-auto p-5 rounded-lg shadow"
      >
        <empresa></empresa>
      </div>
    </div>
  </section>
</template>

<script>
import backgroundUrl from "./img/office_img.jpg";
import Empresa from "./Empresa.vue";

export default {
  name: "App",

  components: {
    Empresa,
  },
  data() {
    return {
      backgroundUrl,
    };
  },
};
</script>
