var render = function render(){var _vm=this,_c=_vm._self._c;return (this.$store.state.regUser.id)?_c('div',{staticClass:"gap-2 lg:gap-4 flex justify-center items-center m-3 lg:mt-0"},[_c('div',{staticClass:"flex flex-wrap lg:w-64 gap-2 lg:gap-0"},[_c('div',{staticClass:"w-full lg:w-1/3 flex items-center justify-center"},[(this.$store.state.userData)?_c('div',{staticClass:"flex-shrink-0"},[(this.$store.state.profileImage.flagImgUploaded === true)?_c('div',[_c('a',{staticClass:"block",on:{"click":_vm.goTo}},[(this.$store.state.profileImage.flagImgUploaded === true)?_c('img',{staticClass:"mx-auto object-cover rounded-full h-10 w-10 cursor-pointer",class:{
                'border-dotted border-2 border-black': _vm.debg,
              },attrs:{"alt":"profil","src":`https://api.postula2.com/imagesTi?id=${this.$store.state.profileImage.image.imageName}`}}):_vm._e()])]):(this.$store.state.userData)?_c('div',[(
              this.$store.state.userData.genero === 'Femenino' &&
              _vm.$store.state.userData.imageProfileInfo.length == 0
            )?_c('a',{staticClass:"block",on:{"click":_vm.goTo}},[_c('img',{staticClass:"mx-auto object-cover rounded-full h-10 w-10 cursor-pointer",attrs:{"alt":"profil","src":require("@/components/img/profileFemale.png")}})]):(this.$store.state.userData.id)?_c('a',{staticClass:"block cursor-pointer",on:{"click":_vm.goTo}},[(this.$store.state.userData.imageProfileInfo.length > 0)?_c('img',{staticClass:"mx-auto object-cover rounded-full h-10 w-10",class:{
                'border-2 border-red-800': _vm.debg,
              },attrs:{"src":`https://api.postula2.com/imagesTi?id=${this.$store.state.userData.imageProfileInfo[0].imageName}`,"alt":""},on:{"click":function($event){return _vm.$router.push('home')}}}):_c('img',{staticClass:"mx-auto object-cover rounded-full h-10 w-10",class:{
                'border-2 border-green-700': _vm.debg,
              },attrs:{"src":require("@/components/img/picture1.jpg"),"alt":""},on:{"click":function($event){return _vm.$router.push('home')}}})]):_c('a',{staticClass:"block cursor-pointer",on:{"click":_vm.goTo}},[_c('img',{staticClass:"mx-auto object-cover rounded-full h-10 w-10 border",class:{
                'border-2 border-indigo-700': _vm.debg,
              },attrs:{"src":require("@/components/img/profilepic.png"),"alt":""},on:{"click":function($event){return _vm.$router.push('home')}}})])]):_vm._e()]):_c('div',[(!this.$store.state.userData)?_c('img',{staticClass:"mx-auto object-cover rounded-full h-10 w-10",class:{
            'border-2 border-blue-700': _vm.debg,
          },attrs:{"src":require("@/components/img/profilepic.png"),"alt":""},on:{"click":function($event){return _vm.$router.push('home')}}}):(this.$store.state.userData.genero === 'Femenino')?_c('img',{staticClass:"mx-auto object-cover rounded-full h-10 w-10",class:{
            'border-2 border-yellow-700': _vm.debg,
          },attrs:{"src":require("@/components/img/profileFemale.png"),"alt":""},on:{"click":function($event){return _vm.$router.push('home')}}}):_c('img',{staticClass:"mx-auto object-cover rounded-full h-10 w-10",attrs:{"src":require("@/components/img/picture1.jpg"),"alt":""},on:{"click":function($event){return _vm.$router.push('home')}}})])]),_c('div',{staticClass:"w-full lg:w-1/3 cursor-pointer flex items-center justify-center px-2 lg:px-0",on:{"click":_vm.goTo}},[_c('span',{staticClass:"text-gray-600 dark:text-white text-xs font-medium"},[_vm._v(" "+_vm._s(this.$store.state.regUser.nombreUser)+" ")]),_c('span',{staticClass:"text-gray-400 text-xs2 hidden"},[_vm._v(" "+_vm._s(_vm.$store.state.regUser.emailUser)+" ")])]),_c('div',{staticClass:"w-full lg:w-1/3 flex items-center justify-center"},[_c('button',{staticClass:"py-1.5 px-2 bg-red-300 hover:bg-red-600 focus:ring-red-500 focus:ring-offset-red-200 text-white transition ease-in duration-200 text-center text-xs font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg lg:uppercase",on:{"click":function($event){return _vm.reloaded()}}},[_vm._v(" Salir ")])])]),_c('div',{staticClass:"inline-block text-right"},[(_vm.isLoading)?_c('loader',{scopedSlots:_vm._u([{key:"msg",fn:function(){return [_vm._v(" "+_vm._s(_vm.msg)+" ")]},proxy:true},{key:"msg1",fn:function(){return [_vm._v(" "+_vm._s(_vm.msg1)+" ")]},proxy:true}],null,false,2476820201)}):_vm._e()],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }