<template>
  <div class="w-full flex justify-center">
    <h3
      v-if="$store.state.flagLogin.greenFlag"
      class="text-sm font-semibold mx-3 my-2 p-2 bg-green-200 text-left rounded-lg"
    >
      <slot name="msg"></slot>
    </h3>
    <h3
      v-if="$store.state.flagLogin.redFlag"
      class="text-sm font-semibold mx-6 my-2 p-2 bg-red-300 text-left text-gray-600 rounded-md"
    >
      <slot name="msg1"></slot>
    </h3>
    <div
      class=" flex justify-center items-center bg-transparent transition ease-in duration-200 text-center text-base font-semibold shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-full"
    >
      <svg
        width="20"
        height="20"
        fill="currentColor"
        class="animate-spin color_post2"
        viewBox="0 0 1792 1792"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M526 1394q0 53-37.5 90.5t-90.5 37.5q-52 0-90-38t-38-90q0-53 37.5-90.5t90.5-37.5 90.5 37.5 37.5 90.5zm498 206q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-704-704q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm1202 498q0 52-38 90t-90 38q-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-964-996q0 66-47 113t-113 47-113-47-47-113 47-113 113-47 113 47 47 113zm1170 498q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-640-704q0 80-56 136t-136 56-136-56-56-136 56-136 136-56 136 56 56 136zm530 206q0 93-66 158.5t-158 65.5q-93 0-158.5-65.5t-65.5-158.5q0-92 65.5-158t158.5-66q92 0 158 66t66 158z"
        ></path>
      </svg>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.color_post2{
  color: #134992;
}
</style>
