<template>
  <div>
    <loader v-if="isLoading" />

    <main
      class="
        bg-gray-50
        dark:bg-gray-800
        h-full
        max-h-full
        overflow-y-auto
        relative
        mb-10
        pb-16
      "
    >
      <div class="flex items-start justify-between">
        <div class="flex flex-col w-full md:space-y-4">
          <div
            class="
              overflow-visible
              h-screen
              pb-24
              px-4
              md:px-6 md:mt-4 md:mb-8
              lg:mt-6
              border-dashed border-red-500
            "
          >
            <div
              class="
                flex
                items-center
                space-x-4
                font-light
                w-full
                text-center text-xl
                sm:text-md
                md:text-2xl
                dark:text-white
                text-gray-800
                border-b border-gray-200
              "
            >
              Editar analista
            </div>
            <div
              class="
                flex
                items-center
                mt-0.5
                font-light
                w-1/6
                text-center text-xs
                sm:text-xs
                md:text-xs
                dark:text-white
                text-blue-500
              "
            >
              <span @click="volver" class="cursor-pointer">⇤ Volver</span>
            </div>
            <div
              class="
                place-items-center
                mx-auto
                grid
                sm:grid-cols-1
                md:grid-cols-1
                lg:grid-cols-1
                w-5/6
                py-6
                mt-2
                mb-4
              "
            >
              <formAnalistEditar></formAnalistEditar>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
<script>
import loader from "@/components/shared/loader.vue";
import formAnalistEditar from "@/components/formAnalistEditar.vue";

export default {
  name: "AnunciosEditarByEmprs",

  components: {
    formAnalistEditar,
    loader,
  },
  data() {
    return {
      assets: [],

      isLoading: false,
    };
  },
  methods: {
    volver() {
      this.$router.push({
        name: "AnalistaConsultaTodos",
      });
    },
  },
};
</script>
