<template>
  <div class="container flex-wrap px-5 py-4 mx-auto items-center">
    <div class="overflow-hidden rounded-lg h-90 w-full md:w-12/12 m-auto">
      <a class="w-full block h-full">
        <img
          alt="blog photo"
          src="./img/BANNER2.png"
          class="max-h-40 md:max-h-48 w-full object-cover"
        />
      </a>
      <div class="bg-gray-300 dark:bg-gray-800 w-full">
        <div
          class="max-w-md mx-auto overflow-hidden bg-gray-300 rounded-lg dark:bg-gray-800"
        >
          <div class="px-3 md:px-8 py-6">
            <h2
              class="text-2xl font-bold text-center text-gray-700 dark:text-white"
            >
              Registro de usuarios
            </h2>

            <p
              class="mt-1 text-center text-lg text-gray-500 dark:text-gray-400"
            >
              Crea una cuenta
            </p>

            <FormulateForm @submit="onSubmit">
              <div class="w-full mt-3">
                <label
                  for="price"
                  class="block text-xs font-medium text-gray-700 text-left ml-1"
                >
                  <strong>Nombre</strong>
                </label>
                <FormulateInput
                  type="text"
                  name="nombre"
                  v-model.lazy="data.nombreUser"
                  placeholder="Nombre"
                  validation="required|min:4|matches:/[A-Za-z\s\á\é\í\ó\ú]+$/"
                  class="text-left bg-white rounded-lg"
                  :validation-messages="{
                    min: 'Debe tener al menos 4 caracteres',
                    matches: 'Este campo solo acepta letras',
                    required: 'Este campo es obligatorio',
                  }"
                />
              </div>
              <div class="w-full mt-2">
                <label
                  for="price"
                  class="block text-xs font-medium text-gray-700 text-left ml-1"
                >
                  <strong>Apellido</strong>
                </label>
                <FormulateInput
                  type="text"
                  placeholder="Apellido"
                  name="apellido"
                  v-model.lazy="data.apellidoUser"
                  validation="required|min:4|matches:/^[A-Za-z\s*\á\é\í\ó\ú\ñ]+$/"
                  class="text-left bg-white rounded-lg"
                  error-behavior="value"
                  :validation-messages="{
                    min: 'Debe tener al menos 4 caracteres',
                    matches: 'Este campo solo acepta letras',
                    required: 'Este campo es obligatorio',
                  }"
                />
              </div>
              <div class="w-full mt-2">
                <label
                  for="price"
                  class="block text-xs font-medium text-gray-700 text-left ml-1"
                >
                  <strong>Correo electrónico</strong>
                </label>
                <FormulateInput
                  type="email"
                  name="email"
                  v-model.lazy.trim="data.emailUser"
                  placeholder="Correo electrónico"
                  validation="^required|min:4,^required|email"
                  class="text-left bg-white rounded-lg"
                  error-behavior="value"
                  :validation-messages="{
                    required: 'Debe ingresar un email valido',
                  }"
                />
              </div>
              <div class="w-full flex mt-2">
                <div class="grid w-1/4">
                  <label
                    for="price"
                    class="text-xs font-medium text-gray-700 text-left ml-1"
                  >
                    <strong>Teléfono</strong>
                  </label>

                  <select
                    v-model.lazy.trim="cod"
                    id="operador"
                    name="codigoOperador"
                    class="text-sm focus:ring-indigo-500 h-10 py-2 px-4 border-t border-l border-gray-300 border-b focus:border-indigo-500 pl-2 pr-2 border-transparent bg-transparent text-gray-500 bg-white rounded-md"
                    placeholder="est"
                    aria-placeholder="asd"
                  >
                    <option disabled value="" class="">04XX</option>
                    <option>0412</option>
                    <option>0414</option>
                    <option>0424</option>
                    <option>0416</option>
                    <option>0426</option>
                  </select>
                </div>
                <div class="mt-0.5 w-3/4 ml-1.5">
                  <FormulateInput
                    type="number"
                    placeholder="Teléfono"
                    name="numeroCelular"
                    v-model.lazy="numero"
                    class="text-left bg-white rounded-lg mt-4"
                    validation="required|max:7,length|min:7,length|matches:/^[0-9]/|"
                    :validation-messages="{
                      required: 'Debes rellenar este campo',
                      max: 'Maximo 7 digitos',
                      min: 'Al menos 7 digitos',
                      matches: 'Deben ser numeros',
                      not: 'No permitido',
                    }"
                  />
                </div>
              </div>

              <div class="w-full mt-2">
                <label
                  for="price"
                  class="block text-xs font-medium text-gray-700 text-left ml-1"
                >
                  <strong> Contraseña </strong>
                </label>
                <FormulateInput
                  type="password"
                  name="password"
                  placeholder="Contraseña"
                  validation="required|min:6 "
                  :validation-messages="{
                    required: 'Debes rellenar este campo',
                    min: 'Minimo 6 caracteres',
                  }"
                  class="text-left bg-white rounded-lg mb-2"
                />
                <label
                  for="price"
                  class="block text-xs font-medium text-gray-700 text-left ml-1"
                >
                  <strong> Confirmar contraseña </strong>
                </label>
                <FormulateInput
                  type="password"
                  placeholder="Confirmar contraseña"
                  name="password_confirm"
                  v-model.lazy.trim="data.passwUser"
                  class="text-left bg-white rounded-lg mb-2"
                  validation="required|confirm"
                  :validation-messages="{
                    required: 'Debes rellenar este campo',
                    confirm: 'Contraseña no coincide',
                  }"
                  validation-name="Password confirmation es"
                />
              </div>

              <div class="w-full mt-2">
                <label
                  for="price"
                  class="block text-xs font-medium text-gray-700 text-left ml-1"
                >
                  <strong> Pregunta de seguridad </strong>
                </label>
                <FormulateInput
                  v-model.lazy.trim="data.secQuest"
                  class="text-left bg-white rounded-lg mb-2"
                  :options="{
                    'Nombre de tu primera mascota':
                      'Nombre de tu primera mascota',
                    'Nombre de tu primer jefe': 'Nombre de tu primer jefe',
                    'Lugar de nacimiento de tu padre':
                      'Lugar de nacimiento de tu padre',
                    'Nombra tu comida favorita': 'Nombra tu comida favorita',
                    'Marca de vehiculo favorita': 'Marca de vehiculo favorita',
                    'Nombre de tu hermano/a': 'Nombre de tu hermano/a',
                    'Nombre de tu primer maestro':
                      'Nombre de tu primer maestro',
                  }"
                  validation="required"
                  :validation-messages="{
                    required: 'Debes rellenar este campo',
                  }"
                  type="select"
                  placeholder="Seleccione"
                />
                <label
                  for="price"
                  class="block text-xs font-medium text-gray-700 text-left ml-1 mt-0"
                >
                  <strong> Respuesta </strong>
                </label>
                <FormulateInput
                  type="text"
                  placeholder="Respuesta de seguridad"
                  name="password_confirm"
                  v-model.lazy.trim="data.resSecQuest"
                  class="text-left bg-white rounded-lg"
                  validation="required"
                  :validation-messages="{
                    required: 'Debes rellenar este campo',
                  }"
                  validation-name="Password confirmation es"
                />
              </div>

              <div class="w-full p-2 mt-4 bg-gray-100 rounded-md">
                <FormulateInput
                  v-model.lazy.trim="data.autorizationUsageInfo"
                  class="formulate-input-decorator text-left text-sm px-1 rounded-lg mb-2"
                  label="Autorizo el uso de mi información para fines adicionales"
                  validation="required"
                  :validation-messages="{
                    required: 'Debes rellenar este campo',
                  }"
                  type="checkbox"
                  placeholder="Seleccione"
                />
                <FormulateInput
                  v-model.lazy.trim="data.autorizationNewsProms"
                  class="formulate-input-decorator text-left text-sm px-1 rounded-lg mb-2"
                  label="Quiero recibir Novedades y Promociones"
                  type="checkbox"
                  placeholder="Seleccione"
                />
              </div>
              <div class="flex items-center justify-center mt-4">
                <button
                  class="py-2 px-4 bg-postul2 hover:bg-blue-800 hover:ring-offset-blue-200 focus:ring-blue-600 focus:ring-offset-blue-200 text-postul2 text-white w-2/6 transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg"
                  type="submit"
                >
                  Enviar
                </button>
              </div>
            </FormulateForm>
          </div>
          <loader v-if="isLoading">
            <template v-slot:msg>
              {{ msg }}
            </template>

            <template v-slot:msg1>
              {{ msg1 }}
            </template>
          </loader>
        </div>
      </div>
      <div
        class="flex items-center justify-center py-1.5 text-xs text-center bg-gray-200 dark:bg-gray-700"
      >
        <span class="text-gray-600 dark:text-gray-200"
          >¿Ya estás registrado?
        </span>

        <a
          href="#"
          class="mx-2 font-bold text-blue-600 dark:text-blue-400 hover:text-blue-500"
          @click="$router.push('login')"
          >Iniciar sesión</a
        >
      </div>
    </div>
  </div>
</template>
<style scoped>
.text-xs2 {
  font-size: 0.5rem /* 12px */;
  line-height: 0.6rem /* 16px */;
}
.bg-postul2 {
  --tw-border-opacity: 1;
  background-color: rgba(19, 73, 146, var(--tw-bg-opacity));
}
.border-postul2 {
  --tw-border-opacity: 1;
  border-color: rgba(25, 73, 146, var(--tw-bg-opacity));
}
.hover\:border-postul2:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(19, 73, 146, var(--tw-bg-opacity));
}
.text-postul2 {
  --tw-text-opacity: 1;
  color: rgba(239, 224, 37, var(--tw-text-opacity));
}
.hover\:text-postul2:hover {
  --tw-text-opacity: 1;
  color: rgba(239, 224, 37, var(--tw-text-opacity));
}
.hover\:bgg-postul2:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(23, 73, 146, var(--tw-bg-opacity));
}
</style>
<style lang="scss" scoped>
.formulate-input {
  & [data-classification="number"] {
    input {
      .no-outline:focus {
        outline: none;
      }
    }
  }
}
</style>
<script>
import axios from "axios";
import loader from "@/components/shared/loader.vue";

export default {
  name: "registrarPerson",
  components: { loader },
  methods: {
    onSubmit() {
      ////CLG("ta llegando hola");
      let dataUser = this.data;
      this.isLoading = true;

      this.data.telf1 = this.cod + this.numero;

      if (!this.cod || !this.numero) {
        alert("Debe ingresar un número telefónico valido");
        return;
      }
      axios
        //.post("https://api.postula2.com/createUser", this.data)
        .post("https://api.postula2.com/createUser", this.data)
        .then((res) => {
          //Perform Success Action
          //CLG("mission acomplished");
          //CLG("respuesta post id objeto registrad", res.data);
          ////CLG("esssss", res.data.error.code);

          if (res.data.error) {
            //error 201-->api responde, datos ya existentes
            if (res.data.error.code == 201) {
              this.msg1 =
                "¡Verifique la información ingresada e intente nuevamente!";
              //this.$store.commit("getflagLogin", redFlag);
              this.$store.commit({
                type: "getflagLogin",
                redFlag: true,
              });
              setTimeout(() => {
                this.isLoading = false;
                this.$store.commit({
                  type: "getflagLogin",
                  redFlag: null,
                });
              }, 3000);
            }
          } else if (res.data.success.code == 200) {
            this.$store.commit("getRegUser", res.data.success.data);
            let myTok = "Bearer " + res.data.success.token;
            this.$store.commit("getMyTokCredentApi", myTok);
            //console.log("toke",res.data.success.token)
            // alert(
            //   "Registrado satisfactoriamente, debe completar fase inicial de registro"
            // );
            this.msg =
              "Registrado satisfactoriamente.. debe completar fase inicial de registro!";
            //this.$store.commit("getflagLogin", redFlag);
            this.$store.commit({
              type: "getflagLogin",
              greenFlag: true,
            });
            setTimeout(() => {
              this.isLoading = false;
              this.$store.commit({
                type: "getflagLogin",
                greenFlag: null,
              });
              this.$router.push({
                name: "DatosCv",
                params: { dataUser },
              });
            }, 2500);

            //CLG("llego hasta aquilani");
          }
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG("mission pelaste bolas");
          //CLG(error);
          this.msg1 =
            "¡Verifique la información ingresada e intente nuevamente!";
          //this.$store.commit("getflagLogin", redFlag);
          this.$store.commit({
            type: "getflagLogin",
            redFlag: true,
          });
          setTimeout(() => {
            this.isLoading = false;
            this.$store.commit({
              type: "getflagLogin",
              redFlag: null,
            });
          }, 3000);
        })
        .finally(() => {
          //Perform action in always
        });
    },
  },
  data() {
    return {
      isLoading: false,
      msg: null,
      msg1: null,
      numero: "",
      cod: "",
      data: {
        nombreUser: "",
        apellidoUser: "",
        emailUser: "",
        telf1: "",
        passwUser: "",
        secQuest: "",
        resSecQuest: "",
        autorizationUsageInfo: "",
        autorizationNewsProms: false,
      },
    };
  },
};
</script>
