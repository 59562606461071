<template>
  <div class="bg-white p-3 border-t-8 py-4 rounded-lg border-blue-postula2 shadow-md">
    <div v-if="this.flagImgUploaded === true" class="image overflow-hidden">
      <img
        v-if="$store.state.profileImage.image.encryptedName"
        class="h-auto w-44 rounded-full mx-auto border-4 border-red-800"
        :src="this.imgUploaded"
        alt=""
      />
      <img
        v-else-if="this.$store.state.userData.genero === 'Femenino'"
        class="h-auto w-44 rounded-full mx-auto border-4 border-green-800"
        src="@/components/img/profileFemale.png"
        alt=""
      />
      <img
        v-else
        src="@/components/img/picture1.jpg"
        alt="profil"
        class="h-auto w-44 rounded-full mx-auto border-4 border-indigo-800"
      />
    </div>
    <div v-else class="image overflow-hidden relative ">
      <img
        v-if="this.imgUploadedName"
        class="h-auto w-44 rounded-full mx-auto border-2 border-yellow-500"
        :src="`https://api.postula2.com/imagesTi?id=${this.$store.state.userData.imageProfileInfo[0].imageName}`"
        alt=""
      />
      <img
        v-else-if="this.$store.state.profileImage.flagImgUploaded"
        class="h-36 w-36 rounded-full mx-auto border-2 border-blue-postula2 object-cover"
        :src="`https://api.postula2.com/imagesTi?id=${this.$store.state.profileImage.image.imageName}`"
        alt=""
      />
      <!-- imagen que se renderiza actualmente -->
      <img
        v-else-if="this.$store.state.userData.imageProfileInfo.length > 0"
        class="h-36 w-36 rounded-full mx-auto object-cover"
        v-bind:class="{
          'border-2 border-dotted border-green-500': debg,
          'border-2 border-blue-postula2': !debg,
        }"
        :src="`https://api.postula2.com/imagesTi?id=${this.$store.state.userData.imageProfileInfo[0].imageName}`"
        alt=""
      />
      <!--  -->
      <img
        v-else-if="this.$store.state.profileImage.flagImgUploaded === true"
        class="h-auto w-44 rounded-full mx-auto border-2 border-red-700"
        :src="`https://api.postula2.com/imagesTi?id=${this.$store.state.userData.id}`"
        alt=""
      />
      <img
        v-else-if="this.$store.state.userData.genero === 'Femenino'"
        class="h-auto w-44 rounded-full mx-auto border-2 border-pink-400"
        src="@/components/img/profileFemale.png"
        alt=""
      />
      <img
        v-else
        src="@/components/img/picture1.jpg"
        alt="profil"
        class="h-auto w-44 rounded-full mx-auto border-2 border-red-500"
      />

      <!--@click="activateUpload = !activateUpload" esto va en el siguiente tag a para abrir el editor de imagenes  -->
      <a class="relative block my-3 cursor-pointer hidden">
        <span
          v-bind:class="{
            'border-2 border-dotted border-green-500': debg,
            'border-2 border-blue-postula2': !debg,
          }"
          class="absolute w-7 h-7 my-6 mx-3 transform -translate-x-1/2 bg-white border-2 border-blue-postula2 rounded-full left-2/3 -bottom-2"
          ><svg
            width="20"
            height="20"
            fill="currentColor"
            class="w-6 h-6"
            viewBox="0 0 1792 1792"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M491 1536l91-91-235-235-91 91v107h128v128h107zm523-928q0-22-22-22-10 0-17 7l-542 542q-7 7-7 17 0 22 22 22 10 0 17-7l542-542q7-7 7-17zm-54-192l416 416-832 832h-416v-416zm683 96q0 53-37 90l-166 166-416-416 166-165q36-38 90-38 53 0 91 38l235 234q37 39 37 91z"
            ></path>
          </svg>
        </span>
      </a>
    </div>
    <h1 class="text-gray-900 font-bold text-xl leading-8 my-1 capitalize">
      {{
        $store.state.userData.owner.nombreUser +
        " " +
        $store.state.userData.owner.apellidoUser
      }}
    </h1>
    <h3 class="text-gray-600 font-lg text-semibold leading-6 break-words">
      {{ $store.state.userData.owner.emailUser }}
    </h3>

    <div
      v-if="activateUpload"
      v-bind:class="{
        'border-2 border-green-400': debg,
      }"
      class="flex-wrap w-52 vfa-demo bg-light pt-3"
    >
      <VueFileAgent
        class="upload-block relative w-24"
        ref="vfaDemoRef"
        :uploadHeaders="{}"
        :accept="'image/*'"
        :multiple="false"
        :progress="true"
        :deletable="true"
        :theme="'list'"
        :maxSize="'0,5MB'"
        :maxFiles="1"
        :helpText="'Drag an image file here'"
        :errorText="{
          size: 'This file is too large to be attached',
        }"
        v-model="fileRecord"
      >
        <template v-slot:file-preview="slotProps">
          <div :key="slotProps.index" class="file-row text-sm w-48">
            <button
              v-bind:class="{
                'border-2 border-red-400': debg,
              }"
              type="button"
              class="close remove"
              aria-label="Remove"
              @click="removePic()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <!-- <div
            class="progress"
            :class="{ completed: slotProps.fileRecord.progress() == 100 }"
          >
            <div
              class="progress-bar"
              role="progressbar"
              :style="{ width: slotProps.fileRecord.progress() + '%' }"
            ></div>
          </div> -->
            <!-- <p>{{ slotProps.fileRecord }}</p> -->
            <strong>{{ slotProps.fileRecord.name() }}</strong>
            <span class="text-muted">({{ slotProps.fileRecord.size() }})</span>
          </div>
        </template>
        <template v-slot:file-preview-new>
          <div class="text-left my-3" key="new">
            <a href="" class=""></a>
          </div>
        </template>
        <!--       <template v-slot:after-inner>
        <div class="text-left pt-1">
          <a href="#" class="">Select files</a> or drag & drop here
        </div>
      </template > -->
        <template v-slot:after-outer>
          <div class="w-40">
            <button
              @click="uploadFiles()"
              type="button"
              class="mt-1.5 py-1 px-3 flex w-auto justify-center items-center bg-green-500 hover:bg-green-700 focus:ring-green-500 focus:ring-offset-green-200 text-white w- transition ease-in duration-200 text-center text-sm font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-full"
            >
              <img class="w-7 h-7" src="../img/new-file-front-colorr.png" alt="" />
              <span class="ml-2 font-semibold"> Guardar </span>
            </button>
          </div>
        </template>
      </VueFileAgent>
    </div>

    <p class="hidden text-sm text-gray-500 hover:text-gray-600 leading-6"></p>
    <ul
      class="bg-gray-100 text-gray-600 hover:text-gray-700 hover:shadow py-2 px-3 mt-3 divide-y rounded shadow-sm"
    >
      <li class="flex items-center py-3">
        <span class="text-sm">Estatus</span>
        <span class="ml-auto"
          ><span class="bg-green-500 py-1 px-2 rounded text-white text-sm font-semibold"
            >Activo</span
          ></span
        >
      </li>
      <li class="flex items-center py-3">
        <span class="text-sm">Miembro desde</span>
        <span class="ml-auto text-sm">
          {{ $store.state.userData.createdAt | formatedTime }}
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ImagenPerfilyStatusPersona",
  components: {},
  filters: {
    formatedTime: function (value) {
      var date = new Date(value);

      return (
        date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
        // " " +
        //date.getHours() +
        //":" +
        // date.getMinutes() +
        //":" +
        //date.getSeconds()
      );
      ////CLG(formatedTime);
    },
  },
  data: function () {
    return {
      fileRecord: [],
      uploadUrl: "",
      uploadHeaders: { Authorization: this.$store.state.myTokToApiComs },
      fileRecordsForUpload: [], // maintain an upload queue
      imageFileLocation: "",
      flag: false,
      flagImgUploaded: false,
      imgUploadedName: "",
      imgUploaded: "",
      activateUpload: false,
      debg: false,
    };
  },
  watch: {
    imageFileLocation: function () {
      this.flag = true;
    },
    flagImgUploaded: function () {
      var axios = require("axios");

      var config = {
        method: "get",
        maxBodyLength: Infinity,
        url: "https://api.postula2.com/imagesTi",
        headers: {
          Authorization: this.$store.state.myTokToApiComs,
        },
        params: {
          id: this.$store.state.userData.id,
        },
      };

      axios(config).then(function (response) {
        // console.log("respuesta", response);
        // console.log(JSON.stringify(response.data));
        const image = JSON.stringify(response.data);
      });
    },
  },
  methods: {
    removePic: function () {
      // var profilePic = this.fileRecord;
      this.$refs.vfaDemoRef.deleteUpload(this.uploadUrl, this.uploadHeaders, [
        this.fileRecord,
      ]);
      this.fileRecord = [];
      // this.uploaded = false;
    },
    upload() {
      const formData = new FormData();
      formData.append("file", document.getElementById("upload").files[0]);

      axios
        .post("https://api.postula2.com/uploadImage", formData, {
          headers: {
            Authorization: this.$store.state.myTokToApiComs,
          },
          params: {
            id: this.$store.state.userData.id,
          },
        })
        .then((response) => {
          //console.log("holi ", response.data);
          this.imageFileLocation = response.data.savedAt;
          this.$store.commit("getProfileImage", response.data.savedAt);
          console.log(this.imageFileLocation);
        });
    },
    uploadFiles: function () {
      this.uploadUrl =
        "https://api.postula2.com/uploadImage?id=" + this.$store.state.userData.id;
      //console.log("url es: ", this.uploadUrl);
      // Using the default uploader. You may use another uploader instead.
      this.$refs.vfaDemoRef
        .upload(
          this.uploadUrl,
          this.uploadHeaders,
          this.fileRecordsForUpload,
          this.$store.state.userData.id
        )
        .then((response) => {
          this.imgUploadedName = response[0].data.encryptedName;
          this.flagImgUploaded = true;
          this.$store.commit({
            type: "getProfileImage",
            flagImgUploaded: true,
            image: response[0].data,
          });
          //this.$store.commit("getProfileImage", response[0].data);
          // this.imageFileLocation = response.data.savedAt;
          // console.log(this.imageFileLocation);
        })
        .finally(() => {
          //   forceRerender();
          this.$store.commit("incrementUserImgComponntValue");
        });
      this.fileRecordsForUpload = [];
      this.imgUploaded =
        "https://api.postula2.com/imagesTi?id=" + this.$store.state.userData.id;
    },
    deleteUploadedFile: function (fileRecord) {
      // Using the default uploader. You may use another uploader instead.
      this.$refs.vfaDemoRef.deleteUpload(this.uploadUrl, this.uploadHeaders, fileRecord);
    },
    filesSelected: function (fileRecordsNewlySelected) {
      var validFileRecords = fileRecordsNewlySelected.filter(
        (fileRecord) => !fileRecord.error
      );
      this.fileRecordsForUpload = this.fileRecordsForUpload.concat(validFileRecords);
    },
    onBeforeDelete: function (fileRecord) {
      var i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        // queued file, not yet uploaded. Just remove from the arrays
        this.fileRecordsForUpload.splice(i, 1);
        var k = this.fileRecord.indexOf(fileRecord);
        if (k !== -1) this.fileRecord.splice(k, 1);
      } else {
        if (confirm("Are you sure you want to delete?")) {
          this.$refs.vfaDemoRef.deleteFileRecord(fileRecord); // will trigger 'delete' event
        }
      }
    },
    fileDeleted: function (fileRecord) {
      var i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        this.fileRecordsForUpload.splice(i, 1);
      } else {
        this.deleteUploadedFile(fileRecord);
      }
    },
    misDatos() {
      this.$router.push({
        name: "DatosCv",
      });
    },
  },
};
</script>
<style scoped>
.border-blue-postula2 {
  --tw-border-opacity: 1;
  border-color: rgba(19, 73, 146, var(--tw-border-opacity));
}
</style>
