<template>
  <div class="ml-2 flex-shrink-0 flex">
    <p
      v-if="this.evaluacion"
      class="px-3 mx-3 inline-flex leading-5 font-semibold rounded-full bg-green-100 text-green-800"
    >
      {{ "Revisado" }}
    </p>
    <p
      v-else
      class="px-3 mx-3 inline-flex leading-5 font-semibold rounded-full bg-red-300 text-red-600"
    >
      {{ "Pendiente" }}
    </p>
    <p
      class="hidden px-3 mx-3 inline-flex leading-5 font-semibold rounded-full bg-yellow-200 text-yellow-800"
    >
      {{ "Evaluado" }}
    </p>
    <p
      class="hidden px-3 mx-3 inline-flex leading-5 font-semibold rounded-full bg-blue-100 text-blue-800"
    >
      {{ "Contactado" }}
    </p>
    <p
      class="hidden px-3 mx-3 inline-flex leading-5 font-semibold rounded-full bg-red-100 text-red-800"
    >
      {{ "Finalizado" }}
    </p>
    <p class="text-gray-400 text-xs ml-2 hidden">
      <svg
        @click="deleteEval(evalu.id)"
        width="17"
        height="17"
        title="Eliminar"
        class="text-red-400 cursor-pointer"
        fill="currentColor"
        viewBox="0 0 1792 1792"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M704 1376v-704q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v704q0 14 9 23t23 9h64q14 0 23-9t9-23zm256 0v-704q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v704q0 14 9 23t23 9h64q14 0 23-9t9-23zm256 0v-704q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v704q0 14 9 23t23 9h64q14 0 23-9t9-23zm-544-992h448l-48-117q-7-9-17-11h-317q-10 2-17 11zm928 32v64q0 14-9 23t-23 9h-96v948q0 83-47 143.5t-113 60.5h-832q-66 0-113-58.5t-47-141.5v-952h-96q-14 0-23-9t-9-23v-64q0-14 9-23t23-9h309l70-167q15-37 54-63t79-26h320q40 0 79 26t54 63l70 167h309q14 0 23 9t9 23z"
        ></path>
      </svg>
    </p>
  </div>
</template>
<style></style>
<script>
import axios from "axios";

export default {
  name: "estatusPostulado",
  data() {
    return {
      evaluacion: "",
      idAnuncio: "",
      idPersona: "",
    };
  },
  props: {
    info: {},
    info2: {},
  },
  created() {
     //console.log("info del props", this.info);
     //console.log("info del props", this.info2);
  },
  mounted() {
    this.idAnuncio = this.info;
    this.idPersona = this.info2;
    //console.log("en mounted tenggo", this.idAnuncio, this.idPersona);
    //console.log("Prueba 2");
    axios({
      method: "post",
      url: `https://api.postula2.com/findEvaluacnAnalist`,
      headers: {
        "Content-Type": "application/json",
        Authorization: this.$store.state.myTokToApiComs,
      },
      data: {
        idRegistroPrimarioUser: this.idPersona,
        idAnuncio: this.idAnuncio,
      },
    })
      .then((res) => {
        //console.log("respuesta back es ", res.data);
        this.evaluacion = res.data.resp;
        this.$store.commit("getPersonEvaluacn", res.data.evaluaciones[0]);
        this.$store.commit("setFechaPostAnunc", res.data.fechaPostAnunc);

        //Perform Success Action
        //CLG("mandado al commit store", res.data[0]);
        // //CLG("id de owner es: ", this.$store.state.userData.owner.id);
        // //CLG("id de anuncio es: ", this.$store.state.anuncs.id);
        // //CLG("id de analista es: ", this.$store.state.analista.id);
      })
      .catch(() => {
        // error.response.status Check status code
        //CLG("mission pelaste bolas");
        //CLG(error);
      });
  },
};
</script>
