<template>
  <div v-if="preferncLabor" class="grid grid-cols-2 border-t ">
    <div class="border-t border-gray-200">
      <dl>
        <!-- <div class="hidden">
          {{ preferncLabor[0].id }}
        </div> -->
        <div
          v-if="$store.state.userData"
          class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
        >
          <dt
            v-if="!editExpectSalarFlag"
            class="text-sm text-left font-medium text-gray-500"
          >
            Expectativa salarial
          </dt>
          <dd
            class="
              mt-1
              text-sm text-gray-900
              sm:mt-0 sm:col-span-2
              capitalize
              flex
            "
          >
            <div
              v-if="editExpectSalarFlag"
              class="
                flex flex-col
                md:flex-row
                w-3/4
                md:w-full
                max-w-sm
                md:space-x-3
                space-y-3
                md:space-y-0
                justify-start
              "
            >
              <div class="">
                <FormulateForm #default="{ hasErrors }">
                  <div
                    v-if="editExpectSalarFlag"
                    class="
                      flex flex-nowrap
                      items-start
                      md:flex-nowrap
                      w-3/4
                      md:w-full
                      max-w-sm
                      md:space-x-3
                      space-y-3
                      md:space-y-0
                      justify-start
                    "
                  >
                    <div class="w-1/5">
                      <label
                        for="price"
                        class="
                          text-xs
                          font-medium
                          text-gray-700 text-left
                          ml-1
                          truncate
                          w-6
                        "
                      >
                        <strong>Editar Numero</strong>
                      </label>
                      <FormulateInput
                        v-model.lazy="moneda"
                        :options="{
                          Bs: 'Bs',
                          $: '$',
                        }"
                        type="select"
                        placeholder="Seleccione"
                        validation="required"
                        :validation-messages="{
                          required: 'Debes rellenar este campo',
                        }"
                        errors-class="text-left ml-1"
                      />
                    </div>
                    <div class="mt-3 pt-5 w-full ml-1.5">
                      <FormulateInput
                        type="number"
                        placeholder="1234567"
                        name="numeroCelular"
                        v-model.lazy="monto"
                        validation="required|max:5,length|min:1,length|matches:/^[0-9]/|"
                        error-behavior="value"
                        :validation-messages="{
                          required: 'Debes rellenar este campo',
                          max: 'Maximo 4 digitos',
                          min: 'Al menos 1 digito',
                          matches: 'Deben ser numeros',
                          not: 'No permitido',
                        }"
                        errors-class="text-left ml-1  z-10"
                      />
                    </div>

                    <div
                      class="
                        pt-6
                        flex
                        text-gray-500
                        items-start
                        justify-between
                        pb-0.5
                        space-x-1
                      "
                    >
                      <button
                        type="submit"
                        :disabled="hasErrors"
                        @click.prevent="editExpectSalar(preferncLabor[0].id)"
                        class="
                          p-1
                          flex
                          items-center
                          transition
                          ease-in
                          duration-200
                          uppercase
                          rounded-full
                          border border-green-200
                          text-green-600
                          hover:border-green-600
                          hover:bg-green-600
                          hover:text-yellow-200
                          focus:outline-none
                        "
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-5 w-5"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          stroke-width="2"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
                          />
                        </svg>
                      </button>
                      <button
                        @click="editExpectSalarFlag = !editExpectSalarFlag"
                        class="
                          items-center
                          p-1
                          transition
                          ease-in
                          duration-200
                          uppercase
                          rounded-full
                          border border-red-300
                          text-red-500
                          hover:bg-red-800 hover:text-yellow-200
                          focus:outline-none
                        "
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-5 w-5"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          stroke-width="2"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </FormulateForm>
              </div>
            </div>

            <div class="flex" v-if="!editExpectSalarFlag">
              <span
                v-if="preferncLabor[0]"
                class="text-gray-700 mr-6 dark:text-white font-normal"
              >
                {{ preferncLabor[0].expectSalar }}
              </span>
              <span
                v-else
                class="text-gray-700 mr-6 dark:text-white font-semibold"
              >
                Sin información disponible
              </span>
              <label class="flex items-center">
                <button
                  v-if="preferncLabor[0]"
                  @click="editExpectSalarFlag = !editExpectSalarFlag"
                  class="
                    flex
                    items-center
                    p-1
                    transition
                    ease-in
                    duration-200
                    uppercase
                    rounded-full
                    hover:bg-blue-800 hover:text-yellow-200
                    focus:outline-none
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-3.5 w-3.5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                    />
                  </svg>
                </button>
              </label>
            </div>
          </dd>
        </div>

        <div
          v-if="$store.state.userData"
          class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
        >
          <dt
            v-if="!editEstatsActualFlag"
            class="text-sm text-left font-medium text-gray-500"
          >
            Estatus laboral actual
          </dt>
          <dd
            class="
              mt-1
              text-sm text-gray-900
              sm:mt-0 sm:col-span-2
              capitalize
              flex
            "
          >
            <form
              v-if="editEstatsActualFlag"
              class="
                flex flex-col
                md:flex-row
                w-3/4
                md:w-full
                max-w-sm
                md:space-x-3
                space-y-3
                md:space-y-0
                justify-start
              "
            >
              <div class="">
                <label
                  for="price"
                  class="block text-xs font-medium text-gray-700 text-left ml-1"
                >
                  <strong>Editar Estatus laboral actual</strong>
                </label>
                <select
                  type="text"
                  id='"form-subscribe-Subscribe'
                  class="
                    rounded-lg
                    border-transparent
                    flex-1
                    appearance-none
                    border border-gray-300
                    w-60
                    px-2
                    py-2
                    bg-white
                    text-gray-700
                    placeholder-gray-400
                    shadow-sm
                    text-xs
                    focus:outline-none
                    focus:ring-2
                    focus:ring-purple-600
                    focus:border-transparent
                  "
                  v-model="data.estatsActual"
                >
                  <option class="text-gray-300" disabled value="">
                    Seleccione
                  </option>
                  <option value="contratado">Contratado</option>
                  <option value="desempleado">Desempleado</option>
                  <option value="pensionado">Pensionado</option>
                </select>
              </div>

              <div
                class="
                  flex
                  w-auto
                  text-gray-500
                  items-end
                  justify-between
                  px-2
                  pb-0.5
                  space-x-1
                "
              >
                <button
                  @click.prevent="editEstatsActual(preferncLabor[0].id)"
                  class="
                    p-1
                    flex
                    items-center
                    transition
                    ease-in
                    duration-200
                    uppercase
                    rounded-full
                    border border-green-200
                    text-green-600
                    hover:border-green-600
                    hover:bg-green-600
                    hover:text-yellow-200
                    focus:outline-none
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
                    />
                  </svg>
                </button>
                <button
                  @click="editEstatsActualFlag = !editEstatsActualFlag"
                  class="
                    flex
                    items-center
                    p-1
                    transition
                    ease-in
                    duration-200
                    uppercase
                    rounded-full
                    border border-red-300
                    text-red-500
                    hover:bg-red-800 hover:text-yellow-200
                    focus:outline-none
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </button>
              </div>
            </form>

            <div class="flex" v-if="!editEstatsActualFlag">
              <span
                v-if="preferncLabor[0]"
                class="text-gray-700 mr-6 dark:text-white font-normal"
              >
                {{ preferncLabor[0].estatsActual }}
              </span>
              <span
                v-else
                class="text-gray-700 mr-6 dark:text-white font-semibold"
              >
                Sin información disponible
              </span>
              <label class="flex items-center">
                <button
                  v-if="preferncLabor[0]"
                  @click="editEstatsActualFlag = !editEstatsActualFlag"
                  class="
                    flex
                    items-center
                    p-1
                    transition
                    ease-in
                    duration-200
                    uppercase
                    rounded-full
                    hover:bg-blue-800 hover:text-yellow-200
                    focus:outline-none
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-3.5 w-3.5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                    />
                  </svg>
                </button>
              </label>
            </div>
          </dd>
        </div>
      </dl>
    </div>
    <!-- //////////////////////////////// -->
    <div class="border-t border-gray-200">
      <dl>
        <div
          v-if="$store.state.userData"
          class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
        >
          <dt
            v-if="!editViajarFlag"
            class="text-sm text-left font-medium text-gray-500"
          >
            Disponibilidad para viajar
          </dt>
          <dd
            class="
              mt-1
              text-sm text-gray-900
              sm:mt-0 sm:col-span-2
              capitalize
              flex
            "
          >
            <form
              v-if="editViajarFlag"
              class="
                flex flex-col
                md:flex-row
                w-3/4
                md:w-full
                max-w-sm
                md:space-x-3
                space-y-3
                md:space-y-0
                justify-start
              "
            >
              <div class="">
                <label
                  for="price"
                  class="block text-xs font-medium text-gray-700 text-left ml-1"
                >
                  <strong>Editar Disponibilidad para viajar</strong>
                </label>
                <select
                  type="text"
                  id='"form-subscribe-Subscribe'
                  class="
                    rounded-lg
                    border-transparent
                    flex-1
                    appearance-none
                    border border-gray-300
                    w-60
                    px-2
                    py-2
                    bg-white
                    text-gray-700
                    placeholder-gray-400
                    shadow-sm
                    text-xs
                    focus:outline-none
                    focus:ring-2
                    focus:ring-purple-600
                    focus:border-transparent
                  "
                  v-model="data.viajar"
                >
                  <option class="text-gray-300" disabled value="">
                    Seleccione
                  </option>
                  <option value="Si">Si</option>
                  <option value="No">No</option>
                </select>
              </div>

              <div
                v-if="preferncLabor[0]"
                class="
                  flex
                  w-auto
                  text-gray-500
                  items-end
                  justify-between
                  px-2
                  pb-0.5
                  space-x-1
                "
              >
                <button
                  @click.prevent="editViajar(preferncLabor[0].id)"
                  class="
                    p-1
                    flex
                    items-center
                    transition
                    ease-in
                    duration-200
                    uppercase
                    rounded-full
                    border border-green-200
                    text-green-600
                    hover:border-green-600
                    hover:bg-green-600
                    hover:text-yellow-200
                    focus:outline-none
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
                    />
                  </svg>
                </button>
                <button
                  @click="editViajarFlag = !editViajarFlag"
                  class="
                    flex
                    items-center
                    p-1
                    transition
                    ease-in
                    duration-200
                    uppercase
                    rounded-full
                    border border-red-300
                    text-red-500
                    hover:bg-red-800 hover:text-yellow-200
                    focus:outline-none
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </button>
              </div>
            </form>

            <div class="flex" v-if="!editViajarFlag">
              <span
                v-if="preferncLabor[0]"
                class="text-gray-700 mr-6 dark:text-white font-normal"
              >
                {{ preferncLabor[0].viajar }}
              </span>
              <span
                v-else
                class="text-gray-700 mr-6 dark:text-white font-semibold"
              >
                Sin información disponible
              </span>
              <label class="flex items-center">
                <button
                  v-if="preferncLabor[0]"
                  @click="editViajarFlag = !editViajarFlag"
                  class="
                    flex
                    items-center
                    p-1
                    transition
                    ease-in
                    duration-200
                    uppercase
                    rounded-full
                    hover:bg-blue-800 hover:text-yellow-200
                    focus:outline-none
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-3.5 w-3.5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                    />
                  </svg>
                </button>
              </label>
            </div>
          </dd>
        </div>

        <div
          v-if="$store.state.userData"
          class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
        >
          <dt
            v-if="!editCambResidencFlag"
            class="text-sm text-left font-medium text-gray-500"
          >
            Disponibilidad para cambio de residencia
          </dt>
          <dd
            class="
              mt-1
              text-sm text-gray-900
              sm:mt-0 sm:col-span-2
              capitalize
              flex
            "
          >
            <form
              v-if="editCambResidencFlag"
              class="
                flex flex-col
                md:flex-row
                w-3/4
                md:w-full
                max-w-sm
                md:space-x-3
                space-y-3
                md:space-y-0
                justify-start
              "
            >
              <div class="">
                <label
                  for="price"
                  class="block text-xs font-medium text-gray-700 text-left ml-1"
                >
                  <strong> Editar Disponibilidad cambio de residencia</strong>
                </label>
                <select
                  type="text"
                  id='"form-subscribe-Subscribe'
                  class="
                    rounded-lg
                    border-transparent
                    flex-1
                    appearance-none
                    border border-gray-300
                    w-60
                    px-2
                    py-2
                    bg-white
                    text-gray-700
                    placeholder-gray-400
                    shadow-sm
                    text-xs
                    focus:outline-none
                    focus:ring-2
                    focus:ring-purple-600
                    focus:border-transparent
                  "
                  placeholder=" Parroquia de residencia"
                  v-model="data.cambResidenc"
                >
                  <option class="text-gray-300" disabled value="">
                    Seleccione
                  </option>
                  <option value="si">Si</option>
                  <option value="no">No</option>
                </select>
              </div>

              <div
                class="
                  flex
                  w-auto
                  text-gray-500
                  items-end
                  justify-between
                  px-2
                  pb-0.5
                  space-x-1
                "
              >
                <button
                  @click.prevent="editCambResidenc(preferncLabor[0].id)"
                  class="
                    p-1
                    flex
                    items-center
                    transition
                    ease-in
                    duration-200
                    uppercase
                    rounded-full
                    border border-green-200
                    text-green-600
                    hover:border-green-600
                    hover:bg-green-600
                    hover:text-yellow-200
                    focus:outline-none
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
                    />
                  </svg>
                </button>
                <button
                  @click="editCambResidencFlag = !editCambResidencFlag"
                  class="
                    flex
                    items-center
                    p-1
                    transition
                    ease-in
                    duration-200
                    uppercase
                    rounded-full
                    border border-red-300
                    text-red-500
                    hover:bg-red-800 hover:text-yellow-200
                    focus:outline-none
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </button>
              </div>
            </form>

            <div class="flex" v-if="!editCambResidencFlag">
              <span
                v-if="preferncLabor[0]"
                class="text-gray-700 mr-6 dark:text-white font-normal"
              >
                {{ preferncLabor[0].cambResidenc }}
              </span>
              <span
                v-else
                class="text-gray-700 mr-6 dark:text-white font-semibold"
              >
                Sin información disponible
              </span>
              <label class="flex items-center">
                <button
                  v-if="preferncLabor[0]"
                  @click="editCambResidencFlag = !editCambResidencFlag"
                  class="
                    flex
                    items-center
                    p-1
                    transition
                    ease-in
                    duration-200
                    uppercase
                    rounded-full
                    hover:bg-blue-800 hover:text-yellow-200
                    focus:outline-none
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-3.5 w-3.5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                    />
                  </svg>
                </button>
              </label>
            </div>
          </dd>
        </div>
      </dl>
    </div>
    <loader v-if="isLoading">
      <template v-slot:msg>
        {{ msg }}
      </template>

      <template v-slot:msg1>
        {{ msg1 }}
      </template>
    </loader>
  </div>
</template>
<style scoped></style>
<script>
import axios from "axios";
import loader from "@/components/shared/loader.vue";

export default {
  name: "formPrefLabor",
  components: {
    loader,
  },
  props: {
    preferncLabor: {},
  },
  methods: {
    editarCampo() {
      this.editarFlag = !this.editarFlag;
    },
    editExpectSalar(idPreferncLabor) {
      this.data.expectSalar = this.monto + " " + this.moneda;

      let data = JSON.stringify({
        id: idPreferncLabor,
        expectSalar: this.data.expectSalar,
      });
      axios({
        method: "put",
        url: `https://api.postula2.com/editPreferLabor/`,
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.myTokToApiComs,
        },
        data: data,
      })
        .then(() => {
          //CLG("campo editado con exito");
          this.editFinished();
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG("mission pelaste bolas in formPrefLabor");
          //CLG(error);
        })
        .finally(() => {
          //Perform action in always
          this.editExpectSalarFlag = !this.editExpectSalarFlag;
          this.data.expectSalar = "";
        });
    },
    editEstatsActual(idPreferncLabor) {
      let data = JSON.stringify({
        id: idPreferncLabor,
        estatsActual: this.data.estatsActual,
      });
      axios({
        method: "put",
        url: `https://api.postula2.com/editPreferLabor/`,
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.myTokToApiComs,
        },
        data: data,
      })
        .then(() => {
          //CLG("campo editado con exito");
          this.editFinished();
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG("mission pelaste bolas in formPrefLabor");
          //CLG(error);
        })
        .finally(() => {
          //Perform action in always
          this.editEstatsActualFlag = !this.editEstatsActualFlag;
          this.data.estatsActual = "";
        });
    },
    editCambResidenc(idPreferncLabor) {
      let data = JSON.stringify({
        id: idPreferncLabor,
        cambResidenc: this.data.cambResidenc,
      });
      axios({
        method: "put",
        url: `https://api.postula2.com/editPreferLabor/`,
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.myTokToApiComs,
        },
        data: data,
      })
        .then(() => {
          //CLG("campo editado con exito");
          this.editFinished();
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG("mission pelaste bolas in formPrefLabor");
          //CLG(error);
        })
        .finally(() => {
          //Perform action in always
          this.editCambResidencFlag = !this.editCambResidencFlag;
          this.data.cambResidenc = "";
        });
    },
    editViajar(idPreferncLabor) {
      //CLG("id: ", idPreferncLabor, "viajar: ", this.data.viajar);
      let data = JSON.stringify({
        id: idPreferncLabor,
        viajar: this.data.viajar,
      });
      axios({
        method: "put",
        url: `https://api.postula2.com/editPreferLabor/`,
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.myTokToApiComs,
        },
        data: data,
      })
        .then(() => {
          //CLG("campo editado con exito");
          this.editFinished();
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG("mission pelaste bolas in formPrefLabor");
          //CLG(error);
        })
        .finally(() => {
          //Perform action in always
          this.editViajarFlag = !this.editViajarFlag;
          this.data.viajar = "";
        });
    },
    editar() {
      this.editing = true;
    },
    editFinished() {
      this.editing = false;
      //https://api.postula2.com/persona/624745e3e602450004288516/experncLabors
      axios({
        method: "get",
        url: `https://api.postula2.com/persona/${this.$store.state.userData.id}/PreferncLabor`,
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.myTokToApiComs,
        },
      })
        .then((res) => {
          this.$store.state.userData.preferncLabor = res.data;
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG("mission pelaste bolas in preferncLabor");
          //CLG(error);
        })
        .finally(() => {
          //Perform action in always
        });
    },
    onSubmit() {
      //let dataUser = this.data;
      this.isLoading = true;
      this.data.owner = this.$store.state.userData.id;
      axios
        .post(
          "https://api.postula2.com/PreferncLabor",
          this.data
        )
        .then(() => {
          //Perform Success Action
          //CLG("response:", res);
          this.msg = "Preferencia Laboral registrada satisfactoriamente...!";
          //this.$store.commit("getflagLogin", redFlag);
          this.$store.commit({
            type: "getflagLogin",
            greenFlag: true,
          });
          setTimeout(() => {
            this.editFinished();
            this.data.expectSalar = "";
            this.data.estatsActual = "";
            this.data.cambResidenc = "";
            this.data.viajar = "";

            this.data.owner = null;
            //this.editing = false;
            this.isLoading = false;
            this.$store.commit({
              type: "getflagLogin",
              greenFlag: null,
            });
          }, 2000);
        })
        .catch(() => {
          // error.response.status Check status code
          //CLG(error);
          this.msg1 =
            "¡Verifique la información ingresada e intente nuevamente!";
          //this.$store.commit("getflagLogin", redFlag);
          this.$store.commit({
            type: "getflagLogin",
            redFlag: true,
          });
          setTimeout(() => {
            this.isLoading = false;
            this.$store.commit({
              type: "getflagLogin",
              redFlag: null,
            });
          }, 3000);
        })
        .finally(() => {
          //Perform action in always
        });
    },
  },
  data() {
    return {
      //preferncLabor
      editarFlag: false,

      editExpectSalarFlag: false,
      editEstatsActualFlag: false,
      editCambResidencFlag: false,
      editViajarFlag: false,

      editing: false,
      isLoading: false,
      msg: null,
      msg1: null,

      monto: "",
      moneda: "",
      data: {
        expectSalar: "",
        estatsActual: "",
        cambResidenc: "",
        viajar: "",
        owner: null,
      },
    };
  },
};
</script>
