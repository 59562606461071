<template>
  <div class="container flex-wrap px-5 py-24 mx-auto items-center">
    <div
      class="w-full max-w-md mx-auto overflow-hidden bg-gray-300 rounded-lg shadow-md dark:bg-gray-800"
    >
      <div class="px-8 py-6">
        <h2
          class="text-3xl font-bold text-center text-gray-700 dark:text-white"
        >
          Ingresar como Analista
        </h2>

        <p class="mt-1 text-center text-gray-500 dark:text-gray-400">
          Inicio de sesión como analista de empresa asociada
        </p>

        <form @submit.stop.prevent="onSubmit">
          <div class="w-full mt-4">
            <input
              class="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-500 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
              type="email"
              placeholder="Email Address"
              aria-label="Email Address"
              name="emailUser"
              v-model.lazy.trim="data.emailAnalist"
            />
          </div>

          <div class="w-full mt-4">
            <input
              class="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-500 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
              type="password"
              placeholder="Password"
              aria-label="Password"
              name="passwUser"
              v-model.lazy.trim="data.passwAnalist"
            />
          </div>

          <div class="flex items-center justify-between mt-4">
            <a
              href="#"
              class="hidden text-sm text-gray-600 dark:text-gray-200 hover:text-gray-500"
              >¿Contraseña olvidada?</a
            >

            <button
              class="flex-shrink-0 px-4 py-2 text-base font-semibold text-white backasista rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2"
              type="submit"
            >
              Enviar
            </button>
          </div>
        </form>
      </div>
      <loader v-if="isLoading">
        <template v-slot:msg>
          {{ msg }}
        </template>

        <template v-slot:msg1>
          {{ msg1 }}
        </template>
      </loader>
      <div
        class="flex items-center justify-center py-4 text-center bg-gray-200 dark:bg-gray-700"
      >
        <span class="hidden text-sm text-gray-600 dark:text-gray-200"
          >¿No tienes una cuenta?
        </span>

        <a
          href="#"
          class="mx-2 text-sm font-bold text-blue-600 dark:text-blue-400 hover:text-blue-500"
          @click="$router.push('homec')"
          >Volver</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import loader from "@/components/shared/loader.vue";

export default {
  name: "loginAnalista",
  components: { loader },

  methods: {
    onSubmit() {
      this.isLoading = true;

      //CLG("respuesta get", this.data.emailAnalist);

      if (this.data.emailAnalist && this.data.passwAnalist) {
        axios
          .get(
            `https://api.postula2.com/analista?emailAnalist=${this.data.emailAnalist}`
          )
          .then((res) => {
            //Perform Success Action
            if (res.data.length == 0 && res.status == 200) {
              this.msg1 =
                "¡Analista no encontrado, contacte al administrador de soporte!";
              //this.$store.commit("getflagLogin", redFlag);
              this.$store.commit({
                type: "getflagLogin",
                redFlag: true,
              });
              setTimeout(() => {
                this.isLoading = false;
                this.$store.commit({
                  type: "getflagLogin",
                  redFlag: null,
                });
              }, 3000);
              //CLG("respuesta obtenida", res.status);
            } else {
              if (res.data[0].passwAnalist == this.data.passwAnalist) {
                ////CLG("tiene esta empresa--> ", res.data[0].empresa.length);
                this.$store.commit("getEmprs", res.data[0].owner);
                this.$store.commit("getRegUserEmprs", res.data[0].owner);
                this.$store.commit("getAnalist", res.data[0]);

                //CLG("Datos de empresa commiteados ", res.data[0].owner);
                //CLG("Datos de analista commiteados ", res.data[0]);
                //CLG("¡¡¡ACCESS GRANTED!!!");
                this.msg = "¡Verificado, redireccionando...!";
                //this.$store.commit("getflagLogin", redFlag);
                this.$store.commit({
                  type: "getflagLogin",
                  greenFlag: true,
                });
                setTimeout(() => {
                  this.isLoading = false;
                  this.$store.commit({
                    type: "getflagLogin",
                    greenFlag: null,
                  });
                  this.$router.push({
                    name: "homeAnalista",
                    params: {},
                  });
                }, 1800);
              } else {
                if (
                  this.data.emailAnalist &&
                  this.data.passwAnalist != res.data[0].passwAnalist
                ) {
                  this.msg1 =
                    "¡Verifique la información ingresada e intente nuevamente!";
                  //this.$store.commit("getflagLogin", redFlag);
                  this.$store.commit({
                    type: "getflagLogin",
                    redFlag: true,
                  });
                  setTimeout(() => {
                    this.isLoading = false;
                    this.$store.commit({
                      type: "getflagLogin",
                      redFlag: null,
                    });
                  }, 2000);
                }
              }
              ////CLG("respuesta usuario es", res.data);
              ////CLG("respuesta usuario es", res.data[0].passwAnalist);
            }

            //this.$store.commit("getreguser", res.data);
            //   this.$router.push({
            //     name: "DatosCv",
            //     params: { dataUser },
            //   });
            //   //CLG("llego hasta aquilani");
          })
          .catch(() => {
            // error.response.status Check status code
            //CLG("mission pelaste bolas");
            //CLG(error);
          })
          .finally(() => {
            //Perform action in always
          });
      } else {
        if (!this.data.emailAnalist || !this.data.passwAnalist)
          this.msg1 = "¡No pueden existir campos vacios, intente nuevamente!";
        //this.$store.commit("getflagLogin", redFlag);
        this.$store.commit({
          type: "getflagLogin",
          redFlag: true,
        });
        setTimeout(() => {
          this.isLoading = false;
          this.$store.commit({
            type: "getflagLogin",
            redFlag: null,
          });
        }, 2000);
      }
    },
  },
  data() {
    return {
      isLoading: false,
      msg: "",
      msg1: "",

      data: {
        emailAnalist: "",
        passwAnalist: "",
      },
    };
  },
};
</script>
